import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H5,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { GranulatFarbeBedarfVorschauDatenLadenResult, GranulatFarbeBedarfVorschauDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import { format } from 'date-fns';
import styles from './GranulatFarbeBedarfVorschauPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function GranulatFarbeBedarfVorschauPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.GranulatFarbeBedarfVorschauAnzeigen') {
          return GranulatFarbeBedarfVorschau;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function GranulatFarbeBedarfVorschau(props: CustomFormProps<GranulatFarbeBedarfVorschauDatenLadenResult>): JSX.Element {
  const payload = GranulatFarbeBedarfVorschauDatenLadenResultFromJSON(props.tokenPayload);
  return (
    <div>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <div className={styles.current_date}>{format(props.userTask.startedAt ?? new Date(), 'dd.LL.yyyy HH:mm')}</div>
      <div className={styles.bedarf_view}>
        <div className={styles.header}>
          <H5>Granulat-Farben-Fehlbestände<br /><br />für<br /><br />geplante PAUs (Plantafel-BL)</H5>
        </div>
        <div className={styles.content}>
          <HTMLTable>
            <thead>
              <tr>
                <th>Farbe</th>
                <th>Fehl-<br />Menge<br />[kg]</th>
                <th>Batch-Nr.</th>
                <th>PAU-Nr.</th>
                <th>Start-Datum</th>
                <th>Bedarf<br />[kg]</th>
                <th>Bestand<br />[kg]</th>
              </tr>
            </thead>
            <tbody>
              {payload.entries.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.granulat_farbe_nummer}: {entry.granulat_farbe_bezeichnung}</td>
                  <td>{entry.granulat_farbe_fehlmenge}</td>
                  <td>{entry.granulat_farbe_batch_nummer}</td>
                  <td className={styles.pau_nummer}>{entry.pau_nummer}</td>
                  <td>{entry.pau_datum_start ? format(entry.pau_datum_start, 'dd.LL.yy') : ''}</td>
                  <td>{entry.granulat_farbe_bedarf}</td>
                  <td>{entry.granulat_farbe_bestand}</td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </div>
      </div>
    </div>
  );
}
