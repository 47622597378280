import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  EtikettTrayDaten,
  EtikettTrayDatenTypEnum,
} from 'wacoplast_wws__api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './EtikettTrayPrint.module.scss';
import React from 'react';

export function EtikettTrayPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return EtikettTray;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function EtikettTray(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const werkstoffBezeichnung = payload.maschinenlaufplan.werkstoff_bezeichnung;
  const etikett_tray = payload.etikett_tray;

  if (!etikett_tray) return <></>;

  if (etikett_tray.typ === EtikettTrayDatenTypEnum.shb) {
    return <EtikettTraySHB etikett_tray={etikett_tray} />;
  }

  if (etikett_tray.typ === EtikettTrayDatenTypEnum.sonax) {
    return <EtikettTraySonax etikett_tray={etikett_tray} />;
  }

  return (
    <EtikettTrayDefault etikett_tray={etikett_tray} kantenschutz={payload.maschinenlaufplan.kantenschutz} werkstoffBezeichnung={werkstoffBezeichnung} />
  );
}

function EtikettTraySHB({ etikett_tray }: { etikett_tray: EtikettTrayDaten }): JSX.Element {
  const [showStapel2, setShowStapel2] = React.useState(false);
  return (
    <div className={styles.etikett_tray_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <Button className={styles.stapelselection_button} onClick={() => setShowStapel2(!showStapel2)}>Stapel {showStapel2 ? '1' : '2'} zeigen</Button>
      <HTMLTable className={styles.etikett_tray_table}>
        <tbody>
          <tr>
            <th colSpan={9} className={styles.right}>PAU:</th>
            <th>{etikett_tray.produktionsauftrag_nummer}</th>
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td colSpan={5} className={styles.larger}>{etikett_tray.flaschen_typ_nummer}</td>
            <td colSpan={5} className={styles.larger}>{etikett_tray.halsteil_bezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>Chargen-Nr: {etikett_tray.bestell_nummer}</td>
          </tr>
          <tr>
            <td colSpan={10}>Kunden-Auftrags-Nr.: {etikett_tray.empfaenger_auftrags_nummer} {etikett_tray.empfaenger_name}</td>
          </tr>
          <tr>
            <td colSpan={10}>Kunden-Artikel-Nr.: {etikett_tray.artikel_nummer_empfaenger}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>{etikett_tray.artikel_gewicht} g / {etikett_tray.farbe_nummer}_{etikett_tray.farbe_bezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>{showStapel2 ? etikett_tray.vpe_je_stapel_2 : etikett_tray.vpe_je_stapel_1} x {etikett_tray.flaschen_pro_vpe} = {(showStapel2 ? etikett_tray.vpe_je_stapel_2 ?? 0 : etikett_tray.vpe_je_stapel_1) * etikett_tray.flaschen_pro_vpe} Stück</td>
          </tr>
          <tr>
            <td colSpan={5} className={styles.tall} />
            <td colSpan={5} rowSpan={3}><div className={styles.big_box}>Etikett<br />mit<br />Paletten-Nummer</div></td>
          </tr>
          <tr>
            <td colSpan={2} className={styles.small}><div className={styles.row}>Schicht: Früh:<div className={styles.check_box} /></div></td>
            <td colSpan={1} className={styles.small}><div className={styles.row}>Mittag:<div className={styles.check_box} /></div></td>
            <td colSpan={1} className={styles.small}><div className={styles.row}>Spät:<div className={styles.check_box} /></div></td>
          </tr>
          <tr>
            <td colSpan={4} className={styles.small}>Produktionsdatum: ___________________</td>
          </tr>
          <tr>
            <td colSpan={3} className={styles.small}><div className={styles.row}>Packer: <div className={styles.long_box} /></div></td>
            <td colSpan={3} />
            <td colSpan={4} className={styles.small}>Palettenhöhe: {showStapel2 ? etikett_tray.hoehe_stapel_2 : etikett_tray.hoehe_stapel_1} mm</td>
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}

function EtikettTraySonax({ etikett_tray }: { etikett_tray: EtikettTrayDaten }): JSX.Element {
  const [showStapel2, setShowStapel2] = React.useState(false);
  return (
    <div className={styles.etikett_tray_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <Button className={styles.stapelselection_button} onClick={() => setShowStapel2(!showStapel2)}>Stapel {showStapel2 ? '1' : '2'} zeigen</Button>
      <HTMLTable className={styles.etikett_tray_table}>
        <tbody>
          <tr>
            <th colSpan={9} className={styles.right}>PAU:</th>
            <th>{etikett_tray.produktionsauftrag_nummer}</th>
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td colSpan={10} className={styles.larger}>{etikett_tray.flaschen_typ_bezeichnung}</td>
          </tr>
          <tr />
          <tr>
            <td colSpan={4} className={styles.sonax_art_nr_header}>SONAX-Art.-Nr.:</td>
            <td colSpan={5} className={styles.large}>{etikett_tray.artikel_nummer_kunde}</td>
          </tr>
          <tr>
            <td colSpan={4} className={styles.farbe_header} />
            <td colSpan={5} className={styles.large} />
          </tr>
          <tr />
          <tr>
            <td colSpan={10} className={styles.larger}>{showStapel2 ? etikett_tray.vpe_je_stapel_2 ?? 0 : etikett_tray.vpe_je_stapel_1} x {etikett_tray.flaschen_pro_vpe} = {(showStapel2 ? etikett_tray.vpe_je_stapel_2 ?? 0 : etikett_tray.vpe_je_stapel_1) * etikett_tray.flaschen_pro_vpe} Stück</td>
          </tr>
          <tr />
          <tr>
            <td colSpan={4} className={styles.palettenhoehe_header}>Palettenhöhe [mm]:</td>
            <td colSpan={3} className={styles.large}>{showStapel2 ? etikett_tray.hoehe_stapel_2 ?? 0 : etikett_tray.hoehe_stapel_1}</td>
          </tr>
          <tr className={styles.tall} />
          <tr>
            <td colSpan={4} className={styles.small}>Produktionsdatum: ................</td>
            <td className={styles.small}>Schicht: Früh:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
            <td className={styles.small}>Mittag:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
            <td className={styles.small}>Nacht:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td className={styles.small}>Packer:</td>
            <td colSpan={3}><div className={styles.long_box} /></td>
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}

function EtikettTrayDefault({ etikett_tray, kantenschutz, werkstoffBezeichnung }: { etikett_tray: EtikettTrayDaten, kantenschutz: boolean, werkstoffBezeichnung: string }): JSX.Element {
  const [showStapel2, setShowStapel2] = React.useState(false);

  return (
    <div className={styles.etikett_tray_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <Button className={styles.stapelselection_button} onClick={() => setShowStapel2(!showStapel2)}>Stapel {showStapel2 ? '1' : '2'} zeigen</Button>
      <HTMLTable className={styles.etikett_tray_table}>
        <tbody>
          <tr>
            <th colSpan={9} className={styles.right}>PAU:</th>
            <th>{etikett_tray.produktionsauftrag_nummer}</th>
          </tr>
          <tr>
            <td colSpan={10} className={styles.larger}>{etikett_tray.flaschen_typ_bezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>{etikett_tray.flaschen_typ_nummer}.{etikett_tray.halsteil_nummer} {etikett_tray.mit_blindenzeichen ? 'm.BLZ' : 'o.BLZ'} {werkstoffBezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>{etikett_tray.artikel_gewicht} g / {etikett_tray.farbe_nummer}_{etikett_tray.farbe_bezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>{etikett_tray.halsteil_bezeichnung}</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.larger}>{showStapel2 ? etikett_tray.vpe_je_stapel_2 : etikett_tray.vpe_je_stapel_1} x {etikett_tray.flaschen_pro_vpe} = {(showStapel2 ? etikett_tray.vpe_je_stapel_2 ?? 0 : etikett_tray.vpe_je_stapel_1) * etikett_tray.flaschen_pro_vpe} Stück</td>
          </tr>
          <tr>
            <td colSpan={10} className={styles.large}>ART.-NR.:  {etikett_tray.artikel_nummer_kunde}</td>
          </tr>
          <tr>
            <th colSpan={3} className={styles.double_underline}>Stretcher-Nr.:</th>
            <td colSpan={2}>{etikett_tray.stretcher ? <>{kantenschutz ? '1, ' : ''}2, 3</> : null}</td>
            <th colSpan={3} className={styles.double_underline}>Palettenhöhe:</th>
            <td colSpan={2}>{showStapel2 ? etikett_tray.hoehe_stapel_2 : etikett_tray.hoehe_stapel_1} mm</td>
          </tr>
          <tr />
          <tr>
            <td colSpan={4} className={styles.small}>Produktionsdatum: ................</td>
            <td className={styles.small}>Schicht: Früh:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
            <td className={styles.small}>Mittag:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
            <td className={styles.small}>Nacht:</td>
            <td className={styles.small}><div className={styles.check_box} /></td>
          </tr>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr>
            <td className={styles.small}>Packer:</td>
            <td colSpan={3}><div className={styles.long_box} /></td>
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}
