import { Firma, FirmaTyp } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  StammdatenFieldEditorSimpleRendererProps,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
} from '../../infrastructure';

export function makeFirmaNummerEditor(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<Firma, number>) => {
    if (!props.stammdatenEntity.typ) {
      return makeTextEditorRenderer('Bitte zuerst Typ auswählen.', true)(props);
    }
    if (props.stammdatenEntity.typ !== FirmaTyp.Kunde) {
      return makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true)(props);
    }
    return makeNumberEditorRenderer()(props);
  };
}
