"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaschinenlaufplanErstellenModalResultToJSON = exports.MaschinenlaufplanErstellenModalResultFromJSONTyped = exports.MaschinenlaufplanErstellenModalResultFromJSON = void 0;
const runtime_1 = require("../runtime");
function MaschinenlaufplanErstellenModalResultFromJSON(json) {
    return MaschinenlaufplanErstellenModalResultFromJSONTyped(json, false);
}
exports.MaschinenlaufplanErstellenModalResultFromJSON = MaschinenlaufplanErstellenModalResultFromJSON;
function MaschinenlaufplanErstellenModalResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
    };
}
exports.MaschinenlaufplanErstellenModalResultFromJSONTyped = MaschinenlaufplanErstellenModalResultFromJSONTyped;
function MaschinenlaufplanErstellenModalResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bemerkung': value.bemerkung,
    };
}
exports.MaschinenlaufplanErstellenModalResultToJSON = MaschinenlaufplanErstellenModalResultToJSON;
