"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenToJSON = exports.ProduktionsauftragBlasenFromJSONTyped = exports.ProduktionsauftragBlasenFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProduktionsauftragBlasenFromJSON(json) {
    return ProduktionsauftragBlasenFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenFromJSON = ProduktionsauftragBlasenFromJSON;
function ProduktionsauftragBlasenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': json['nummer'],
        'datum_anlage': (new Date(json['datum_anlage'])),
        'status': _1.ProduktionsauftragStatusFromJSON(json['status']),
        'abwicklungspfad': _1.AbwicklungspfadProduktionsauftragFromJSON(json['abwicklungspfad']),
        'kundenauftrag_database_id': json['kundenauftrag_database_id'],
        'menge': json['menge'],
        'werkstoff_typ_1_database_id': json['werkstoff_typ_1_database_id'],
        'werkstoff_typ_1_version_id': json['werkstoff_typ_1_version_id'],
        'anteil_werkstoff_1': json['anteil_werkstoff_1'],
        'werkstoff_typ_2_database_id': !runtime_1.exists(json, 'werkstoff_typ_2_database_id') ? undefined : json['werkstoff_typ_2_database_id'],
        'werkstoff_typ_2_version_id': !runtime_1.exists(json, 'werkstoff_typ_2_version_id') ? undefined : json['werkstoff_typ_2_version_id'],
        'anteil_werkstoff_2': !runtime_1.exists(json, 'anteil_werkstoff_2') ? undefined : json['anteil_werkstoff_2'],
        'pau_start': !runtime_1.exists(json, 'pau_start') ? undefined : (new Date(json['pau_start'])),
        'pau_ende': !runtime_1.exists(json, 'pau_ende') ? undefined : (new Date(json['pau_ende'])),
        'vorgegebene_blasmaschine_database_id': !runtime_1.exists(json, 'vorgegebene_blasmaschine_database_id') ? undefined : json['vorgegebene_blasmaschine_database_id'],
        'vorgegebene_blasmaschine_version_id': !runtime_1.exists(json, 'vorgegebene_blasmaschine_version_id') ? undefined : json['vorgegebene_blasmaschine_version_id'],
        'blasmaschine_database_id': json['blasmaschine_database_id'],
        'blasmaschine_version_id': json['blasmaschine_version_id'],
        'blasdauer': json['blasdauer'],
        'fertig_vor_liefertermin': json['fertig_vor_liefertermin'],
        'nnn': json['nnn'],
        'zaehlerstand_umbau_start': !runtime_1.exists(json, 'zaehlerstand_umbau_start') ? undefined : json['zaehlerstand_umbau_start'],
        'zaehlerstand_produktion_start': !runtime_1.exists(json, 'zaehlerstand_produktion_start') ? undefined : json['zaehlerstand_produktion_start'],
        'zaehlerstand_produktion_ende': !runtime_1.exists(json, 'zaehlerstand_produktion_ende') ? undefined : json['zaehlerstand_produktion_ende'],
        'anzahl_nester': !runtime_1.exists(json, 'anzahl_nester') ? undefined : json['anzahl_nester'],
        'menge_gut': !runtime_1.exists(json, 'menge_gut') ? undefined : json['menge_gut'],
        'menge_gesamt': !runtime_1.exists(json, 'menge_gesamt') ? undefined : json['menge_gesamt'],
        'menge_einricht_ausschuss': !runtime_1.exists(json, 'menge_einricht_ausschuss') ? undefined : json['menge_einricht_ausschuss'],
        'menge_produktion_ausschuss': !runtime_1.exists(json, 'menge_produktion_ausschuss') ? undefined : json['menge_produktion_ausschuss'],
        'prozent_einricht_ausschuss': !runtime_1.exists(json, 'prozent_einricht_ausschuss') ? undefined : json['prozent_einricht_ausschuss'],
        'prozent_produktions_ausschuss': !runtime_1.exists(json, 'prozent_produktions_ausschuss') ? undefined : json['prozent_produktions_ausschuss'],
        'technische_unterbrechung': !runtime_1.exists(json, 'technische_unterbrechung') ? undefined : json['technische_unterbrechung'],
        'invalide_ausschusswerte': !runtime_1.exists(json, 'invalide_ausschusswerte') ? undefined : json['invalide_ausschusswerte'],
        'aktuelle_papiere_gedruckt': !runtime_1.exists(json, 'aktuelle_papiere_gedruckt') ? undefined : json['aktuelle_papiere_gedruckt'],
        'database_id': json['database_id'],
        'kundenauftrag': _1.KundenauftragFromJSON(json['kundenauftrag']),
        'werkstoff_typ_1': _1.WerkstoffTypFromJSON(json['werkstoff_typ_1']),
        'werkstoff_typ_2': !runtime_1.exists(json, 'werkstoff_typ_2') ? undefined : _1.WerkstoffTypFromJSON(json['werkstoff_typ_2']),
        'blasmaschine': _1.BlasmaschineFromJSON(json['blasmaschine']),
        'vorgegebene_blasmaschine': !runtime_1.exists(json, 'vorgegebene_blasmaschine') ? undefined : _1.BlasmaschineFromJSON(json['vorgegebene_blasmaschine']),
        'verpackungs_vorschrift': !runtime_1.exists(json, 'verpackungs_vorschrift') ? undefined : _1.VerpackungsVorschriftFromJSON(json['verpackungs_vorschrift']),
        'verpackungs_vorschrift_database_id': !runtime_1.exists(json, 'verpackungs_vorschrift_database_id') ? undefined : json['verpackungs_vorschrift_database_id'],
        'verpackungs_vorschrift_version_id': !runtime_1.exists(json, 'verpackungs_vorschrift_version_id') ? undefined : json['verpackungs_vorschrift_version_id'],
    };
}
exports.ProduktionsauftragBlasenFromJSONTyped = ProduktionsauftragBlasenFromJSONTyped;
function ProduktionsauftragBlasenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'datum_anlage': (value.datum_anlage.toISOString()),
        'status': _1.ProduktionsauftragStatusToJSON(value.status),
        'abwicklungspfad': _1.AbwicklungspfadProduktionsauftragToJSON(value.abwicklungspfad),
        'kundenauftrag_database_id': value.kundenauftrag_database_id,
        'menge': value.menge,
        'werkstoff_typ_1_database_id': value.werkstoff_typ_1_database_id,
        'werkstoff_typ_1_version_id': value.werkstoff_typ_1_version_id,
        'anteil_werkstoff_1': value.anteil_werkstoff_1,
        'werkstoff_typ_2_database_id': value.werkstoff_typ_2_database_id,
        'werkstoff_typ_2_version_id': value.werkstoff_typ_2_version_id,
        'anteil_werkstoff_2': value.anteil_werkstoff_2,
        'pau_start': value.pau_start === undefined ? undefined : (value.pau_start.toISOString().substr(0, 10)),
        'pau_ende': value.pau_ende === undefined ? undefined : (value.pau_ende.toISOString().substr(0, 10)),
        'vorgegebene_blasmaschine_database_id': value.vorgegebene_blasmaschine_database_id,
        'vorgegebene_blasmaschine_version_id': value.vorgegebene_blasmaschine_version_id,
        'blasmaschine_database_id': value.blasmaschine_database_id,
        'blasmaschine_version_id': value.blasmaschine_version_id,
        'blasdauer': value.blasdauer,
        'fertig_vor_liefertermin': value.fertig_vor_liefertermin,
        'nnn': value.nnn,
        'zaehlerstand_umbau_start': value.zaehlerstand_umbau_start,
        'zaehlerstand_produktion_start': value.zaehlerstand_produktion_start,
        'zaehlerstand_produktion_ende': value.zaehlerstand_produktion_ende,
        'anzahl_nester': value.anzahl_nester,
        'menge_gut': value.menge_gut,
        'menge_gesamt': value.menge_gesamt,
        'menge_einricht_ausschuss': value.menge_einricht_ausschuss,
        'menge_produktion_ausschuss': value.menge_produktion_ausschuss,
        'prozent_einricht_ausschuss': value.prozent_einricht_ausschuss,
        'prozent_produktions_ausschuss': value.prozent_produktions_ausschuss,
        'technische_unterbrechung': value.technische_unterbrechung,
        'invalide_ausschusswerte': value.invalide_ausschusswerte,
        'aktuelle_papiere_gedruckt': value.aktuelle_papiere_gedruckt,
        'database_id': value.database_id,
        'kundenauftrag': _1.KundenauftragToJSON(value.kundenauftrag),
        'werkstoff_typ_1': _1.WerkstoffTypToJSON(value.werkstoff_typ_1),
        'werkstoff_typ_2': _1.WerkstoffTypToJSON(value.werkstoff_typ_2),
        'blasmaschine': _1.BlasmaschineToJSON(value.blasmaschine),
        'vorgegebene_blasmaschine': _1.BlasmaschineToJSON(value.vorgegebene_blasmaschine),
        'verpackungs_vorschrift': _1.VerpackungsVorschriftToJSON(value.verpackungs_vorschrift),
        'verpackungs_vorschrift_database_id': value.verpackungs_vorschrift_database_id,
        'verpackungs_vorschrift_version_id': value.verpackungs_vorschrift_version_id,
    };
}
exports.ProduktionsauftragBlasenToJSON = ProduktionsauftragBlasenToJSON;
