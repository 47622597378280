import { CreateWerkstoffMischung, Werkstoff, WerkstoffMischung } from 'wacoplast_wws__api';
import {
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeStammdatenEntityRelatedDisabledEditor,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makeWerkstoffFilteredDropDown } from './WerkstoffFilteredDropDown';
import { makeAnteilNumberEditor } from './AnteilNumberEditor';


export type WerkstoffMischungVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function WerkstoffMischungVerwalten(props: WerkstoffMischungVerwaltenProps): JSX.Element {
  const werkstoffResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffe.getAllWerkstoffGet, props.services.werkstoffe);
  const werkstoffTypResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);
  const defaultWerkstoffTyp = werkstoffTypResult.data?.find(werkstoffTyp => werkstoffTyp.werkstoff_database_id === undefined);

  const editors: StammdatenFieldEditors<CreateWerkstoffMischung> = [
    {
      type: 'complex',
      fields: ['werkstoff_1_database_id', 'werkstoff_1_version_id'],
      renderer: makeVersionedDropDownPickerRenderer<WerkstoffMischung, Werkstoff>(werkstoffResult, (data) => {
        return { primaryTitle: data.bezeichnung ?? data.nummer, secondaryTitle: data.bezeichnung ? data.nummer : undefined };
      }, 'werkstoff_1', false, (data) => {
        return {
          werkstoff_1_database_id: data?.database_id,
          werkstoff_1_version_id: data?.version_id,
          werkstoff_typ_1_database_id: defaultWerkstoffTyp?.database_id,
          werkstoff_typ_1_version_id: defaultWerkstoffTyp?.version_id,
          werkstoff_typ_1: defaultWerkstoffTyp,
        };
      }),
      label: 'Werkstoff 1',
    },
    {
      type: 'complex',
      fields: ['werkstoff_typ_1_database_id', 'werkstoff_typ_1_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<WerkstoffMischung, unknown>(makeWerkstoffFilteredDropDown(werkstoffTypResult, (data) => {
        return { primaryTitle: data.bezeichnung ?? String(data.nummer), secondaryTitle: data.bezeichnung ? String(data.nummer) : undefined };
      }, 'werkstoff_typ_1', (werkstoff_mischung: WerkstoffMischung) => werkstoff_mischung.werkstoff_1_database_id ?? -1, false), (stammdatenEntity) => stammdatenEntity.werkstoff_1_database_id === undefined),
      label: 'Werkstofftyp 1',
    },
    {
      type: 'complex',
      fields: ['anteil_1'],
      renderer: makeAnteilNumberEditor(),
      label: 'Anteil 1 [%]',
    },
    {
      type: 'complex',
      fields: ['werkstoff_2_database_id', 'werkstoff_2_version_id'],
      renderer: makeVersionedDropDownPickerRenderer<WerkstoffMischung, Werkstoff>(werkstoffResult, (data) => {
        return { primaryTitle: data.bezeichnung ?? data.nummer, secondaryTitle: data.bezeichnung ? data.nummer : undefined };
      }, 'werkstoff_2', true, (data) => {
        return {
          werkstoff_2_database_id: data?.database_id,
          werkstoff_2_version_id: data?.version_id,
          werkstoff_typ_2_database_id: data ? defaultWerkstoffTyp?.database_id : undefined,
          werkstoff_typ_2_version_id: data ? defaultWerkstoffTyp?.version_id : undefined,
          anteil_1: data ? 50 : 100,
          anteil_2: data ? 50 : undefined,
        };
      }),
      label: 'Werkstoff 2',
    },
    {
      type: 'complex',
      fields: ['werkstoff_typ_2_database_id', 'werkstoff_typ_2_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<WerkstoffMischung, unknown>(makeWerkstoffFilteredDropDown(werkstoffTypResult, (data) => {
        return { primaryTitle: data.bezeichnung ?? String(data.nummer), secondaryTitle: data.bezeichnung ? String(data.nummer) : undefined };
      }, 'werkstoff_typ_2', (werkstoff_mischung: WerkstoffMischung) => werkstoff_mischung.werkstoff_2_database_id ?? -1, true), (stammdatenEntity) => stammdatenEntity.werkstoff_2_database_id === undefined),
      label: 'Werkstofftyp 2',
    },
    {
      type: 'simple',
      field: 'anteil_2',
      renderer: makeTextEditorRenderer(undefined, true),
      label: 'Anteil 2 [%]',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Werkstoff 1',
          colId: 'werkstoff_1',
          ...makeForeignKeyColumn('werkstoff_1', (data: WerkstoffMischung) => {
            return {
              description: data.werkstoff_1.bezeichnung,
              number: data.werkstoff_1.nummer,
            };
          }),
        },
        {
          headerName: 'WerkstoffTyp 1',
          colId: 'werkstoff_typ_1',
          ...makeForeignKeyColumn('werkstoff_typ_1', (data: WerkstoffMischung) => {
            return {
              description: data.werkstoff_typ_1.bezeichnung,
              number: String(data.werkstoff_typ_1.nummer),
            };
          }),
        },
        {
          headerName: 'Anteil Werkstoff 1 [%]',
          field: 'anteil_1',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'anteil_1',
        },
        {
          headerName: 'Werkstoff 2',
          colId: 'werkstoff_2',
          ...makeForeignKeyColumn('werkstoff_2', (data: WerkstoffMischung) => {
            return {
              description: data.werkstoff_2?.bezeichnung ?? '',
              number: data.werkstoff_2?.nummer,
            };
          }),
        },
        {
          headerName: 'WerkstoffTyp 2',
          colId: 'werkstoff_typ_2',
          ...makeForeignKeyColumn('werkstoff_typ_2', (data: WerkstoffMischung) => {
            return {
              description: data.werkstoff_typ_2?.bezeichnung ?? '',
              number: data.werkstoff_typ_2 ? String(data.werkstoff_typ_2?.nummer) : undefined,
            };
          }),
        },
        {
          headerName: 'Anteil Werkstoff 2 [%]',
          field: 'anteil_2',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'anteil_2',
        },
      ]}
      stammdatenService={{
        create: (data: CreateWerkstoffMischung) => props.services.werkstoffMischung.postWerkstoffMischungPost({ CreateWerkstoffMischung: data }),
        update: (data) => props.services.werkstoffMischung.putByDatabaseIdWerkstoffMischungDatabaseIdPut({ database_id: data.database_id, UpdateWerkstoffMischung: data }),
        delete: (data, ignoreIntegrity) => props.services.werkstoffMischung.deleteByDatabaseIdWerkstoffMischungDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.werkstoffMischung.restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.werkstoffMischung.getAllWerkstoffMischungGet({ include_deleted }),
        getHistory: (data) => props.services.werkstoffMischung.getHistoryWerkstoffMischungDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.werkstoffMischung.downloadExcelWerkstoffMischungExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.WerkstoffMischung}
      defaultEntityForCreation={{ anteil_1: 100 }}
    />
  );
}
