import { DateInput } from '@blueprintjs/datetime';
import { parse, startOfWeek } from 'date-fns';
import { LieferterminKennzeichen } from 'wacoplast_wws__api';
import { LieferterminKennzeichenKeyMapping } from '.';
import { CommonEditorProps, EnumDropDownPicker } from '..';
import { formatLiefertermin, parseDateString } from '../..';
import { commonDateInputProps } from '../inputs';
import styles from './LieferterminEditor.module.scss';


export type LieferterminEditorProps = CommonEditorProps<{
  liefertermin_soll: Date | null,
  liefertermin_kennzeichen: LieferterminKennzeichen | null,
}>;

function LieferterminSollEditor(props: LieferterminEditorProps): JSX.Element {
  if (props.value.liefertermin_kennzeichen === LieferterminKennzeichen.KW) {
    return (
      // eslint-disable-next-line @blueprintjs/no-deprecated-components
      <DateInput
        {...commonDateInputProps}
        disabled={props.disabled}
        placeholder='z. B. "KW 3"'
        minDate={startOfWeek(new Date(), { weekStartsOn: 1 })}
        onChange={(date) => {
          const startOfWeekDate = date ? startOfWeek(date, { weekStartsOn: 1 }) : null;
          props.onChange({
            liefertermin_soll: startOfWeekDate,
            liefertermin_kennzeichen: props.value.liefertermin_kennzeichen,
          });
        }}
        defaultValue={props.value.liefertermin_soll ?? undefined}
        formatDate={date => formatLiefertermin(LieferterminKennzeichen.KW, date)}
        parseDate={(str) => parse(str.toLowerCase().replace('kw ', ''), 'I', new Date())}
        dayPickerProps={{ firstDayOfWeek: 1, showWeekNumbers: true }}
        value={props.value.liefertermin_soll}
      />
    );
  }

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <DateInput
      {...commonDateInputProps}
      minDate={new Date()}
      disabled={props.value.liefertermin_kennzeichen !== LieferterminKennzeichen.T || props.disabled}
      placeholder={props.value.liefertermin_kennzeichen === LieferterminKennzeichen.T ? 'z. B. "24.06.2020"' : undefined}
      onChange={(date) => props.onChange({ liefertermin_soll: date, liefertermin_kennzeichen: props.value.liefertermin_kennzeichen })}
      defaultValue={props.value.liefertermin_soll ?? undefined}
      formatDate={date => formatLiefertermin(LieferterminKennzeichen.T, date)}
      parseDate={(str) => parseDateString(str)}
      timePrecision={undefined}
      value={props.value.liefertermin_soll}
    />
  );
}

export function LieferterminEditor(props: LieferterminEditorProps): JSX.Element {
  return (
    <div className={styles.liefertermin_editor}>
      <EnumDropDownPicker
        data={Object.values(LieferterminKennzeichen)}
        getDisplayData={(data) => LieferterminKennzeichenKeyMapping[data] ?? ''}
        value={props.value.liefertermin_kennzeichen}
        disabled={props.disabled}
        onChange={(data) => {
          if (data === LieferterminKennzeichen.W) {
            props.onChange({ liefertermin_kennzeichen: data, liefertermin_soll: null });
          } else if (data === LieferterminKennzeichen.KW) {
            const startOfWeekDate = props.value.liefertermin_soll && startOfWeek(props.value.liefertermin_soll, { weekStartsOn: 1 });
            props.onChange({ liefertermin_kennzeichen: data, liefertermin_soll: startOfWeekDate });
          } else {
            props.onChange({ liefertermin_kennzeichen: data, liefertermin_soll: props.value.liefertermin_soll });
          }
        }}
      />
      <LieferterminSollEditor {...props} />
    </div>
  );
}
