import { Card } from '@blueprintjs/core';
import React from 'react';
import { RequireClaims } from '../../infrastructure/RequireClaims';
import { stammdatenDialogs } from '../stammdatenDialogs';

import styles from './Dashboard.module.scss';

export type StammdatenNavigatorProps = {
  openStartDialog: (startDialogId: string) => void,
};

export class StammdatenNavigator extends React.Component<StammdatenNavigatorProps> {
  public render(): JSX.Element {
    return (
      <Card className={styles.stammdaten_navigator}>
        <Card className={styles.stammdaten_navigator_title}>Stammdaten</Card>
        {
          stammdatenDialogs.map(dialog => (
            <RequireClaims key={dialog.title} claims={dialog.readClaims} hideOnError>
              <Card key={dialog.title} onClick={() => this.props.openStartDialog(dialog.route)} interactive>{dialog.title}</Card>
            </RequireClaims>
          ))
        }
      </Card>
    );
  }
}
