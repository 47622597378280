"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVerpackungsMaterialToJSON = exports.CreateVerpackungsMaterialFromJSONTyped = exports.CreateVerpackungsMaterialFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateVerpackungsMaterialFromJSON(json) {
    return CreateVerpackungsMaterialFromJSONTyped(json, false);
}
exports.CreateVerpackungsMaterialFromJSON = CreateVerpackungsMaterialFromJSON;
function CreateVerpackungsMaterialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'material_gruppe': _1.MaterialGruppeFromJSON(json['material_gruppe']),
        'bezeichnung_lang': json['bezeichnung_lang'],
        'bezeichnung_kurz': json['bezeichnung_kurz'],
        'durchmesser_mm': !runtime_1.exists(json, 'durchmesser_mm') ? undefined : json['durchmesser_mm'],
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'gewicht_kg': !runtime_1.exists(json, 'gewicht_kg') ? undefined : json['gewicht_kg'],
    };
}
exports.CreateVerpackungsMaterialFromJSONTyped = CreateVerpackungsMaterialFromJSONTyped;
function CreateVerpackungsMaterialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'material_gruppe': _1.MaterialGruppeToJSON(value.material_gruppe),
        'bezeichnung_lang': value.bezeichnung_lang,
        'bezeichnung_kurz': value.bezeichnung_kurz,
        'durchmesser_mm': value.durchmesser_mm,
        'nummer': value.nummer,
        'gewicht_kg': value.gewicht_kg,
    };
}
exports.CreateVerpackungsMaterialToJSON = CreateVerpackungsMaterialToJSON;
