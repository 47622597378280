import { DataModels } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Callout,
  Collapse,
  H4,
  Switch,
} from '@blueprintjs/core';
import { ValueFormatterParams } from 'ag-grid-community';
import React, { useState } from 'react';
import {
  EmitableEventNames,
  Kundenauftrag,
  KundenauftragArt,
  KundenauftragStatus,
} from 'wacoplast_wws__api';
import {
  KundenauftragAnlegenModal,
  KundenauftragArtKeyMapping,
  KundenauftragEditierenModal,
  KundenauftragStatusKeyMapping,
} from '.';
import {
  AuthProviderContext,
  CollapseDots,
  DATE_COLUMN_OPTIONS,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGridRenderer,
  StammdatenPerformableRowActions,
  StartDialogProps,
  formatDateString,
  formatLiefertermin,
  makeForeignKeyColumn,
  numberValueFormatterGenAGGrid,
  useRunningProcesses,
  useUserTasks,
} from '../../infrastructure';
import { RequireClaims } from '../../infrastructure/RequireClaims';
import { ProcessConstants } from '../../processes';
import { defaultColumnState } from './defaultColumnConfiguration';
import styles from './KundenauftragVerwalten.module.scss';
import { KundenauftragAnsehenModal } from './KundenauftragAnsehenModal';
import { StornierenModals, renderStornierenModal, stornieren } from '..';


const QUERY_WAITING_USER_TASKS_FOR_ERFASSEN = {
  state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
  processModelId: [
    ProcessConstants['Produktionsauftrag erfassen'].processId,
    ProcessConstants['Produktionsauftrag erfassen Blasen'].processId,
    ProcessConstants['Produktionsauftrag erfassen Lager'].processId,
    ProcessConstants['Produktionsauftrag erfassen Extern'].processId,
    ProcessConstants['Produktionsauftrag durchführen Blasen'].processId,
    ProcessConstants['Kundenauftrag abwickeln'].processId,
  ],
};
const QUERY_RUNNING_PRODUKTIONSAUFTRAG_PROCESS_INSTANCES = [ProcessConstants['Produktionsauftrag durchführen'].processId];

export function KundenauftragVerwalten(props: PropsWithTransaction<PropsWithServices<StartDialogProps>>): JSX.Element {
  const runningProduktionsauftragProcessInstances = useRunningProcesses(props.atlasEngineClient, QUERY_RUNNING_PRODUKTIONSAUFTRAG_PROCESS_INSTANCES);
  const waitingUserTasksForErfassen = useUserTasks(props.atlasEngineClient, QUERY_WAITING_USER_TASKS_FOR_ERFASSEN).filter((userTask) => {

    const customForms = ['wacoplast-wws.RückmeldungVerpackungsVorschriftWählen'];

    return userTask.processModelId !== ProcessConstants['Produktionsauftrag durchführen Blasen'].processId || customForms.includes(userTask.userTaskConfig.customForm ?? '');
  });

  return <KundenauftragVerwaltenView {...props} waitingUserTasksForErfassen={waitingUserTasksForErfassen} runningProduktionsauftragProcessInstances={runningProduktionsauftragProcessInstances} />;
}

export type KundenauftragVerwaltenViewProps = PropsWithTransaction<PropsWithServices<StartDialogProps>> & {
  waitingUserTasksForErfassen: DataModels.FlowNodeInstances.UserTaskInstance[];
  runningProduktionsauftragProcessInstances: Array<DataModels.ProcessInstances.ProcessInstance>;
};

type ShownModal = null | {
  modal: 'create';
  correlationId: string;
} | {
  modal: 'continue';
  correlationId: string;
} | {
  modal: 'edit';
  kundenauftrag: Kundenauftrag;
} | {
  modal: 'inspect';
  kundenauftrag: Kundenauftrag;
} | {
  modal: StornierenModals,
};

type KundenauftragVerwaltenViewState = {
  stammdaten: Kundenauftrag[] | null;
  showModal: ShownModal;
  displayFinished: boolean;
};

class KundenauftragVerwaltenView extends React.Component<KundenauftragVerwaltenViewProps, KundenauftragVerwaltenViewState> {

  constructor(props: KundenauftragVerwaltenViewProps) {
    super(props);
    this.state = {
      stammdaten: null,
      showModal: null,
      displayFinished: false,
    };
  }

  private updateStammdaten(changes: Array<Kundenauftrag>): void {
    const databaseIdsToFilter = changes.map(change => change.database_id);
    const stammdaten = this.state.stammdaten?.filter(entity => !databaseIdsToFilter.includes(entity.database_id)) ?? [];
    const stammdatenChanges = changes
      .filter((changedEntity, index) => index === changes.findIndex(entity => entity.database_id === changedEntity.database_id) && (this.state.displayFinished || ![KundenauftragStatus.NUMBER_6, KundenauftragStatus.NUMBER_7, KundenauftragStatus.NUMBER_8, KundenauftragStatus.NUMBER_9].includes(changedEntity.status)));
    this.setState({ stammdaten: [...stammdaten, ...stammdatenChanges] });
  }

  public componentDidMount(): void {
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Kundenauftrag, (data) => this.updateStammdaten(data.affected_entities as any));
    this.loadData();
  }

  private handleDisplayFinishedEntriesChanged = (value: boolean): void => {
    this.setState({ displayFinished: value }, () => this.loadData());
  };

  public loadData(): Promise<void> {
    return this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const stammdaten = await this.props.services.kundenauftrag.getAllKundenauftragGet({ include_finished: this.state.displayFinished });
        this.setState({
          stammdaten,
        });
      },
    }).catch(e => {
      console.error('Error loading KUAUs', e);
    });
  }

  private openKundenauftragAnlegenModal = (): void => {
    this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const result = await this.props.atlasEngineClient.processModels.startProcessInstance({
          processModelId: ProcessConstants['Kundenauftrag abwickeln'].processId,
        });

        this.setState({
          showModal: {
            modal: 'create',
            correlationId: result.correlationId,
          },
        });
      },
    }).catch(e => {
      console.error('Error writing KUAU', e);
    });
  };

  private openKundenauftragEditierenModal = (entity: Kundenauftrag): void => {
    if (this.isEntityEditable(entity)) {
      this.setState({
        showModal: {
          modal: 'edit',
          kundenauftrag: entity,
        },
      });
    }
  };

  private openKundenauftragAnsehenModal = (entity: Kundenauftrag): void => {
    this.setState({
      showModal: {
        modal: 'inspect',
        kundenauftrag: entity,
      },
    });
  };

  private isEntityEditable = (entity: Kundenauftrag): boolean => {
    return ![KundenauftragStatus.NUMBER_7, KundenauftragStatus.NUMBER_8, KundenauftragStatus.NUMBER_9].includes(entity.status);
  };

  public render(): JSX.Element {
    const displayFinished = (
      <Switch
        onChange={(event) => {
          const checked = (event.target as any).checked;
          this.handleDisplayFinishedEntriesChanged(checked);
        }}
        checked={this.state.displayFinished}
        label='Fertige KUAUs anzeigen'
      />
    );

    return (
      <>
        {this.state.showModal?.modal && !['continue', 'edit', 'inspect', 'create'].includes(this.state.showModal?.modal) && renderStornierenModal(this.state.showModal?.modal as any ?? null, (modal) => this.setState({ showModal: { modal } }))}
        {this.state.showModal?.modal && ['create', 'continue'].includes(this.state.showModal.modal) && (
          <KundenauftragAnlegenModal
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
            transactionService={this.props.transactionService}
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlationId={(this.state.showModal as any).correlationId}
          />
        )}
        {this.state.showModal?.modal === 'edit' &&
          <KundenauftragEditierenModal
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            config={this.props.config}
            services={this.props.services}
            stammdatenEntity={this.state.showModal.kundenauftrag}
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
          />
        }
        {
          this.state.showModal?.modal === 'inspect' &&
          <KundenauftragAnsehenModal
            atlasEngineClient={this.props.atlasEngineClient}
            subscriptionEventService={this.props.subscriptionEventService}
            config={this.props.config}
            services={this.props.services}
            stammdatenEntity={this.state.showModal.kundenauftrag}
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
          />
        }
        <AuthProviderContext.Consumer>
          {(authProvider) => (
            <StammdatenAgGridRenderer
              title='Kundenauftrag verwalten / Produktionsauftrag anlegen'
              onCloseStartDialogClicked={() => this.props.closeStartDialog()}
              stammdaten={this.state.stammdaten}
              isVersioned={false}
              performableActions={[
                { type: 'custom', createElement: () => displayFinished },
                { type: 'exportExcel',
                  requiredClaims: ['read_kundenauftrag'],
                  action: () => {
                    this.props.services.kundenauftrag.downloadExcelKundenauftragExcelPost().then((result) => {
                      const link = document.createElement('a');
                      link.href = URL.createObjectURL(result);
                      link.download = 'exportKundenauftrag.xls';

                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  } },
                { requiredClaims: ['manipulate_kundenauftrag', 'manipulate_produktionsauftrag'], type: 'create', action: this.openKundenauftragAnlegenModal },
              ]}
              getPerformableRowActions={(entity: Kundenauftrag) => {
                const actions: StammdatenPerformableRowActions<Kundenauftrag> = [{ type: 'inspect', onPerform: () => this.openKundenauftragAnsehenModal(entity) }];
                if (this.isEntityEditable(entity)) {
                  actions.push({ requiredClaims: ['manipulate_kundenauftrag'], type: 'edit', onPerform: () => this.openKundenauftragEditierenModal(entity) });
                }
                const userTaskProduktionsauftragErfassen = this.props.waitingUserTasksForErfassen.find((userTask) => userTask.correlationId === entity.process_correlation_id && ['Activity_1h1gt80', 'Activity_0xa8wyu', 'Activity_09fuyau', 'Activity_1g9hx10'].includes(userTask.flowNodeId));
                if ([KundenauftragStatus.NUMBER_1, KundenauftragStatus.NUMBER_3, KundenauftragStatus.NUMBER_4].includes(entity.status)) {
                  actions.push({ requiredClaims: ['manipulate_kundenauftrag'],
                    label: 'Stornieren',
                    intent: 'warning',
                    type: 'custom',
                    onPerform: () => {
                      if (userTaskProduktionsauftragErfassen) {
                        stornieren(userTaskProduktionsauftragErfassen, 'pau_erstellung_abbrechen', this.props.atlasEngineClient, (modal) => this.setState({ showModal: { modal } }));
                      } else {
                        this.setState({ showModal: { modal: 'stornieren' } });
                        let finishedStornierungen = 0;
                        ['produktionsauftrag_lager_stornieren', 'produktionsauftrag_extern_stornieren', 'produktionsauftrag_blasen_stornieren'].forEach(signalName => {
                          stornieren({ correlationId: entity.process_correlation_id! } as any, signalName, this.props.atlasEngineClient, (modal) => {
                            if (modal !== 'stornieren') {
                              finishedStornierungen++;
                            }
                            if (finishedStornierungen === 3) {
                              this.setState({ showModal: { modal: 'continue', correlationId: entity.process_correlation_id! } });
                            }
                          });
                        });
                      }
                    } });
                }
                return actions;
              }}
              defaultColumnState={defaultColumnState}
              authProvider={authProvider.authProvider}
              columnDefs={[
                {
                  headerName: 'Nummer',
                  field: 'nummer',
                  colId: 'nummer',
                  filter: 'agTextColumnFilter',
                  ...NUMMER_SORT_OPTS,
                },
                {
                  headerName: 'Datum-Eingang',
                  field: 'datum_eingang',
                  colId: 'datum_eingang',
                  valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Datum-Erfassung',
                  field: 'datum_erfassung',
                  colId: 'datum_erfassung',
                  valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Status',
                  field: 'status',
                  colId: 'status',
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    valueFormatter: ({ value }: { value: any }) => `${KundenauftragStatusKeyMapping[KundenauftragStatus[value]]} (${value})`,
                  },
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  valueFormatter: ({ value }: { value: any }) => `${KundenauftragStatusKeyMapping[KundenauftragStatus[value]]} (${value})`,
                },
                {
                  headerName: 'Liefertermin-Soll',
                  field: 'liefertermin_soll',
                  colId: 'liefertermin_soll',
                  valueFormatter: (val: ValueFormatterParams) =>
                    formatLiefertermin(val.data.liefertermin_kennzeichen, val.data.liefertermin_soll),
                  ...DATE_COLUMN_OPTIONS,
                },
                // TODO: Hier fehlt der Abwicklungspfad der KUAUs. Bei uns haben nur PAUs einen Abwicklungspfad
                {
                  headerName: 'Kunde',
                  colId: 'kunde',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.kunde.name_kurz,
                      number: data.artikel.kunde.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Bestell-Nummer',
                  field: 'bestell_nummer',
                  colId: 'bestell_nummer',
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Artikel',
                  colId: 'artikel',
                  valueGetter: ({ data }) => data.artikel.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'KU-Art-Nr.',
                  colId: 'artikel_nummer_des_kunden',
                  valueGetter: ({ data }) => data.artikel.artikel_nummer_des_kunden,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Auftragsart',
                  field: 'auftragsart',
                  colId: 'auftragsart',
                  filter: 'agTextColumnFilter',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  valueFormatter: (val: ValueFormatterParams) => KundenauftragArtKeyMapping[KundenauftragArt[val.value]],
                },
                {
                  headerName: 'Rahmenauftrag',
                  colId: 'rahmenauftrag_nummer',
                  valueGetter: ({ data }) => data.rahmenauftrag?.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'offene Menge / Bestellbestand',
                  colId: 'offene_menge',
                  valueGetter: ({ data }) => data.rahmenauftrag ? `${data.rahmenauftrag?.offene_menge} / ${data.rahmenauftrag?.bestellbestand}` : '',
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'gefertigt',
                  colId: 'gefertigt',
                  valueGetter: ({ data }) => data.rahmenauftrag?.gefertigte_menge,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Menge',
                  field: 'menge',
                  colId: 'menge',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Flaschentyp',
                  colId: 'flaschen_typ',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.flaschen_form.flaschentyp.bezeichnung,
                      number: data.artikel.flaschen_form.flaschentyp.nummer,
                    };
                  }),
                },
                {
                  headerName: 'BLZ',
                  colId: 'blinden_zeichen',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.blindenzeichen.bezeichnung,
                      number: data.artikel.blindenzeichen.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Vol. [ml]',
                  colId: 'volumen_ml',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.artikel.flaschen_form.flaschentyp.volumen,
                },
                {
                  headerName: 'Halsteil',
                  colId: 'hals_teil',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.flaschen_form.hals_teil.bezeichnung,
                      number: data.artikel.flaschen_form.hals_teil.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Gewicht [g]',
                  colId: 'gewicht_g',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.artikel.gewicht,
                },
                {
                  headerName: 'Farbe',
                  colId: 'farbe',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.granulat_farbe.bezeichnung,
                      number: data.artikel.granulat_farbe.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Batch-Nr.',
                  colId: 'batch_nummer',
                  valueGetter: ({ data }) => data.artikel.granulat_farbe.batch_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Farb-%',
                  colId: 'einfaerbungs_grad',
                  valueGetter: ({ data }) => data.artikel.einfaerbungs_grad,
                  valueFormatter: numberValueFormatterGenAGGrid(1),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Farb-Bedarf [kg]',
                  colId: 'farb_bedarf',
                  valueGetter: ({ data }) => data.farb_bedarf,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'F-Bestand [kg]',
                  colId: 'lagerbestand',
                  valueGetter: ({ data }) => data.artikel.granulat_farbe.bestand,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Werkstoff 1',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: `${data.artikel.werkstoff_mischung.anteil_1}% ${data.artikel.werkstoff_mischung.werkstoff_1.bezeichnung}`,
                      number: data.artikel.werkstoff_mischung.werkstoff_1.nummer,
                    };
                  }),
                  colId: 'werkstoff_1',
                },
                {
                  headerName: 'WerkstoffTyp 1',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.werkstoff_typ_1.bezeichnung ?? '',
                      number: String(data.artikel.werkstoff_typ_1.nummer),
                    };
                  }),
                  colId: 'werkstoff_typ_1',
                },
                {
                  headerName: 'Werkstoff 2',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.werkstoff_mischung.werkstoff_2 ? `${data.artikel.werkstoff_mischung.anteil_2}% ${data.artikel.werkstoff_mischung.werkstoff_2.bezeichnung}` : '',
                      number: data.artikel.werkstoff_mischung.werkstoff_2?.nummer,
                    };
                  }),
                  colId: 'werkstoff_2',
                },
                {
                  headerName: 'WerkstoffTyp 2',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.werkstoff_typ_2?.bezeichnung ?? '',
                      number: data.artikel.werkstoff_typ_2 ? String(data.artikel.werkstoff_typ_2?.nummer) : '',
                    };
                  }),
                  colId: 'werkstoff_typ_2',
                },
                {
                  headerName: 'Empfänger',
                  colId: 'empfaenger',
                  ...makeForeignKeyColumn('artikel', (data: Kundenauftrag) => {
                    return {
                      description: data.artikel.empfaenger?.name_kurz ?? '',
                      number: data.artikel.empfaenger?.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Empfänger-Auftragsnummer',
                  field: 'auftragsnummer_des_empfaenger',
                  colId: 'auftragsnummer_des_empfaenger',
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger Artikel Nr',
                  colId: 'artikel_nummer_des_empfaengers',
                  valueGetter: ({ data }) => data.artikel.artikel_nummer_des_empfaengers,
                  filter: 'agTextColumnFilter',
                },
                // TODO: Hier fehlt BLM (SHB) und mögliche BLM (WACO) der KUAUs. Sind bei uns im PAU...
                // Änderungsnummer
                // Eingangsdatum (vorher)
                // Erfassungsdatum (vorher)
                // Menge (vorher)
                // LT (vorher)
                // Artikel (vorher)
                // Anlieferort Nr (vorher)
                // Datum letzte Status Änderung
                {
                  headerName: 'Gut-Menge',
                  field: 'versandte_menge',
                  colId: 'versandte_menge',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                // Bereitgestellte Menge (roh-FL) {nicht bei R-AU}
                // Bereitgestellte Menge (bedruckt) {nicht bei R-AU}
              ]}
              transactionService={this.props.transactionService}
            >
              <RequireClaims hideOnError claims={['manipulate_kundenauftrag', 'manipulate_produktionsauftrag']}>
                <CalloutWaitingUserTasksForErfassen
                  kundenauftraege={this.state.stammdaten ?? []}
                  waitingUserTasksForErfassen={this.props.waitingUserTasksForErfassen}
                  onKundenauftragErfassenClicked={(process_correlation_id) => {
                    this.setState({
                      showModal: {
                        modal: 'continue',
                        correlationId: process_correlation_id,
                      },
                    });
                  }}
                />
              </RequireClaims>
            </StammdatenAgGridRenderer>
          )}
        </AuthProviderContext.Consumer>
      </>
    );
  }
}

type CalloutWaitingUserTasksForErfassenProps = {
  kundenauftraege: Kundenauftrag[];
  waitingUserTasksForErfassen: DataModels.FlowNodeInstances.UserTaskInstance[];
  onKundenauftragErfassenClicked: (process_correlation_id: string) => void;
};

function CalloutWaitingUserTasksForErfassen(props: CalloutWaitingUserTasksForErfassenProps): JSX.Element | null {
  const [showPending, setShowPending] = useState(false);

  const userTasks = props.kundenauftraege.map((kundenauftrag) => {
    const process_correlation_id = kundenauftrag.process_correlation_id;
    if (!process_correlation_id) {
      return null;
    }

    const userTasks = props.waitingUserTasksForErfassen.filter((x) => x.correlationId === process_correlation_id);
    if (userTasks.length === 0) {
      return null;
    }

    return (
      <li key={kundenauftrag.database_id}>
        PAU zu Kundenauftrag <b>{kundenauftrag.nummer}</b> ist noch nicht fertig angelegt:
        <ul>
          {userTasks.map((userTask) => (
            <li key={userTask.flowNodeInstanceId}>
              {userTask.flowNodeName}
              {' '}
              <Button
                small
                onClick={() => props.onKundenauftragErfassenClicked(process_correlation_id)}
              >
                Bearbeitung fortsetzen
              </Button>
            </li>
          ))}
        </ul>
      </li>
    );
  }).filter((x) => x !== null);

  if (userTasks.length === 0) {
    return null;
  }

  return (
    <Callout intent='warning' className={styles.kundenauftraege_pending_callout}>
      <H4>
        Nicht vollständig angelegte Produktionsaufträge <CollapseDots onClick={() => setShowPending(!showPending)} />
      </H4>
      <Collapse isOpen={showPending}>
        <div>
          <p>Das Anlegen der folgenden Produktionsaufträge wurde unterbrochen. Um die Aufträge anzulegen, bitte die folgenden Dialoge abschließen:</p>
          <ul>
            {userTasks}
          </ul>
        </div>
      </Collapse>
    </Callout>
  );
}
