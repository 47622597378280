"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReducedFlaschenFormToJSON = exports.ReducedFlaschenFormFromJSONTyped = exports.ReducedFlaschenFormFromJSON = void 0;
const _1 = require("./");
function ReducedFlaschenFormFromJSON(json) {
    return ReducedFlaschenFormFromJSONTyped(json, false);
}
exports.ReducedFlaschenFormFromJSON = ReducedFlaschenFormFromJSON;
function ReducedFlaschenFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'database_id': json['database_id'],
        'version_id': json['version_id'],
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'flaschentyp': _1.ReducedFlaschentypFromJSON(json['flaschentyp']),
        'hals_teil': _1.ReducedHalsTeilFromJSON(json['hals_teil']),
    };
}
exports.ReducedFlaschenFormFromJSONTyped = ReducedFlaschenFormFromJSONTyped;
function ReducedFlaschenFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'database_id': value.database_id,
        'version_id': value.version_id,
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'flaschentyp': _1.ReducedFlaschentypToJSON(value.flaschentyp),
        'hals_teil': _1.ReducedHalsTeilToJSON(value.hals_teil),
    };
}
exports.ReducedFlaschenFormToJSON = ReducedFlaschenFormToJSON;
