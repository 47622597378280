import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H2,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { format } from 'date-fns';
import { UmbauplanDatenLadenResult, UmbauplanDatenLadenResultFromJSON, UmbauplanDatenLadenResultSortierkriteriumEnum } from 'wacoplast_wws__api';

import styles from './UmbauplanPrint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

export function UmbauplanPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.UmbauplanAnzeigen') {
          return GranulatFarbeBedarfVorschau;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function GranulatFarbeBedarfVorschau(props: CustomFormProps<UmbauplanDatenLadenResult>): JSX.Element {
  const payload = UmbauplanDatenLadenResultFromJSON(props.tokenPayload);
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.header__datetime}>
          {props.userTask.startedAt ? format(props.userTask.startedAt, 'dd.MM.yyyy') : ''}
          <br />
          {props.userTask.startedAt ? format(props.userTask.startedAt, 'HH:mm') : ''}
        </div>
        <H2>BLM &nbsp; &nbsp; UMBAU - PLAN</H2>
      </div>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      {payload.sortierkriterium === UmbauplanDatenLadenResultSortierkriteriumEnum.Datum
        ? <UmbauplanNachDatum {...props} tokenPayload={payload} />
        : <UmbauplanNachBLM {...props} tokenPayload={payload} />
      }
    </div>
  );
}

function UmbauplanNachDatum(props: CustomFormProps<UmbauplanDatenLadenResult>): JSX.Element {
  return (
    <HTMLTable className={styles.content}>
      <thead>
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th className={styles.centered_cell} colSpan={4}><H2><b>{format(props.tokenPayload.start, 'dd.MM.yyyy')} - {format(props.tokenPayload.end, 'dd.MM.yyyy')}</b></H2></th>
        </tr>
        <tr className={styles.table_header}>
          <th className={styles.centered_cell}><b>Datum</b></th>
          <th className={styles.centered_cell}><b>BLM</b></th>
          <th />
          <th className={styles.centered_cell}><b>FL-TYP</b></th>
          <th className={styles.centered_cell}>FL-Bezeichnung</th>
          <th />
          <th><b>Halsteil</b></th>
          <th className={styles.centered_cell}><b>Details zu Halsteil</b></th>
        </tr>
      </thead>
      <tbody>
        {props.tokenPayload.entries.map((entry, index) => (
          <Fragment key={index}>
            {index > 0 && entry.datum.getDate() !== props.tokenPayload.entries[index - 1]?.datum.getDate()
              ? (
                <tr className={styles.empty_row}>
                  <td colSpan={8} />
                </tr>
              )
              : null
            }
            <tr>
              <td className={styles.centered_cell}>{format(entry.datum, 'dd.MM.yy')}</td>
              <td className={styles.centered_cell}>{entry.blasmaschine_nummer}</td>
              <td className={styles.centered_cell}>{entry.umbauart}</td>
              <td>{entry.flaschen_typ_nummer}</td>
              <td>{entry.flaschen_typ_bezeichnung}</td>
              <td className={styles.centered_cell}>mit</td>
              <td>{entry.halsteil_bezeichnung}</td>
              <td>{entry.halsteil_verwendung}{entry.halsteil_verwendung || entry.halsteil_bemerkung ? '; ' : ''}{entry.halsteil_bemerkung}</td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </HTMLTable>
  );
}

function UmbauplanNachBLM(props: CustomFormProps<UmbauplanDatenLadenResult>): JSX.Element {
  return (
    <HTMLTable className={styles.content}>
      <thead>
        <tr>
          <th />
          <th />
          <th />
          <th className={styles.centered_cell} colSpan={5}><H2><b>{format(props.tokenPayload.start, 'dd.MM.yyyy')} - {format(props.tokenPayload.end, 'dd.MM.yyyy')}</b></H2></th>
        </tr>
        <tr className={styles.table_header}>
          <th className={styles.centered_cell}><b>BLM</b></th>
          <th />
          <th className={styles.centered_cell}><b>FL-TYP</b></th>
          <th className={styles.centered_cell}>FL-Bezeichnung</th>
          <th />
          <th><b>Halsteil</b></th>
          <th className={styles.centered_cell}><b>Details zu Halsteil</b></th>
          <th className={styles.centered_cell}><b>Datum</b></th>
        </tr>
      </thead>
      <tbody>
        {props.tokenPayload.entries.sort(
          (a, b) => parseInt(a.blasmaschine_nummer) - parseInt(b.blasmaschine_nummer) || a.datum.getTime() - b.datum.getTime(),
        ).map((entry, index) => (
          <Fragment key={index}>
            {index > 0 && entry.blasmaschine_nummer !== props.tokenPayload.entries[index - 1]?.blasmaschine_nummer
              ? (
                <tr className={styles.empty_row}>
                  <td colSpan={8} />
                </tr>
              )
              : null
            }
            <tr>
              <td className={styles.centered_cell}>{entry.blasmaschine_nummer}</td>
              <td className={styles.centered_cell}>{entry.umbauart}</td>
              <td>{entry.flaschen_typ_nummer}</td>
              <td>{entry.flaschen_typ_bezeichnung}</td>
              <td className={styles.centered_cell}>mit</td>
              <td>{entry.halsteil_bezeichnung}</td>
              <td>{entry.halsteil_verwendung}{entry.halsteil_verwendung || entry.halsteil_bemerkung ? '; ' : ''}{entry.halsteil_bemerkung}</td>
              <td className={styles.centered_cell}>{format(entry.datum, 'dd.MM.yy')}</td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </HTMLTable>
  );
}
