import { CreateExtruderTemperatur, ExtruderTemperatur } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type ExtruderTemperaturVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function ExtruderTemperaturVerwalten(props: ExtruderTemperaturVerwaltenProps): JSX.Element {
  const werkstoffMischungResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffMischung.getAllWerkstoffMischungGet, props.services.werkstoffMischung);

  const editors: StammdatenFieldEditors<CreateExtruderTemperatur> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['werkstoff_mischung_database_id', 'werkstoff_mischung_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(werkstoffMischungResult, (data) => ({
        id: data.database_id,
        primaryTitle: `${data.anteil_1}% ${data.werkstoff_1.bezeichnung}${data.werkstoff_2 ? ` / ${data.anteil_2}% ${data.werkstoff_2.bezeichnung}` : ''}`,
        secondaryTitle: `${data.werkstoff_1.nummer}${data.werkstoff_2 ? ` / ${data.werkstoff_2.nummer}` : ''}`,
      }), 'werkstoff_mischung'),
      label: 'Werkstoff-Mischung',
    },
    {
      type: 'simple',
      field: 'extruder_temperatur',
      renderer: makeNumberEditorRenderer(),
      label: 'Extruder Temperatur [°C]',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Werkstoff-Mischung',
          colId: 'werkstoff',
          ...makeForeignKeyColumn('werkstoff_mischung', (data: ExtruderTemperatur) => {
            return {
              description: `${data.werkstoff_mischung.anteil_1}% ${data.werkstoff_mischung.werkstoff_1.bezeichnung}${data.werkstoff_mischung.werkstoff_2 ? ` / ${data.werkstoff_mischung.anteil_2}% ${data.werkstoff_mischung.werkstoff_2.bezeichnung}` : ''}`,
              number: `${data.werkstoff_mischung.werkstoff_1.nummer}${data.werkstoff_mischung.werkstoff_2 ? ` / ${data.werkstoff_mischung.werkstoff_2.nummer}` : ''}`,
            };
          }),
        },
        {
          headerName: 'Extruder Temperatur [°C]',
          field: 'extruder_temperatur',
          colId: 'extruder_temperatur',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
      ]}
      stammdatenService={{
        create: (data: CreateExtruderTemperatur) => props.services.extruder_temperatur.postStellblattExtruderTemperaturPost({ CreateExtruderTemperatur: data }),
        update: (data) => props.services.extruder_temperatur.putByDatabaseIdStellblattExtruderTemperaturDatabaseIdPut({ database_id: data.database_id, UpdateExtruderTemperatur: data }),
        delete: (data, ignoreIntegrity) => props.services.extruder_temperatur.deleteByDatabaseIdStellblattExtruderTemperaturDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.extruder_temperatur.restoreByDatabaseIdStellblattExtruderTemperaturDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.extruder_temperatur.getAllStellblattExtruderTemperaturGet({ include_deleted }),
        getHistory: (data: ExtruderTemperatur) => props.services.extruder_temperatur.getHistoryStellblattExtruderTemperaturDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.extruder_temperatur.lockByDatabaseIdStellblattExtruderTemperaturDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.extruder_temperatur.unlockByDatabaseIdStellblattExtruderTemperaturDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
        export: () => props.services.extruder_temperatur.downloadExcelStellblattExtruderTemperaturExcelPost(),
      }}
      subscriptionEventService={props.subscriptionEventService}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.ExtruderTemperatur}
    />
  );
}
