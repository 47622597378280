/* eslint-disable react/display-name */
import { DateInput } from '@blueprintjs/datetime';
import React from 'react';
import {
  Firma,
  Kundenauftrag,
  KundenauftragArt,
  KundenauftragStatus,
  UpdateKundenauftrag,
} from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DateEditor,
  ModalProps,
  PropsWithServices,
  StammdatenFieldEditorSimpleRendererProps,
  StammdatenFieldEditors,
  StammdatenModal,
  UseFetchLikeServiceFunctionResult,
  getValidatedDateTimePickerComponentValue,
  makeLieferterminEditorRenderer,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { makeArtikelDropDownPickerRenderer } from '../rahmenauftrag';
import { calculateMinDateWithLimit } from './calculateDateWithLimit';
import { MAX_DAYS_FOR_AUFTRAGSEINGANG } from './constants';

export type KundenauftragEditierenModalProps = PropsWithServices<ModalProps<boolean>> & {
  stammdatenEntity: Kundenauftrag;
};

export function KundenauftragEditierenModal(props: KundenauftragEditierenModalProps): JSX.Element {
  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);

  return <KundenauftragEditierenModalCard {...props} kundenResult={kundenResult} />;
}

export class KundenauftragEditierenModalCard extends React.Component<KundenauftragEditierenModalProps & {
  kundenResult: UseFetchLikeServiceFunctionResult<Array<Firma>>
}> {
  private getFields(): StammdatenFieldEditors<UpdateKundenauftrag> {
    function makeDatumEingangEditorRenderer(): any {
      return (props: StammdatenFieldEditorSimpleRendererProps<Kundenauftrag, Date | null>) => (
        <DateEditor
          minDate={calculateMinDateWithLimit(MAX_DAYS_FOR_AUFTRAGSEINGANG, props.stammdatenEntity.datum_erfassung)}
          maxDate={props.stammdatenEntity.datum_erfassung}
          disabled={props.disabled}
          onChange={(date) => props.onChange(date)}
          value={props.value}
          ref={React.createRef<DateInput>()}
        />
      );
    }

    const auftragWhileProductionEditors: StammdatenFieldEditors<UpdateKundenauftrag> = [
      {
        type: 'simple',
        field: 'nummer',
        label: 'Nummer',
        renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      },
      {
        type: 'simple',
        field: 'menge',
        label: 'Menge',
        renderer: makeNumberEditorRenderer(),
      },
      {
        type: 'complex',
        fields: ['liefertermin_soll', 'liefertermin_kennzeichen'],
        renderer: makeLieferterminEditorRenderer(),
        label: 'Liefertermin',
      },
    ];

    const abrufauftragBeforeInArbeitEditors: StammdatenFieldEditors<UpdateKundenauftrag> = [
      ...auftragWhileProductionEditors,
      {
        type: 'simple',
        field: 'bestell_nummer',
        label: 'Bestellnummer',
        renderer: makeTextEditorRenderer(),
      },
      {
        type: 'simple',
        field: 'datum_eingang',
        label: 'Datum-Eingang',
        renderer: makeDatumEingangEditorRenderer(),
      },
      {
        type: 'simple',
        field: 'auftragsnummer_des_empfaenger',
        label: 'Empfänger-Auftragsnummer',
        renderer: makeTextEditorRenderer(),
      }];

    const allEditors: StammdatenFieldEditors<UpdateKundenauftrag> = [
      ...abrufauftragBeforeInArbeitEditors,
      {
        type: 'complex',
        fields: ['artikel_database_id', 'artikel_version_id'],
        renderer: makeArtikelDropDownPickerRenderer(this.props.services.artikel, this.props.kundenResult),
      },
    ];

    const auftragsart = this.props.stammdatenEntity.auftragsart;
    const status = this.props.stammdatenEntity.status;

    if (auftragsart === KundenauftragArt.A && [KundenauftragStatus.NUMBER_1, KundenauftragStatus.NUMBER_3].includes(status)) {
      return abrufauftragBeforeInArbeitEditors;
    } else if (auftragsart !== KundenauftragArt.A && (status === KundenauftragStatus.NUMBER_1 || status === KundenauftragStatus.NUMBER_3)) {
      return allEditors;
    } else if ([KundenauftragStatus.NUMBER_4, KundenauftragStatus.NUMBER_5, KundenauftragStatus.NUMBER_6].includes(status)) {
      return auftragWhileProductionEditors;
    }
    return [];
  }

  render(): JSX.Element {

    const fields = this.getFields();

    if (fields.length === 0) {
      return (
        <StammdatenModal
          dialogTitle='Editieren nicht mehr möglich'
          fields={[]}
          onDialogConfirmed={async () => undefined}
          onModalFinished={() => this.props.onModalFinished(false)}
          primaryButtonIntent='primary'
          primaryButtonTitle='Verstanden'
          stammdatenEntity={{}}
          hideChangingReason
        />
      );
    }

    return (
      <StammdatenModal
        dialogTitle='Kundenauftrag editieren'
        fields={fields}
        onDialogConfirmed={async (entity) => this.props.services.kundenauftrag.putByDatabaseIdKundenauftragDatabaseIdPut({
          UpdateKundenauftrag: {
            ...entity,
            liefertermin_soll: getValidatedDateTimePickerComponentValue(entity.liefertermin_soll),
            datum_eingang: getValidatedDateTimePickerComponentValue(entity.datum_eingang),
            datum_erfassung: getValidatedDateTimePickerComponentValue(entity.datum_erfassung),
          },
          database_id: entity.database_id,
        })}
        onModalFinished={(result) => this.props.onModalFinished(result)}
        primaryButtonIntent='primary'
        primaryButtonTitle='Speichern'
        stammdatenEntity={this.props.stammdatenEntity}
        hideChangingReason
      />
    );
  }
}
