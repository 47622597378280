"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsplanDatenLadenResultToJSON = exports.ProduktionsplanDatenLadenResultFromJSONTyped = exports.ProduktionsplanDatenLadenResultFromJSON = void 0;
const _1 = require("./");
function ProduktionsplanDatenLadenResultFromJSON(json) {
    return ProduktionsplanDatenLadenResultFromJSONTyped(json, false);
}
exports.ProduktionsplanDatenLadenResultFromJSON = ProduktionsplanDatenLadenResultFromJSON;
function ProduktionsplanDatenLadenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tables': (json['tables'].map(_1.ProduktionsplanTableFromJSON)),
    };
}
exports.ProduktionsplanDatenLadenResultFromJSONTyped = ProduktionsplanDatenLadenResultFromJSONTyped;
function ProduktionsplanDatenLadenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tables': (value.tables.map(_1.ProduktionsplanTableToJSON)),
    };
}
exports.ProduktionsplanDatenLadenResultToJSON = ProduktionsplanDatenLadenResultToJSON;
