import { Dialog, H2 } from '@blueprintjs/core';
import { RueckmeldungProduktionsdatenErfassenResult, RueckmeldungProudktionswerteValidierenToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  TextViewer,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungProudktionswerteValidieren(
  props: CustomFormProps<RueckmeldungProduktionsdatenErfassenResult &
  { anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Sind die Produktionswerte korrekt?</H2>
        <DialogFormField fieldLabel='Menge Gut' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_gut.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Einricht-Ausschuss' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_einricht_ausschuss.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Produktions-Ausschuss' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_produktion_ausschuss.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Gesamt' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_gesamt.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Einrichtausschuss [%]' fieldLocation=''>
          <TextViewer value={`${props.tokenPayload.prozent_einricht_ausschuss.toFixed(2)}%`} />
        </DialogFormField>
        <DialogFormField fieldLabel='Produktionsausschuss [%]' fieldLocation=''>
          <TextViewer value={`${props.tokenPayload.prozent_produktions_ausschuss.toFixed(2)}%`} />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => props.finishUserTask(RueckmeldungProudktionswerteValidierenToJSON({ produktionswerte_korrekt: false }))} disabled={props.disabled}>Nein, korrigieren</AsyncButton>
        <AsyncButton onClick={() => props.finishUserTask(RueckmeldungProudktionswerteValidierenToJSON({ produktionswerte_korrekt: true }))} disabled={props.disabled} intent='primary'>Ja</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
