import { Identity } from '@atlas-engine/atlas_engine_client';
import { PropsWithChildren, useContext, useEffect } from 'react';
import {
  NavigateFunction,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { AuthProviderContext, StartDialogProps } from '.';
import { DialogStrings } from '../DialogStrings';
import { RequireClaims } from '../RequireClaims';
import { withServices } from '../withServices';
import { PropsWithServices } from '../withServices';

function makeStartDialogPropsProxy(identity: Identity | null, navigate: NavigateFunction): StartDialogProps {
  return {
    closeStartDialog: () => navigate('/'),
    openStartDialog: (dialog: typeof DialogStrings[keyof typeof DialogStrings]['route']) => navigate(`/${dialog}`),
    getIdentity: () => identity as Identity,
    startDialogConfiguration: {
      body: '',
      id: '',
      startButtonTitle: '',
      title: '',
      url: '',
    },
    startProcess: (processModelId: string) => {
      throw new Error('startProcess not implemented');
    },
  };
}

export type StartDialogRoutesProps = {
  dialogs: { [startDialog in typeof DialogStrings[keyof typeof DialogStrings]['route']]: React.ComponentClass<StartDialogProps> | React.FunctionComponent<StartDialogProps>}
  additionalRoutes: Array<{
    path: string;
    requiredClaims: Array<string>;
    element: React.ComponentClass<PropsWithServices<{ getIdentity: () => Identity }>> | React.FunctionComponent<PropsWithServices<{ getIdentity: () => Identity }>>;
    documentTitle?: string;
  }>;
};

export function StartDialogRoutes(props: StartDialogRoutesProps): JSX.Element {
  const authServiceContext = useContext(AuthProviderContext);
  const navigate = useNavigate();

  const identity = authServiceContext.authProvider.getIdentity() ?? null;

  const DocumentTitleSetter = (props: PropsWithChildren<{ title: string }>): JSX.Element => {
    useEffect(() => {
      document.title = props.title;
      return () => {
        document.title = 'Wacoplast-WWS';
      };
    }, [props.title]);
    return <>{props.children}</>;
  };

  return (
    <Routes>
      {
        [
          ...Object.entries(props.dialogs).map(([startDialogId, StartDialog]) => {
            const url = `/${startDialogId}`;
            const dialogProps = makeStartDialogPropsProxy(identity, navigate);
            const dialogStrings = Object.values(DialogStrings).find((dialogString) => dialogString.route === startDialogId);
            const requiredClaims = dialogStrings?.readClaims ?? [];

            return (
              <Route
                key={startDialogId}
                path={url}
                element={(
                  <RequireClaims key={startDialogId} claims={requiredClaims}>
                    <DocumentTitleSetter title={dialogStrings?.title ?? 'Wacoplast-WWS'}>
                      <StartDialog {...dialogProps} />
                    </DocumentTitleSetter>
                  </RequireClaims>
                )}
              />
            );
          }),
          ...props.additionalRoutes.map(additionalRoute => {
            const Element = withServices(additionalRoute.element);
            return (
              <Route
                key={additionalRoute.path}
                path={additionalRoute.path}
                element={(
                  <RequireClaims key={additionalRoute.path} claims={additionalRoute.requiredClaims}>
                    <DocumentTitleSetter title={additionalRoute.documentTitle ?? 'Wacoplast-WWS'}>
                      <Element getIdentity={() => identity as Identity} />
                    </DocumentTitleSetter>
                  </RequireClaims>
                )}
              />
            );
          })]
      }
    </Routes>
  );
}
