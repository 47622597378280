"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlaschenTypApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FlaschenTypApi extends runtime.BaseAPI {
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFlaschenTypDatabaseIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling deleteByDatabaseIdFlaschenTypDatabaseIdDelete.');
            }
            if (requestParameters.DeleteRequest === null || requestParameters.DeleteRequest === undefined) {
                throw new runtime.RequiredError('DeleteRequest', 'Required parameter requestParameters.DeleteRequest was null or undefined when calling deleteByDatabaseIdFlaschenTypDatabaseIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DeleteRequestToJSON(requestParameters.DeleteRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdFlaschenTypDatabaseIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteByDatabaseIdFlaschenTypDatabaseIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Download Excel
     */
    downloadExcelFlaschenTypExcelPostRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/excel`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Download Excel
     */
    downloadExcelFlaschenTypExcelPost(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.downloadExcelFlaschenTypExcelPostRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllFlaschenTypGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FlaschenTypFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllFlaschenTypGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllFlaschenTypGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFlaschenTypDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getByDatabaseIdFlaschenTypDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdFlaschenTypDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByDatabaseIdFlaschenTypDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get History
     */
    getHistoryFlaschenTypDatabaseIdHistoryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getHistoryFlaschenTypDatabaseIdHistoryGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}/history`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FlaschenTypFromJSON));
        });
    }
    /**
     * Get History
     */
    getHistoryFlaschenTypDatabaseIdHistoryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHistoryFlaschenTypDatabaseIdHistoryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFlaschenTypDatabaseIdLockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling lockByDatabaseIdFlaschenTypDatabaseIdLockPatch.');
            }
            if (requestParameters.LockRequest === null || requestParameters.LockRequest === undefined) {
                throw new runtime.RequiredError('LockRequest', 'Required parameter requestParameters.LockRequest was null or undefined when calling lockByDatabaseIdFlaschenTypDatabaseIdLockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}/lock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LockRequestToJSON(requestParameters.LockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdFlaschenTypDatabaseIdLockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lockByDatabaseIdFlaschenTypDatabaseIdLockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Post
     */
    postFlaschenTypPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.CreateFlaschenTyp === null || requestParameters.CreateFlaschenTyp === undefined) {
                throw new runtime.RequiredError('CreateFlaschenTyp', 'Required parameter requestParameters.CreateFlaschenTyp was null or undefined when calling postFlaschenTypPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CreateFlaschenTypToJSON(requestParameters.CreateFlaschenTyp),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Post
     */
    postFlaschenTypPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postFlaschenTypPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFlaschenTypDatabaseIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling putByDatabaseIdFlaschenTypDatabaseIdPut.');
            }
            if (requestParameters.UpdateFlaschenTyp === null || requestParameters.UpdateFlaschenTyp === undefined) {
                throw new runtime.RequiredError('UpdateFlaschenTyp', 'Required parameter requestParameters.UpdateFlaschenTyp was null or undefined when calling putByDatabaseIdFlaschenTypDatabaseIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateFlaschenTypToJSON(requestParameters.UpdateFlaschenTyp),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdFlaschenTypDatabaseIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putByDatabaseIdFlaschenTypDatabaseIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatch.');
            }
            if (requestParameters.RestoreRequest === null || requestParameters.RestoreRequest === undefined) {
                throw new runtime.RequiredError('RestoreRequest', 'Required parameter requestParameters.RestoreRequest was null or undefined when calling restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}/restore`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RestoreRequestToJSON(requestParameters.RestoreRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFlaschenTypDatabaseIdUnlockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling unlockByDatabaseIdFlaschenTypDatabaseIdUnlockPatch.');
            }
            if (requestParameters.UnlockRequest === null || requestParameters.UnlockRequest === undefined) {
                throw new runtime.RequiredError('UnlockRequest', 'Required parameter requestParameters.UnlockRequest was null or undefined when calling unlockByDatabaseIdFlaschenTypDatabaseIdUnlockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/flaschen_typ/{database_id}/unlock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UnlockRequestToJSON(requestParameters.UnlockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FlaschenTypFromJSON(jsonValue));
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdFlaschenTypDatabaseIdUnlockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unlockByDatabaseIdFlaschenTypDatabaseIdUnlockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.FlaschenTypApi = FlaschenTypApi;
