import { ValueFormatterParams } from 'ag-grid-community';
import { CreateQHinweisProdukt, QHinweisProdukt } from 'wacoplast_wws__api';
import {
  DATE_COLUMN_OPTIONS,
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatDateString,
  getValidatedDateTimePickerComponentValue,
  makeDateEditorRenderer,
  makeForeignKeyColumn,
  makeTextAreaEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type QHinweisProduktVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function QHinweisProduktVerwalten(props: QHinweisProduktVerwaltenProps): JSX.Element {

  const flaschentypResult = useFetchLikeGetAllServiceFunction(props.services.flaschenTyp.getAllFlaschenTypGet, props.services.flaschenTyp);

  const editors: StammdatenFieldEditors<CreateQHinweisProdukt> = [
    {
      type: 'complex',
      fields: ['flaschen_typ_database_id', 'flaschen_typ_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschentypResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'flaschen_typ'),
      label: 'Flaschentyp',
    },
    {
      type: 'simple',
      field: 'datum',
      renderer: makeDateEditorRenderer(),
      label: 'Datum',
    },
    {
      type: 'simple',
      field: 'hinweis_aus_reklamation',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Hinweis aus Reklamation',
    },
    {
      type: 'simple',
      field: 'erfolgte_korrekturmassnahmen',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Erfolgte Korrekturmaßnahmen',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      columnDefs={[
        {
          headerName: 'FlaschenTyp',
          colId: 'flaschen_typ',
          ...makeForeignKeyColumn('flaschen_typ', (data: QHinweisProdukt) => {
            return {
              description: data.flaschen_typ.bezeichnung,
              number: data.flaschen_typ.nummer,
            };
          }),
        },
        {
          headerName: 'Datum',
          field: 'datum',
          colId: 'datum',
          valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
          sort: 'asc',
          sortingOrder: ['asc', 'desc'],
          ...DATE_COLUMN_OPTIONS,
        },
        {
          headerName: 'Hinweis aus Reklamation',
          field: 'hinweis_aus_reklamation',
          colId: 'hinweis_aus_reklamation',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Erfolgte Korrektumassnahmen',
          field: 'erfolgte_korrekturmassnahmen',
          colId: 'erfolgte_korrekturmassnahmen',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateQHinweisProdukt) => props.services.qHinweis.postQHinweisProduktPost({
          CreateQHinweisProdukt: {
            ...data,
            datum: getValidatedDateTimePickerComponentValue(data.datum),
          },
        }),
        update: (data) => props.services.qHinweis.putByDatabaseIdQHinweisProduktDatabaseIdPut({
          database_id: data.database_id,
          UpdateQHinweisProdukt: {
            ...data,
            datum: getValidatedDateTimePickerComponentValue(data.datum),
          },
        }),
        delete: (data, ignoreIntegrity) => props.services.qHinweis.deleteByDatabaseIdQHinweisProduktDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.qHinweis.restoreByDatabaseIdQHinweisProduktDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.qHinweis.getAllQHinweisProduktGet({ include_deleted }),
        getHistory: (data) => props.services.qHinweis.getHistoryQHinweisProduktDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.qHinweis.downloadExcelQHinweisProduktExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      defaultColumnState={defaultColumnState}
      dialogStrings={DialogStrings.Qhinweisprodukt}
    />
  );
}
