"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerpackungsEinheitToJSON = exports.VerpackungsEinheitFromJSONTyped = exports.VerpackungsEinheitFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function VerpackungsEinheitFromJSON(json) {
    return VerpackungsEinheitFromJSONTyped(json, false);
}
exports.VerpackungsEinheitFromJSON = VerpackungsEinheitFromJSON;
function VerpackungsEinheitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'material_gruppe': _1.MaterialGruppeFromJSON(json['material_gruppe']),
        'bezeichnung_lang': json['bezeichnung_lang'],
        'bezeichnung_kurz': json['bezeichnung_kurz'],
        'durchmesser_mm': !runtime_1.exists(json, 'durchmesser_mm') ? undefined : json['durchmesser_mm'],
        'nummer': json['nummer'],
        'laenge_mm': !runtime_1.exists(json, 'laenge_mm') ? undefined : json['laenge_mm'],
        'breite_mm': !runtime_1.exists(json, 'breite_mm') ? undefined : json['breite_mm'],
        'pro_lage_bei_euro_palette': json['pro_lage_bei_euro_palette'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'gewicht_kg': !runtime_1.exists(json, 'gewicht_kg') ? undefined : json['gewicht_kg'],
    };
}
exports.VerpackungsEinheitFromJSONTyped = VerpackungsEinheitFromJSONTyped;
function VerpackungsEinheitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'material_gruppe': _1.MaterialGruppeToJSON(value.material_gruppe),
        'bezeichnung_lang': value.bezeichnung_lang,
        'bezeichnung_kurz': value.bezeichnung_kurz,
        'durchmesser_mm': value.durchmesser_mm,
        'nummer': value.nummer,
        'laenge_mm': value.laenge_mm,
        'breite_mm': value.breite_mm,
        'pro_lage_bei_euro_palette': value.pro_lage_bei_euro_palette,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'gewicht_kg': value.gewicht_kg,
    };
}
exports.VerpackungsEinheitToJSON = VerpackungsEinheitToJSON;
