"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GewichtskontrolleDatenToJSON = exports.GewichtskontrolleDatenFromJSONTyped = exports.GewichtskontrolleDatenFromJSON = void 0;
const runtime_1 = require("../runtime");
function GewichtskontrolleDatenFromJSON(json) {
    return GewichtskontrolleDatenFromJSONTyped(json, false);
}
exports.GewichtskontrolleDatenFromJSON = GewichtskontrolleDatenFromJSON;
function GewichtskontrolleDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'kunde_name': json['kunde_name'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'artikel_gewicht': json['artikel_gewicht'],
        'gewichtstoleranz': !runtime_1.exists(json, 'gewichtstoleranz') ? undefined : json['gewichtstoleranz'],
    };
}
exports.GewichtskontrolleDatenFromJSONTyped = GewichtskontrolleDatenFromJSONTyped;
function GewichtskontrolleDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'kunde_name': value.kunde_name,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'artikel_gewicht': value.artikel_gewicht,
        'gewichtstoleranz': value.gewichtstoleranz,
    };
}
exports.GewichtskontrolleDatenToJSON = GewichtskontrolleDatenToJSON;
