import { CreateHalsteilZuBlasform, HalsteilZuBlasform } from 'wacoplast_wws__api';
import {
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextAreaEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type HalsteilZuBlasformVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function HalsteilZuBlasformVerwalten(props: HalsteilZuBlasformVerwaltenProps): JSX.Element {
  const blasformResult = useFetchLikeGetAllServiceFunction(props.services.blasform.getAllStellblattBlasformGet, props.services.blasform);
  const halsTeilResult = useFetchLikeGetAllServiceFunction(props.services.halsTeil.getAllHalsTeilGet, props.services.halsTeil);
  const flaschentypResult = useFetchLikeGetAllServiceFunction(props.services.flaschenTyp.getAllFlaschenTypGet, props.services.flaschenTyp);
  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);

  const editors: StammdatenFieldEditors<CreateHalsteilZuBlasform> = [
    {
      type: 'complex',
      fields: ['blasform_database_id', 'blasform_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(blasformResult, (data) => ({ id: data.database_id, primaryTitle: data.nummer }), 'blasform'),
      label: 'Blasform',
    },
    {
      type: 'complex',
      fields: ['hals_teil_database_id', 'hals_teil_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(halsTeilResult, (data) => ({ id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer }), 'hals_teil'),
      label: 'Halsteil',
    },
    {
      type: 'complex',
      fields: ['flaschentyp_database_id', 'flaschentyp_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschentypResult, (data) => ({ id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer }), 'flaschentyp'),
      label: 'Flaschentyp',
    },
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kundenResult, (data) => ({ id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer }), 'kunde', true),
      label: 'Kunde',
    },
    {
      type: 'simple',
      field: 'bemerkung',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Bemerkung',
    },
    {
      type: 'simple',
      field: 'prioritaet',
      renderer: makeNumberEditorRenderer(),
      label: 'Priorität',
    },
    {
      type: 'simple',
      field: 'reservefeld_1',
      renderer: makeTextEditorRenderer(),
      label: 'Reservefeld 1',
    },
    {
      type: 'simple',
      field: 'reservefeld_2',
      renderer: makeTextEditorRenderer(),
      label: 'Reservefeld 2',
    },
    {
      type: 'simple',
      field: 'reservefeld_3',
      renderer: makeTextEditorRenderer(),
      label: 'Reservefeld 3',
    },
    {
      type: 'simple',
      field: 'reservefeld_4',
      renderer: makeTextEditorRenderer(),
      label: 'Reservefeld 4',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Blasform',
          field: 'blasform',
          colId: 'blasform',
          valueGetter: params => params.data.blasform.nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Halsteil',
          colId: 'hals_teil',
          ...makeForeignKeyColumn('hals_teil', (data: HalsteilZuBlasform) => {
            return {
              description: data.hals_teil.bezeichnung,
              number: data.hals_teil.nummer,
            };
          }),
        },
        {
          headerName: 'Flaschentyp',
          colId: 'flaschentyp',
          ...makeForeignKeyColumn('flaschentyp', (data: HalsteilZuBlasform) => {
            return {
              description: data.flaschentyp.bezeichnung,
              number: data.flaschentyp.nummer,
            };
          }),
        },
        {
          headerName: 'Kunde',
          colId: 'kunde',
          ...makeForeignKeyColumn('kunde', (data: HalsteilZuBlasform) => {
            return {
              description: data.kunde?.name_kurz ?? '',
              number: data.kunde?.nummer,
            };
          }),
        },
        {
          headerName: 'Bemerkung',
          field: 'bemerkung',
          colId: 'bemerkung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Priorität',
          field: 'prioritaet',
          colId: 'prioritaet',
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Reservefeld 1',
          field: 'reservefeld_1',
          colId: 'reservefeld_1',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reservefeld 2',
          field: 'reservefeld_2',
          colId: 'reservefeld_2',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reservefeld 2',
          field: 'reservefeld_3',
          colId: 'reservefeld_3',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reservefeld 4',
          field: 'reservefeld_4',
          colId: 'reservefeld_4',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateHalsteilZuBlasform) => props.services.halsteil_zu_blasform.postStellblattHalsteilZuBlasformPost({ CreateHalsteilZuBlasform: data }),
        update: (data) => props.services.halsteil_zu_blasform.putByDatabaseIdStellblattHalsteilZuBlasformDatabaseIdPut({ database_id: data.database_id, UpdateHalsteilZuBlasform: data }),
        delete: (data, ignoreIntegrity) => props.services.halsteil_zu_blasform.deleteByDatabaseIdStellblattHalsteilZuBlasformDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.halsteil_zu_blasform.restoreByDatabaseIdStellblattHalsteilZuBlasformDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.halsteil_zu_blasform.getAllStellblattHalsteilZuBlasformGet({ include_deleted }),
        getHistory: (data: HalsteilZuBlasform) => props.services.halsteil_zu_blasform.getHistoryStellblattHalsteilZuBlasformDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.halsteil_zu_blasform.lockByDatabaseIdStellblattHalsteilZuBlasformDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.halsteil_zu_blasform.unlockByDatabaseIdStellblattHalsteilZuBlasformDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
        export: () => props.services.halsteil_zu_blasform.downloadExcelStellblattHalsteilZuBlasformExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.HalsteilZuBlasform}
    />
  );
}
