import { AtlasEngineClient, DataModels } from '@atlas-engine/atlas_engine_client';
import { UserTaskInstance } from '@atlas-engine/atlas_engine_sdk';
import React, { PropsWithChildren } from 'react';
import { CustomFormProps, IdentityProvider } from '.';

export type RenderSequentialUserTasksProps = PropsWithChildren<{
  correlationId: string;
  atlasEngineClient: AtlasEngineClient;
  getIdentity: IdentityProvider;
  getComponent: (userTask: DataModels.FlowNodeInstances.UserTaskInstance) => React.ComponentType<CustomFormProps> | null;

  handleUserTaskFinished?: (api: RenderSequentialUserTasksApi, userTask: DataModels.FlowNodeInstances.UserTaskInstance, result: DataModels.FlowNodeInstances.UserTaskResult) => Promise<void>;
  handleUserTaskAborted?: (api: RenderSequentialUserTasksApi, userTask: DataModels.FlowNodeInstances.UserTaskInstance, result: any) => Promise<void>;
  handleUserTaskSuspended?: (api: RenderSequentialUserTasksApi, userTask: DataModels.FlowNodeInstances.UserTaskInstance) => Promise<void>;
}>;

export type RenderSequentialUserTasksState = {
  currentUserTask: DataModels.FlowNodeInstances.UserTaskInstance | null;
  customFormForCurrentUserTask: React.ComponentType<CustomFormProps> | null;
  userTaskActionsDisabled: boolean;
}

export type RenderSequentialUserTasksApi = {
  finishUserTask: (result: DataModels.FlowNodeInstances.UserTaskResult) => Promise<void>;
}

export class RenderSequentialUserTasks extends React.Component<RenderSequentialUserTasksProps, RenderSequentialUserTasksState> {

  private userTaskSubscription: any;

  constructor(props: RenderSequentialUserTasksProps) {
    super(props);
    this.state = {
      currentUserTask: null,
      customFormForCurrentUserTask: null,
      userTaskActionsDisabled: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.userTaskSubscription = await this.props.atlasEngineClient.userTasks.onUserTaskWaiting((userTask) => {
      if (userTask.correlationId === this.props.correlationId) {
        this.checkForNewUserTasks();
      }
    });

    this.checkForNewUserTasks();
  }

  public componentWillUnmount(): void {
    if (this.userTaskSubscription) {
      this.props.atlasEngineClient.userTasks.removeSubscription(null as any, this.userTaskSubscription);
    }
  }

  private async checkForNewUserTasks(): Promise<void> {
    const response = await this.props.atlasEngineClient.userTasks.query({
      correlationId: this.props.correlationId,
      state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
    });

    if (response.userTasks.length > 0) {
      this.onUserTasksFound(response.userTasks);
    }
  }

  private onUserTasksFound = (userTasks: DataModels.FlowNodeInstances.UserTaskInstance[]): void => {
    if (this.state.currentUserTask) {
      return;
    }

    for (const userTask of userTasks) {
      const component = this.props.getComponent(userTask);
      if (component) {
        this.setState({
          currentUserTask: userTask,
          customFormForCurrentUserTask: component,
        });
        continue;
      }
    }
  };

  private handleUserTaskFinished = async (result: DataModels.FlowNodeInstances.UserTaskResult): Promise<void> => {
    if (this.state.userTaskActionsDisabled) {
      return;
    }
    this.setState({
      userTaskActionsDisabled: true,
    });
    const currentUserTask = this.getActiveUserTask();

    if (this.props.handleUserTaskFinished) {
      await this.props.handleUserTaskFinished(this, currentUserTask, result);
    } else {
      await this.finishUserTask(result);
    }

    this.setState({
      userTaskActionsDisabled: false,
    });
  };

  private handleUserTaskAborted = async (result: any): Promise<void> => {
    if (this.state.userTaskActionsDisabled) {
      return;
    }
    this.setState({
      userTaskActionsDisabled: true,
    });
    const currentUserTask = this.getActiveUserTask();

    if (this.props.handleUserTaskAborted) {
      await this.props.handleUserTaskAborted(this, currentUserTask, result);
    } else {
      await this.abortUserTask(result);
    }

    this.setState({
      userTaskActionsDisabled: false,
    });
  };

  private abortUserTask = async (result: any): Promise<void> => {
    throw new Error('not implemented');
  };

  public finishUserTask = async (result: DataModels.FlowNodeInstances.UserTaskResult): Promise<void> => {
    const currentUserTask = this.getActiveUserTask();

    await this.props.atlasEngineClient.userTasks.finishUserTask(currentUserTask.flowNodeInstanceId, result);

    this.setState({
      currentUserTask: null,
      customFormForCurrentUserTask: null,
    });

    this.checkForNewUserTasks();
  };

  public render(): JSX.Element | React.ReactNode {
    if (this.state.customFormForCurrentUserTask && this.state.currentUserTask) {
      const CustomFormForUserTask = this.state.customFormForCurrentUserTask;
      const tokenPayload = this.state.currentUserTask?.tokens.find((t) => t.type === DataModels.FlowNodeInstances.ProcessTokenType.onEnter)?.payload;
      return (
        <CustomFormForUserTask
          abortUserTask={this.handleUserTaskAborted}
          finishUserTask={this.handleUserTaskFinished}
          getIdentity={this.props.getIdentity}
          userTask={this.state.currentUserTask}
          tokenPayload={tokenPayload}
          disabled={this.state.userTaskActionsDisabled}
        />
      );
    }

    return this.props.children;
  }

  private getActiveUserTask(): UserTaskInstance {
    if (!this.state.currentUserTask) {
      throw new Error('no current user task');
    }
    return this.state.currentUserTask;
  }

}

export function withBoundProps<TComponentProps extends TBoundProps, TBoundProps extends Partial<TComponentProps>>(Component: React.ComponentType<TComponentProps>, boundProps: TBoundProps) {
  // eslint-disable-next-line react/display-name
  return (props: Omit<TComponentProps, keyof TBoundProps>) => <Component {...boundProps as any} {...props} />;
}
