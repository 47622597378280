import {
  Button,
  Dialog,
  H2,
  H5,
} from '@blueprintjs/core';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  formatDateTimeString,
} from '../../../infrastructure';

import { ArbeitspapiereDatenLadenResult } from 'wacoplast_wws__api';
import styles from './ArbeitspapiereAnzeigen.module.scss';

export function ArbeitspapiereAnzeigen(props: PropsWithServices<CustomFormProps<ArbeitspapiereDatenLadenResult>> & ModalProps<void>): JSX.Element {
  const finish = async (): Promise<void> => {
    await props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog
      isOpen
      onClose={() => props.onModalFinished()}
      title='Papiere drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Die Arbeitspapiere können nun gedruckt werden.</H2>
        {props.userTask.startedAt && <H5>Generiert am: {formatDateTimeString(props.userTask.startedAt)}</H5>}
        <div className={styles.arbeitspapiere_buttons_grid}>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/maschinenlaufplan/${props.userTask.correlationId}`, '_blank')}>MLP ansehen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/stellblatt/${props.userTask.correlationId}`, '_blank')}>Stellblatt ansehen</Button>
          {props.tokenPayload.qhinweise.hinweise.length > 0 &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/qhinweise/${props.userTask.correlationId}`, '_blank')}>Q-Hinweise ansehen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/pruefprotokoll/${props.userTask.correlationId}`, '_blank')}>Prüfprotokoll ansehen</Button>
          {props.tokenPayload.packordnung &&
            <Button intent='none' onClick={() => window.open(`${props.config.restServiceUrl}/file_storage/${props.tokenPayload.packordnung}`, '_blank')}>Packordnung ansehen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/gewichtskontrolle/${props.userTask.correlationId}`, '_blank')}>Gewichtskontrolle ansehen</Button>
          {props.tokenPayload.begleitdokument &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/begleitdokument/${props.userTask.correlationId}`, '_blank')}>Begleitdokument ansehen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/vp_unterschriften/${props.userTask.correlationId}`, '_blank')}>VP-Unterschriften ansehen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/rueckmeldung1/${props.userTask.correlationId}`, '_blank')}>Rückmeldung1 ansehen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/rueckmeldung2/${props.userTask.correlationId}`, '_blank')}>Rückmeldung2 ansehen</Button>
          {props.tokenPayload.etikett_tray &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/etikett_tray/${props.userTask.correlationId}`, '_blank')}>Etikett-Tray ansehen</Button>
          }
          {props.tokenPayload.etikett_karton &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/etikett_karton/${props.userTask.correlationId}`, '_blank')}>Etikett-Karton ansehen</Button>
          }
          {props.tokenPayload.abmusterung &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/abmusterung/${props.userTask.correlationId}`, '_blank')}>Abmusterung ansehen</Button>
          }
        </div>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={props.onModalFinished}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
