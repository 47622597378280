import { EmitableEventNames } from 'wacoplast_wws__api';

export const DialogStrings: Record<'Artikel' | 'Blasform' | 'Blasmaschine' | 'BlasmaschineZeiten' | 'Blindenzeichen' | 'Dashboard' | 'EinstellparameterBlasform' | 'ExtruderTemperatur' | 'Fahrzeug' | 'FahrzeugZuKunde' | 'Firma' | 'Flaschenform' | 'Flaschentyp' | 'Gewichtstoleranz' | 'Granulatfarbe' | 'Halsteil' | 'HalsteilZuBlasform' | 'Kundenauftrag' | 'Lagerposten' | 'Lieferant' | 'Packordnung' | 'Plantafel' | 'Produktionsauftrag' | 'Qhinweisprodukt' | 'Rahmenauftrag' | 'StellblattAbstaende' | 'Verpackungseinheit' | 'Verpackungsmaterial' | 'Verpackungsvorschrift' | 'Werkstoff' | 'Werkstofftyp' | 'WerkstoffMischung', {
  title: string;
  subscriptionEventName?: EmitableEventNames;
  route: string;
  readClaims: string[];
  writeClaims: string[];
}> = {
  Artikel: {
    route: 'artikel',
    subscriptionEventName: EmitableEventNames.Artikel,
    title: 'Artikel',
    readClaims: ['read_artikel'],
    writeClaims: ['manipulate_artikel'] },
  Blasform: {
    route: 'blasform',
    subscriptionEventName: EmitableEventNames.Blasform,
    title: 'Blasformen',
    readClaims: ['read_blasform'],
    writeClaims: ['manipulate_blasform'] },
  Blasmaschine: {
    route: 'blasmaschine',
    subscriptionEventName: EmitableEventNames.Blasmaschine,
    title: 'BLM-Kavitäten',
    readClaims: ['read_blasmaschine'],
    writeClaims: ['manipulate_blasmaschine'] },
  BlasmaschineZeiten: {
    route: 'blasmaschinezeiten',
    subscriptionEventName: EmitableEventNames.BlasmaschineZeiten,
    title: 'BLM-Einstellungen',
    readClaims: ['read_blasmaschinezeiten'],
    writeClaims: ['manipulate_blasmaschinezeiten'] },
  Blindenzeichen: {
    route: 'blindenzeichen',
    subscriptionEventName: EmitableEventNames.Blindenzeichen,
    title: 'Blindenzeichen',
    readClaims: ['read_blindenzeichen'],
    writeClaims: ['manipulate_blindenzeichen'] },
  Dashboard: {
    route: '',
    title: 'Dashboard',
    readClaims: [],
    writeClaims: [] },
  EinstellparameterBlasform: {
    route: 'einstellparameterblasform',
    subscriptionEventName: EmitableEventNames.EinstellparameterBlasform,
    title: 'BLF-Zubehör-Optionen',
    readClaims: ['read_einstellparameterblasform'],
    writeClaims: ['manipulate_einstellparameterblasform'] },
  ExtruderTemperatur: {
    route: 'extrudertemperatur',
    subscriptionEventName: EmitableEventNames.ExtruderTemperatur,
    title: 'ExtruderTemperatur',
    readClaims: ['read_extrudertemperatur'],
    writeClaims: ['manipulate_extrudertemperatur'] },
  Fahrzeug: {
    route: 'fahrzeug',
    subscriptionEventName: EmitableEventNames.Fahrzeug,
    title: 'Fahrzeug',
    readClaims: ['read_fahrzeug'],
    writeClaims: ['manipulate_fahrzeug'] },
  FahrzeugZuKunde: {
    route: 'fahrzeugzukunde',
    subscriptionEventName: EmitableEventNames.FahrzeugZuKunde,
    title: 'FahrzeugZuKunde',
    readClaims: ['read_fahrzeugzukunde'],
    writeClaims: ['manipulate_fahrzeugzukunde'] },
  Firma: {
    route: 'firma',
    subscriptionEventName: EmitableEventNames.Firma,
    title: 'Kunde/Empfänger',
    readClaims: ['read_firma'],
    writeClaims: ['manipulate_firma'] },
  Flaschenform: {
    route: 'flaschenform',
    subscriptionEventName: EmitableEventNames.FlaschenForm,
    title: 'Flaschenform',
    readClaims: ['read_flaschenform'],
    writeClaims: ['manipulate_flaschenform'] },
  Flaschentyp: {
    route: 'flaschentyp',
    subscriptionEventName: EmitableEventNames.FlaschenTyp,
    title: 'Flaschentyp',
    readClaims: ['read_flaschentyp'],
    writeClaims: ['manipulate_flaschentyp'] },
  Gewichtstoleranz: {
    route: 'gewichtstoleranz',
    subscriptionEventName: EmitableEventNames.Gewichtstoleranz,
    title: 'Gewichtstoleranz',
    readClaims: ['read_gewichtstoleranz'],
    writeClaims: ['manipulate_gewichtstoleranz'] },
  Granulatfarbe: {
    route: 'granulatfarbe',
    subscriptionEventName: EmitableEventNames.GranulatFarbe,
    title: 'GranulatFarbe',
    readClaims: ['read_granulatfarbe'],
    writeClaims: ['manipulate_granulatfarbe'] },
  Halsteil: {
    route: 'halsteil',
    subscriptionEventName: EmitableEventNames.HalsTeil,
    title: 'HalsTeil',
    readClaims: ['read_halsteil'],
    writeClaims: ['manipulate_halsteil'] },
  HalsteilZuBlasform: {
    route: 'halsteilzublasform',
    subscriptionEventName: EmitableEventNames.HalsteilZuBlasform,
    title: 'BLF-Zubehör',
    readClaims: ['read_halsteilzublasform'],
    writeClaims: ['manipulate_halsteilzublasform'] },
  Kundenauftrag: {
    route: 'kundenauftrag',
    title: 'Kundenauftrag',
    readClaims: ['read_kundenauftrag'],
    writeClaims: ['manipulate_kundenauftrag'] },
  Lagerposten: {
    route: 'lagerposten',
    subscriptionEventName: EmitableEventNames.Lagerposten,
    title: 'Lagerposten',
    readClaims: ['read_lagerposten'],
    writeClaims: ['manipulate_lagerposten'] },
  Lieferant: {
    route: 'lieferant',
    subscriptionEventName: EmitableEventNames.Lieferant,
    title: 'Lieferant',
    readClaims: ['read_lieferant'],
    writeClaims: ['manipulate_lieferant'] },
  Packordnung: {
    route: 'packordnung',
    subscriptionEventName: EmitableEventNames.Packordnung,
    title: 'Packordnung',
    readClaims: ['read_packordnung'],
    writeClaims: ['manipulate_packordnung'] },
  Plantafel: {
    route: 'plantafel',
    title: 'Plantafel',
    readClaims: ['read_kundenauftrag', 'read_produktionsauftrag'],
    writeClaims: ['manipulate_kundenauftrag', 'manipulate_produktionsauftrag'] },
  Produktionsauftrag: {
    route: 'produktionsauftrag',
    title: 'Produktionsauftrag',
    readClaims: ['read_produktionsauftrag'],
    writeClaims: ['manipulate_produktionsauftrag'] },
  Qhinweisprodukt: {
    route: 'qhinweisprodukt',
    subscriptionEventName: EmitableEventNames.QHinweisProdukt,
    title: 'Q-Hinweis (Produkt)',
    readClaims: ['read_qhinweisprodukt'],
    writeClaims: ['manipulate_qhinweisprodukt'] },
  Rahmenauftrag: {
    route: 'rahmenauftrag',
    subscriptionEventName: EmitableEventNames.Rahmenauftrag,
    title: 'Rahmenauftrag',
    readClaims: ['read_rahmenauftrag'],
    writeClaims: ['manipulate_rahmenauftrag'] },
  StellblattAbstaende: {
    route: 'stellblattabstaende',
    subscriptionEventName: EmitableEventNames.Abstaende,
    title: 'Abst-Kopf-Messer-Form',
    readClaims: ['read_abstaende'],
    writeClaims: ['manipulate_abstaende'] },
  Verpackungseinheit: {
    route: 'verpackungseinheit',
    subscriptionEventName: EmitableEventNames.VerpackungsEinheit,
    title: 'Verpackungs-Einheit',
    readClaims: ['read_verpackungseinheit'],
    writeClaims: ['manipulate_verpackungseinheit'] },
  Verpackungsmaterial: {
    route: 'verpackungsmaterial',
    subscriptionEventName: EmitableEventNames.VerpackungsMaterial,
    title: 'Verpackungs-Material',
    readClaims: ['read_verpackungsmaterial'],
    writeClaims: ['manipulate_verpackungsmaterial'] },
  Verpackungsvorschrift: {
    route: 'verpackungsvorschrift',
    subscriptionEventName: EmitableEventNames.VerpackungsVorschrift,
    title: 'Verpackungs-Vorschrift',
    readClaims: ['read_verpackungsvorschrift'],
    writeClaims: ['manipulate_verpackungsvorschrift'] },
  Werkstoff: {
    route: 'werkstoff',
    subscriptionEventName: EmitableEventNames.Werkstoff,
    title: 'Werkstoff',
    readClaims: ['read_werkstoff'],
    writeClaims: ['manipulate_werkstoff'] },
  Werkstofftyp: {
    route: 'werkstofftyp',
    subscriptionEventName: EmitableEventNames.WerkstoffTyp,
    title: 'WerkstoffTyp',
    readClaims: ['read_werkstofftyp'],
    writeClaims: ['manipulate_werkstofftyp'] },
  WerkstoffMischung: {
    route: 'werkstoffmischung',
    subscriptionEventName: EmitableEventNames.WerkstoffMischung,
    title: 'Werkstoff-Mischung',
    readClaims: ['read_werkstoffmischung'],
    writeClaims: ['manipulate_werkstoffmischung'] },
};
