import { Identity } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';

import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  RenderSequentialUserTasks,
  withBoundProps,
  withServices,
} from '../../../infrastructure';
import { RueckstellMessprotokollAnzeigen } from '../../printables';

export type RueckstellMessprotkollModalProps = PropsWithServices<ModalProps<void>> & { correlation_id: string, getIdentity: () => Identity };

export function RueckstellMessprotokollModal(props: RueckstellMessprotkollModalProps): JSX.Element {
  return (
    <RenderSequentialUserTasks
      {...props}
      correlationId={props.correlation_id}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.RückstellMessprotokollAnzeigen') {
          return withBoundProps(withServices(RueckstellMessprotokollAnzeigen), { onModalFinished: props.onModalFinished });
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {<Button onClick={() => props.onModalFinished()} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

