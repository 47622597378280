"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PruefprotokollDatenToJSON = exports.PruefprotokollDatenFromJSONTyped = exports.PruefprotokollDatenFromJSON = void 0;
function PruefprotokollDatenFromJSON(json) {
    return PruefprotokollDatenFromJSONTyped(json, false);
}
exports.PruefprotokollDatenFromJSON = PruefprotokollDatenFromJSON;
function PruefprotokollDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'kunde_name': json['kunde_name'],
        'werkstoff_bezeichnung': json['werkstoff_bezeichnung'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
    };
}
exports.PruefprotokollDatenFromJSONTyped = PruefprotokollDatenFromJSONTyped;
function PruefprotokollDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'kunde_name': value.kunde_name,
        'werkstoff_bezeichnung': value.werkstoff_bezeichnung,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
    };
}
exports.PruefprotokollDatenToJSON = PruefprotokollDatenToJSON;
