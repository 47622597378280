import { LOCALE_DE } from './editors';

export const formatNumber = (
  value: number | null | undefined,
  fractionDigits?: number,
): string | null => {
  return value
    ? Intl.NumberFormat(LOCALE_DE, {
      minimumFractionDigits: fractionDigits,
    }).format(value)
    : null;
};
