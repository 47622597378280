/* eslint-disable react/display-name */

import { MaterialGruppe, VerpackungsEinheit } from 'wacoplast_wws__api';
import { EnumDropDownPicker, StammdatenFieldEditorComplexRendererProps, getEnumDisplayData } from '../../../infrastructure';

export function makeMaterialGruppeEditor(data: Array<MaterialGruppe>, getItemData: getEnumDisplayData<MaterialGruppe>): any {
  return (props: StammdatenFieldEditorComplexRendererProps<VerpackungsEinheit>) => {
    return (
      <EnumDropDownPicker
        data={data}
        getDisplayData={getItemData}
        onChange={(value) => props.onChange({
          material_gruppe: value ?? undefined,
          breite_mm: value !== MaterialGruppe.NUMBER_50 ? props.stammdatenEntity.breite_mm : undefined,
          laenge_mm: value !== MaterialGruppe.NUMBER_50 ? props.stammdatenEntity.laenge_mm : undefined,
        })}
        value={props.stammdatenEntity.material_gruppe}
        disabled={props.disabled}
      />
    );
  };
}
