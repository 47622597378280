"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaschinenlaufplanVorschauDatenLadenResultToJSON = exports.MaschinenlaufplanVorschauDatenLadenResultFromJSONTyped = exports.MaschinenlaufplanVorschauDatenLadenResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MaschinenlaufplanVorschauDatenLadenResultFromJSON(json) {
    return MaschinenlaufplanVorschauDatenLadenResultFromJSONTyped(json, false);
}
exports.MaschinenlaufplanVorschauDatenLadenResultFromJSON = MaschinenlaufplanVorschauDatenLadenResultFromJSON;
function MaschinenlaufplanVorschauDatenLadenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kundenauftrag_nummer': json['kundenauftrag_nummer'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'kundenauftrag_art': _1.KundenauftragArtFromJSON(json['kundenauftrag_art']),
        'kunde_name': json['kunde_name'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'bestelldatum': (new Date(json['bestelldatum'])),
        'kunde_bestell_nummer': !runtime_1.exists(json, 'kunde_bestell_nummer') ? undefined : json['kunde_bestell_nummer'],
        'empfaenger_auftrags_nummer': !runtime_1.exists(json, 'empfaenger_auftrags_nummer') ? undefined : json['empfaenger_auftrags_nummer'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'artikel_nummer_empfaenger': !runtime_1.exists(json, 'artikel_nummer_empfaenger') ? undefined : json['artikel_nummer_empfaenger'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'blindenzeichen_bezeichnung': json['blindenzeichen_bezeichnung'],
        'volumen': json['volumen'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'gewicht': json['gewicht'],
        'toleranz': !runtime_1.exists(json, 'toleranz') ? undefined : json['toleranz'],
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'liefertermin': !runtime_1.exists(json, 'liefertermin') ? undefined : (new Date(json['liefertermin'])),
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'farbe_nummer': json['farbe_nummer'],
        'farbe_batch_nummer': !runtime_1.exists(json, 'farbe_batch_nummer') ? undefined : json['farbe_batch_nummer'],
        'lagerplatz_nummer': !runtime_1.exists(json, 'lagerplatz_nummer') ? undefined : json['lagerplatz_nummer'],
        'einfaerbungsgrad': !runtime_1.exists(json, 'einfaerbungsgrad') ? undefined : json['einfaerbungsgrad'],
        'werkstoff_typ_1_bezeichnung': !runtime_1.exists(json, 'werkstoff_typ_1_bezeichnung') ? undefined : json['werkstoff_typ_1_bezeichnung'],
        'werkstoff_typ_2_bezeichnung': !runtime_1.exists(json, 'werkstoff_typ_2_bezeichnung') ? undefined : json['werkstoff_typ_2_bezeichnung'],
        'werkstoff_bezeichnung': json['werkstoff_bezeichnung'],
        'flaschen_pro_schicht': json['flaschen_pro_schicht'],
        'transportweg': _1.TransportwegFromJSON(json['transportweg']),
        'verpackungs_einheit_bezeichnung': json['verpackungs_einheit_bezeichnung'],
        'karton_aufdruck_art': !runtime_1.exists(json, 'karton_aufdruck_art') ? undefined : _1.AufdruckArtFromJSON(json['karton_aufdruck_art']),
        'vpe_zustand': _1.VerpackungZustandFromJSON(json['vpe_zustand']),
        'karton_geschlossen': !runtime_1.exists(json, 'karton_geschlossen') ? undefined : json['karton_geschlossen'],
        'karton_polybeutel': !runtime_1.exists(json, 'karton_polybeutel') ? undefined : json['karton_polybeutel'],
        'tray_abdeckung_zustand': !runtime_1.exists(json, 'tray_abdeckung_zustand') ? undefined : _1.VerpackungZustandFromJSON(json['tray_abdeckung_zustand']),
        'flaschen_pro_vpe': json['flaschen_pro_vpe'],
        'anzahl_lagen_1': json['anzahl_lagen_1'],
        'vpes_pro_stapel_1': json['vpes_pro_stapel_1'],
        'flaschen_pro_stapel_1': json['flaschen_pro_stapel_1'],
        'hoehe_stapel_1': json['hoehe_stapel_1'],
        'anzahl_stapel_1': json['anzahl_stapel_1'],
        'anzahl_lagen_2': !runtime_1.exists(json, 'anzahl_lagen_2') ? undefined : json['anzahl_lagen_2'],
        'vpes_pro_stapel_2': !runtime_1.exists(json, 'vpes_pro_stapel_2') ? undefined : json['vpes_pro_stapel_2'],
        'flaschen_pro_stapel_2': !runtime_1.exists(json, 'flaschen_pro_stapel_2') ? undefined : json['flaschen_pro_stapel_2'],
        'hoehe_stapel_2': !runtime_1.exists(json, 'hoehe_stapel_2') ? undefined : json['hoehe_stapel_2'],
        'anzahl_stapel_2': !runtime_1.exists(json, 'anzahl_stapel_2') ? undefined : json['anzahl_stapel_2'],
        'fuellweise': !runtime_1.exists(json, 'fuellweise') ? undefined : json['fuellweise'],
        'anzahl_lagen_vorgabe': !runtime_1.exists(json, 'anzahl_lagen_vorgabe') ? undefined : json['anzahl_lagen_vorgabe'],
        'anzahl_flaschen': json['anzahl_flaschen'],
        'anzahl_kartons': !runtime_1.exists(json, 'anzahl_kartons') ? undefined : json['anzahl_kartons'],
        'kantenschutz_material_bezeichnung': !runtime_1.exists(json, 'kantenschutz_material_bezeichnung') ? undefined : json['kantenschutz_material_bezeichnung'],
        'folie_material_bezeichnung': !runtime_1.exists(json, 'folie_material_bezeichnung') ? undefined : json['folie_material_bezeichnung'],
        'hoehe_ladeeinheit': !runtime_1.exists(json, 'hoehe_ladeeinheit') ? undefined : json['hoehe_ladeeinheit'],
        'anzahl_stellplaetze': json['anzahl_stellplaetze'],
        'bemerkung': json['bemerkung'],
        'fahrzeug_bezeichnung': json['fahrzeug_bezeichnung'],
        'fahrzeug_ladehoehe': json['fahrzeug_ladehoehe'],
    };
}
exports.MaschinenlaufplanVorschauDatenLadenResultFromJSONTyped = MaschinenlaufplanVorschauDatenLadenResultFromJSONTyped;
function MaschinenlaufplanVorschauDatenLadenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kundenauftrag_nummer': value.kundenauftrag_nummer,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'kundenauftrag_art': _1.KundenauftragArtToJSON(value.kundenauftrag_art),
        'kunde_name': value.kunde_name,
        'empfaenger_name': value.empfaenger_name,
        'bestelldatum': (value.bestelldatum.toISOString()),
        'kunde_bestell_nummer': value.kunde_bestell_nummer,
        'empfaenger_auftrags_nummer': value.empfaenger_auftrags_nummer,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'artikel_nummer_empfaenger': value.artikel_nummer_empfaenger,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'blindenzeichen_bezeichnung': value.blindenzeichen_bezeichnung,
        'volumen': value.volumen,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'gewicht': value.gewicht,
        'toleranz': value.toleranz,
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'liefertermin': value.liefertermin === undefined ? undefined : (value.liefertermin.toISOString()),
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'farbe_nummer': value.farbe_nummer,
        'farbe_batch_nummer': value.farbe_batch_nummer,
        'lagerplatz_nummer': value.lagerplatz_nummer,
        'einfaerbungsgrad': value.einfaerbungsgrad,
        'werkstoff_typ_1_bezeichnung': value.werkstoff_typ_1_bezeichnung,
        'werkstoff_typ_2_bezeichnung': value.werkstoff_typ_2_bezeichnung,
        'werkstoff_bezeichnung': value.werkstoff_bezeichnung,
        'flaschen_pro_schicht': value.flaschen_pro_schicht,
        'transportweg': _1.TransportwegToJSON(value.transportweg),
        'verpackungs_einheit_bezeichnung': value.verpackungs_einheit_bezeichnung,
        'karton_aufdruck_art': _1.AufdruckArtToJSON(value.karton_aufdruck_art),
        'vpe_zustand': _1.VerpackungZustandToJSON(value.vpe_zustand),
        'karton_geschlossen': value.karton_geschlossen,
        'karton_polybeutel': value.karton_polybeutel,
        'tray_abdeckung_zustand': _1.VerpackungZustandToJSON(value.tray_abdeckung_zustand),
        'flaschen_pro_vpe': value.flaschen_pro_vpe,
        'anzahl_lagen_1': value.anzahl_lagen_1,
        'vpes_pro_stapel_1': value.vpes_pro_stapel_1,
        'flaschen_pro_stapel_1': value.flaschen_pro_stapel_1,
        'hoehe_stapel_1': value.hoehe_stapel_1,
        'anzahl_stapel_1': value.anzahl_stapel_1,
        'anzahl_lagen_2': value.anzahl_lagen_2,
        'vpes_pro_stapel_2': value.vpes_pro_stapel_2,
        'flaschen_pro_stapel_2': value.flaschen_pro_stapel_2,
        'hoehe_stapel_2': value.hoehe_stapel_2,
        'anzahl_stapel_2': value.anzahl_stapel_2,
        'fuellweise': value.fuellweise,
        'anzahl_lagen_vorgabe': value.anzahl_lagen_vorgabe,
        'anzahl_flaschen': value.anzahl_flaschen,
        'anzahl_kartons': value.anzahl_kartons,
        'kantenschutz_material_bezeichnung': value.kantenschutz_material_bezeichnung,
        'folie_material_bezeichnung': value.folie_material_bezeichnung,
        'hoehe_ladeeinheit': value.hoehe_ladeeinheit,
        'anzahl_stellplaetze': value.anzahl_stellplaetze,
        'bemerkung': value.bemerkung,
        'fahrzeug_bezeichnung': value.fahrzeug_bezeichnung,
        'fahrzeug_ladehoehe': value.fahrzeug_ladehoehe,
    };
}
exports.MaschinenlaufplanVorschauDatenLadenResultToJSON = MaschinenlaufplanVorschauDatenLadenResultToJSON;
