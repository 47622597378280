import {
  Button,
  ControlGroup,
  Dialog,
  HTMLTable,
  Tag,
} from '@blueprintjs/core';
import { useCallback } from 'react';

import { VerpackungsVorschrift } from 'wacoplast_wws__api';
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  ModalProps,
  PropsWithServices,
  useFetchLikeServiceFunction,
} from '../../../infrastructure';
import styles from './Modal.module.scss';
import { AufdruckArtKeyMapping, TransportwegKeyMapping, VerpackungZustandKeyMapping } from '../../../stammdaten';

export function VerpackungsVorschriftTable(props: PropsWithServices<{ verpackungsVorschrift?: VerpackungsVorschrift | null }>): JSX.Element {
  const verpackungsVorschrift = props.verpackungsVorschrift;
  const packordnungServiceFunction = useCallback((initOverrides?: RequestInit) => props.services.packordnung.findPackordnungPackordnungFindPackordnungFlaschenFormDatabaseIdFlaschenFormVersionIdVerpackungsEinheitDatabaseIdVerpackungsEinheitVersionIdGet({
    flaschen_form_database_id: props.verpackungsVorschrift?.flaschen_form_database_id ?? -1,
    flaschen_form_version_id: props.verpackungsVorschrift?.flaschen_form_version_id ?? -1,
    verpackungs_einheit_database_id: props.verpackungsVorschrift?.verpackungs_einheit_database_id ?? -1,
    verpackungs_einheit_version_id: props.verpackungsVorschrift?.verpackungs_einheit_version_id ?? -1,
  }, initOverrides), [props.services.packordnung, props.verpackungsVorschrift]);
  const packordnungResult = useFetchLikeServiceFunction(packordnungServiceFunction, null);

  return (
    <ControlGroup fill>
      <HTMLTable striped className={styles.verpackungs_vorschrift_information_table}>
        <tbody>
          <tr>
            <td>VPE</td>
            <td>{verpackungsVorschrift?.verpackungs_einheit.bezeichnung_lang} <Tag minimal>{verpackungsVorschrift?.verpackungs_einheit.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Zustand</td>
            <td>{verpackungsVorschrift ? VerpackungZustandKeyMapping[verpackungsVorschrift.zustand] : undefined}</td>
          </tr>
          <tr>
            <td>Aufdruck</td>
            <td>{verpackungsVorschrift?.aufdruck ? AufdruckArtKeyMapping[verpackungsVorschrift.aufdruck] : undefined}</td>
          </tr>
          <tr>
            <td>Geschlossen</td>
            <td>{verpackungsVorschrift?.geschlossen === true ? 'Ja' : verpackungsVorschrift?.geschlossen === false ? 'Nein' : undefined}</td>
          </tr>
          <tr>
            <td>Polybeutel</td>
            <td>{verpackungsVorschrift?.polybeutel === true ? 'Ja' : verpackungsVorschrift?.polybeutel === false ? 'Nein' : undefined}</td>
          </tr>
          <tr>
            <td>Stück je VPE</td>
            <td>{packordnungResult.data?.anzahl_flaschen}</td>
          </tr>
          <tr>
            <td>Füllweise</td>
            <td>{packordnungResult.data?.fuellweise}</td>
          </tr>
          <tr>
            <td>Stapelhöhe</td>
            <td>{verpackungsVorschrift?.stapel_hoehe_1} {verpackungsVorschrift?.stapel_hoehe_2 ? `/ ${verpackungsVorschrift.stapel_hoehe_2}` : ''} Lagen</td>
          </tr>
          <tr>
            <td>Spezialfall (Karton-Reduktion)</td>
            <td>{verpackungsVorschrift?.spezialfall_karton_reduktion ? 'Ja' : 'Nein'}</td>
          </tr>
          <tr>
            <td>Transportweg-Variante</td>
            <td>{verpackungsVorschrift?.transportweg ? TransportwegKeyMapping[verpackungsVorschrift.transportweg] : undefined}</td>
          </tr>
          <tr>
            <td>Bemerkung</td>
            <td>{verpackungsVorschrift?.bemerkung}</td>
          </tr>
        </tbody>
      </HTMLTable>
      <HTMLTable striped className={styles.verpackungs_vorschrift_information_table}>
        <tbody>
          <tr>
            <td>TET</td>
            <td>{verpackungsVorschrift?.transport_einheit_traeger.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift?.transport_einheit_traeger.nummer}</Tag></td>
          </tr>
          <tr>
            <td>Unterlage</td>
            {verpackungsVorschrift?.unterlage
              ? <td> <Tag minimal>{verpackungsVorschrift.unterlage.nummer}</Tag></td>
              : <td />
            }
          </tr>
          <tr>
            <td>Unterlage-Anzahl</td>
            <td>{verpackungsVorschrift?.unterlagen_anzahl}</td>
          </tr>
          <tr>
            <td>Zwischenlage</td>
            {verpackungsVorschrift?.zwischenlage
              ? <td>{verpackungsVorschrift.zwischenlage.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.zwischenlage.nummer}</Tag></td>
              : <td />
            }
          </tr>
          <tr>
            <td>Zwischenlage-Anzahl</td>
            <td>{verpackungsVorschrift?.zwischenlagen_anzahl}</td>
          </tr>
          <tr>
            <td>Abdeckung</td>
            {verpackungsVorschrift?.abdeckung
              ? <td>{verpackungsVorschrift.abdeckung.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.abdeckung.nummer}</Tag></td>
              : <td />
            }
          </tr>
          <tr>
            <td>Abdeckung-Anzahl</td>
            <td>{verpackungsVorschrift?.abdeckung_anzahl}</td>
          </tr>
          <tr>
            <td>Etikett</td>
            <td>ja</td>
          </tr>
          <tr>
            <td>Stretchen</td>
            <td>{verpackungsVorschrift?.folie ? <>ja</> : <>nein</>}</td>
          </tr>
          <tr>
            <td>Kantenschutz</td>
            {verpackungsVorschrift?.kantenschutz
              ? <td>{verpackungsVorschrift.kantenschutz.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.kantenschutz.nummer}</Tag></td>
              : <td />
            }
          </tr>
          <tr>
            <td>Folie</td>
            {verpackungsVorschrift?.folie
              ? <td>{verpackungsVorschrift.folie.bezeichnung_kurz} <Tag minimal>{verpackungsVorschrift.folie.nummer}</Tag></td>
              : <td />
            }
          </tr>
        </tbody>
      </HTMLTable>
    </ControlGroup>
  );
}


type VerpackungsVorschriftModalProps = PropsWithServices<ModalProps<void> & {
  nummer: string;
  verpackungsVorschrift?: VerpackungsVorschrift | null;
  onAendernClick?: () => void;
}>;

export function VerpackungsVorschriftModal(props: VerpackungsVorschriftModalProps): JSX.Element {

  return (
    <Dialog isOpen={true} className={styles.hinweisModalContainer} onClose={() => props.onModalFinished()}>
      <DialogHeader
        onClick={() => props.onModalFinished()}
      >
        Verpackungsvorschrift zu {props.nummer}
      </DialogHeader>
      <DialogBody>
        <div className={styles['content-container']}>
          <VerpackungsVorschriftTable {...props} />
        </div>
      </DialogBody>
      <DialogFooter>
        {props.onAendernClick && <Button onClick={() => props.onAendernClick?.()}>Ändern</Button>}
        <Button onClick={() => props.onModalFinished()}>Schließen</Button>
      </DialogFooter>
    </Dialog>
  );
}
