import { Button, Intent } from '@blueprintjs/core';
import React, { PropsWithChildren } from 'react';


export type AsyncButtonProps = PropsWithChildren<{
  onClick: (() => Promise<void>) | (() => void);
  onDone?: (err: any | undefined) => void;
  cssClass?: string;
  disabled?: boolean;
  label?: string | JSX.Element;
  intent?: Intent;
}>;

export type AsyncButtonState = {
  isLoading: boolean;
  error: Error | any;
};

export class AsyncButton extends React.Component<AsyncButtonProps, AsyncButtonState> {

  constructor(props: AsyncButtonProps) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
    };
  }

  private onClick = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    try {
      await this.props.onClick();
      this.setState({
        isLoading: false,
        error: null,
      });
      if (this.props.onDone) {
        this.props.onDone(undefined);
      }
    } catch (error) {
      this.setState({
        error,
        isLoading: false,
      });
      if (this.props.onDone) {
        this.props.onDone(error);
      }
    }
  };

  public render(): JSX.Element {
    return (
      <Button
        onClick={this.onClick}
        className={this.props.cssClass}
        disabled={this.props.disabled ?? false}
        loading={this.state.isLoading}
        intent={this.props.intent}
      >
        {this.props.label}
        {this.props.children}
      </Button>
    );
  }

}
