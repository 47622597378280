import { GridApi, IFilterType, RowNode } from 'ag-grid-community';
import { comparator } from './ColDefComparator';
import {
  ForeignKeyCellValues,
  ForeignKeyCellValuesProvider,
  GetValueFunction,
  resolveForeignKeyCellValues,
} from '.';
import { ForeignKeyCellRenderer } from './ForeignKeyCellRenderer';
import { TextMatcherParams } from 'ag-grid-community/dist/lib/filter/provided/text/textFilter';

type ComparatorRowNode<TEntityType> = Omit<RowNode, 'data'> & { data: TEntityType }

export type ForeignKeyColumnDef<TType, TForeignKeyType> = {
  field: keyof TType,
  cellRenderer: any,
  cellRendererParams: ForeignKeyCellValuesProvider<TType, TForeignKeyType>,
  comparator: (valueA: keyof TType, valueB: keyof TType, nodeA: ComparatorRowNode<TType>, nodeB: ComparatorRowNode<TType>, isInverted: boolean) => number,
  headerComponentParams: {
    sortBy: 'description' | 'number',
  }
  filter: IFilterType,
  filterParams: {
    filters: Array<{
      title: string,
      display: string,
      filter: IFilterType,
      filterParams: {
        buttons: ['reset'],
        textFormatter: (value: ForeignKeyCellValues) => string,
        textMatcher?: (params: TextMatcherParams) => boolean,
      },
    }>,
  },
  filterValueGetter: (params: { data: TType }) => ForeignKeyCellValues,
};

export function makeForeignKeyColumn<TType, TFieldName extends keyof TType>(
  foreignKeyFieldName: TFieldName,
  getValues: GetValueFunction<TType, TType[TFieldName]>,
): ForeignKeyColumnDef<TType, TType[TFieldName]> {
  const valuesProvider: ForeignKeyCellValuesProvider<TType, TType[TFieldName]> = {
    getValue: getValues,
  };

  const colDef: ForeignKeyColumnDef<TType, TType[TFieldName]> = {
    field: foreignKeyFieldName,
    cellRenderer: ForeignKeyCellRenderer,
    cellRendererParams: valuesProvider,
    //TODO this is not a valid comparator; we use it internally.
    //in the StammdatenAgGridRenderer this function will be wrapped in a valid comparator. otherwise we could not access the gridapi here
    // it is required to access the gridapi, because we need to access the headerComponentParams to distinguish between sorting by description or number
    comparator: ((_objectA: keyof TType, _objectB: keyof TType, nodeA: ComparatorRowNode<TType>, nodeB: ComparatorRowNode<TType>, _isInverted: any, gridApi: GridApi, colId: string) => {
      const column = gridApi?.getColumnDef(colId);
      const sortBy: 'number' | 'description' = column?.headerComponentParams?.sortBy ?? 'description';
      const a = resolveForeignKeyCellValues(valuesProvider, nodeA.data, nodeA.data[foreignKeyFieldName])[sortBy];
      const b = resolveForeignKeyCellValues(valuesProvider, nodeB.data, nodeB.data[foreignKeyFieldName])[sortBy];

      return comparator(a, b);
    }) as any,
    headerComponentParams: {
      sortBy: 'description',
    },
    filter: 'agMultiColumnFilter',
    filterValueGetter: ({ data }) => resolveForeignKeyCellValues(valuesProvider, data, data[foreignKeyFieldName]),
    filterParams: {
      filters: [
        {
          title: 'Bezeichnung filtern',
          display: 'accordion',
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            textFormatter: (data) => (data.description ?? data) as string,
            textMatcher: (params: TextMatcherParams): boolean => params.value?.toLowerCase().trim().includes(params.filterText?.toLowerCase().trim()),
          },
        },
        {
          title: 'Nummer filtern',
          display: 'accordion',
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            textFormatter: (data) => (data.number ?? data) as string,
          },
        },
      ],
    },
  };

  return colDef;
}
