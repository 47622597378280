import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H5,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { RueckstellMessprotokollDatenLadenResult, RueckstellMessprotokollDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import { format } from 'date-fns';
import styles from './RueckstellmessprotokollPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function RueckstellmessprotokollPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.RückstellMessprotokollAnzeigen') {
          return Rueckstellmessprotokoll;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function Rueckstellmessprotokoll(props: CustomFormProps<RueckstellMessprotokollDatenLadenResult>): JSX.Element {
  const payload = RueckstellMessprotokollDatenLadenResultFromJSON(props.tokenPayload);
  return (
    <div>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <div className={styles.current_date}>{format(props.userTask.startedAt ?? new Date(), 'dd.LL.yyyy')}</div>
      <div className={styles.protokol_view}>
        <div className={styles.header}>
          <H5>Rückstell-Messprotokoll</H5>
        </div>
        <div className={styles.content}>
          <HTMLTable>
            <thead>
              <tr>
                <th rowSpan={3}>Masch.<br />-Nr.</th>
                <th rowSpan={3}>Artikel</th>
                <th rowSpan={3}>Farbe</th>
                <th colSpan={8}>Gewicht [ g ]</th>
                <th rowSpan={3}>Material</th>
                <th rowSpan={3}>Bemerkungen,<br />visuelle Prüfung nach WN-503</th>
                <td className={styles.center}>Info Technik</td>
              </tr>
              <tr>
                <th colSpan={2}>SOLL</th>
                <th colSpan={6}>IST</th>
                <th rowSpan={2}>Entlüftung</th>
              </tr>
              <tr>
                <th>Nom.</th>
                <th>&#177;</th>
                <th>N1</th>
                <th>N2</th>
                <th>N3</th>
                <th>N4</th>
                <th>N5</th>
                <th>N6</th>
              </tr>
            </thead>
            <tbody>
              {
                payload.entries.map(entry => (
                  <tr key={entry.blasmaschine_nummer}>
                    <td className={styles.center}>{entry.blasmaschine_nummer}</td>
                    <td>{entry.flaschen_typ_bezeichnung}; {entry.halsteil_bezeichnung}</td>
                    <td className={styles.right}>{entry.granulat_farbe_nummer}</td>
                    <td className={styles.right}>{formatNumber(Number(entry.artikel_gewicht), 1)}</td>
                    <td className={styles.center}>{formatNumber(entry.toleranz, 1) ?? '0,0'}</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                ))
              }
              <tr className={styles.borderless}>
                <td />
                <td>Datum:</td>
                <td>Uhrzeit:</td>
                <td colSpan={6} />
                <td colSpan={3}>Info Schichtführer:</td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
      </div>
    </div>
  );
}
