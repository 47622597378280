import { ColumnState } from 'ag-grid-community';

export const defaultColumnState: Array<ColumnState> = [
  {
    colId: 'nummer',
    width: 100,
    hide: false,
    pinned: null,
    sort: 'asc',
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'kundenauftrag_nummer',
    width: 131,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'rahmenauftrag_nummer',
    width: 75,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'auftragsart',
    width: 68,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'datum_eingang',
    width: 104,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'datum_anlage',
    width: 103,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'abwicklungspfad',
    width: 94,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'status',
    width: 113,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'kunde',
    width: 111,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'bestell_nummer',
    width: 123,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'artikel_nummer_des_kunden',
    width: 116,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'flaschen_typ',
    width: 165,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'blinden_zeichen',
    width: 93,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'kundenauftrag_menge',
    width: 79,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'produktion_auftrag_menge',
    width: 113,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'liefertermin_soll',
    width: 97,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'volumen_ml',
    width: 120,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'hals_teil',
    width: 101,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'gewicht_g',
    width: 112,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'farbe',
    width: 105,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'granulat_farbe_batch_nummer',
    width: 99,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'einfaerbungs_grad',
    width: 85,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'farb_bedarf',
    width: 120,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'lagerbestand',
    width: 120,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'werkstoff_1',
    width: 105,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'werkstoff_2',
    width: 105,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'werkstoff_typ_1',
    width: 124,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'werkstoff_typ_2',
    width: 124,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'empfaenger',
    width: 110,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'blasdauer',
    width: 85,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'fertig_vor_liefertermin',
    width: 86,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'blasmaschine_nummer',
    width: 66,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'blasmaschine_nnn',
    width: 81,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'pau_start',
    width: 96,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'pau_ende',
    width: 95,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'vorgegebene_blasmaschine_nummer',
    width: 92,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'menge_gesamt',
    width: 133,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'menge_gut',
    width: 116,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'zaehlerstand_umbau_start',
    width: 167,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'zaehlerstand_produktion_start',
    width: 182,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'zaehlerstand_produktion_ende',
    width: 183,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'menge_einricht_ausschuss',
    width: 121,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'menge_produktion_ausschuss',
    width: 129,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'artikel_nummer',
    width: 100,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'auftragsnummer_des_empfaenger',
    width: 145,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'artikel_nummer_des_empfaengers',
    width: 140,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'prozent_einricht_ausschuss',
    width: 146,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'prozent_produktions_ausschuss',
    width: 131,
    hide: false,
    pinned: null,
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'technische_unterbrechung',
    width: 75,
    hide: false,
    pinned: 'false',
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
  {
    colId: 'stammdaten_action_buttons',
    width: 225,
    hide: false,
    pinned: 'false',
    sort: null,
    sortIndex: null,
    aggFunc: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    pivotIndex: null,
    flex: null,
  },
];
