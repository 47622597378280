"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsplanEntryToJSON = exports.ProduktionsplanEntryFromJSONTyped = exports.ProduktionsplanEntryFromJSON = exports.ProduktionsplanEntryVerpackungsartEnum = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
/**
* @export
* @enum {string}
*/
var ProduktionsplanEntryVerpackungsartEnum;
(function (ProduktionsplanEntryVerpackungsartEnum) {
    ProduktionsplanEntryVerpackungsartEnum["K"] = "K";
    ProduktionsplanEntryVerpackungsartEnum["T"] = "T";
})(ProduktionsplanEntryVerpackungsartEnum = exports.ProduktionsplanEntryVerpackungsartEnum || (exports.ProduktionsplanEntryVerpackungsartEnum = {}));
function ProduktionsplanEntryFromJSON(json) {
    return ProduktionsplanEntryFromJSONTyped(json, false);
}
exports.ProduktionsplanEntryFromJSON = ProduktionsplanEntryFromJSON;
function ProduktionsplanEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pau_nummer': json['pau_nummer'],
        'kunde_bestellnummer': json['kunde_bestellnummer'],
        'kunde_name': json['kunde_name'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'flaschen_typ_bezeichnung': !runtime_1.exists(json, 'flaschen_typ_bezeichnung') ? undefined : json['flaschen_typ_bezeichnung'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'artikel_gewicht': json['artikel_gewicht'],
        'granulat_farbe_bezeichnung': json['granulat_farbe_bezeichnung'],
        'granulat_farbe_nummer': json['granulat_farbe_nummer'],
        'granulat_farbe_batch_nummer': !runtime_1.exists(json, 'granulat_farbe_batch_nummer') ? undefined : json['granulat_farbe_batch_nummer'],
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'verpackungsart': json['verpackungsart'],
        'werkstoff_bezeichnung': json['werkstoff_bezeichnung'],
        'menge_kundenauftrag': json['menge_kundenauftrag'],
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenFromJSON(json['liefertermin_kennzeichen']),
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : (new Date(json['liefertermin_soll'])),
        'start_datum': (new Date(json['start_datum'])),
        'end_datum': (new Date(json['end_datum'])),
    };
}
exports.ProduktionsplanEntryFromJSONTyped = ProduktionsplanEntryFromJSONTyped;
function ProduktionsplanEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pau_nummer': value.pau_nummer,
        'kunde_bestellnummer': value.kunde_bestellnummer,
        'kunde_name': value.kunde_name,
        'empfaenger_name': value.empfaenger_name,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'artikel_gewicht': value.artikel_gewicht,
        'granulat_farbe_bezeichnung': value.granulat_farbe_bezeichnung,
        'granulat_farbe_nummer': value.granulat_farbe_nummer,
        'granulat_farbe_batch_nummer': value.granulat_farbe_batch_nummer,
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'verpackungsart': value.verpackungsart,
        'werkstoff_bezeichnung': value.werkstoff_bezeichnung,
        'menge_kundenauftrag': value.menge_kundenauftrag,
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenToJSON(value.liefertermin_kennzeichen),
        'liefertermin_soll': value.liefertermin_soll === undefined ? undefined : (value.liefertermin_soll.toISOString()),
        'start_datum': (value.start_datum.toISOString().substr(0, 10)),
        'end_datum': (value.end_datum.toISOString().substr(0, 10)),
    };
}
exports.ProduktionsplanEntryToJSON = ProduktionsplanEntryToJSON;
