"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArbeitspapiereDatenLadenResultToJSON = exports.ArbeitspapiereDatenLadenResultFromJSONTyped = exports.ArbeitspapiereDatenLadenResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ArbeitspapiereDatenLadenResultFromJSON(json) {
    return ArbeitspapiereDatenLadenResultFromJSONTyped(json, false);
}
exports.ArbeitspapiereDatenLadenResultFromJSON = ArbeitspapiereDatenLadenResultFromJSON;
function ArbeitspapiereDatenLadenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'maschinenlaufplan': _1.MaschinenlaufplanDatenFromJSON(json['maschinenlaufplan']),
        'stellblatt': _1.StellblattDatenFromJSON(json['stellblatt']),
        'gewichtstoleranz': _1.GewichtskontrolleDatenFromJSON(json['gewichtstoleranz']),
        'qhinweise': _1.QHinweiseDatenFromJSON(json['qhinweise']),
        'rueckmeldung1': _1.Rueckmeldung1DatenFromJSON(json['rueckmeldung1']),
        'rueckmeldung2': _1.Rueckmeldung2DatenFromJSON(json['rueckmeldung2']),
        'begleitdokument': !runtime_1.exists(json, 'begleitdokument') ? undefined : _1.BegleitdokumentDatenFromJSON(json['begleitdokument']),
        'etikett_tray': !runtime_1.exists(json, 'etikett_tray') ? undefined : _1.EtikettTrayDatenFromJSON(json['etikett_tray']),
        'etikett_karton': !runtime_1.exists(json, 'etikett_karton') ? undefined : _1.EtikettKartonDatenFromJSON(json['etikett_karton']),
        'vp_unterschriften': _1.VPUnterschriftenDatenFromJSON(json['vp_unterschriften']),
        'pruefprotokoll': _1.PruefprotokollDatenFromJSON(json['pruefprotokoll']),
        'packordnung': !runtime_1.exists(json, 'packordnung') ? undefined : json['packordnung'],
        'abmusterung': !runtime_1.exists(json, 'abmusterung') ? undefined : _1.AbmusterungDatenFromJSON(json['abmusterung']),
    };
}
exports.ArbeitspapiereDatenLadenResultFromJSONTyped = ArbeitspapiereDatenLadenResultFromJSONTyped;
function ArbeitspapiereDatenLadenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'maschinenlaufplan': _1.MaschinenlaufplanDatenToJSON(value.maschinenlaufplan),
        'stellblatt': _1.StellblattDatenToJSON(value.stellblatt),
        'gewichtstoleranz': _1.GewichtskontrolleDatenToJSON(value.gewichtstoleranz),
        'qhinweise': _1.QHinweiseDatenToJSON(value.qhinweise),
        'rueckmeldung1': _1.Rueckmeldung1DatenToJSON(value.rueckmeldung1),
        'rueckmeldung2': _1.Rueckmeldung2DatenToJSON(value.rueckmeldung2),
        'begleitdokument': _1.BegleitdokumentDatenToJSON(value.begleitdokument),
        'etikett_tray': _1.EtikettTrayDatenToJSON(value.etikett_tray),
        'etikett_karton': _1.EtikettKartonDatenToJSON(value.etikett_karton),
        'vp_unterschriften': _1.VPUnterschriftenDatenToJSON(value.vp_unterschriften),
        'pruefprotokoll': _1.PruefprotokollDatenToJSON(value.pruefprotokoll),
        'packordnung': value.packordnung,
        'abmusterung': _1.AbmusterungDatenToJSON(value.abmusterung),
    };
}
exports.ArbeitspapiereDatenLadenResultToJSON = ArbeitspapiereDatenLadenResultToJSON;
