import { Button, Dialog, Tag } from '@blueprintjs/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import {
  Artikel,
  CreateRahmenauftrag,
  LieferterminKennzeichen,
  Rahmenauftrag,
  RahmenauftragStatus,
} from 'wacoplast_wws__api';
import { RahmenauftragStatusMapping, makeArtikelDropDownPickerRenderer } from '.';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DATE_COLUMN_OPTIONS,
  DialogBody,
  DialogFooter,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatDateString,
  formatLiefertermin,
  getValidatedDateTimePickerComponentValue,
  makeDateEditorRenderer,
  makeNumberEditorRenderer,
  makeStammdatenEntityRelatedDisabledEditor,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { ArtikelInformationTable } from '../../stammdaten/artikel_verwalten/ArtikelInformationTable';
import { defaultColumnState } from './defaultColumnConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type RahmenauftragVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function RahmenauftragVerwalten(props: RahmenauftragVerwaltenProps): JSX.Element {

  const [artikelToDisplay, setArtikelToDisplay] = useState<Artikel | null>(null);

  const firmaResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);

  const editors: StammdatenFieldEditors<CreateRahmenauftrag> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
    },
    {
      type: 'complex',
      fields: ['artikel_database_id', 'artikel_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, any>(makeArtikelDropDownPickerRenderer(props.services.artikel, firmaResult), (entity) => entity.status && entity.status !== RahmenauftragStatus.NUMBER_1),
    },
    {
      type: 'simple',
      field: 'menge',
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, number>(makeNumberEditorRenderer(), (entity) => entity.status && entity.status === RahmenauftragStatus.NUMBER_9),
      label: 'Menge',

    },
    {
      type: 'simple',
      field: 'datum_eingang',
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, Date>(makeDateEditorRenderer(), (entity) => entity.status && entity.status !== RahmenauftragStatus.NUMBER_1),
      label: 'Datum-Eingang',
    },
    {
      type: 'simple',
      field: 'liefertermin_soll',
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, Date>(makeDateEditorRenderer(), (entity) => entity.status && entity.status === RahmenauftragStatus.NUMBER_9),
      label: 'Liefertermin',
    },
    {
      type: 'simple',
      field: 'bestell_nummer',
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, string>(makeTextEditorRenderer(), (entity) => entity.status && entity.status !== RahmenauftragStatus.NUMBER_1),
      label: 'Bestellnummer',
    },
    {
      type: 'simple',
      field: 'auftragsnummer_des_empfaenger',
      renderer: makeStammdatenEntityRelatedDisabledEditor<Rahmenauftrag, string>(makeTextEditorRenderer(), (entity) => entity.status && entity.status !== RahmenauftragStatus.NUMBER_1),
      label: 'Auftragsnummer des Empfängers',
    },
  ];

  return (
    <>
      {artikelToDisplay && (
        <Dialog
          isOpen={true}
          onClose={() => setArtikelToDisplay(null)}
          title={`Artikel ${artikelToDisplay.nummer}`}
        >
          <DialogBody>
            <ArtikelInformationTable artikel={artikelToDisplay} />
          </DialogBody>
          <DialogFooter>
            <Button text='Fertig' onClick={() => setArtikelToDisplay(null)} />
          </DialogFooter>
        </Dialog>
      )}
      <StammdatenAgGrid
        editEditors={editors}
        createEditors={editors}
        defaultColumnState={defaultColumnState}
        getCustomPerformableRowActions={(entity) => {
          if (entity.status !== RahmenauftragStatus.NUMBER_1) {
            return [{
              label: 'Artikel ansehen',
              type: 'custom',
              onPerform(entity) {
                setArtikelToDisplay(entity.artikel);
              },
              intent: 'none',
              icon: faFolderOpen,
            }];
          }
          return [{
            label: 'Artikel ansehen',
            type: 'custom',
            onPerform(entity) {
              setArtikelToDisplay(entity.artikel);
            },
            intent: 'none',
            icon: faFolderOpen,
          }, {
            label: 'Stornieren',
            type: 'custom',
            onPerform: (data) => {
              props.services.rahmenauftrag.stornierenByDatabaseIdRahmenauftragDatabaseIdStornierenPatch({
                database_id: data.database_id,
              });
            },
            intent: 'warning',
          }];
        }}
        columnDefs={
          [
            {
              headerName: 'Nummer',
              colId: 'nummer',
              field: 'nummer',
              filter: 'agTextColumnFilter',
              ...NUMMER_SORT_OPTS,
            },
            {
              headerName: 'Artikel',
              colId: 'artikel_nummer',
              valueGetter: ({ data }) => data.artikel.nummer,
              filter: 'agTextColumnFilter',
              cellRenderer: (params: any) => {
                return <>{params.value} <Tag interactive minimal onClick={() => setArtikelToDisplay(params.node.data.artikel)} icon={<FontAwesomeIcon icon={faFolderOpen} />} /></>;
              },
            },
            {
              headerName: 'Menge',
              colId: 'menge',
              field: 'menge',
              filter: 'agNumberColumnFilter',
              valueFormatter: numberValueFormatterGenAGGrid(0),
            },
            {
              headerName: 'gefertigt',
              colId: 'gefertigt',
              field: 'gefertigte_menge',
              filter: 'agNumberColumnFilter',
              valueFormatter: numberValueFormatterGenAGGrid(0),
            },
            {
              headerName: 'Bestellbestand',
              colId: 'bestellbestand',
              field: 'bestellbestand',
              filter: 'agNumberColumnFilter',
              valueFormatter: numberValueFormatterGenAGGrid(0),
            },
            {
              headerName: 'offene Menge',
              colId: 'offene_menge',
              field: 'offene_menge',
              filter: 'agNumberColumnFilter',
              valueFormatter: numberValueFormatterGenAGGrid(0),
            },
            {
              headerName: 'Bestellnummer',
              colId: 'bestell_nummer',
              field: 'bestell_nummer',
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Status',
              colId: 'status',
              field: 'status',
              filter: 'agSetColumnFilter',
              valueGetter: ({ data }) => RahmenauftragStatusMapping[data.status],
            },
            {
              headerName: 'Liefertermin',
              colId: 'liefertermin_soll',
              field: 'liefertermin_soll',
              filter: 'agTextColumnFilter',
              valueFormatter: (val: ValueFormatterParams) =>
                formatLiefertermin(val.data.liefertermin_kennzeichen, val.data.liefertermin_soll),
            },
            {
              headerName: 'Auftragsnummer des Empfängers',
              colId: 'auftragsnummer_des_empfaenger',
              field: 'auftragsnummer_des_empfaenger',
              filter: 'agTextColumnFilter',
            },
            {
              headerName: 'Datum-Eingang',
              colId: 'datum_eingang',
              field: 'datum_eingang',
              valueFormatter: (val: ValueFormatterParams) => formatDateString(val.value as Date),
              ...DATE_COLUMN_OPTIONS,
            },
          ]}
        stammdatenService={{
          create: (data: CreateRahmenauftrag) => props.services.rahmenauftrag.postRahmenauftragPost({
            CreateRahmenauftrag: {
              ...data,
              datum_eingang: getValidatedDateTimePickerComponentValue(data.datum_eingang),
              liefertermin_soll: getValidatedDateTimePickerComponentValue(data.liefertermin_soll),
              status: RahmenauftragStatus.NUMBER_1,
            },
          }),
          update: (data) => props.services.rahmenauftrag.putByDatabaseIdRahmenauftragDatabaseIdPut({
            database_id: data.database_id,
            UpdateRahmenauftrag: {
              ...data,
              datum_eingang: getValidatedDateTimePickerComponentValue(data.datum_eingang),
              liefertermin_soll: getValidatedDateTimePickerComponentValue(data.liefertermin_soll),
            },
          }),
          restore: (data) => props.services.rahmenauftrag.restoreByDatabaseIdRahmenauftragDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
          getAll: (includeDeleted) => props.services.rahmenauftrag.getAllRahmenauftragGet({ include_deleted: includeDeleted }),
          getHistory: (data) => props.services.rahmenauftrag.getHistoryRahmenauftragDatabaseIdHistoryGet({ database_id: data.database_id }),
          export: () => props.services.rahmenauftrag.downloadExcelRahmenauftragExcelPost(),
        }}
        transactionService={props.transactionService}
        subscriptionEventService={props.subscriptionEventService}
        onCloseStartDialogClicked={() => props.closeStartDialog()}
        dialogStrings={DialogStrings.Rahmenauftrag}
        defaultEntityForCreation={{ liefertermin_kennzeichen: LieferterminKennzeichen.T }}
        filterHistoryColDefs={(colDefs) => colDefs.filter(colDef => !['gefertigt', 'bestellbestand', 'offene_menge'].includes((colDef as any).colId))}
      />
    </>
  );
}
