import { Dialog, H2 } from '@blueprintjs/core';
import React from 'react';
import { BpmnValidationError, RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON, RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  NumberEditor,
  getPayloadOrBpmnValidationErrorFromToken,
  removeFieldFromValidationError,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungMengeErfassenKeineUnterbrechung(
  props: CustomFormProps<{ anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, RueckmeldungMengeErfassenKeineUnterbrechungResultFromJSON);

  const [zaehlerstand_umbau_start, setZaehlerstandUmbauStart] = React.useState<number | null>(previousValues.produktionsphase?.zaehlerstand_umbau_start ?? null);
  const [zaehlerstand_produktion_start, setZaehlerstandProduktionStart] = React.useState<number | null>(previousValues.produktionsphase?.zaehlerstand_produktion_start ?? null);
  const [zaehlerstand_produktion_ende, setZaehlerstandProduktionEnde] = React.useState<number | null>(previousValues.produktionsphase?.zaehlerstand_produktion_ende ?? null);
  const [menge_gut, setMengeGut] = React.useState<number | null>(previousValues.produktionsphase?.menge_gut ?? null);
  const [validationError, _setValidationError] = React.useState<BpmnValidationError | null>(err);
  const setValidationError = (validationError: BpmnValidationError | null, fieldLocation: string | Array<string>): void => {
    _setValidationError({ ...removeFieldFromValidationError(validationError, fieldLocation) as BpmnValidationError, previous_payload: validationError?.previous_payload ?? {} });
  };

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Mengen erfassen</H2>

        <DialogFormField fieldLabel='Zählerstand Umbau-Start' fieldLocation={['produktionsphase', 'zaehlerstand_umbau_start']} lastValidationResponse={validationError}>
          <NumberEditor
            value={zaehlerstand_umbau_start}
            onChange={(data) => {
              setValidationError(validationError, ['produktionsphase', 'zaehlerstand_umbau_start']);
              setZaehlerstandUmbauStart(data);
            }}
          />
        </DialogFormField>
        <DialogFormField fieldLabel='Zählerstand Produktion-Start' fieldLocation={['produktionsphase', 'zaehlerstand_produktion_start']} lastValidationResponse={validationError}>
          <NumberEditor
            value={zaehlerstand_produktion_start}
            onChange={(data) => {
              setValidationError(validationError, ['produktionsphase', 'zaehlerstand_produktion_start']);
              setZaehlerstandProduktionStart(data);
            }}
          />
        </DialogFormField>
        <DialogFormField fieldLabel='Zählerstand Produktion-Ende' fieldLocation={['produktionsphase', 'zaehlerstand_produktion_ende']} lastValidationResponse={validationError}>
          <NumberEditor
            value={zaehlerstand_produktion_ende}
            onChange={(data) => {
              setValidationError(validationError, ['produktionsphase', 'zaehlerstand_produktion_ende']);
              setZaehlerstandProduktionEnde(data);
            }}
          />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Gut' fieldLocation={['produktionsphase', 'menge_gut']} lastValidationResponse={validationError}>
          <NumberEditor
            value={menge_gut}
            onChange={(data) => {
              setValidationError(validationError, ['produktionsphase', 'menge_gut']);
              setMengeGut(data);
            }}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onFinish={() => {
          return props.finishUserTask(RueckmeldungMengeErfassenKeineUnterbrechungResultToJSON(
            {
              produktionsphase: {
                zaehlerstand_umbau_start: zaehlerstand_umbau_start ?? undefined,
                zaehlerstand_produktion_start: zaehlerstand_produktion_start ?? undefined,
                zaehlerstand_produktion_ende: zaehlerstand_produktion_ende ?? undefined,
                menge_gut: menge_gut ?? undefined,
              },
            },
          ));
        }}
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
