"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rueckmeldung1DatenToJSON = exports.Rueckmeldung1DatenFromJSONTyped = exports.Rueckmeldung1DatenFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function Rueckmeldung1DatenFromJSON(json) {
    return Rueckmeldung1DatenFromJSONTyped(json, false);
}
exports.Rueckmeldung1DatenFromJSON = Rueckmeldung1DatenFromJSON;
function Rueckmeldung1DatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kundenauftrag_nummer': json['kundenauftrag_nummer'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'kunde_name': json['kunde_name'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'flaschentyp_bezeichnung': json['flaschentyp_bezeichnung'],
        'flaschentyp_spezifikation': json['flaschentyp_spezifikation'],
        'volumen': json['volumen'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'artikel_gewicht': json['artikel_gewicht'],
        'gewichtstoleranz': !runtime_1.exists(json, 'gewichtstoleranz') ? undefined : json['gewichtstoleranz'],
        'flaschentyp_nummer': json['flaschentyp_nummer'],
        'kunde_bestell_nummer': !runtime_1.exists(json, 'kunde_bestell_nummer') ? undefined : json['kunde_bestell_nummer'],
        'liefertermin': !runtime_1.exists(json, 'liefertermin') ? undefined : (new Date(json['liefertermin'])),
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'lagerplatz_nummer': !runtime_1.exists(json, 'lagerplatz_nummer') ? undefined : json['lagerplatz_nummer'],
        'farbe_nummer': json['farbe_nummer'],
        'farbe_batch_nummer': !runtime_1.exists(json, 'farbe_batch_nummer') ? undefined : json['farbe_batch_nummer'],
        'einfaerbungsgrad': !runtime_1.exists(json, 'einfaerbungsgrad') ? undefined : json['einfaerbungsgrad'],
        'werkstoff_bezeichnung': json['werkstoff_bezeichnung'],
        'verpackungs_einheit_zustand': _1.VerpackungZustandFromJSON(json['verpackungs_einheit_zustand']),
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'stretcher': json['stretcher'],
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'verpackungs_einheit_bezeichnung': json['verpackungs_einheit_bezeichnung'],
        'flaschen_pro_vpe': json['flaschen_pro_vpe'],
        'vpes_pro_palette': json['vpes_pro_palette'],
        'stueck_pro_schicht': json['stueck_pro_schicht'],
        'kantenschutz': json['kantenschutz'],
        'bestelldatum': (new Date(json['bestelldatum'])),
        'vpe_is_karton': json['vpe_is_karton'],
    };
}
exports.Rueckmeldung1DatenFromJSONTyped = Rueckmeldung1DatenFromJSONTyped;
function Rueckmeldung1DatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kundenauftrag_nummer': value.kundenauftrag_nummer,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'kunde_name': value.kunde_name,
        'empfaenger_name': value.empfaenger_name,
        'flaschentyp_bezeichnung': value.flaschentyp_bezeichnung,
        'flaschentyp_spezifikation': value.flaschentyp_spezifikation,
        'volumen': value.volumen,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'artikel_gewicht': value.artikel_gewicht,
        'gewichtstoleranz': value.gewichtstoleranz,
        'flaschentyp_nummer': value.flaschentyp_nummer,
        'kunde_bestell_nummer': value.kunde_bestell_nummer,
        'liefertermin': value.liefertermin === undefined ? undefined : (value.liefertermin.toISOString()),
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'lagerplatz_nummer': value.lagerplatz_nummer,
        'farbe_nummer': value.farbe_nummer,
        'farbe_batch_nummer': value.farbe_batch_nummer,
        'einfaerbungsgrad': value.einfaerbungsgrad,
        'werkstoff_bezeichnung': value.werkstoff_bezeichnung,
        'verpackungs_einheit_zustand': _1.VerpackungZustandToJSON(value.verpackungs_einheit_zustand),
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'stretcher': value.stretcher,
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'verpackungs_einheit_bezeichnung': value.verpackungs_einheit_bezeichnung,
        'flaschen_pro_vpe': value.flaschen_pro_vpe,
        'vpes_pro_palette': value.vpes_pro_palette,
        'stueck_pro_schicht': value.stueck_pro_schicht,
        'kantenschutz': value.kantenschutz,
        'bestelldatum': (value.bestelldatum.toISOString()),
        'vpe_is_karton': value.vpe_is_karton,
    };
}
exports.Rueckmeldung1DatenToJSON = Rueckmeldung1DatenToJSON;
