"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UmbauplanEntryToJSON = exports.UmbauplanEntryFromJSONTyped = exports.UmbauplanEntryFromJSON = exports.UmbauplanEntryUmbauartEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var UmbauplanEntryUmbauartEnum;
(function (UmbauplanEntryUmbauartEnum) {
    UmbauplanEntryUmbauartEnum["A"] = "A";
    UmbauplanEntryUmbauartEnum["U"] = "U";
    UmbauplanEntryUmbauartEnum["empty"] = "";
})(UmbauplanEntryUmbauartEnum = exports.UmbauplanEntryUmbauartEnum || (exports.UmbauplanEntryUmbauartEnum = {}));
function UmbauplanEntryFromJSON(json) {
    return UmbauplanEntryFromJSONTyped(json, false);
}
exports.UmbauplanEntryFromJSON = UmbauplanEntryFromJSON;
function UmbauplanEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datum': (new Date(json['datum'])),
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'umbauart': json['umbauart'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'halsteil_verwendung': !runtime_1.exists(json, 'halsteil_verwendung') ? undefined : json['halsteil_verwendung'],
        'halsteil_bemerkung': !runtime_1.exists(json, 'halsteil_bemerkung') ? undefined : json['halsteil_bemerkung'],
    };
}
exports.UmbauplanEntryFromJSONTyped = UmbauplanEntryFromJSONTyped;
function UmbauplanEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datum': (value.datum.toISOString().substr(0, 10)),
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'umbauart': value.umbauart,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'halsteil_verwendung': value.halsteil_verwendung,
        'halsteil_bemerkung': value.halsteil_bemerkung,
    };
}
exports.UmbauplanEntryToJSON = UmbauplanEntryToJSON;
