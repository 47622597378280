/* eslint-disable react/display-name */
import { BlasmaschineZeiten } from 'wacoplast_wws__api';
import { NumberEditor, StammdatenFieldEditorSimpleRendererProps } from '../../../infrastructure';

export function makeWbkNumberEditorRenderer(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<BlasmaschineZeiten, number | undefined>) => {
    return (
      <NumberEditor
        max={10}
        value={props.value !== undefined ? Math.abs(props.value) : null}
        disabled={props.disabled}
        onChange={(value) => props.onChange(value !== null ? 0 - value : undefined)}
        precision={3}
        showButtons={true}
      />
    );
  };
}
