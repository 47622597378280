"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.KundenauftragErstellenPayloadToJSON = exports.KundenauftragErstellenPayloadFromJSONTyped = exports.KundenauftragErstellenPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function KundenauftragErstellenPayloadFromJSON(json) {
    return KundenauftragErstellenPayloadFromJSONTyped(json, false);
}
exports.KundenauftragErstellenPayloadFromJSON = KundenauftragErstellenPayloadFromJSON;
function KundenauftragErstellenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'artikel': _1.ArtikelFromJSON(json['artikel']),
        'auftragsart': _1.KundenauftragArtFromJSON(json['auftragsart']),
        'rahmenauftrag': !runtime_1.exists(json, 'rahmenauftrag') ? undefined : _1.RahmenauftragFromJSON(json['rahmenauftrag']),
        'menge': json['menge'],
        'auftragsnummer_des_empfaenger': !runtime_1.exists(json, 'auftragsnummer_des_empfaenger') ? undefined : json['auftragsnummer_des_empfaenger'],
        'bestell_nummer': json['bestell_nummer'],
        'datum_eingang': (new Date(json['datum_eingang'])),
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenFromJSON(json['liefertermin_kennzeichen']),
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : (new Date(json['liefertermin_soll'])),
    };
}
exports.KundenauftragErstellenPayloadFromJSONTyped = KundenauftragErstellenPayloadFromJSONTyped;
function KundenauftragErstellenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'artikel': _1.ArtikelToJSON(value.artikel),
        'auftragsart': _1.KundenauftragArtToJSON(value.auftragsart),
        'rahmenauftrag': _1.RahmenauftragToJSON(value.rahmenauftrag),
        'menge': value.menge,
        'auftragsnummer_des_empfaenger': value.auftragsnummer_des_empfaenger,
        'bestell_nummer': value.bestell_nummer,
        'datum_eingang': (value.datum_eingang.toISOString()),
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenToJSON(value.liefertermin_kennzeichen),
        'liefertermin_soll': value.liefertermin_soll === undefined ? undefined : (value.liefertermin_soll.toISOString()),
    };
}
exports.KundenauftragErstellenPayloadToJSON = KundenauftragErstellenPayloadToJSON;
