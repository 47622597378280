import {
  Button,
  Dialog,
  Intent,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import {
  ColumnState,
  GetMainMenuItemsParams,
  GridApi,
  MenuItemDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { PropsWithChildren, useCallback, useState } from 'react';
import { DeletionStateKeyMapping, StammdatenColDefs } from '.';
import {
  DATE_COLUMN_OPTIONS,
  DialogBody,
  DialogFooter,
  applyColumnStateOnHistorieColumnDefs,
  formatDateString,
  processCellForClipboard,
} from '..';
import { AgGridThemeContext } from '../../App';
import { ModalProps, agGridLocaleDE, useFetchLikeServiceFunction } from '../../infrastructure';
import { formatEmptyStringToLeer, formatEnumValueToDescription } from '../filterFormatters';
import { TransactionErrorModal } from '../transactions/TransactionErrorModal';


export type StammdatenHistoryModalResult = boolean;

export type StammdatenHistoryModalProps<TEntityType> = ModalProps<StammdatenHistoryModalResult> & {
  dialogTitle: string;
  columnDefs: StammdatenColDefs<TEntityType>;
  hideDefaultColumns?: Array<'version_id' | 'created_at' | 'changed_by_user_name' | 'deletion_state'>
  entity: TEntityType;
  getHistory: (entity: TEntityType) => Promise<TEntityType[]>;
  defaultColumnState?: Array<ColumnState>;
  localStorageKey?: string;
};


export function StammdatenHistoryModal<TEntityType>(props: StammdatenHistoryModalProps<TEntityType>): JSX.Element {
  const {
    getHistory: originalGetHistory,
    entity,
  } = props;

  const getHistory = useCallback(() => originalGetHistory(entity), [originalGetHistory, entity]);
  const result = useFetchLikeServiceFunction(getHistory, null);
  const [displayedDataIsFiltered, setDisplayedDataIsFiltered] = useState(false);
  const [gridApi, setGridApi] = useState<null | GridApi>(null);

  const close = (): void => {
    props.onModalFinished(false);
  };

  if (result.isLoading) {
    return (
      <StammdatenHistoryModalWrapper
        dialogTitle={props.dialogTitle}
        onClosed={close}
      >
        <Spinner />
      </StammdatenHistoryModalWrapper>
    );
  }

  if (result.error) {
    return (
      <TransactionErrorModal
        error={result.error}
        abort={() => close()}
        retry={() => result.reload()}
      />
    );
  }

  return (
    <StammdatenHistoryModalWrapper onClosed={close} dialogTitle={props.dialogTitle} displayedDataIsFiltered={displayedDataIsFiltered}>
      <AgGridThemeContext.Consumer>
        {({ agGridTheme }) => (
          <div className={agGridTheme} style={{ height: '60vh' }}>
            <AgGridReact
              onGridReady={({ api }) => setGridApi(api)}
              rowData={result.data}
              suppressFieldDotNotation={true}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true,
                floatingFilter: true,
                filterParams: { applyMiniFilterWhileTyping: true, buttons: ['reset'] },
                menuTabs: ['generalMenuTab'],
              }}
              suppressCellSelection={true}
              processCellForClipboard={processCellForClipboard}
              getContextMenuItems={(params) => {
                return params.defaultItems?.filter((item) => {
                  return item !== 'copyWithGroupHeaders' && item !== 'paste';
                }) ?? [];
              }}
              suppressColumnVirtualisation={true}
              getMainMenuItems={(params: GetMainMenuItemsParams): Array<string | MenuItemDef> => {
                return ([
                  'pinSubMenu',
                  'separator',
                  {
                    name: 'Spalten zurücksetzen',
                    action: () => {
                      params.api.resetQuickFilter();
                      params.api.setColumnDefs(getColumnDefs(props));
                      params.columnApi.resetColumnState();
                    },
                  },
                  {
                    name: 'Spalten ausbreiten',
                    action: () => params.columnApi?.autoSizeAllColumns(false),
                  },
                  {
                    name: 'Spalten an Sicht anpassen',
                    action: () => params.api.sizeColumnsToFit(),
                  },
                ]
                );
              }}
              getRowId={({ data }) => data.database_id + data.version_id}
              localeText={agGridLocaleDE}
              suppressMultiSort
              columnDefs={getColumnDefs<TEntityType>(props)}
              onFilterChanged={() => {
                setDisplayedDataIsFiltered(gridApi?.isAnyFilterPresent() ?? false);
              }}
            />
          </div>
        )}
      </AgGridThemeContext.Consumer>
    </StammdatenHistoryModalWrapper>
  );
}

type StammdatenHistoryModalWrapperProps = PropsWithChildren<{
  dialogTitle: string;
  onClosed: () => void;
  displayedDataIsFiltered?: boolean;
}>;

function getColumnDefs<TEntityType>(props: StammdatenHistoryModalProps<TEntityType>): StammdatenColDefs<any> {
  const defaultColumns: StammdatenColDefs<any> = [{
    headerName: 'Version',
    field: 'version_id',
    filter: false,
    width: 30,
    colId: 'version_id',
  },
  {
    headerName: 'Erstellt am',
    field: 'created_at',
    valueFormatter: (value) => formatDateString(value.value),
    width: 120,
    colId: 'created_at',
    ...DATE_COLUMN_OPTIONS,
  },
  {
    headerName: 'Geändert von',
    field: 'changed_by_user_name',
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: ({ value }: any) => formatEmptyStringToLeer(value),
    },
    width: 100,
    colId: 'changed_by_user_name',
  },
  {
    headerName: 'Änderungsgrund',
    field: 'aenderungsgrund',
    filter: false,
    hide: true,
    width: 200,
    colId: 'aenderungsgrund',
  },
  {
    headerName: 'Status',
    field: 'deletion_state',
    valueFormatter: ({ value, data }) => statusValueFormatter(value, data, (props.entity as any).version_id),
    width: 80,
    colId: 'deletion_state',
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: ({ value, data }: any) => statusValueFormatter(value, data, (props.entity as any).version_id),
    },
  }];

  return applyColumnStateOnHistorieColumnDefs<any>(props.localStorageKey ?? '', [
    ...defaultColumns.filter(column => !props.hideDefaultColumns || !props.hideDefaultColumns.includes((column as any).colId)),
    ...props.columnDefs,
  ], props.defaultColumnState ?? []);
}

function statusValueFormatter(status: number, rowValue: any, currentVersionId?: number): string {
  if (currentVersionId && rowValue.version_id !== currentVersionId && status === 1) {
    return 'Inaktiv';
  }
  return formatEnumValueToDescription(status.toString(), DeletionStateKeyMapping);
}

function StammdatenHistoryModalWrapper({ onClosed, dialogTitle, children, displayedDataIsFiltered }: StammdatenHistoryModalWrapperProps): JSX.Element {
  const title = (
    <>
      {dialogTitle} {' '}
      {displayedDataIsFiltered &&
        <Tag minimal intent='warning'>Gefilterte Ansicht</Tag>
      }
    </>
  );
  return (
    <Dialog isOpen={true} onClose={onClosed} title={title} style={{ width: '100%' }}>
      <DialogBody>
        {children}
      </DialogBody>
      <DialogFooter>
        <Button
          intent={Intent.PRIMARY}
          onClick={onClosed}
        >
          Schließen
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
