import { Dialog, H2 } from '@blueprintjs/core';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungIstNichtLetzteTeilrueckmeldungHinweis(props: CustomFormProps & {
  finish: () => void,
  anzahl_teilrueckmeldungen?: number;
}): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen - 1)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>{`Teilrückmeldung Nr. ${props.tokenPayload.anzahl_teilrueckmeldungen - 1} ist abgeschlossen`}</H2>
      </DialogBody>
      <DialogFooter
        onFinish={async () => {
          await props.finishUserTask({});
          props.finish();
        }}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
