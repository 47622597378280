import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import styles from './VPUnterschriftenPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function VPUnterschriftenPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return VPUnterschriften;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function VPUnterschriften(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const rawPayload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const payload = rawPayload.vp_unterschriften;
  const rowsPerPage = 42;
  const totalRowsPerPage = (rowsPerPage + 1) * 2;
  const amountOfEntries = rawPayload.maschinenlaufplan.karton_geschlossen !== undefined
    ? (rawPayload.maschinenlaufplan.vpes_pro_stapel_1 * rawPayload.maschinenlaufplan.anzahl_stapel_1) + ((rawPayload.maschinenlaufplan.vpes_pro_stapel_2 ?? 0) + (rawPayload.maschinenlaufplan.anzahl_stapel_2 ?? 0))
    : rawPayload.maschinenlaufplan.anzahl_stapel_1 + (rawPayload.maschinenlaufplan.anzahl_stapel_2 ?? 0);
  const amountOfPages = Math.ceil(amountOfEntries / totalRowsPerPage);

  return (
    <div className={styles.vpunterschriften_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <HTMLTable className={styles.vpunterschriften_table}>
        <thead>
          <tr>
            <th colSpan={7} className={styles.page_header}>MLP: VP-Unterschriften</th>
          </tr>
          <tr />
          <tr>
            <th colSpan={7} className={styles.table_header}>PAU-Nr.: {payload.produktionsauftrag_nummer}, BLM: {payload.blasmaschine_nummer}, {payload.flaschen_typ_nummer} {payload.flaschen_typ_bezeichnung} {payload.farbe_bezeichnung} {payload.bestellnummer} {rawPayload.maschinenlaufplan.empfaenger_name}</th>
          </tr>
          <tr>
            <th className={styles.nummer_column_header}>{rawPayload.maschinenlaufplan.karton_geschlossen !== undefined ? 'Karton' : 'Stapel'}<br />Nr.</th>
            <th className={styles.datum_verpacker_column_header}>Datum</th>
            <th className={styles.datum_verpacker_column_header}>Verpacker<br />Name</th>
            <th className={styles.empty_column}> </th>
            <th className={styles.nummer_column_header}>{rawPayload.maschinenlaufplan.karton_geschlossen !== undefined ? 'Karton' : 'Stapel'}<br />Nr.</th>
            <th className={styles.datum_verpacker_column_header}>Datum</th>
            <th className={styles.datum_verpacker_column_header}>Verpacker<br />Name</th>
          </tr>
        </thead>
        <tbody>
          {new Array(amountOfPages).fill(0).map((_, pageIndex) => {
            return new Array(pageIndex + 1 === amountOfPages ? amountOfEntries % totalRowsPerPage : rowsPerPage).fill(0).map((_, index) => {
              const nummerLeft = pageIndex * totalRowsPerPage + index + 1;
              const nummerRight = pageIndex * totalRowsPerPage + index + rowsPerPage + 1;
              return (
                <tr key={pageIndex * totalRowsPerPage + index} className={index === 0 && pageIndex !== 0 ? styles.page_break : ''}>
                  <th>{nummerLeft <= amountOfEntries ? nummerLeft : ''}</th>
                  <td> </td>
                  <td> </td>
                  <td className={styles.empty_column}> </td>
                  <th>{nummerRight <= amountOfEntries ? nummerRight : ''}</th>
                  <td> </td>
                  <td> </td>
                </tr>
              );
            });
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
}
