"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GranulatFarbeToJSON = exports.GranulatFarbeFromJSONTyped = exports.GranulatFarbeFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GranulatFarbeFromJSON(json) {
    return GranulatFarbeFromJSONTyped(json, false);
}
exports.GranulatFarbeFromJSON = GranulatFarbeFromJSON;
function GranulatFarbeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': json['nummer'],
        'bezeichnung': json['bezeichnung'],
        'batch_nummer': !runtime_1.exists(json, 'batch_nummer') ? undefined : json['batch_nummer'],
        'preis_pro_kg': !runtime_1.exists(json, 'preis_pro_kg') ? undefined : json['preis_pro_kg'],
        'lieferant_database_id': json['lieferant_database_id'],
        'lieferant_version_id': json['lieferant_version_id'],
        'bestand': json['bestand'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'lieferant': _1.LieferantFromJSON(json['lieferant']),
    };
}
exports.GranulatFarbeFromJSONTyped = GranulatFarbeFromJSONTyped;
function GranulatFarbeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'bezeichnung': value.bezeichnung,
        'batch_nummer': value.batch_nummer,
        'preis_pro_kg': value.preis_pro_kg,
        'lieferant_database_id': value.lieferant_database_id,
        'lieferant_version_id': value.lieferant_version_id,
        'bestand': value.bestand,
        'aenderungsgrund': value.aenderungsgrund,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'lieferant': _1.LieferantToJSON(value.lieferant),
    };
}
exports.GranulatFarbeToJSON = GranulatFarbeToJSON;
