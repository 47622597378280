/* eslint-disable react/display-name */
import { DataModels } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H2,
  Spinner,
} from '@blueprintjs/core';
import React from 'react';
import { KundenauftragStornierungAbfragen, KundenauftragdetailsAngeben } from '.';
import {
  ProduktionsauftragBlasenErfassen,
  ProduktionsauftragBlasenVerpackungsVorschriftWaehlen,
  ProduktionsauftragErfassen,
  ProduktionsauftragExternErfassen,
  ProduktionsauftragLagerErfassen,
} from '..';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  IdentityProvider,
  ModalProps,
  PropsWithTransaction,
  RenderSequentialUserTasks,
  withBoundProps,
} from '../../infrastructure';
import { PropsWithServices, withServices } from '../../infrastructure/withServices';


export type KundenauftragAnlegenModalResult = {
  kundenauftrag_erstellt: boolean
};


export type KundenauftragAnlegenModalProps = PropsWithTransaction<PropsWithServices<ModalProps<KundenauftragAnlegenModalResult>>> &
{
  getIdentity: IdentityProvider;
  correlationId: string;
};

export class KundenauftragAnlegenModal extends React.Component<KundenauftragAnlegenModalProps> {

  private abort = (): void => {
    this.props.onModalFinished({ kundenauftrag_erstellt: false });
  };

  private finish = (): void => {
    this.props.onModalFinished({ kundenauftrag_erstellt: true });
  };

  public getComponentForCustomForm = (userTask: DataModels.FlowNodeInstances.UserTaskInstance): React.ComponentType<CustomFormProps> | null => {
    const customForm = userTask.userTaskConfig.customForm;

    const props = {
      subscriptionEventService: this.props.subscriptionEventService,
      atlasEngineClient: this.props.atlasEngineClient,
      config: this.props.config,
      getIdentity: this.props.getIdentity,
      services: this.props.services,
      transactionService: this.props.transactionService,
    };

    if (customForm === 'wacoplast-wws.KundenauftragdetailsAngeben') {
      return withBoundProps(KundenauftragdetailsAngeben, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragErfassen') {
      return withBoundProps(ProduktionsauftragErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragLagerMengeErfassen') {
      return withBoundProps(ProduktionsauftragLagerErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragBlasenErfassen') {
      return withBoundProps(ProduktionsauftragBlasenErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragExternMengeErfassen') {
      return withBoundProps(ProduktionsauftragExternErfassen as any, props);
    }

    if (customForm === 'wacoplast-wws.RückmeldungVerpackungsVorschriftWählen') {
      return withServices(withBoundProps(ProduktionsauftragBlasenVerpackungsVorschriftWaehlen as any, { onFinish: this.finish, onAbort: this.abort }));
    }

    if (customForm === 'wacoplast-wws.KundenauftragStornierungAbfragen') {
      return withBoundProps(KundenauftragStornierungAbfragen as any, { ...props, finish: this.finish });
    }

    if (customForm === 'wacoplast-wws.KundenauftragErfolgreichAngelegt') {
      return (props: CustomFormProps<{ kundenauftrag_nummer: string }>) => {
        return (
          <Dialog
            isOpen={true}
            onClose={() => props.abortUserTask()}
            title='Kundenauftrag anlegen'
            isCloseButtonShown={!props.disabled}
          >
            <DialogBody>
              <H2>Der Kundenauftrag {props.tokenPayload.kundenauftrag_nummer} wurde erfolgreich angelegt</H2>
            </DialogBody>
            <DialogFooter
              onFinish={() => props.finishUserTask({})}
              disabled={props.disabled}
            />
          </Dialog>
        );
      };
    }

    if (customForm === 'wacoplast-wws.ProduktionsauftragErfasstHinweis') {
      return (props: CustomFormProps<{ vpv_required: boolean, produktionsauftrag_nummern: Array<string> }>) => {
        return (
          <Dialog
            isOpen={true}
            onClose={() => props.abortUserTask()}
            title='Kundenauftrag anlegen'
            isCloseButtonShown={!props.disabled}
          >
            <DialogBody>
              <H2>
                {props.tokenPayload.produktionsauftrag_nummern.length > 1 &&
                  <>Die Produktionsaufträge {props.tokenPayload.produktionsauftrag_nummern.join(' und ')} wurden erfolgreich angelegt</>
                }
                {props.tokenPayload.produktionsauftrag_nummern.length === 1 &&
                  <>Der Produktionsauftrag {props.tokenPayload.produktionsauftrag_nummern[0]} wurde erfolgreich angelegt</>
                }
                {props.tokenPayload.vpv_required && <>, Verpackung fehlt noch</>}
              </H2>
            </DialogBody>
            <DialogFooter
              onFinish={async () => {
                await props.finishUserTask({});
                if (!props.tokenPayload.vpv_required) {
                  this.finish();
                }
              }}
              disabled={props.disabled}
            />
          </Dialog>
        );
      };
    }

    return null;
  };


  public render(): JSX.Element | null {
    const loadingModal = (
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {this.props.correlationId && <Button onClick={this.abort} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    );

    if (!this.props.correlationId) {
      return loadingModal;
    }

    return (
      <RenderSequentialUserTasks
        atlasEngineClient={this.props.atlasEngineClient}
        correlationId={this.props.correlationId}
        getIdentity={this.props.getIdentity}
        getComponent={this.getComponentForCustomForm}
        handleUserTaskAborted={async (api, userTask, result) => {
          await api.finishUserTask({ response: 'abort', ...result });
          if (userTask.userTaskConfig.customForm === 'wacoplast-wws.KundenauftragdetailsAngeben') {
            this.abort();
          } else {
            this.finish();
          }
        }}
      >
        {loadingModal}
      </RenderSequentialUserTasks>
    );
  }
}
