import { CreateWerkstoffTyp, WerkstoffTyp } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';


export type WerkstoffTypVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function WerkstoffTypVerwalten(props: WerkstoffTypVerwaltenProps): JSX.Element {
  const werkstoffResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffe.getAllWerkstoffGet, props.services.werkstoffe);

  const editors: StammdatenFieldEditors<CreateWerkstoffTyp> = [
    {
      type: 'complex',
      fields: ['werkstoff_database_id', 'werkstoff_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(werkstoffResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung ?? '', secondaryTitle: data.nummer };
      }, 'werkstoff', true),
      label: 'Werkstoff',
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'verwendung',
      renderer: makeTextEditorRenderer(),
      label: 'Verwendung',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Werkstoff',
          colId: 'werkstoff',
          ...makeForeignKeyColumn('werkstoff', (data: WerkstoffTyp) => {
            return {
              description: data.werkstoff?.bezeichnung ?? '',
              number: data.werkstoff?.nummer ?? '',
            };
          }),
        },
        {
          headerName: 'Verwendung',
          field: 'verwendung',
          colId: 'verwendung',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateWerkstoffTyp) => props.services.werkstoffTyp.postWerkstoffTypPost({ CreateWerkstoffTyp: data }),
        update: (data) => props.services.werkstoffTyp.putByDatabaseIdWerkstoffTypDatabaseIdPut({ database_id: data.database_id, UpdateWerkstoffTyp: data }),
        delete: (data, ignoreIntegrity) => props.services.werkstoffTyp.deleteByDatabaseIdWerkstoffTypDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.werkstoffTyp.restoreByDatabaseIdWerkstoffTypDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.werkstoffTyp.getAllWerkstoffTypGet({ include_deleted }),
        getHistory: (data) => props.services.werkstoffTyp.getHistoryWerkstoffTypDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.werkstoffTyp.downloadExcelWerkstoffTypExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Werkstofftyp}
    />
  );
}
