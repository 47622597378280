import {
  Button,
  Dialog,
  H2,
  H5,
} from '@blueprintjs/core';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  formatDateTimeString,
} from '../../../infrastructure';

import { MaschinenlaufplanVorschauBestaetigenResult } from 'wacoplast_wws__api';

export function MaschinenlaufplanVorschauBestaetigen(props: PropsWithServices<CustomFormProps<MaschinenlaufplanVorschauBestaetigenResult>> & ModalProps<void>): JSX.Element {
  const finish = (): Promise<void> => {
    return props.finishUserTask({ vorschau_korrekt: true });
  };

  const decline = (): Promise<void> => {
    return props.finishUserTask({ vorschau_korrekt: false });
  };

  return (
    <Dialog
      isOpen
      onClose={() => props.onModalFinished()}
      title='Papiere drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <div>
          <H2>Die Maschinenlaufplan Vorschau kann nun geprüft werden. Ist sie korrekt?</H2>
          <div>
            {props.userTask.startedAt && <H5>Generiert am {formatDateTimeString(props.userTask.startedAt)}:</H5>}
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/maschinenlaufplan_vorschau/${props.userTask.correlationId}`, '_blank')}>Ansehen</Button>
          </div>
        </div>
      </DialogBody>
      <DialogFooter
        onAbort={props.onModalFinished}
        disabled={props.disabled}
      >
        <AsyncButton onClick={decline} disabled={props.disabled}>Nein, Korrektur vornehmen</AsyncButton>
        <AsyncButton intent='primary' onClick={finish} disabled={props.disabled}>Ja</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
