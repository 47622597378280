/* eslint-disable react/display-name */
import React from 'react';

export type StammdatenFieldEditorSimpleRendererProps<TEntityType, TFieldType> = {
  stammdatenEntity: TEntityType;
  value: TFieldType;
  onChange: (value: TFieldType) => void;
  disabled?: boolean;
};

export type StammdatenFieldEditorSimpleRenderer<TEntityType, TFieldType> = React.ComponentType<StammdatenFieldEditorSimpleRendererProps<TEntityType, TFieldType>>;

export type StammdatenFieldEditorSimple<TEntityType, TFieldName extends keyof TEntityType> = {
  type: 'simple';
  field: keyof TEntityType;
  renderValidationResult?: boolean;
  renderer: StammdatenFieldEditorSimpleRenderer<TEntityType, TEntityType[TFieldName]>;
  label?: string;
  notEditable?: boolean;
  gridArea?: string;
};

export type StammdatenFieldEditorComplexRendererProps<TEntityType> = {
  stammdatenEntity: TEntityType;
  onChange: (updatedPartialEntity: Partial<TEntityType>) => void;
  disabled?: boolean;
};

export type StammdatenFieldEditorComplexRenderer<TEntityType> = React.ComponentType<StammdatenFieldEditorComplexRendererProps<TEntityType>>;

export type StammdatenFieldEditorComplex<TEntityType> = {
  type: 'complex';
  fields: Array<keyof TEntityType>;
  renderValidationResult?: boolean;
  renderer: StammdatenFieldEditorComplexRenderer<TEntityType>;
  label?: string;
  notEditable?: boolean;
  gridArea?: string;
};

export type StammdatenFieldLabel = {
  type: 'label';
  label: string;
  gridArea: string;
};

export type StammdatenFieldSeperator = {
  type: 'seperator';
  gridArea: string;
}

export type StammdatenFieldEditors<TEntityType> = Array<StammdatenFieldEditorSimple<TEntityType, any> | StammdatenFieldEditorComplex<TEntityType> | StammdatenFieldLabel | StammdatenFieldSeperator>;

export const AUTOMATIC_GENERATION_PLACEHOLDER = 'Dieser Wert ist unveränderlich';
