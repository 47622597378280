import { CreateWerkstoff } from 'wacoplast_wws__api';

import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeTextEditorRenderer,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';


export type WerkstoffVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function WerkstoffVerwalten(props: WerkstoffVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateWerkstoff> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateWerkstoff) => props.services.werkstoffe.postWerkstoffPost({ CreateWerkstoff: data }),
        update: (data) => props.services.werkstoffe.putByDatabaseIdWerkstoffDatabaseIdPut({ database_id: data.database_id, UpdateWerkstoff: data }),
        delete: (data, ignoreIntegrity) => props.services.werkstoffe.deleteByDatabaseIdWerkstoffDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.werkstoffe.restoreByDatabaseIdWerkstoffDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.werkstoffe.getAllWerkstoffGet({ include_deleted }),
        getHistory: (data) => props.services.werkstoffe.getHistoryWerkstoffDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.werkstoffe.downloadExcelWerkstoffExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Werkstoff}
    />
  );
}
