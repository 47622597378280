"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rueckmeldung2DatenToJSON = exports.Rueckmeldung2DatenFromJSONTyped = exports.Rueckmeldung2DatenFromJSON = void 0;
const runtime_1 = require("../runtime");
function Rueckmeldung2DatenFromJSON(json) {
    return Rueckmeldung2DatenFromJSONTyped(json, false);
}
exports.Rueckmeldung2DatenFromJSON = Rueckmeldung2DatenFromJSON;
function Rueckmeldung2DatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'kunde_bestell_nummer': !runtime_1.exists(json, 'kunde_bestell_nummer') ? undefined : json['kunde_bestell_nummer'],
        'bestelldatum': (new Date(json['bestelldatum'])),
        'kunde_name': json['kunde_name'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'flaschentyp_nummer': json['flaschentyp_nummer'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'volumen': json['volumen'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'verpackungs_einheit_bezeichnung': json['verpackungs_einheit_bezeichnung'],
    };
}
exports.Rueckmeldung2DatenFromJSONTyped = Rueckmeldung2DatenFromJSONTyped;
function Rueckmeldung2DatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'kunde_bestell_nummer': value.kunde_bestell_nummer,
        'bestelldatum': (value.bestelldatum.toISOString()),
        'kunde_name': value.kunde_name,
        'empfaenger_name': value.empfaenger_name,
        'flaschentyp_nummer': value.flaschentyp_nummer,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'volumen': value.volumen,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'verpackungs_einheit_bezeichnung': value.verpackungs_einheit_bezeichnung,
    };
}
exports.Rueckmeldung2DatenToJSON = Rueckmeldung2DatenToJSON;
