import { ModalProps, PropsWithServices, useFetchLikeServiceFunction } from '../../infrastructure';
import { Produktionsauftrag } from 'wacoplast_wws__api';
import { VerpackungsVorschriftModal } from '../plantafel/modals';
import { useCallback } from 'react';

export type VerpackungsVorschriftAnzeigenModalProps = PropsWithServices<ModalProps<void>> & { produktionsauftrag: Produktionsauftrag };

export function VerpackungsVorschriftAnzeigenModal(props: VerpackungsVorschriftAnzeigenModalProps): JSX.Element {
  const serviceFunction = useCallback((initOverrides?: RequestInit) => props.services.produktionsauftrag.getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGet({ database_id: props.produktionsauftrag.database_id }, initOverrides), [props.services.produktionsauftrag, props.produktionsauftrag.database_id]);
  const produktionsauftrag = useFetchLikeServiceFunction(serviceFunction, null);
  return (
    <VerpackungsVorschriftModal
      verpackungsVorschrift={produktionsauftrag.data?.verpackungs_vorschrift}
      nummer={`PAU ${produktionsauftrag.data?.nummer}`}
      onModalFinished={props.onModalFinished}
      services={props.services}
      atlasEngineClient={props.atlasEngineClient}
      config={props.config}
      subscriptionEventService={props.subscriptionEventService}
    />
  );
}
