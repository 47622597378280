import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Dialog,
  H1,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { AufdruckArt, MaschinenlaufplanVorschauDatenLadenResult, MaschinenlaufplanVorschauDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import styles from './MLPVorschauPrint.module.scss';
import logo from './assets/mlp_logo.jpg';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function MaschinenlaufplanVorschauPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.MaschinenlaufplanVorschauBestaetigen') {
          return MaschinenlaufplanVorschau;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function MaschinenlaufplanVorschau(props: CustomFormProps<MaschinenlaufplanVorschauDatenLadenResult>): JSX.Element {
  const payload = MaschinenlaufplanVorschauDatenLadenResultFromJSON(props.tokenPayload);

  return (
    <div className={styles.page_background}>
      <div className={styles.page_header}>
        <img className={styles.logo} src={logo} alt='Logo' />
        <H1 className={styles.page_title}>Daten für Maschinenlaufplan</H1>
        <div className={styles.header_spacer} />
      </div>
      <HTMLTable className={styles.mlp_vorschau_table}>
        <tbody>
          <tr className={styles.kuau_pau_au_art_row}>
            <th className={styles.kuau_pau_au_art_header}>KUAU- / PAU-NR. / AuArt</th>
            <td>{payload.kundenauftrag_nummer}</td>
            <td>{payload.produktionsauftrag_nummer}</td>
            <td className={styles.kundenauftrag_art}>{payload.kundenauftrag_art}</td>
            <th className={styles.blasmaschine_nr_header} colSpan={3}>Blasmaschine - Nr.</th>
            <td className={styles.blasmaschine_nr}>{payload.blasmaschine_nummer}</td>
          </tr>
          <tr>
            <th>Kunde</th>
            <td colSpan={2}>{payload.kunde_name}</td>
            <td rowSpan={11} className={styles.empty_cell} />
            <th className={styles.liefertermin}>Liefertermin</th>
            <td>{payload.liefertermin ? formatDateString(payload.liefertermin) : ''}</td>
            <td colSpan={2} rowSpan={2} className={styles.gray_box} />
          </tr>
          <tr>
            <th>Empfänger</th>
            <td colSpan={2}>{payload.empfaenger_name}</td>
            <th>PAU-Menge</th>
            <td>{formatNumber(payload.menge_produktionsauftrag)}</td>
          </tr>
          <tr>
            <th>Bestelldatum</th>
            <td colSpan={2}>{formatDateString(payload.bestelldatum)}</td>
            <th>Farbe</th>
            <td colSpan={3}>{payload.farbe_bezeichnung}</td>
          </tr>
          <tr>
            <th>KU-Bestell Nr.</th>
            <td colSpan={2}>{payload.kunde_bestell_nummer}</td>
            <th>Farb- / Batch- / Platz-Nr.</th>
            <td className={styles.width_third}>{payload.farbe_nummer}</td>
            <td className={styles.width_third}>{payload.farbe_batch_nummer}</td>
            <td className={styles.width_third} />
          </tr>
          <tr>
            <th>EMPF-AU-Nr</th>
            <td colSpan={2}>{payload.empfaenger_auftrags_nummer}</td>
            <th>Einfärbung [%]</th>
            <td>{formatNumber(payload.einfaerbungsgrad, 1)}</td>
            <td colSpan={2} className={styles.gray_box} />
          </tr>
          <tr>
            <th>ART-Nr. ( KU <span className={styles.white_bar}>|</span> EMPF )</th>
            <td>{payload.artikel_nummer_kunde}</td>
            <td>{payload.artikel_nummer_empfaenger}</td>
            <th>Werkstoff-Typ</th>
            <td colSpan={3}>{!payload.werkstoff_typ_2_bezeichnung ? payload.werkstoff_typ_1_bezeichnung : `1: ${payload.werkstoff_typ_1_bezeichnung}; 2: ${payload.werkstoff_typ_2_bezeichnung}`}</td>
          </tr>
          <tr>
            <th>FL-TYP - Nr.</th>
            <td colSpan={2}>{payload.flaschen_typ_nummer}</td>
            <th>Werkstoff</th>
            <td colSpan={3}>{payload.werkstoff_bezeichnung}</td>
          </tr>
          <tr>
            <th>FL-TYP - Bez</th>
            <td colSpan={2}>{payload.flaschen_typ_bezeichnung}</td>
            <td colSpan={4} rowSpan={4} className={styles.empty_cell} />
          </tr>
          <tr>
            <th className={styles.text_right_align}>Form-Spez.</th>
            <td>{payload.blindenzeichen_bezeichnung}</td>
            <td className={styles.empty_cell} />
          </tr>
          <tr>
            <th className={styles.text_right_align}>Vol.[ml] <span className={styles.white_bar}>|</span> Gewinde</th>
            <td>{formatNumber(payload.volumen)}</td>
            <td>{payload.halsteil_bezeichnung}</td>
          </tr>
          <tr>
            <th>Gewicht <span className={styles.white_bar}>|</span> int. Toleranz [g]</th>
            <td>{formatNumber(payload.gewicht, 1)}</td>
            <td className={styles.toleranz}>&#177;{payload.toleranz}</td>
          </tr>
          <tr className={styles.transport_header}>
            <th className={styles.transport_weg}>{payload.transportweg}</th>
            <td colSpan={5} />
            <th colSpan={2} className={styles.fahrzeug}>{payload.fahrzeug_bezeichnung}</th>
          </tr>
          {payload.karton_aufdruck_art !== undefined
            ? (
              <>
                <tr className={styles.verpackung_row}>
                  <th>Verpackungs-Einheit</th>
                  <td colSpan={4} className={styles.vpe}>{payload.verpackungs_einheit_bezeichnung}</td>
                  <td colSpan={2} className={styles.empty_cell} />
                  <th className={styles.ku_vorgaben}>KU-Vorgaben</th>
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Karton-Ausführung</th>
                  <td className={styles.text_left_align}>
                    {payload.karton_aufdruck_art !== AufdruckArt.egal && <>{payload.karton_aufdruck_art} </>}
                    Druck
                    {payload.karton_aufdruck_art === AufdruckArt.egal && <> {payload.karton_aufdruck_art}</>}
                  </td>
                  <td colSpan={2} rowSpan={6} className={styles.empty_cell} />
                  <th>Stück je Karton</th>
                  <td colSpan={2}>{payload.flaschen_pro_vpe}</td>
                  <td className={styles.empty_cell}>{payload.fuellweise}</td>
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Karton-Zustand 1</th>
                  <td className={styles.text_left_align}>{payload.vpe_zustand}</td>
                  <th>Anzahl Lagen</th>
                  <td>{formatNumber(payload.anzahl_lagen_1) ?? 0}</td>
                  <td>{formatNumber(payload.anzahl_lagen_2)}</td>
                  <td className={styles.anzahl_lagen_vorgabe}>{payload.anzahl_lagen_vorgabe}</td>
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Karton-Zustand 2</th>
                  <td className={styles.text_left_align}>{payload.karton_geschlossen ? 'geschlossen' : 'offen'}</td>
                  <th>Kartons pro Stapel</th>
                  <td>{formatNumber(payload.vpes_pro_stapel_1) ?? 0}</td>
                  <td>{formatNumber(payload.vpes_pro_stapel_2)}</td>
                  <td rowSpan={2} className={styles.empty_cell} />
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Polybeutel</th>
                  <td className={styles.text_left_align}>{payload.karton_polybeutel ? 'ja' : 'nein'}</td>
                  <th>Anzahl Flaschen pro Stapel</th>
                  <td>{formatNumber(payload.flaschen_pro_stapel_1) ?? 0}</td>
                  <td>{formatNumber(payload.flaschen_pro_stapel_2)}</td>
                </tr>
              </>
            )
            : (
              <>
                <tr className={styles.verpackung_row}>
                  <th>Verpackungs-Einheit</th>
                  <td colSpan={4} className={styles.vpe}>{payload.verpackungs_einheit_bezeichnung}</td>
                  <td colSpan={2} className={styles.empty_cell} />
                  <th className={styles.ku_vorgaben}>KU-Vorgaben</th>
                </tr>
                <tr className={styles.verpackung_row}>
                  <td colSpan={4} className={styles.empty_cell} />
                  <th>Stück je Tray</th>
                  <td colSpan={2}>{formatNumber(payload.flaschen_pro_vpe) ?? 0}</td>
                  <td className={styles.empty_cell}>{payload.fuellweise}</td>
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Karton (TR / D) -Zust.</th>
                  <td className={styles.text_left_align}>{payload.vpe_zustand}</td>
                  <td className={styles.text_left_align}>{payload.tray_abdeckung_zustand}</td>
                  <td className={styles.empty_cell} />
                  <th>Anzahl Lagen</th>
                  <td>{formatNumber(payload.anzahl_lagen_1) ?? 0}</td>
                  <td>{formatNumber(payload.anzahl_lagen_2)}</td>
                  <td className={styles.anzahl_lagen_vorgabe}>{payload.anzahl_lagen_vorgabe}</td>
                </tr>
                <tr className={styles.verpackung_row}>
                  <td colSpan={4} rowSpan={2} className={styles.empty_cell} />
                  <th>Trays pro Stapel</th>
                  <td>{formatNumber(payload.vpes_pro_stapel_1) ?? 0}</td>
                  <td>{formatNumber(payload.vpes_pro_stapel_2)}</td>
                  <td rowSpan={2} className={styles.empty_cell} />
                </tr>
                <tr className={styles.verpackung_row}>
                  <th>Anzahl Flaschen pro Stapel</th>
                  <td>{formatNumber(payload.flaschen_pro_stapel_1) ?? 0}</td>
                  <td>{formatNumber(payload.flaschen_pro_stapel_2)}</td>
                </tr>
              </>
            )
          }
          <tr className={styles.verpackung_row}>
            <td rowSpan={2} colSpan={4} className={styles.empty_cell} />
            <th className={styles.text_right_align}>Höhe des Stapels [mm]</th>
            <td>{formatNumber(payload.hoehe_stapel_1) ?? 0}</td>
            <td>{formatNumber(payload.hoehe_stapel_2)}</td>
            <td className={styles.hoehe_stapel_vorgabe} />
          </tr>
          <tr className={styles.verpackung_row}>
            <th className={styles.anzahl_stapel_flaschen_header}>Anzahl Stapel <span className={styles.white_bar}>|</span> <span className={styles.anzahl_flaschen_header}>Anzahl Flaschen</span></th>
            <td className={styles.anzahl_stapel}>{formatNumber(payload.anzahl_stapel_1) ?? 0}</td>
            <td className={styles.anzahl_stapel}>{formatNumber(payload.anzahl_stapel_2)}</td>
            <td className={styles.anzahl_flaschen}>{formatNumber(payload.anzahl_flaschen)}</td>
          </tr>
          <tr className={styles.verpackung_row}>
            <td colSpan={2} rowSpan={7} className={styles.empty_cell} />
            <th>Kantenschutz</th>
            <td colSpan={2}>{payload.kantenschutz_material_bezeichnung ? 'ja' : 'nein'}</td>
            <td colSpan={2} className={styles.kantenschutz_material_bezeichnung}>{payload.kantenschutz_material_bezeichnung}</td>
            <td className={styles.anzahl_kartons}>{payload.karton_aufdruck_art ? <>{formatNumber(payload.anzahl_kartons)} Kartons</> ?? 0 : null}</td>
          </tr>
          <tr className={styles.verpackung_row}>
            <th>Stretcher Nr.</th>
            <td colSpan={2}>{payload.folie_material_bezeichnung && (
              <>
                {payload.kantenschutz_material_bezeichnung ? <>1,2,3</> : <>2,3</>}
              </>)}
            </td>
            <td colSpan={2} className={styles.folie_material_bezeichnung}>{payload.folie_material_bezeichnung}</td>
            <td rowSpan={2} className={styles.empty_cell} />
          </tr>
          <tr className={styles.verpackung_row}>
            <th>Höhe LE [mm] / <span className={styles.stellplaetze}>Stellplätze</span></th>
            <td colSpan={2}>{formatNumber(payload.hoehe_ladeeinheit)} / {formatNumber(payload.anzahl_stellplaetze) ?? 0}</td>
            <td colSpan={2} className={styles.bemerkung_header}>Bemerkung</td>
          </tr>
          <tr className={styles.verpackung_row}>
            <td colSpan={3} className={styles.empty_cell} />
            <td colSpan={3} rowSpan={3} className={styles.bemerkung}>{payload.bemerkung.split('\n').map(line => (<>{line}<br /></>))}</td>
          </tr>
          <tr>
            <td colSpan={3} className={styles.empty_cell} />
          </tr>
          <tr className={styles.verpackung_row}>
            <th>LKW</th>
            <td colSpan={2}>{payload.fahrzeug_bezeichnung}</td>
          </tr>
          <tr className={styles.verpackung_row}>
            <th>Ladehöhe [mm]</th>
            <td colSpan={2}>{formatNumber(payload.fahrzeug_ladehoehe)}</td>
            <td colSpan={3} className={styles.empty_cell} />
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}
