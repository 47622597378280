"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbmusterungDatenToJSON = exports.AbmusterungDatenFromJSONTyped = exports.AbmusterungDatenFromJSON = void 0;
const runtime_1 = require("../runtime");
function AbmusterungDatenFromJSON(json) {
    return AbmusterungDatenFromJSONTyped(json, false);
}
exports.AbmusterungDatenFromJSON = AbmusterungDatenFromJSON;
function AbmusterungDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'kunde_name': json['kunde_name'],
        'bestelldatum': (new Date(json['bestelldatum'])),
        'bestell_nummer': !runtime_1.exists(json, 'bestell_nummer') ? undefined : json['bestell_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'farbe_batch_nummer': !runtime_1.exists(json, 'farbe_batch_nummer') ? undefined : json['farbe_batch_nummer'],
        'artikel_gewicht': json['artikel_gewicht'],
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'einfaerbungsgrad': !runtime_1.exists(json, 'einfaerbungsgrad') ? undefined : json['einfaerbungsgrad'],
        'farbe_nummer': json['farbe_nummer'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'blasmaschine_nummer': json['blasmaschine_nummer'],
    };
}
exports.AbmusterungDatenFromJSONTyped = AbmusterungDatenFromJSONTyped;
function AbmusterungDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'kunde_name': value.kunde_name,
        'bestelldatum': (value.bestelldatum.toISOString()),
        'bestell_nummer': value.bestell_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'farbe_batch_nummer': value.farbe_batch_nummer,
        'artikel_gewicht': value.artikel_gewicht,
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'einfaerbungsgrad': value.einfaerbungsgrad,
        'farbe_nummer': value.farbe_nummer,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'blasmaschine_nummer': value.blasmaschine_nummer,
    };
}
exports.AbmusterungDatenToJSON = AbmusterungDatenToJSON;
