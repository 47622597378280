import { Checkbox, ControlGroup, Spinner } from '@blueprintjs/core';
import { Blindenzeichen, FlaschentypBlindenzeichenAssociation } from 'wacoplast_wws__api';
import { StammdatenFieldEditorSimpleRendererProps, UseFetchLikeServiceFunctionResult, getDefaultDisplayData } from '../../infrastructure';

export function makeMoeglicheBlindenzeichenSelector<TEntityType>(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>,
  getItemData: getDefaultDisplayData<Blindenzeichen>,
): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, Array<FlaschentypBlindenzeichenAssociation> | null>) => {

    if (dataProvider.isLoading) {
      return <Spinner />;
    }

    return (
      <ControlGroup>
        {
          dataProvider.data?.map((blindenzeichen, index) => {
            return (
              <Checkbox
                style={{ marginRight: '20px' }}
                disabled={props.disabled}
                inline
                key={index}
                label={getItemData(blindenzeichen).primaryTitle}
                checked={props.value !== null && props.value !== undefined && props.value.findIndex((association) => association.blindenzeichen_database_id === blindenzeichen.database_id && association.blindenzeichen_version_id === blindenzeichen.version_id) !== -1}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    props.onChange([...(props.value ?? []), { blindenzeichen_database_id: blindenzeichen.database_id, blindenzeichen_version_id: blindenzeichen.version_id }]);
                  } else {
                    props.onChange(props.value?.filter((association) => association.blindenzeichen_database_id !== blindenzeichen.database_id || association.blindenzeichen_version_id !== blindenzeichen.version_id) ?? []);
                  }
                }}
              />
            );
          })
        }
      </ControlGroup>
    );
  };
}
