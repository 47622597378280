/* eslint-disable react/display-name */
import { Dialog } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { Kundenauftrag } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  PropsWithServices,
  PropsWithTransaction,
  SidepanelFormCard,
  UseFetchLikeServiceFunctionResult,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { KundenauftragInformationTable } from '../../..';

export type ProduktionsauftragExternBestellungEingangRueckmeldenProps = PropsWithTransaction<PropsWithServices<CustomFormProps<{ kundenauftrag_database_id: number }> & { finish: () => void }>>;
type ProduktionsauftragExternBestellungEingangRueckmeldenCardProps = ProduktionsauftragExternBestellungEingangRueckmeldenProps & { kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>};

class ProduktionsauftragExternBestellungEingangRueckmeldenCard extends React.PureComponent<ProduktionsauftragExternBestellungEingangRueckmeldenCardProps> {

  private submit = async (): Promise<void> => {
    await this.props.finishUserTask({});
    this.props.finish();
  };

  public render(): JSX.Element {
    return (
      <Dialog
        isOpen={true}
        onClose={() => this.props.abortUserTask()}
        title='Externe Bestellung: Eingang rückmelden'
        isCloseButtonShown={!this.props.disabled}
      >
        <DialogBody>
          <SidepanelFormCard
            title='Externe Bestellung: Eingang rückmelden'
            footer={<DialogFooter onFinish={this.submit} onAbort={this.props.abortUserTask} disabled={this.props.disabled} />}
            sidepanel={<KundenauftragInformationTable {...this.props} kundenauftrag={this.props.kundenauftrag} />}
          />
        </DialogBody>
      </Dialog>
    );
  }
}

export function ProduktionsauftragExternBestellungEingangRueckmelden(props: ProduktionsauftragExternBestellungEingangRueckmeldenProps): JSX.Element {
  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const serviceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(serviceFunction, null);

  return <ProduktionsauftragExternBestellungEingangRueckmeldenCard {...props} kundenauftrag={kundenauftrag} />;
}
