import { Classes } from '@blueprintjs/core';
import { ICellEditorParams } from 'ag-grid-community';
import React from 'react';
import { PlantafelEntry, WerkstoffTyp } from 'wacoplast_wws__api';
import { DefaultDropDownPicker, formatWerkstoffTypNummer } from '../../../infrastructure';

export type AgGridWerkstoffTypEditorProps = Omit<ICellEditorParams, 'data' | 'value'> & {
  data: PlantafelEntry;
  value: WerkstoffTyp;
  werkstoffTypen: WerkstoffTyp[];
  fieldName: 'werkstoff_typ_1' | 'werkstoff_typ_2';
}

type AgGridWerkstoffTypEditorState = {
  werkstoffTyp: WerkstoffTyp;
};

export class AgGridWerkstoffTypEditor extends React.Component<AgGridWerkstoffTypEditorProps, AgGridWerkstoffTypEditorState> {

  constructor(props: AgGridWerkstoffTypEditorProps) {
    super(props);

    this.state = {
      werkstoffTyp: props.value,
    };
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  public getValue(): WerkstoffTyp {
    return this.state.werkstoffTyp;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  public isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  public isCancelAfterEnd(): boolean {
    return false;
  }

  public render(): JSX.Element {
    const matchingWerkstoff = this.props.data.produktionsauftrag_blasen.kundenauftrag.artikel.werkstoff_mischung[this.props.fieldName === 'werkstoff_typ_1' ? 'werkstoff_1' : 'werkstoff_2'];
    const filteredWerkstoffTypen = this.props.werkstoffTypen.filter((x) => x.werkstoff_database_id === undefined || x.werkstoff_database_id === matchingWerkstoff?.database_id);
    return (
      <div className={`ag-cell ${Classes.SMALL}`} style={{ width: `${this.props.column.getActualWidth()}px` }}>
        <DefaultDropDownPicker
          dataProvider={{ data: filteredWerkstoffTypen, error: null, isLoading: false, reload: () => {} }}
          getDisplayData={(werkstoff_typ) => ({ primaryTitle: werkstoff_typ.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoff_typ.nummer) })}
          onChange={(werkstoff_typ: any) => {
            this.setState({ werkstoffTyp: werkstoff_typ }, () => {
              this.props.api.stopEditing();
            });
          }}
          value={this.state.werkstoffTyp}
        />
      </div>
    );
  }
}
