import { Dialog, H2 } from '@blueprintjs/core';
import { ProduktionsauftragBlasenIstLetzteTeilrueckmeldungAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungIstLetzteTeilrueckmeldungAbfragen(
  props: CustomFormProps<{ anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Ist dies die letzte Teilrückmeldung?</H2>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstLetzteTeilrueckmeldungAbfragenResultToJSON({ ist_letzte_teilrueckmeldung: false }))} disabled={props.disabled}>Nein</AsyncButton>
        <AsyncButton onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstLetzteTeilrueckmeldungAbfragenResultToJSON({ ist_letzte_teilrueckmeldung: true }))} disabled={props.disabled} intent='primary'>Ja</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
