import { Dialog, H2 } from '@blueprintjs/core';
import { RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungDurchTechnischesProblemUnterbrochenErfragen(
  props: CustomFormProps<{ anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Wurde die Produktion durch ein technisches Problem unterbrochen?</H2>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => props.finishUserTask(RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON({ technische_unterbrechung: true }))} disabled={props.disabled}>Ja</AsyncButton>
        <AsyncButton onClick={() => props.finishUserTask(RueckmeldungDurchTechnischesProblemUnterbrochenErfragenResultToJSON({ technische_unterbrechung: false }))} disabled={props.disabled} intent='primary'>Nein</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
