import {
  Button,
  Dialog,
  HTMLTable,
  Tag,
} from '@blueprintjs/core';
import { formatNumber } from '../../../../infrastructure/formatNumber';
import { Lagerposten } from 'wacoplast_wws__api';
import { DialogBody, DialogFooter, formatDateString } from '../../../../infrastructure';

export type LagerpostenDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  data: Array<Lagerposten>;
}

export function LagerpostenDialog(props: LagerpostenDialogProps): JSX.Element {
  return (props.isOpen && (
    <Dialog title='Ähnliche Lagerposten'
      style={
        { maxWidth: '66vw', width: 'auto', maxHeight: '80vh' }}
      isOpen={true}
      onClose={props.onClose}
    >
      <DialogBody scrollHorizontalOverflow>
        <HTMLTable striped bordered>
          <thead style={{ position: 'sticky', backgroundColor: '#edeff2', top: 0, zIndex: 999999 }}>
            <th>PAU</th>
            <th>KUAU</th>
            <th>Kunde</th>
            <th>Reserviert für</th>
            <th>Reserviert durch</th>
            <th>Reserviert am</th>
            <th>gesperrt</th>
            <th>Hinweis</th>
            <th>Empf.</th>
            <th>Bestell-Nr.</th>
            <th>Kunden-Artikel-Nr.</th>
            <th>FL-Form-Nr.</th>
            <th>FL-Typ-Bez.</th>
            <th>HT-Bez.</th>
            <th>BLZ</th>
            <th>Gewicht [g]</th>
            <th>Farb-Nr.</th>
            <th>Farb-%</th>
            <th>Werkstofftyp</th>
            <th>VPE</th>
            <th>Stück je VPE</th>
            <th>Anz. VPE je Stapel</th>
            <th>Stück je Stapel</th>
            <th>Höhe Stapel</th>
            <th>Anzahl Kartons</th>
            <th>Anzahl Stapel</th>
            <th>Bestand</th>
            <th>Lager-Ort</th>
            <th>Lager-Platz</th>
            <th>Liefertermin Soll</th>
            <th>Lagerzugang-PR</th>
            <th>Auslieferung Datum/Menge #</th>
            <th>Bestellmenge</th>
            <th>Menge Zugang PR (Stück)</th>
            <th>Buchung von</th>
            <th>Buchung am</th>
            <th>Letzer Vorgang</th>
          </thead>
          <tbody>
            {
              props.data.map((lagerEntry, index) => {

                const flaschen_je_stapel = (lagerEntry.stueck_je_vpe ?? 0) * (lagerEntry.anzahl_vpe_je_stapel ?? 0);
                const anzahl_kartons = (lagerEntry.stueck_je_vpe ?? 0) * (lagerEntry.anzahl_stapel ?? 0);
                const bestand = (lagerEntry.stueck_je_vpe ?? 0) * (lagerEntry.anzahl_stapel ?? 0);

                return (
                  <tr key={index}>
                    <td>{lagerEntry.pau_nummer}</td>
                    <td>{lagerEntry.kuau_nummer}</td>
                    <td>{lagerEntry.kunde_name_kurz} <Tag minimal>{lagerEntry.kunde_nummer}</Tag></td>
                    <td>{lagerEntry.reserviert_fuer}</td>
                    <td>{lagerEntry.reserviert_durch}</td>
                    <td>{lagerEntry.reserviert_am}</td>
                    <td>{lagerEntry.gesperrt}</td>
                    <td>{lagerEntry.hinweis}</td>
                    <td>{lagerEntry.empfaenger_name_kurz} <Tag minimal>{lagerEntry.empfaenger_nummer}</Tag></td>
                    <td>{lagerEntry.bestell_nummer}</td>
                    <td>{lagerEntry.kunden_artikelNummer}</td>
                    <td>{lagerEntry.flaschen_form_nummer}</td>
                    <td>{lagerEntry.flaschen_typ_bezeichnung}</td>
                    <td>{lagerEntry.halsteil_bezeichnung}</td>
                    <td>{lagerEntry.blindenzeichen_bezeichnung}</td>
                    <td>{lagerEntry.gewicht}</td>
                    <td>{lagerEntry.farbe_bezeichnung} <Tag minimal>{lagerEntry.farbe_nummer}</Tag></td>
                    <td>{lagerEntry.einfaerbungsgrad}</td>
                    <td>{lagerEntry.werkstoff_typ_bezeichnung}</td>
                    <td>{lagerEntry.verpackungs_einheit_bezeichnung} <Tag minimal>{lagerEntry.verpackungs_einheit_nummer}</Tag></td>
                    <td>{lagerEntry.stueck_je_vpe ? formatNumber(lagerEntry.stueck_je_vpe) : null}</td>
                    <td>{lagerEntry.anzahl_vpe_je_stapel ? formatNumber(lagerEntry.anzahl_vpe_je_stapel) : null}</td>
                    <td>{flaschen_je_stapel ? formatNumber(flaschen_je_stapel) : null}</td>
                    <td>{lagerEntry.hoehe_stapel ? formatNumber(lagerEntry.hoehe_stapel) : null}</td>
                    <td>{anzahl_kartons && lagerEntry.verpackungs_einheit_ist_karton ? formatNumber(anzahl_kartons) : null}</td>
                    <td>{lagerEntry.anzahl_stapel}</td>
                    <td>{bestand ? formatNumber(bestand) : null}</td>
                    <td>{lagerEntry.lagerort}</td>
                    <td>{lagerEntry.lagerplatz}</td>
                    <td>{lagerEntry.liefertermin_soll}</td>
                    <td>{formatDateString(lagerEntry.created_at)}</td>
                    <td>{lagerEntry.auslieferung}</td>
                    <td>{lagerEntry.bestellmenge ? formatNumber(lagerEntry.bestellmenge) : null}</td>
                    <td>{lagerEntry.menge_zugang_pr_stueck ? formatNumber(lagerEntry.menge_zugang_pr_stueck) : null}</td>
                    <td>{lagerEntry.buchung_von}</td>
                    <td>{lagerEntry.buchung_am}</td>
                    <td>{lagerEntry.letzter_vorgang}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </HTMLTable>
      </DialogBody>
      <DialogFooter>
        <Button onClick={props.onClose}>Fertig</Button>
      </DialogFooter>
    </Dialog>
  )) || <></>;
}
