import { Code } from '@blueprintjs/core';
import { ForbiddenError, isAtlasEngineError } from '@atlas-engine/atlas_engine_sdk';


export function getErrorDescription(error: any): {summary: string, details: JSX.Element | string, performableActions: Array<'retry' | 'abort' | 'backToDashboard' | 'reload'>} {
  if (isAtlasEngineError(error)) {
    if (error instanceof ForbiddenError && error?.additionalInformation?.claim) {

      const missingClaim = error?.additionalInformation?.claim;

      return {
        summary: 'Berechtigungsfehler',
        details: <>Für diese Aktion fehlt folgende Berechtigung: <Code>{missingClaim}</Code>.</>,
        performableActions: ['abort', 'backToDashboard'],
      };
    }

    return {
      summary: `${error.name}: ${error.message}`,
      details: <>{JSON.stringify(error, null, '  ')}</>,
      performableActions: ['abort', 'backToDashboard', 'retry'],
    };
  }

  if (Array.isArray(error.detail)) {
    const validationError = error.detail?.[0];
    if (validationError.msg && validationError.type) {
      return {
        summary: validationError.type,
        details: validationError.msg,
        performableActions: ['abort'],
      };
    }
  }

  const errorToString: string = error.detail ?? error.message ?? error.statusText ?? '';
  if (error.status === 401 || errorToString === 'Invalid token or expired token.') {
    return {
      summary: 'Sitzung abgelaufen',
      details: 'Bitte melden Sie sich erneut an.',
      performableActions: ['reload'],
    };
  }

  if (/network/i.test(errorToString) || errorToString === 'Failed to fetch'){
    return {
      summary: 'Netzwerkfehler',
      details: 'Der Server konnte nicht erreicht werden. Bitte erneut versuchen.',
      performableActions: ['abort', 'retry'],
    };
  }

  if (errorToString?.startsWith('Missing Authorization: ')) {
    return {
      summary: 'Berechtigungsfehler',
      details: <>Für diese Aktion fehlt eine Berechtigung. {errorToString.replace('Missing Authorization: ', '')}</>,
      performableActions: ['abort', 'backToDashboard'],
    };
  }

  if (errorToString === 'Unknown address') {
    return {
      summary: 'Unbekannte Adresse',
      details: 'Die angeforderte Adresse ist unbekannt.',
      performableActions: ['backToDashboard'],
    };
  }

  if (errorToString === 'Eintrag noch in Verwendung') {
    return {
      summary: 'Eintrag noch in Verwendung',
      details: 'Der Eintrag kann nicht gelöscht werden.',
      performableActions: ['abort'],
    };
  }

  console.dir(error);

  return {
    summary: 'Unbekannter Fehler',
    details: <>{errorToString}</>,
    performableActions: ['abort', 'backToDashboard', 'retry'],
  };
}
