import { CreateFlaschenTyp } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { makeTextEditorWithSublabelForBLZRenderer } from './';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makeMoeglicheBlindenzeichenSelector } from './MoeglicheBlindenzeichenSelector';

export type FlaschenTypVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FlaschenTypVerwalten(props: FlaschenTypVerwaltenProps): JSX.Element {

  const blindenzeichenResult = useFetchLikeGetAllServiceFunction(props.services.blindenzeichen.getAllBlindenzeichenGet, props.services.blindenzeichen);

  const editors: StammdatenFieldEditors<CreateFlaschenTyp> = [
    {
      type: 'complex',
      fields: ['nummer'],
      renderer: makeTextEditorWithSublabelForBLZRenderer(blindenzeichenResult),
      label: 'Nummer',
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
    {
      type: 'simple',
      field: 'volumen',
      renderer: makeNumberEditorRenderer(),
      label: 'Volumen [ml]',
    },
    {
      type: 'simple',
      field: 'moegliche_blindenzeichen',
      renderer: makeMoeglicheBlindenzeichenSelector(blindenzeichenResult, (data) => ({ primaryTitle: data.bezeichnung, secondaryTitle: data.nummer })),
      label: 'Mögliche Blindenzeichen',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Volumen [ml]',
          field: 'volumen',
          colId: 'volumen',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Mögliche Blindenzeichen',
          field: 'moegliche_blindenzeichen',
          colId: 'blindenzeichen',
          filter: 'agTextColumnFilter',
          valueGetter: (params) => params.data.moegliche_blindenzeichen.map((blz) => blindenzeichenResult.data?.find((b) => b.database_id === blz.blindenzeichen_database_id && b.version_id === blz.blindenzeichen_version_id)?.bezeichnung),
        },
      ]}
      stammdatenService={{
        create: (data: CreateFlaschenTyp) => props.services.flaschenTyp.postFlaschenTypPost({ CreateFlaschenTyp: data }),
        update: (data) => props.services.flaschenTyp.putByDatabaseIdFlaschenTypDatabaseIdPut({ database_id: data.database_id, UpdateFlaschenTyp: data }),
        delete: (data, ignoreIntegrity) => props.services.flaschenTyp.deleteByDatabaseIdFlaschenTypDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.flaschenTyp.restoreByDatabaseIdFlaschenTypDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.flaschenTyp.getAllFlaschenTypGet({ include_deleted }),
        getHistory: (data) => props.services.flaschenTyp.getHistoryFlaschenTypDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.flaschenTyp.downloadExcelFlaschenTypExcelPost(),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Flaschentyp}
      defaultEntityForCreation={{ moegliche_blindenzeichen: [] }}
    />
  );
}
