"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WerkstoffMischungApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class WerkstoffMischungApi extends runtime.BaseAPI {
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdWerkstoffMischungDatabaseIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling deleteByDatabaseIdWerkstoffMischungDatabaseIdDelete.');
            }
            if (requestParameters.DeleteRequest === null || requestParameters.DeleteRequest === undefined) {
                throw new runtime.RequiredError('DeleteRequest', 'Required parameter requestParameters.DeleteRequest was null or undefined when calling deleteByDatabaseIdWerkstoffMischungDatabaseIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DeleteRequestToJSON(requestParameters.DeleteRequest),
            }, initOverrides);
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Delete By Database Id
     */
    deleteByDatabaseIdWerkstoffMischungDatabaseIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteByDatabaseIdWerkstoffMischungDatabaseIdDeleteRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Download Excel
     */
    downloadExcelWerkstoffMischungExcelPostRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/excel`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Download Excel
     */
    downloadExcelWerkstoffMischungExcelPost(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.downloadExcelWerkstoffMischungExcelPostRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllWerkstoffMischungGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WerkstoffMischungFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllWerkstoffMischungGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllWerkstoffMischungGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdWerkstoffMischungDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getByDatabaseIdWerkstoffMischungDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Get By Database Id
     */
    getByDatabaseIdWerkstoffMischungDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByDatabaseIdWerkstoffMischungDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get History
     */
    getHistoryWerkstoffMischungDatabaseIdHistoryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getHistoryWerkstoffMischungDatabaseIdHistoryGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}/history`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WerkstoffMischungFromJSON));
        });
    }
    /**
     * Get History
     */
    getHistoryWerkstoffMischungDatabaseIdHistoryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHistoryWerkstoffMischungDatabaseIdHistoryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdWerkstoffMischungDatabaseIdLockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling lockByDatabaseIdWerkstoffMischungDatabaseIdLockPatch.');
            }
            if (requestParameters.LockRequest === null || requestParameters.LockRequest === undefined) {
                throw new runtime.RequiredError('LockRequest', 'Required parameter requestParameters.LockRequest was null or undefined when calling lockByDatabaseIdWerkstoffMischungDatabaseIdLockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}/lock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LockRequestToJSON(requestParameters.LockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Lock By Database Id
     */
    lockByDatabaseIdWerkstoffMischungDatabaseIdLockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.lockByDatabaseIdWerkstoffMischungDatabaseIdLockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Post
     */
    postWerkstoffMischungPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.CreateWerkstoffMischung === null || requestParameters.CreateWerkstoffMischung === undefined) {
                throw new runtime.RequiredError('CreateWerkstoffMischung', 'Required parameter requestParameters.CreateWerkstoffMischung was null or undefined when calling postWerkstoffMischungPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CreateWerkstoffMischungToJSON(requestParameters.CreateWerkstoffMischung),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Post
     */
    postWerkstoffMischungPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postWerkstoffMischungPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdWerkstoffMischungDatabaseIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling putByDatabaseIdWerkstoffMischungDatabaseIdPut.');
            }
            if (requestParameters.UpdateWerkstoffMischung === null || requestParameters.UpdateWerkstoffMischung === undefined) {
                throw new runtime.RequiredError('UpdateWerkstoffMischung', 'Required parameter requestParameters.UpdateWerkstoffMischung was null or undefined when calling putByDatabaseIdWerkstoffMischungDatabaseIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UpdateWerkstoffMischungToJSON(requestParameters.UpdateWerkstoffMischung),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Put By Database Id
     */
    putByDatabaseIdWerkstoffMischungDatabaseIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.putByDatabaseIdWerkstoffMischungDatabaseIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatch.');
            }
            if (requestParameters.RestoreRequest === null || requestParameters.RestoreRequest === undefined) {
                throw new runtime.RequiredError('RestoreRequest', 'Required parameter requestParameters.RestoreRequest was null or undefined when calling restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}/restore`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RestoreRequestToJSON(requestParameters.RestoreRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Restore By Database Id
     */
    restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.restoreByDatabaseIdWerkstoffMischungDatabaseIdRestorePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdWerkstoffMischungDatabaseIdUnlockPatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling unlockByDatabaseIdWerkstoffMischungDatabaseIdUnlockPatch.');
            }
            if (requestParameters.UnlockRequest === null || requestParameters.UnlockRequest === undefined) {
                throw new runtime.RequiredError('UnlockRequest', 'Required parameter requestParameters.UnlockRequest was null or undefined when calling unlockByDatabaseIdWerkstoffMischungDatabaseIdUnlockPatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/werkstoff_mischung/{database_id}/unlock`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UnlockRequestToJSON(requestParameters.UnlockRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WerkstoffMischungFromJSON(jsonValue));
        });
    }
    /**
     * Unlock By Database Id
     */
    unlockByDatabaseIdWerkstoffMischungDatabaseIdUnlockPatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unlockByDatabaseIdWerkstoffMischungDatabaseIdUnlockPatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.WerkstoffMischungApi = WerkstoffMischungApi;
