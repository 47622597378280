import { Identity } from '@atlas-engine/atlas_engine_client';
import { Button, Dialog, Spinner } from '@blueprintjs/core';

import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  RenderSequentialUserTasks,
  withBoundProps,
} from '../../../infrastructure';
import { KundenauftragZuPauStornierungAbfragen } from '../../produktionsauftrag/custom_forms';

export type KundenauftragStornierenModalProps = PropsWithServices<ModalProps<void>> & { correlation_id: string, getIdentity: () => Identity };

export function KundenauftragStornierenModal(props: KundenauftragStornierenModalProps): JSX.Element {
  return (
    <RenderSequentialUserTasks
      {...props}
      correlationId={props.correlation_id}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.KundenauftragStornierungAbfragen') {
          return withBoundProps(KundenauftragZuPauStornierungAbfragen as any, { ...props, finish: props.onModalFinished });
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {<Button onClick={() => props.onModalFinished()} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}
