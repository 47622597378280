"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragLagerToJSON = exports.ProduktionsauftragLagerFromJSONTyped = exports.ProduktionsauftragLagerFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProduktionsauftragLagerFromJSON(json) {
    return ProduktionsauftragLagerFromJSONTyped(json, false);
}
exports.ProduktionsauftragLagerFromJSON = ProduktionsauftragLagerFromJSON;
function ProduktionsauftragLagerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'datum_anlage': (new Date(json['datum_anlage'])),
        'status': _1.ProduktionsauftragStatusFromJSON(json['status']),
        'abwicklungspfad': !runtime_1.exists(json, 'abwicklungspfad') ? undefined : _1.AbwicklungspfadProduktionsauftragFromJSON(json['abwicklungspfad']),
        'kundenauftrag_database_id': json['kundenauftrag_database_id'],
        'menge': json['menge'],
        'werkstoff_typ_1_database_id': json['werkstoff_typ_1_database_id'],
        'werkstoff_typ_1_version_id': json['werkstoff_typ_1_version_id'],
        'anteil_werkstoff_1': json['anteil_werkstoff_1'],
        'werkstoff_typ_2_database_id': !runtime_1.exists(json, 'werkstoff_typ_2_database_id') ? undefined : json['werkstoff_typ_2_database_id'],
        'werkstoff_typ_2_version_id': !runtime_1.exists(json, 'werkstoff_typ_2_version_id') ? undefined : json['werkstoff_typ_2_version_id'],
        'anteil_werkstoff_2': !runtime_1.exists(json, 'anteil_werkstoff_2') ? undefined : json['anteil_werkstoff_2'],
        'pau_start': !runtime_1.exists(json, 'pau_start') ? undefined : (new Date(json['pau_start'])),
        'pau_ende': !runtime_1.exists(json, 'pau_ende') ? undefined : (new Date(json['pau_ende'])),
        'database_id': json['database_id'],
        'kundenauftrag': _1.KundenauftragFromJSON(json['kundenauftrag']),
        'werkstoff_typ_1': _1.WerkstoffTypFromJSON(json['werkstoff_typ_1']),
        'werkstoff_typ_2': !runtime_1.exists(json, 'werkstoff_typ_2') ? undefined : _1.WerkstoffTypFromJSON(json['werkstoff_typ_2']),
    };
}
exports.ProduktionsauftragLagerFromJSONTyped = ProduktionsauftragLagerFromJSONTyped;
function ProduktionsauftragLagerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nummer': value.nummer,
        'datum_anlage': (value.datum_anlage.toISOString()),
        'status': _1.ProduktionsauftragStatusToJSON(value.status),
        'abwicklungspfad': _1.AbwicklungspfadProduktionsauftragToJSON(value.abwicklungspfad),
        'kundenauftrag_database_id': value.kundenauftrag_database_id,
        'menge': value.menge,
        'werkstoff_typ_1_database_id': value.werkstoff_typ_1_database_id,
        'werkstoff_typ_1_version_id': value.werkstoff_typ_1_version_id,
        'anteil_werkstoff_1': value.anteil_werkstoff_1,
        'werkstoff_typ_2_database_id': value.werkstoff_typ_2_database_id,
        'werkstoff_typ_2_version_id': value.werkstoff_typ_2_version_id,
        'anteil_werkstoff_2': value.anteil_werkstoff_2,
        'pau_start': value.pau_start === undefined ? undefined : (value.pau_start.toISOString().substr(0, 10)),
        'pau_ende': value.pau_ende === undefined ? undefined : (value.pau_ende.toISOString().substr(0, 10)),
        'database_id': value.database_id,
        'kundenauftrag': _1.KundenauftragToJSON(value.kundenauftrag),
        'werkstoff_typ_1': _1.WerkstoffTypToJSON(value.werkstoff_typ_1),
        'werkstoff_typ_2': _1.WerkstoffTypToJSON(value.werkstoff_typ_2),
    };
}
exports.ProduktionsauftragLagerToJSON = ProduktionsauftragLagerToJSON;
