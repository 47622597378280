import { Dialog, H2 } from '@blueprintjs/core';
import {
  addDays,
  addMinutes,
  endOfDay,
  startOfDay,
} from 'date-fns';
import React from 'react';
import { UmbauplanZeitraumErfassenResult, UmbauplanZeitraumErfassenResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DateEditor,
  DialogBody,
  DialogFooter,
  DialogFormField,
  ModalProps,
  getValidatedDatePickerComponentValue,
} from '../../../infrastructure';

export function UmbauplanZeitraumErfasssen(props: CustomFormProps<UmbauplanZeitraumErfassenResult> & ModalProps<void>): JSX.Element {

  const [start, setStart] = React.useState<Date | null>(null);
  const [end, setEnd] = React.useState<Date | null>(null);

  const abort = async (): Promise<void> => {
    props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const finish = (): Promise<void> => {
    const validatedStart = getValidatedDatePickerComponentValue(start ?? undefined);
    const timezoneOffset = -validatedStart.getTimezoneOffset();
    const startWithCorrectTimezone = addMinutes(validatedStart, timezoneOffset);
    const validatedEnd = getValidatedDatePickerComponentValue(end ?? undefined);
    const endWithCorrectTimezone = addMinutes(validatedEnd, timezoneOffset);
    return props.finishUserTask(UmbauplanZeitraumErfassenResultToJSON({
      start: startWithCorrectTimezone,
      end: endWithCorrectTimezone,
    }));
  };

  return (
    <Dialog
      isOpen
      onClose={abort}
      title='Umbauplan drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Zeitraum auswählen</H2>
        <DialogFormField fieldLabel='Start' fieldLocation={['start']}>
          <DateEditor
            value={start}
            onChange={setStart}
            minDate={startOfDay(new Date())}
            maxDate={end ?? endOfDay(addDays(new Date(), 10))}
          />
        </DialogFormField>
        <DialogFormField fieldLabel='Ende' fieldLocation={['end']}>
          <DateEditor
            value={end}
            onChange={setEnd}
            minDate={start ?? startOfDay(new Date())}
            maxDate={endOfDay(addDays(new Date(), 10))}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={abort}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
