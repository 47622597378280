/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/display-name */
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  InputGroup,
  Spinner,
} from '@blueprintjs/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  CreatePackordnung,
  FileStorageApi,
  Packordnung,
  PackordnungApi,
} from 'wacoplast_wws__api';
import { DialogBody, FileUploadDialog, StammdatenFieldEditorSimpleRendererProps } from '../../../infrastructure';
import styles from './PackordnungFilePicker.module.scss';

function PackordnungFileDownloadDialog(): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      canOutsideClickClose={false}
      isCloseButtonShown={false}
      canEscapeKeyClose={false}
      title='Einen Moment bitte'
    >
      <DialogBody>
        <Spinner />
      </DialogBody>
    </Dialog>
  );
}

type PackordnungFileUploadDialogProps = {
  fileStorageApi: FileStorageApi,
  fileName?: string,
  onClose: (
    hash?: string | undefined,
    fileName?: string | undefined
  ) => void,
};

function PackordnungFileUploadDialog(props: PackordnungFileUploadDialogProps): JSX.Element {
  return (
    <FileUploadDialog
      onClose={props.onClose}
      fileStorageApi={props.fileStorageApi}
      fileName={props.fileName ?? undefined}
    />
  );
}

export function makePackordnungFilePickerRenderer(fileStorageApi: FileStorageApi, packordnungService: PackordnungApi) {
  return (props: StammdatenFieldEditorSimpleRendererProps<CreatePackordnung, string | null>) => {

    const [fileName, setFileName] = React.useState<string | null>((props.stammdatenEntity as Packordnung).packvorschrift_file?.file_name ?? null);
    const [showModal, setShowModal] = React.useState<null | 'upload' | 'download'>(null);
    const anchorRef = React.useRef<HTMLAnchorElement>(null);

    const downloadTemplate = async (): Promise<void> => {
      setShowModal('download');
      const result = await packordnungService.getPackordnungTemplatePackordnungPackordnungTemplateGet({});
      const resultText = await result.text();
      if (anchorRef.current) {
        anchorRef.current.href = `data:application/vnd.ms-excel.sheet.macroenabled.12;base64,${resultText}`;
        anchorRef.current?.click();
      }
      setShowModal(null);
    };

    return (
      <>
        <a download='VorlagePackordnung.xlsm' ref={anchorRef} style={{ display: 'none' }} />
        <ButtonGroup fill>
          <InputGroup disabled value={fileName ?? 'Keine Datei ausgewählt'} fill />
          <Button
            disabled={props.disabled}
            onClick={downloadTemplate}
          >
            <span className={styles.DropDownPicker_Button_Text}>
              Vorlage laden
            </span>
          </Button>
          <Button
            disabled={props.disabled}
            onClick={() => setShowModal('upload')}
          >
            <span className={styles.DropDownPicker_Button_Text}>
              Hochladen
            </span>
          </Button>
          <Button
            icon={<FontAwesomeIcon size='sm' icon={faTrash} />}
            className={Classes.FIXED}
            disabled={props.disabled}
            onClick={() => {
              props.onChange(null);
              setFileName(null);
            }}
          />
        </ButtonGroup>
        {
          showModal === 'download' && (
            <PackordnungFileDownloadDialog />
          )
        }
        {
          showModal === 'upload' && !props.disabled &&
            <PackordnungFileUploadDialog
              fileStorageApi={fileStorageApi}
              onClose={(hash, fileName) => {
                if (hash && fileName) {
                  props.onChange(hash);
                  setFileName(fileName);
                }
                setShowModal(null);
              }}
              fileName={fileName ?? undefined}
            />
        }
      </>
    );
  };
}
