"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckstellMessprotokollDatenLadenResultToJSON = exports.RueckstellMessprotokollDatenLadenResultFromJSONTyped = exports.RueckstellMessprotokollDatenLadenResultFromJSON = void 0;
const _1 = require("./");
function RueckstellMessprotokollDatenLadenResultFromJSON(json) {
    return RueckstellMessprotokollDatenLadenResultFromJSONTyped(json, false);
}
exports.RueckstellMessprotokollDatenLadenResultFromJSON = RueckstellMessprotokollDatenLadenResultFromJSON;
function RueckstellMessprotokollDatenLadenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entries': (json['entries'].map(_1.RueckstellMessprotokollEntryFromJSON)),
        'material_legend': json['material_legend'],
    };
}
exports.RueckstellMessprotokollDatenLadenResultFromJSONTyped = RueckstellMessprotokollDatenLadenResultFromJSONTyped;
function RueckstellMessprotokollDatenLadenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entries': (value.entries.map(_1.RueckstellMessprotokollEntryToJSON)),
        'material_legend': value.material_legend,
    };
}
exports.RueckstellMessprotokollDatenLadenResultToJSON = RueckstellMessprotokollDatenLadenResultToJSON;
