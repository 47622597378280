import { Button, Dialog, Spinner } from '@blueprintjs/core';
import React from 'react';

import { DataModels } from '@atlas-engine/atlas_engine_client';

import {
  CustomFormProps,
  IdentityProvider,
  ModalProps,
  PropsWithTransaction,
  RenderSequentialUserTasks,
  withBoundProps,
} from '../../infrastructure';
import { PropsWithServices } from '../../infrastructure';
import { DialogBody, DialogFooter } from '../../infrastructure';
import { ProduktionsauftragExternBestellungAufgeben, ProduktionsauftragExternBestellungEingangRueckmelden, ProduktionsauftragLagerRueckmelden } from '..';
import { KundenauftragZuPauStornierungAbfragen } from './custom_forms';

export type ProduktionsauftragAbwickelnModalResult = {
  produktionsauftrag_rueckgemeldet: boolean
};


export type ProduktionsauftragAbwickelnModalProps = PropsWithTransaction<PropsWithServices<ModalProps<ProduktionsauftragAbwickelnModalResult>>> &
{
  getIdentity: IdentityProvider;
  correlationId: string;
  processModelId: string;
};

export class ProduktionsauftragAbwickelnModal extends React.Component<ProduktionsauftragAbwickelnModalProps> {

  private abort = (): void => {
    this.props.onModalFinished({ produktionsauftrag_rueckgemeldet: false });
  };

  private finish = (): void => {
    this.props.onModalFinished({ produktionsauftrag_rueckgemeldet: true });
  };

  public getComponentForCustomForm = (userTask: DataModels.FlowNodeInstances.UserTaskInstance): React.ComponentType<CustomFormProps> | null => {
    const customForm = userTask.userTaskConfig.customForm;

    const props = {
      subscriptionEventService: this.props.subscriptionEventService,
      atlasEngineClient: this.props.atlasEngineClient,
      config: this.props.config,
      getIdentity: this.props.getIdentity,
      services: this.props.services,
      transactionService: this.props.transactionService,
    };

    if (userTask.processModelId === this.props.processModelId && customForm === 'wacoplast-wws.ProduktionsauftragExternBestellungAufgeben') {
      return withBoundProps(ProduktionsauftragExternBestellungAufgeben, props);
    }

    if (userTask.processModelId === this.props.processModelId && customForm === 'wacoplast-wws.ProduktionsauftragExternBestellungEingangRueckmelden') {
      return withBoundProps(ProduktionsauftragExternBestellungEingangRueckmelden, { ...props, finish: this.finish });
    }

    if (userTask.processModelId === this.props.processModelId && customForm === 'wacoplast-wws.ProduktionsauftragLagerRueckmelden') {
      return withBoundProps(ProduktionsauftragLagerRueckmelden, { ...props, finish: this.finish });
    }

    if (customForm === 'wacoplast-wws.KundenauftragStornierungAbfragen') {
      return withBoundProps(KundenauftragZuPauStornierungAbfragen as any, { ...props, finish: this.finish });
    }

    return null;
  };


  public render(): JSX.Element | null {
    const loadingModal = (
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          {this.props.correlationId && <Button onClick={this.abort} intent='warning'>Ausblenden</Button>}
        </DialogFooter>
      </Dialog>
    );

    if (!this.props.correlationId) {
      return loadingModal;
    }

    return (
      <RenderSequentialUserTasks
        atlasEngineClient={this.props.atlasEngineClient}
        correlationId={this.props.correlationId}
        getComponent={this.getComponentForCustomForm}
        getIdentity={this.props.getIdentity}
        handleUserTaskAborted={async (api, userTask, result) => this.abort()}
      >
        {loadingModal}
      </RenderSequentialUserTasks>
    );
  }
}
