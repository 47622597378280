import { Dialog, H2 } from '@blueprintjs/core';
import { CustomFormProps, DialogBody, DialogFooter } from '../../../../infrastructure';

export function RueckmeldungIstAbgeschlossenHinweis(props: CustomFormProps & { finish: () => void }): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Rückmeldung durchführen'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Rückmeldung ist abgeschlossen</H2>
      </DialogBody>
      <DialogFooter
        onFinish={() => {
          props.finish();
          return props.finishUserTask({});
        }}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
