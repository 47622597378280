/* eslint-disable @blueprintjs/no-deprecated-components */
/* eslint-disable @blueprintjs/no-deprecated-type-references */
/* eslint-disable react/display-name */
import { HTMLTable, MenuItem, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { IItemRendererProps } from '@blueprintjs/select';
import { useCallback, useState } from 'react';
import {
  Artikel,
  ArtikelApi,
  Firma,
  Rahmenauftrag,
} from 'wacoplast_wws__api';
import {
  CommonEditorProps,
  DefaultDropDownPicker,
  DialogFormField,
  DropDownPicker,
  StammdatenFieldEditorComplexRendererProps,
  UseFetchLikeServiceFunctionResult,
  useFetchLikeServiceFunction,
} from '../../infrastructure';

export type ArtikelDropDownPickerProps = CommonEditorProps<Artikel | null> & {
  dataProvider: UseFetchLikeServiceFunctionResult<Array<Artikel>>,
  nullable?: boolean,
  filter?: (data: Artikel) => boolean,
};

export function ArtikelDropDownPicker(props: ArtikelDropDownPickerProps): JSX.Element {
  return (
    <div style={{ minWidth: '600px' }}>
      <DropDownPicker
        {...props}
        itemRenderer={(item: Artikel, itemProps?: IItemRendererProps, changeActiveItem?: () => void) => {
          if (itemProps?.index && itemProps.index === 100) {
            return (
              <MenuItem
                key={item.nummer}
                text='Zu viele Einträge, bitte genauer filtern'
                disabled
              />
            );
          } else if (itemProps?.index && itemProps.index > 100) {
            return <></>;
          }
          const text = (
            <>
              <Tag minimal>{item.flaschen_form.flaschentyp.nummer}</Tag>{' '}
              {
                item.artikel_nummer_des_kunden &&
                  <>
                    <Tag minimal>{item.artikel_nummer_des_kunden}</Tag>
                    {' '}
                  </>
              }
              <Tag minimal>{item.flaschen_form.flaschentyp.bezeichnung}</Tag>{' '}
              <Tag minimal>{item.granulat_farbe.bezeichnung}</Tag>
              {
                item.empfaenger?.name_kurz && item.empfaenger.name_kurz !== item.kunde.name_kurz && (
                  <>
                    {' '}
                    <Tag minimal>{item.empfaenger?.name_kurz}</Tag>
                  </>
                )
              }
            </>
          );

          const tooltip = (
            <HTMLTable striped>
              <tbody>
                <tr>
                  <td>Nummer</td>
                  <td>{item.nummer}</td>
                </tr>
                <tr>
                  <td>Kunde</td>
                  <td>{item.kunde.name_kurz} <Tag minimal>{item.kunde.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Empfänger</td>
                  <td>{item.empfaenger?.name_kurz} {item.empfaenger && <Tag minimal>{item.empfaenger.nummer}</Tag>}</td>
                </tr>
                <tr>
                  <td>Flaschentyp</td>
                  <td>{item.flaschen_form.flaschentyp.bezeichnung} <Tag minimal>{item.flaschen_form.flaschentyp.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Blindenzeichen</td>
                  <td>{item.blindenzeichen.bezeichnung} <Tag minimal>{item.blindenzeichen.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>KU-Art.-Nr.</td>
                  <td>{item.artikel_nummer_des_kunden}</td>
                </tr>
                <tr>
                  <td>Halsteil</td>
                  <td>{item.flaschen_form.hals_teil.bezeichnung} <Tag minimal>{item.flaschen_form.hals_teil.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Gewicht</td>
                  <td>{item.gewicht}</td>
                </tr>
                <tr>
                  <td>Werkstoff 1</td>
                  <td>{item.werkstoff_mischung.anteil_1}% {item.werkstoff_mischung.werkstoff_1.bezeichnung} <Tag minimal>{item.werkstoff_mischung.werkstoff_1.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Werkstoff-Typ 1</td>
                  <td>{item.werkstoff_typ_1.bezeichnung} <Tag minimal>{item.werkstoff_typ_1.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Werkstoff 2</td>
                  {item.werkstoff_mischung.werkstoff_2 ?
                    <td>{item.werkstoff_mischung.anteil_2}% {item.werkstoff_mischung.werkstoff_2.bezeichnung} <Tag minimal>{item.werkstoff_mischung.werkstoff_2.nummer}</Tag></td>
                    : <td />
                  }
                </tr>
                <tr>
                  <td>Werkstoff-Typ 2</td>
                  {item.werkstoff_typ_2 ?
                    <td>{item.werkstoff_typ_2.bezeichnung} <Tag minimal>{item.werkstoff_typ_2.nummer}</Tag></td>
                    : <td />
                  }
                </tr>
                <tr>
                  <td>Granulatfarbe</td>
                  <td>{item.granulat_farbe.bezeichnung} <Tag minimal>{item.granulat_farbe.nummer}</Tag></td>
                </tr>
                <tr>
                  <td>Einfärbungsgrad</td>
                  <td>{item.einfaerbungs_grad}</td>
                </tr>
              </tbody>
            </HTMLTable>
          );

          return (
            <Tooltip2
              content={tooltip}
              position='right'
              isOpen={itemProps?.modifiers.active}
              fill
              key={item.nummer}
            >
              <MenuItem
                onMouseEnter={changeActiveItem}
                text={item.nummer}
                labelElement={text}
                onClick={itemProps?.handleClick}
                selected={itemProps?.modifiers.active}
              />
            </Tooltip2>
          );
        }}
        valueRenderer={(item) => {
          return (
            <>
              {item.nummer}{' '}
              <Tag minimal>FLTyp: {item.flaschen_form.flaschentyp.nummer}, {item.flaschen_form.flaschentyp.bezeichnung}</Tag>{' '}
              {
                item.artikel_nummer_des_kunden &&
                  <>
                    <Tag minimal>KU-Art.-Nr.: {item.artikel_nummer_des_kunden}</Tag>
                    {' '}
                  </>
              }
              <Tag minimal>Halsteil: {item.flaschen_form.hals_teil.nummer}, {item.flaschen_form.hals_teil.bezeichnung}</Tag>{' '}
              <Tag minimal>Farbe: {item.granulat_farbe.bezeichnung}</Tag>{' '}
              <Tag minimal>Gewicht: {item.gewicht}</Tag>{' '}
              {
                item.empfaenger?.name_kurz && item.empfaenger.name_kurz !== item.kunde.name_kurz && (
                  <>
                    {' '}
                    <Tag minimal>Empfänger: {item.empfaenger?.name_kurz}</Tag>
                  </>
                )
              }
            </>
          );
        }}
        filter={(query, data) => {
          const matchesNumber = data.nummer.toLowerCase().includes(query.toLowerCase());
          const matchesTypeNumber = data.flaschen_form.flaschentyp.nummer.toLowerCase().includes(query.toLowerCase());
          const matchesTypeName = data.flaschen_form.flaschentyp.bezeichnung.toLowerCase().includes(query.toLowerCase());
          const matchesColor = data.granulat_farbe.bezeichnung.toLowerCase().includes(query.toLowerCase());
          const matchesEmpfaenger = data.empfaenger ? data.empfaenger.name_kurz.toLowerCase().includes(query.toLowerCase()) : false;
          const matchesKundenArtikelNummer = data.artikel_nummer_des_kunden ? data.artikel_nummer_des_kunden.toLowerCase().includes(query.toLowerCase()) : false;
          const matchesCustomFilter = props.filter ? props.filter(data) : true;
          return (matchesNumber || matchesTypeNumber || matchesTypeName || matchesColor || matchesEmpfaenger || matchesKundenArtikelNummer) && matchesCustomFilter;
        }}
      />
    </div>
  );
}

export function makeArtikelDropDownPickerRenderer(
  artikelService: ArtikelApi,
  kundenDataProvider?: UseFetchLikeServiceFunctionResult<Array<Firma>>,
  nullable = false,
): any {
  return (props: StammdatenFieldEditorComplexRendererProps<Rahmenauftrag>) => {
    const [kunde, setKunde] = useState<Firma | null>(props.stammdatenEntity.artikel?.kunde ?? null);
    const serviceFunction = useCallback((initOverrides?: RequestInit) => artikelService.findArtikelArtikelFindArtikelKundeDatabaseIdGet({ kunde_database_id: kunde?.database_id ?? -1 }, initOverrides), [kunde?.database_id, artikelService]);
    const artikel = useFetchLikeServiceFunction(serviceFunction, null);
    return (
      <>
        {kundenDataProvider &&
          <DialogFormField
            fieldLocation=''
            fieldLabel='Kunde'
          >
            <DefaultDropDownPicker
              dataProvider={kundenDataProvider}
              getDisplayData={(data) => {
                return { primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
              }}
              disabled={props.disabled}
              value={kunde}
              onChange={(value) => {
                setKunde(value);
                props.onChange({
                  artikel_database_id: null,
                  artikel_version_id: null,
                } as any);
              }}
            />
          </DialogFormField>
        }
        <DialogFormField
          fieldLocation=''
          fieldLabel='Artikel'
          style={{ margin: 0 }}
        >
          <ArtikelDropDownPicker
            filter={(data) => data.kunde.database_id === kunde?.database_id}
            dataProvider={artikel}
            nullable={nullable}
            disabled={props.disabled || kunde === null}
            value={props.stammdatenEntity.artikel ?? null}
            onChange={(value) => props.onChange({
              artikel_database_id: value?.database_id ?? null,
              artikel_version_id: value?.version_id ?? null,
              artikel: value ?? null,
            } as any)}
          />
        </DialogFormField>
      </>
    );
  };
}
