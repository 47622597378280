/* eslint-disable react/display-name */
import { useCallback, useState } from 'react';
import {
  Artikel,
  BpmnValidationError,
  Firma,
  KundenauftragArt,
  KundenauftragDetailsAngebenResult,
  KundenauftragDetailsAngebenResultFromJSON,
  KundenauftragDetailsAngebenResultToJSON,
  LieferterminKennzeichen,
  Rahmenauftrag,
  RahmenauftragStatus,
} from 'wacoplast_wws__api';
import { Dialog } from '@blueprintjs/core';
import {
  CustomFormProps,
  DateEditor,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  DialogFormField,
  EnumDropDownPicker,
  LieferterminEditor,
  NumberEditor,
  PropsWithServices,
  PropsWithTransaction,
  TextEditor,
  getPayloadOrBpmnValidationErrorFromToken,
  getValidatedDateTimePickerComponentValue,
  isValidDate,
  removeFieldFromValidationError,
  updateValidationError,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { ArtikelDropDownPicker } from '../../..';
import { KundenauftragArtKeyMapping, MAX_DAYS_FOR_AUFTRAGSEINGANG, calculateMinDateWithLimit } from '../..';
import { min } from 'date-fns';

export type KundenauftragDetailsAngebenProps = PropsWithTransaction<PropsWithServices<CustomFormProps<KundenauftragDetailsAngebenResult>>>;

type KundenauftragDetailsAngebenState = {
  kunde: Firma | null,
  artikel: Artikel | null,
  auftragsart: KundenauftragArt | null,
  rahmenauftrag: Rahmenauftrag | null,
  menge: number | null,
  auftragsnummer_des_empfaenger: string | null,
  bestell_nummer: string | null,
  liefertermin_soll: Date | null,
  liefertermin_kennzeichen: LieferterminKennzeichen,
  datum_eingang: Date | null,
  validationError: BpmnValidationError | null,
};

export function KundenauftragdetailsAngeben(props: KundenauftragDetailsAngebenProps): JSX.Element {
  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, KundenauftragDetailsAngebenResultFromJSON);

  const [state, setState] = useState<KundenauftragDetailsAngebenState>({
    kunde: previousValues.artikel?.kunde ?? null,
    artikel: previousValues.artikel ?? null,
    auftragsart: previousValues.auftragsart ?? null,
    rahmenauftrag: previousValues.rahmenauftrag ?? null,
    menge: previousValues.menge ?? null,
    auftragsnummer_des_empfaenger: previousValues.auftragsnummer_des_empfaenger ?? null,
    bestell_nummer: previousValues.bestell_nummer ?? null,
    liefertermin_soll: isValidDate(previousValues.liefertermin_soll) ? previousValues.liefertermin_soll ?? null : null,
    liefertermin_kennzeichen: previousValues.liefertermin_kennzeichen ?? LieferterminKennzeichen.W,
    datum_eingang: isValidDate(previousValues.datum_eingang) ? previousValues.datum_eingang ?? null : null,
    validationError: err,
  });

  const kunden = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const rahmenauftraege = useFetchLikeGetAllServiceFunction(props.services.rahmenauftrag.getAllRahmenauftragGet, props.services.rahmenauftrag);
  const serviceFunction = useCallback((initOverrides?: RequestInit) => props.services.artikel.findArtikelArtikelFindArtikelKundeDatabaseIdGet({ kunde_database_id: state.kunde?.database_id ?? -1 }, initOverrides), [state.kunde?.database_id, props.services.artikel]);
  const artikel = useFetchLikeServiceFunction(serviceFunction, null);

  async function submit(): Promise<void> {
    const payload: KundenauftragDetailsAngebenResult = {
      artikel: state.artikel,
      auftragsart: state.auftragsart,
      rahmenauftrag: state.rahmenauftrag ?? undefined,
      menge: state.menge,
      auftragsnummer_des_empfaenger: state.auftragsnummer_des_empfaenger,
      bestell_nummer: state.bestell_nummer,
      liefertermin_soll: (state.liefertermin_soll ?? undefined) as Date,
      liefertermin_kennzeichen: state.liefertermin_kennzeichen,
      datum_eingang: getValidatedDateTimePickerComponentValue(state.datum_eingang ?? undefined),
    } as any;

    return props.finishUserTask(KundenauftragDetailsAngebenResultToJSON(payload));
  }

  const minDatumEingang = calculateMinDateWithLimit(MAX_DAYS_FOR_AUFTRAGSEINGANG);

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Kundenauftragsdetails angeben'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <DialogFormField
          fieldLocation=''
          fieldLabel='Kunde'
        >
          <DefaultDropDownPicker
            dataProvider={kunden}
            onChange={(value) => {
              setState({
                ...state,
                kunde: value,
                artikel: null,
              });
            }}
            value={state.kunde}
            getDisplayData={(data) => {
              return { primaryTitle: data?.name_kurz ?? '---', secondaryTitle: data?.nummer };
            }}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['artikel']}
          fieldLabel='Artikel'
        >
          <ArtikelDropDownPicker
            disabled={!state.kunde}
            dataProvider={artikel}
            onChange={(value) => {
              setState({
                ...state,
                validationError: updateValidationError({ artikel: null }, state.validationError),
                artikel: value,
              });
            }}
            value={state.artikel}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['auftragsart']}
          fieldLabel='Auftragsart'
        >
          <EnumDropDownPicker
            data={Object.values(KundenauftragArt)}
            getDisplayData={(data) => (data && KundenauftragArtKeyMapping[data]) ?? '---'}
            onChange={(value) => setState({
              ...state,
              validationError: updateValidationError({ auftragsart: null, rahmenauftrag: null }, state.validationError),
              auftragsart: value,
              rahmenauftrag: value === KundenauftragArt.A ? state.rahmenauftrag : null,
            })}
            value={state.auftragsart}
            filter={(data) => {
              if (data !== KundenauftragArt.A) {
                return true;
              }
              if (!rahmenauftraege.data || !state.artikel) {
                return false;
              }
              const matchingRAU = rahmenauftraege.data.find(rau => rau.artikel_database_id === state.artikel!.database_id && rau.status !== RahmenauftragStatus.NUMBER_9 && (rau.offene_menge ?? 0) > 0);
              return matchingRAU !== undefined;
            }}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['rahmenauftrag']}
          fieldLabel='Rahmenauftrag'
        >
          <DefaultDropDownPicker
            disabled={!state.artikel || state.auftragsart !== KundenauftragArt.A}
            dataProvider={rahmenauftraege}
            filter={(data) => data.artikel_database_id === state.artikel?.database_id
              && data.status !== RahmenauftragStatus.NUMBER_9
              && (
                data.liefertermin_kennzeichen === LieferterminKennzeichen.W
                || (data.liefertermin_soll !== undefined && data.liefertermin_soll >= minDatumEingang))
              && (data.offene_menge ?? 0) > 0}
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['rahmenauftrag']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              rahmenauftrag: value,
            })}
            value={state.rahmenauftrag}
            getDisplayData={(data) => {
              return { primaryTitle: data.nummer };
            }}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['menge']}
          fieldLabel='Menge'
        >
          <NumberEditor
            max={state.rahmenauftrag?.offene_menge}
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['menge']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              menge: value,
            })}
            value={state.menge}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['auftragsnummer_des_empfaenger']}
          fieldLabel='Auftragsnummer des Empfängers'
        >
          <TextEditor
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['auftragsnummer_des_empfaenger']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              auftragsnummer_des_empfaenger: value,
            })}
            value={state.auftragsnummer_des_empfaenger}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['bestell_nummer']}
          fieldLabel='Bestellnummer'
        >
          <TextEditor
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['bestell_nummer']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              bestell_nummer: value,
            })}
            value={state.bestell_nummer}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['liefertermin_soll']}
          fieldLabel='Liefertermin soll'
        >
          <LieferterminEditor
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['liefertermin_kennzeichen']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              liefertermin_soll: value.liefertermin_soll,
              liefertermin_kennzeichen: value.liefertermin_kennzeichen ?? LieferterminKennzeichen.W,
            })}
            value={{ liefertermin_soll: state.liefertermin_soll, liefertermin_kennzeichen: state.liefertermin_kennzeichen }}
          />
        </DialogFormField>

        <DialogFormField
          lastValidationResponse={state.validationError}
          fieldLocation={['datum_eingang']}
          fieldLabel='Datum Eingang'
        >
          <DateEditor
            minDate={minDatumEingang}
            maxDate={state.rahmenauftrag?.liefertermin_kennzeichen !== LieferterminKennzeichen.W && state.rahmenauftrag?.liefertermin_soll
              ? min([state.rahmenauftrag?.liefertermin_soll, new Date()])
              : new Date()}
            onChange={(value) => setState({
              ...state,
              validationError: { ...removeFieldFromValidationError(state.validationError, ['datum_eingang']) as BpmnValidationError, previous_payload: state.validationError?.previous_payload ?? {} },
              datum_eingang: value,
            })}
            value={state.datum_eingang}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        onFinish={submit}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
