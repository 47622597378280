import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import { LOCALE_DE } from './inputs';
import { StammdatenFieldEditorSimpleRendererProps } from '../stammdaten_renderer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VALIDATE_INPUT_REGEX = /[^0-9.,-]/;
const BLUR_KEYS = ['Enter', 'Escape'];
const REMOVE_ICON = <FontAwesomeIcon size='sm' icon={faTrash} />;

type NumberEditorProps = {
  disabled?: boolean;
  max?: number;
  min?: number;
  nullable?: boolean;
  placeholder?: string;
  precision?: number;
  showButtons?: boolean;
  value?: number | null;
  onChange: (value: number | null) => void;
}

const defaultNumberEditorOptions = {
  disabled: false,
  max: Number.MAX_SAFE_INTEGER,
  min: 0,
  nullable: false,
  placeholder: undefined,
  precision: 0,
  showButtons: false,
};

const makeNumberParser = (precision: number): ((value: string) => number) => {
  return (value: string) => {
    const unformatted = unformatNumber(value);
    const parsed = Number.parseFloat(unformatted);

    return Number.isNaN(parsed) ? 0 : Number(parsed.toFixed(precision));
  };
};

const makeNumberFormatter = (precision: number): ((value: number) => string) => {
  return Intl.NumberFormat(LOCALE_DE, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format;
};

const unformatNumber = (value: string): string => {
  return value.replaceAll(' ', '').replaceAll('.', '').replaceAll(',', '.');
};

const findChangedChar = (oldValue: string, newValue: string): string => {
  for (let i = 0; i < newValue.length; i++) {
    if (oldValue[i] !== newValue[i]) {
      return newValue.substring(i, i + 1);
    }
  }

  return '';
};

export function NumberEditor(props: NumberEditorProps): JSX.Element {
  const options = {
    min: props.min ?? defaultNumberEditorOptions.min,
    max: props.max ?? defaultNumberEditorOptions.max,
    precision: props.precision ?? defaultNumberEditorOptions.precision,
    nullable: props.nullable ?? defaultNumberEditorOptions.nullable,
    placeholder: props.placeholder ?? defaultNumberEditorOptions.placeholder,
    showButtons: props.showButtons ?? defaultNumberEditorOptions.showButtons,
    disabled: props.disabled ?? defaultNumberEditorOptions.disabled,
  };

  const formatNumber = makeNumberFormatter(options.precision);
  const parseNumber = makeNumberParser(options.precision);

  const [value, setValue] = useState<string | null>(props.value == null ? null : formatNumber(props.value));
  const [altPressed, setAltPressed] = useState<boolean>(false);
  const stepSize = 1 / (10 ** options.precision);

  const handleOnChange = (newValue: string): void => {
    const changedChar = findChangedChar(value ?? '', newValue);
    const invalidChar = VALIDATE_INPUT_REGEX.test(changedChar);
    const commas = newValue.split(',').length - 1;
    const commaButPrecisionZero = commas > 0 && options.precision === 0;
    const minusPosition = newValue.indexOf('-');
    const invalidSign = minusPosition !== -1 && minusPosition !== 0;
    const minus = newValue.split('-').length - 1;
    const fraction = newValue.split(',')[1];
    const tooManyDecimals = fraction && fraction.length > (options.precision);
    const parsedNumber = parseNumber(newValue);
    const outsideRange = parsedNumber < options.min || parsedNumber > options.max || !Number.isSafeInteger(Number(parsedNumber.toFixed(0)));

    const invalidInput = invalidChar || commaButPrecisionZero || commas > 1 || invalidSign || minus > 1 || tooManyDecimals || outsideRange;
    if (invalidInput) {
      return;
    }

    setValue(newValue);
  };

  const handleInputAction = (delta: number): void => {
    const parsedValue = parseNumber(value ?? '');
    const increment = altPressed ? stepSize : 1;
    const newValue = parsedValue + (delta * increment);
    const outsideRange = newValue < options.min || newValue > options.max || !Number.isSafeInteger(Number(newValue.toFixed(0)));

    if (!outsideRange) {
      const formattedValue = formatNumber(newValue);
      setValue(formattedValue);
      props.onChange(newValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (BLUR_KEYS.includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.blur();
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      handleInputAction(event.key === 'ArrowUp' ? 1 : -1);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Alt') {
      setAltPressed(false);
    }
  };

  const handleButtonClick = (delta: number): void => {
    handleInputAction(delta);
  };

  const altPressedListener = (e: KeyboardEvent): void => {
    if (e.key === 'Alt' && options.precision > 0) {
      setAltPressed(true);
    }
  };

  const altReleasedListener = (e: KeyboardEvent): void => {
    if (e.key === 'Alt') {
      setAltPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', altPressedListener);
    window.addEventListener('keyup', altReleasedListener);
    return () => {
      window.removeEventListener('keydown', altPressedListener);
      window.removeEventListener('keyup', altReleasedListener);
    };
  });

  useEffect(() => {
    if (props.value === null) {
      setValue(null);
      return;
    }

    const valueToFormat = props.value == null ? null : formatNumber(props.value);
    setValue(valueToFormat);
  }, [props.value]);

  return (
    <ButtonGroup fill={true}>
      <input
        className={`${Classes.FILL} ${Classes.INPUT}`}
        type='text'
        value={value ?? ''}
        disabled={options.disabled}
        placeholder={options.placeholder}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={() => {
          if (value === null || value === '') {
            props.onChange(null);
            return;
          }

          setValue(parseNumber(value).toFixed(options.precision).toString().replace('.', ','));
        }}
        onBlur={() => {
          if (value === null || value === '') {
            props.onChange(null);
            return;
          }

          const parsed = parseNumber(value);
          const formattedNumber = formatNumber(parsed);
          setValue(formattedNumber);
          props.onChange(parsed);
        }}
      />
      {options.showButtons &&
        <>
          <Button
            tabIndex={-1}
            className={Classes.BUTTON}
            onClick={() => handleButtonClick(1)}
            disabled={options.disabled}
          >+
          </Button>
          <Button
            tabIndex={-1}
            className={Classes.BUTTON}
            disabled={options.disabled}
            onClick={() => handleButtonClick(-1)}
          >-
          </Button>
        </>
      }
    </ButtonGroup>
  );
}

export function makeNumberEditorRenderer<TEntityType>(options: Partial<NumberEditorProps> = {}): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, number | null>) => {
    const numberEditor = (
      <NumberEditor
        min={options.min}
        max={options.max}
        precision={options.precision}
        value={props.value}
        disabled={props.disabled}
        placeholder={options.placeholder}
        onChange={(value) => props.onChange(value)}
        showButtons={options.showButtons}
      />
    );

    if (!options.nullable) {
      return numberEditor;
    }

    return (
      <ButtonGroup fill={true}>
        {numberEditor}
        <Button
          icon={REMOVE_ICON}
          className={Classes.FIXED}
          disabled={props.disabled}
          onClick={(event) => {
            event.stopPropagation();
            props.onChange(null);
          }}
        />
      </ButtonGroup>
    );
  };
}
