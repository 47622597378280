import { CreateFahrzeugZuKunde } from 'wacoplast_wws__api';

import {
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeNumberEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type FahrzeugZuKundeVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function FahrzeugZuKundeVerwalten(props: FahrzeugZuKundeVerwaltenProps): JSX.Element {
  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const fahrzeugResult = useFetchLikeGetAllServiceFunction(props.services.fahrzeug.getAllFahrzeugGet, props.services.fahrzeug);

  const editors: StammdatenFieldEditors<CreateFahrzeugZuKunde> = [
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kundenResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'kunde', false, (data) => {
        return {
          kunde_database_id: data?.database_id,
          kunde_version_id: data?.version_id,
        };
      }),
      label: 'Kunde',
    },
    {
      type: 'complex',
      fields: ['empfaenger_database_id', 'empfaenger_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(empfaengerResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'empfaenger', false),
      label: 'Empfänger',
    },
    {
      type: 'complex',
      fields: ['fahrzeug_database_id', 'fahrzeug_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(fahrzeugResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'fahrzeug', false),
      label: 'Fahrzeug',
    },
    {
      type: 'simple',
      field: 'ladehoehe',
      renderer: makeNumberEditorRenderer(),
      label: 'Ladehöhe [mm]',
    },
    {
      type: 'simple',
      field: 'prioritaet',
      renderer: makeNumberEditorRenderer(),
      label: 'Priorität',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      columnDefs={[
        {
          headerName: 'Nummer',
          valueGetter: (data) => `${data.data.kunde.nummer}.${data.data.empfaenger.nummer}.${data.data.fahrzeug.nummer}`,
          colId: 'nummer',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Ladehöhe',
          field: 'ladehoehe',
          colId: 'ladehoehe',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Priorität',
          field: 'prioritaet',
          colId: 'prioritaet',
          filter: 'agNumberColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateFahrzeugZuKunde) => props.services.fahrzeug_zu_kunde.postFahrzeugZuKundePost({ CreateFahrzeugZuKunde: data }),
        update: (data) => props.services.fahrzeug_zu_kunde.putByDatabaseIdFahrzeugZuKundeDatabaseIdPut({ database_id: data.database_id, UpdateFahrzeugZuKunde: data }),
        delete: (data, ignoreIntegrity) => props.services.fahrzeug_zu_kunde.deleteByDatabaseIdFahrzeugZuKundeDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.fahrzeug_zu_kunde.restoreByDatabaseIdFahrzeugZuKundeDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.fahrzeug_zu_kunde.getAllFahrzeugZuKundeGet({ include_deleted }),
        getHistory: (data) => props.services.fahrzeug_zu_kunde.getHistoryFahrzeugZuKundeDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.fahrzeug_zu_kunde.downloadExcelFahrzeugZuKundeExcelPost(),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      defaultColumnState={defaultColumnState}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.FahrzeugZuKunde}
    />
  );
}
