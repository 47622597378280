"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsplanTableToJSON = exports.ProduktionsplanTableFromJSONTyped = exports.ProduktionsplanTableFromJSON = void 0;
const _1 = require("./");
function ProduktionsplanTableFromJSON(json) {
    return ProduktionsplanTableFromJSONTyped(json, false);
}
exports.ProduktionsplanTableFromJSON = ProduktionsplanTableFromJSON;
function ProduktionsplanTableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entries': (json['entries'].map(_1.ProduktionsplanEntryFromJSON)),
        'blasmaschine_nummer': json['blasmaschine_nummer'],
    };
}
exports.ProduktionsplanTableFromJSONTyped = ProduktionsplanTableFromJSONTyped;
function ProduktionsplanTableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entries': (value.entries.map(_1.ProduktionsplanEntryToJSON)),
        'blasmaschine_nummer': value.blasmaschine_nummer,
    };
}
exports.ProduktionsplanTableToJSON = ProduktionsplanTableToJSON;
