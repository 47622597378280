import { Blasform, FlaschenTyp } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps, UseFetchLikeServiceFunctionResult, makeVersionedDropDownPickerRenderer } from '../../../infrastructure';

export function makeFlaschenTypDropDown(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<FlaschenTyp>>,
): any {
  return (props: StammdatenFieldEditorComplexRendererProps<Blasform>) => {
    return makeVersionedDropDownPickerRenderer(dataProvider, (data) => ({ primaryTitle: data.bezeichnung, secondaryTitle: props.stammdatenEntity.wechselbares_blindenzeichen ? undefined : data.nummer }), 'flaschen_typ')(props);
  };
}
