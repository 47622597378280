/* eslint-disable react/display-name */
import { HTMLTable } from '@blueprintjs/core';
import { BlasmaschineZeiten } from 'wacoplast_wws__api';
import { NumberEditor, StammdatenFieldEditorComplexRendererProps } from '../../../infrastructure';

export function makeExtruderSondertemperaturenEditorRenderer(): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<BlasmaschineZeiten>) => {
    return (
      <HTMLTable bordered condensed>
        <tbody>
          <tr>
            <th>
              <span>Zone-Nr.</span>
            </th>
            {
              new Array(10).fill(0).map((_, index) => {
                const zoneIndex = `zone_${index + 1}`;
                const zoneValue = (props.stammdatenEntity as any)[zoneIndex];
                const parsedValue = isNaN(zoneValue) ? null : Number(zoneValue);

                return (
                  <td key={index}>
                    <NumberEditor
                      value={parsedValue}
                      disabled={props.disabled}
                      onChange={(value) => props.onChange({ [zoneIndex]: value ?? undefined })}
                    />
                  </td>
                );
              })
            }
          </tr>
          <tr>
            <th>
              <span>Zone-Temp.</span>
            </th>
            {
              new Array(10).fill(0).map((_, index) => {
                const zoneTempIndex = `zone_${index + 1}_temp`;
                const zoneTempValue = (props.stammdatenEntity as any)[zoneTempIndex];
                const parsedValue = isNaN(zoneTempValue) ? null : Number(zoneTempValue);

                return (
                  <td key={index}>
                    <NumberEditor
                      value={parsedValue}
                      disabled={props.disabled}
                      onChange={(value) => props.onChange({ [zoneTempIndex]: value ?? undefined })}
                    />
                  </td>
                );
              })
            }
          </tr>
        </tbody>
      </HTMLTable>
    );
  };
}
