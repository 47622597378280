import { Radio, RadioGroup, Spinner } from '@blueprintjs/core';
import {
  Blindenzeichen,
  CreateArtikel,
  FlaschenForm,
  FlaschentypBlindenzeichenAssociation,
} from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps, UseFetchLikeServiceFunctionResult } from '../../infrastructure';

export function makeKonkretesBlindenzeichenSelector(
  dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>,
  flaschenFormDataProvider: UseFetchLikeServiceFunctionResult<Array<FlaschenForm>>,
): any {
  // eslint-disable-next-line react/display-name
  return (props: StammdatenFieldEditorComplexRendererProps<CreateArtikel>) => {

    if (dataProvider.isLoading || flaschenFormDataProvider.isLoading) {
      return <Spinner />;
    }

    const blindenzeichen_associations = flaschenFormDataProvider.data?.find(flaschenForm => flaschenForm.database_id === props.stammdatenEntity.flaschen_form_database_id)?.flaschentyp.moegliche_blindenzeichen ?? [];

    return (
      <RadioGroup
        inline
        onChange={(event) => {
          const selected_database_id = Number((event.target as any).value);
          const blindenzeichen = dataProvider.data?.find((blindenzeichen) => blindenzeichen.database_id === selected_database_id);
          props.onChange({
            blindenzeichen_database_id: blindenzeichen?.database_id,
            blindenzeichen_version_id: blindenzeichen?.version_id,
          });
        }}
        selectedValue={props.stammdatenEntity.blindenzeichen_database_id}
      >
        {
          blindenzeichen_associations.map((blindenzeichen_assoc) => {
            const blindenzeichen = getBlindenzeichenByFlaschentypBlindenzeichenAssociation(dataProvider, blindenzeichen_assoc);
            return <Radio disabled={props.disabled} key={blindenzeichen.database_id} label={blindenzeichen.bezeichnung} value={blindenzeichen.database_id} />;
          })
        }
        {
          blindenzeichen_associations.length === 0 &&
            (<Radio disabled key={0} label='Bitte erst Flaschenform auswählen' value={0} />)
        }
      </RadioGroup>
    );
  };
}

function getBlindenzeichenByFlaschentypBlindenzeichenAssociation(dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>, value: FlaschentypBlindenzeichenAssociation): Blindenzeichen{
  const blindenzeichen = dataProvider.data?.find((item) => item.database_id === value.blindenzeichen_database_id && item.version_id === value.blindenzeichen_version_id);
  return blindenzeichen as Blindenzeichen;
}
