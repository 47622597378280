"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON = exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped = exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON(json) {
    return ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped(json, false);
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON = ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSON;
function ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_database_id': json['produktionsauftrag_database_id'],
        'ist_teilrueckmeldung': json['ist_teilrueckmeldung'],
        'anzahl_stapel_1': json['anzahl_stapel_1'],
        'anzahl_vpe_je_stapel_1': json['anzahl_vpe_je_stapel_1'],
        'hoehe_stapel_1': json['hoehe_stapel_1'],
        'anzahl_stapel_2': !runtime_1.exists(json, 'anzahl_stapel_2') ? undefined : json['anzahl_stapel_2'],
        'anzahl_vpe_je_stapel_2': !runtime_1.exists(json, 'anzahl_vpe_je_stapel_2') ? undefined : json['anzahl_vpe_je_stapel_2'],
        'hoehe_stapel_2': !runtime_1.exists(json, 'hoehe_stapel_2') ? undefined : json['hoehe_stapel_2'],
    };
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped = ProduktionsauftragBlasenLagerzugangErstellenPayloadFromJSONTyped;
function ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_database_id': value.produktionsauftrag_database_id,
        'ist_teilrueckmeldung': value.ist_teilrueckmeldung,
        'anzahl_stapel_1': value.anzahl_stapel_1,
        'anzahl_vpe_je_stapel_1': value.anzahl_vpe_je_stapel_1,
        'hoehe_stapel_1': value.hoehe_stapel_1,
        'anzahl_stapel_2': value.anzahl_stapel_2,
        'anzahl_vpe_je_stapel_2': value.anzahl_vpe_je_stapel_2,
        'hoehe_stapel_2': value.hoehe_stapel_2,
    };
}
exports.ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON = ProduktionsauftragBlasenLagerzugangErstellenPayloadToJSON;
