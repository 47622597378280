import { Dialog, H2 } from '@blueprintjs/core';
import { KundenauftragStornierungAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
} from '../../../infrastructure';

export function KundenauftragZuPauStornierungAbfragen(props: CustomFormProps & { finish: () => void }): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Kundenauftrag stornieren'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>
          Soll der Kundenauftrag auch storniert werden?
        </H2>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton
          onClick={() => {
            props.finish();
            return props.finishUserTask(KundenauftragStornierungAbfragenResultToJSON({ soll_kuau_storniert_werden: true }));
          }}
          disabled={props.disabled}
        >
          Ja, KUAU stornieren
        </AsyncButton>
        <AsyncButton
          intent='primary'
          onClick={() => {
            props.finish();
            return props.finishUserTask(KundenauftragStornierungAbfragenResultToJSON({ soll_kuau_storniert_werden: false }));
          }}
          disabled={props.disabled}
        >
          Nein, KUAU nicht stornieren
        </AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
