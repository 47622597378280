import { Firma, FirmaTyp } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps, makeEnumDropDownPickerRenderer } from '../../infrastructure';
import { FirmaTypKeyMapping } from './FirmaTyp';

export function makeFirmaTypEditor(): any {
  return (props: StammdatenFieldEditorComplexRendererProps<Firma>) => {
    const onChange = (value: FirmaTyp): void => {
      console.log(value);
      props.onChange({ typ: value, nummer: value !== FirmaTyp.Kunde ? undefined : props.stammdatenEntity.nummer });
    };
    return makeEnumDropDownPickerRenderer(Object.values(FirmaTyp), (data) => FirmaTypKeyMapping[data])({ ...props, onChange, value: props.stammdatenEntity.typ });
  };
}
