import { Card } from '@blueprintjs/core';
import React from 'react';
import { RequireClaims } from '../../infrastructure/RequireClaims';
import { verpackungDialogs } from '../stammdatenDialogs';

import styles from './Dashboard.module.scss';

export type VerpackungNavigatorProps = {
  openStartDialog: (startDialogId: string) => void,
};

export class VerpackungNavigator extends React.Component<VerpackungNavigatorProps> {
  public render(): JSX.Element {
    return (
      <Card className={styles.verpackung_navigator}>
        <Card className={styles.verpackung_navigator_title}>Verpackung</Card>
        {
          verpackungDialogs.map(dialog => (
            <RequireClaims key={dialog.title} claims={dialog.readClaims} hideOnError>
              <Card key={dialog.title} onClick={() => this.props.openStartDialog(dialog.route)} interactive>{dialog.title}</Card>
            </RequireClaims>
          ))
        }
      </Card>
    );
  }
}

