"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReducedArtikelToJSON = exports.ReducedArtikelFromJSONTyped = exports.ReducedArtikelFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ReducedArtikelFromJSON(json) {
    return ReducedArtikelFromJSONTyped(json, false);
}
exports.ReducedArtikelFromJSON = ReducedArtikelFromJSON;
function ReducedArtikelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'artikel_nummer_des_empfaengers': !runtime_1.exists(json, 'artikel_nummer_des_empfaengers') ? undefined : json['artikel_nummer_des_empfaengers'],
        'artikel_nummer_des_kunden': !runtime_1.exists(json, 'artikel_nummer_des_kunden') ? undefined : json['artikel_nummer_des_kunden'],
        'blindenzeichen': _1.ReducedBlindenzeichenFromJSON(json['blindenzeichen']),
        'empfaenger': _1.ReducedFirmaFromJSON(json['empfaenger']),
        'flaschen_form': _1.ReducedFlaschenFormFromJSON(json['flaschen_form']),
        'granulat_farbe': _1.ReducedGranulatFarbeFromJSON(json['granulat_farbe']),
        'kunde': _1.ReducedFirmaFromJSON(json['kunde']),
        'werkstoff_mischung': _1.ReducedWerkstoffMischungFromJSON(json['werkstoff_mischung']),
        'werkstoff_typ_1': _1.ReducedWerkstoffTypFromJSON(json['werkstoff_typ_1']),
        'werkstoff_typ_2': !runtime_1.exists(json, 'werkstoff_typ_2') ? undefined : _1.ReducedWerkstoffTypFromJSON(json['werkstoff_typ_2']),
        'einfaerbungs_grad': !runtime_1.exists(json, 'einfaerbungs_grad') ? undefined : json['einfaerbungs_grad'],
        'gewicht': json['gewicht'],
        'nummer': json['nummer'],
        'database_id': json['database_id'],
    };
}
exports.ReducedArtikelFromJSONTyped = ReducedArtikelFromJSONTyped;
function ReducedArtikelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'aenderungsgrund': value.aenderungsgrund,
        'artikel_nummer_des_empfaengers': value.artikel_nummer_des_empfaengers,
        'artikel_nummer_des_kunden': value.artikel_nummer_des_kunden,
        'blindenzeichen': _1.ReducedBlindenzeichenToJSON(value.blindenzeichen),
        'empfaenger': _1.ReducedFirmaToJSON(value.empfaenger),
        'flaschen_form': _1.ReducedFlaschenFormToJSON(value.flaschen_form),
        'granulat_farbe': _1.ReducedGranulatFarbeToJSON(value.granulat_farbe),
        'kunde': _1.ReducedFirmaToJSON(value.kunde),
        'werkstoff_mischung': _1.ReducedWerkstoffMischungToJSON(value.werkstoff_mischung),
        'werkstoff_typ_1': _1.ReducedWerkstoffTypToJSON(value.werkstoff_typ_1),
        'werkstoff_typ_2': _1.ReducedWerkstoffTypToJSON(value.werkstoff_typ_2),
        'einfaerbungs_grad': value.einfaerbungs_grad,
        'gewicht': value.gewicht,
        'nummer': value.nummer,
        'database_id': value.database_id,
    };
}
exports.ReducedArtikelToJSON = ReducedArtikelToJSON;
