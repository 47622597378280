import { Classes } from '@blueprintjs/core';
import { ErrorBoundary, Provider } from '@rollbar/react';
import React, { PropsWithChildren } from 'react';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import styles from './App.module.scss';
import {
  KundenauftragVerwalten,
  Plantafel,
  ProduktionsauftragVerwalten,
  RahmenauftragVerwalten,
} from './auftragsabwicklung';
import {
  AuthProvider,
  DialogStrings,
  EmptyObject,
  PropsWithConfig,
  StartDialogProps,
  StartDialogRoutes,
  TransactionProvider,
  withConfig,
  withServices,
  withTransactionService,
} from './infrastructure';
import { Dashboard, Sidebar } from './navigation';
import {
  ArtikelVerwalten,
  BlasformVerwalten,
  BlasmaschineVerwalten,
  BlasmaschineZeitenVerwalten,
  BlindenzeichenVerwalten,
  EinstellparameterBlasformVerwalten,
  ExtruderTemperaturVerwalten,
  FahrzeugVerwalten,
  FahrzeugZuKundeVerwalten,
  FirmaVerwalten,
  FlaschenFormVerwalten,
  FlaschenTypVerwalten,
  GewichtstoleranzVerwalten,
  GranulatFarbeVerwalten,
  HalsTeilVerwalten,
  HalsteilZuBlasformVerwalten,
  LagerpostenVerwalten,
  LieferantVerwalten,
  PackordnungVerwalten,
  QHinweisProduktVerwalten,
  StellblattAbstaendeVerwalten,
  VerpackungsEinheitVerwalten,
  VerpackungsMaterialVerwalten,
  VerpackungsVorschriftVerwalten,
  WerkstoffMischungVerwalten,
  WerkstoffTypVerwalten,
  WerkstoffVerwalten,
} from './stammdaten';
import './style.scss';
import { TransactionErrorModal } from './infrastructure/transactions/TransactionErrorModal';
import {
  AbmusterungPrint,
  BegleitdokumentPrint,
  EtikettKartonPrint,
  EtikettTrayPrint,
  GewichtskontrollePrint,
  GranulatFarbeBedarfVorschauPrint,
  MaschinenlaufplanPrint,
  MaschinenlaufplanVorschauPrint,
  ProduktionsplanPrint,
  PruefprotokollPrint,
  QHinweisePrint,
  Rueckmeldung1Print,
  Rueckmeldung2Print,
  RueckstellmessprotokollPrint,
  StellblattPrint,
  UmbauplanPrint,
  VPUnterschriftenPrint,
} from './auftragsabwicklung/printables';

export const AgGridThemeContext = React.createContext({ agGridTheme: 'ag-theme-balham' });

function ThemeContextProvider(props: PropsWithChildren<Record<any, any>>) : JSX.Element {

  const [theme, setThemeInState] = React.useState<'light' | 'dark'>(localStorage.getItem('color-theme') as any ?? 'light');

  const setTheme = (newTheme: 'light' | 'dark'): void => {
    localStorage.setItem('color-theme', newTheme);
    setThemeInState(newTheme);
  };

  if (theme === 'light') {
    document.body.classList.remove(Classes.DARK);
    document.body.classList.remove(styles.body_dark_mode);
  } else {
    document.body.classList.add(Classes.DARK);
    document.body.classList.add(styles.body_dark_mode);
  }

  (window as any).machDasEsGutAussieht = () => setTheme(theme === 'light' ? 'dark' : 'light');

  return (
    <AgGridThemeContext.Provider value={{ agGridTheme: theme === 'light' ? 'ag-theme-balham' : 'ag-theme-dark' }}>
      {props.children}
    </AgGridThemeContext.Provider>
  );
}

function App(props: PropsWithConfig<EmptyObject>): JSX.Element {

  const config = props.config;

  return (
    <ThemeContextProvider>
      <AuthProvider
        location={useLocation()}
        navigate={useNavigate()}
        authorityConfig={{
          authority: config.authorityUrl,
          clientId: config.clientId,
          redirectBasePath: config.redirectBasePath,
          responseType: config.responseType,
          scopes: config.scopes,
        }}
      >
        <StartDialogRoutes
          additionalRoutes={[
            {
              path: '/print/granulat_farbe_bedarf_vorschau/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: GranulatFarbeBedarfVorschauPrint,
              documentTitle: 'Granulat-Farbe Bedarfsvorschau',
            },
            {
              path: '/print/rueckstell_messprotokoll/:correlation_id',
              requiredClaims: ['read_produktionsauftrag', 'read_granulatfarbe'],
              element: RueckstellmessprotokollPrint,
              documentTitle: 'Rückstellmessprotokoll',
            },
            {
              path: '/print/umbauplan/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: UmbauplanPrint,
              documentTitle: 'Umbauplan',
            },
            {
              path: '/print/produktionsplan/:blasmaschine_nummer/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: ProduktionsplanPrint,
              documentTitle: 'Produktionsplan',
            },
            {
              path: '/print/maschinenlaufplan_vorschau/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: MaschinenlaufplanVorschauPrint,
              documentTitle: 'Maschinenlaufplan Vorschau',
            },
            {
              path: '/print/arbeitspapiere/maschinenlaufplan/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: MaschinenlaufplanPrint,
              documentTitle: 'Maschinenlaufplan',
            },
            {
              path: '/print/arbeitspapiere/stellblatt/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: StellblattPrint,
              documentTitle: 'Stellblatt',
            },
            {
              path: '/print/arbeitspapiere/gewichtskontrolle/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: GewichtskontrollePrint,
              documentTitle: 'Gewichtskontrolle',
            },
            {
              path: '/print/arbeitspapiere/qhinweise/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: QHinweisePrint,
              documentTitle: 'Q-Hinweise',
            },
            {
              path: '/print/arbeitspapiere/rueckmeldung1/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: Rueckmeldung1Print,
              documentTitle: 'Rückmeldung 1',
            },
            {
              path: '/print/arbeitspapiere/rueckmeldung2/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: Rueckmeldung2Print,
              documentTitle: 'Rückmeldung 2',
            },
            {
              path: '/print/arbeitspapiere/begleitdokument/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: BegleitdokumentPrint,
              documentTitle: 'Begleitdokument',
            },
            {
              path: '/print/arbeitspapiere/etikett_tray/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: EtikettTrayPrint,
              documentTitle: 'Etikett Tray',
            },
            {
              path: '/print/arbeitspapiere/etikett_karton/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: EtikettKartonPrint,
              documentTitle: 'Etikett Karton',
            },
            {
              path: '/print/arbeitspapiere/vp_unterschriften/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: VPUnterschriftenPrint,
              documentTitle: 'VP Unterschriften',
            },
            {
              path: '/print/arbeitspapiere/pruefprotokoll/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: PruefprotokollPrint,
              documentTitle: 'Prüfprotokoll',
            },
            {
              path: '/print/arbeitspapiere/abmusterung/:correlation_id',
              requiredClaims: ['read_produktionsauftrag'],
              element: AbmusterungPrint,
              documentTitle: 'Abmusterung',
            },
            {
              path: '*',
              requiredClaims: [],
              element: () => <TransactionErrorModal error={{ detail: 'Unknown address' }} />,
            },
          ]}
          dialogs={{
            [DialogStrings.Artikel.route]: withSidebar(withTransactionService(withServices(ArtikelVerwalten))),
            [DialogStrings.Blasform.route]: withSidebar(withTransactionService(withServices(BlasformVerwalten))),
            [DialogStrings.Blasmaschine.route]: withSidebar(withTransactionService(withServices(BlasmaschineVerwalten))),
            [DialogStrings.BlasmaschineZeiten.route]: withSidebar(withTransactionService(withServices(BlasmaschineZeitenVerwalten))),
            [DialogStrings.Blindenzeichen.route]: withSidebar(withTransactionService(withServices(BlindenzeichenVerwalten))),
            [DialogStrings.Dashboard.route]: withSidebar(withServices(Dashboard)),
            [DialogStrings.EinstellparameterBlasform.route]: withSidebar(withTransactionService(withServices(EinstellparameterBlasformVerwalten))),
            [DialogStrings.ExtruderTemperatur.route]: withSidebar(withTransactionService(withServices(ExtruderTemperaturVerwalten))),
            [DialogStrings.Fahrzeug.route]: withSidebar(withTransactionService(withServices(FahrzeugVerwalten))),
            [DialogStrings.FahrzeugZuKunde.route]: withSidebar(withTransactionService(withServices(FahrzeugZuKundeVerwalten))),
            [DialogStrings.Firma.route]: withSidebar(withTransactionService(withServices(FirmaVerwalten))),
            [DialogStrings.Flaschenform.route]: withSidebar(withTransactionService(withServices(FlaschenFormVerwalten))),
            [DialogStrings.Flaschentyp.route]: withSidebar(withTransactionService(withServices(FlaschenTypVerwalten))),
            [DialogStrings.Gewichtstoleranz.route]: withSidebar(withTransactionService(withServices(GewichtstoleranzVerwalten))),
            [DialogStrings.Granulatfarbe.route]: withSidebar(withTransactionService(withServices(GranulatFarbeVerwalten))),
            [DialogStrings.Halsteil.route]: withSidebar(withTransactionService(withServices(HalsTeilVerwalten))),
            [DialogStrings.HalsteilZuBlasform.route]: withSidebar(withTransactionService(withServices(HalsteilZuBlasformVerwalten))),
            [DialogStrings.Kundenauftrag.route]: withSidebar(withTransactionService(withServices(KundenauftragVerwalten))),
            [DialogStrings.Lagerposten.route]: withSidebar(withTransactionService(withServices(LagerpostenVerwalten))),
            [DialogStrings.Lieferant.route]: withSidebar(withTransactionService(withServices(LieferantVerwalten))),
            [DialogStrings.Packordnung.route]: withSidebar(withTransactionService(withServices(PackordnungVerwalten))),
            [DialogStrings.Plantafel.route]: withSidebar(withTransactionService(withServices(Plantafel))),
            [DialogStrings.Produktionsauftrag.route]: withSidebar(withTransactionService(withServices(ProduktionsauftragVerwalten))),
            [DialogStrings.Qhinweisprodukt.route]: withSidebar(withTransactionService(withServices(QHinweisProduktVerwalten))),
            [DialogStrings.Rahmenauftrag.route]: withSidebar(withTransactionService(withServices(RahmenauftragVerwalten))),
            [DialogStrings.StellblattAbstaende.route]: withSidebar(withTransactionService(withServices(StellblattAbstaendeVerwalten))),
            [DialogStrings.Verpackungseinheit.route]: withSidebar(withTransactionService(withServices(VerpackungsEinheitVerwalten))),
            [DialogStrings.Verpackungsmaterial.route]: withSidebar(withTransactionService(withServices(VerpackungsMaterialVerwalten))),
            [DialogStrings.Verpackungsvorschrift.route]: withSidebar(withTransactionService(withServices(VerpackungsVorschriftVerwalten))),
            [DialogStrings.Werkstoff.route]: withSidebar(withTransactionService(withServices(WerkstoffVerwalten))),
            [DialogStrings.Werkstofftyp.route]: withSidebar(withTransactionService(withServices(WerkstoffTypVerwalten))),
            [DialogStrings.WerkstoffMischung.route]: withSidebar(withTransactionService(withServices(WerkstoffMischungVerwalten))),
          }}
        />
      </AuthProvider>
    </ThemeContextProvider>
  );
}

const AppWithConfig = withConfig(App);

function withSidebar<TProps extends StartDialogProps>(Component: React.ComponentType<TProps>): (props: TProps) => JSX.Element {
  // eslint-disable-next-line react/display-name
  return (props: TProps) => (
    <>
      <div className={styles.grid}>
        <Sidebar openStartDialog={props.openStartDialog} />
        <div className={styles.component}>
          <Component {...props} />
        </div>
      </div>
    </>
  );
}

function AppWithErrorReporting(): JSX.Element {
  const isProd = process.env.NODE_ENV === 'production';

  const app = (
    <BrowserRouter>
      <TransactionProvider>
        <AppWithConfig />
      </TransactionProvider>
    </BrowserRouter>
  );

  if (!isProd) {
    return app;
  }

  return (
    <Provider
      config={{
        accessToken: '0a7b4ff0f6bd4853b66c3bca2fc2070b',
        environment: 'prd-demo',
        captureUncaught: true,
        captureUnhandledRejections: true,
      }}
    >
      <ErrorBoundary>
        {app}
      </ErrorBoundary>
    </Provider>
  );
}

export default AppWithErrorReporting;

