import { Dialog, H2, HTMLTable } from '@blueprintjs/core';
import React from 'react';
import {
  BpmnValidationError,
  Produktionsphase,
  RueckmeldungMengeErfassenMitUnterbrechungOptionalResultFromJSON,
  RueckmeldungMengeErfassenMitUnterbrechungResultToJSON,
} from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  NumberEditor,
  getPayloadOrBpmnValidationErrorFromToken,
  removeFieldFromValidationError,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungMengeErfassenMitUnterbrechung(
  props: CustomFormProps<{ anzahl_teilrueckmeldungen?: number }>,
): JSX.Element {
  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, RueckmeldungMengeErfassenMitUnterbrechungOptionalResultFromJSON);

  const [produktionsphasen, setProduktionsphasen] = React.useState<Array<Produktionsphase>>(previousValues.produktionsphasen ?? [{} as any, {} as any]);
  const [anzahlProduktionsphasen, setAnzahlProduktionsphasen] = React.useState<number>(previousValues.produktionsphasen?.length ?? 2);
  const [validationError, _setValidationError] = React.useState<BpmnValidationError | null>(err);
  const setValidationError = (validationError: BpmnValidationError | null, fieldLocation: string | Array<string>): void => {
    _setValidationError({ ...removeFieldFromValidationError(validationError, fieldLocation) as BpmnValidationError, previous_payload: validationError?.previous_payload ?? {} });
  };

  const updateAnzahlProduktionsphasen = (value: number): void => {
    const amountOfNewProduktionsphasen = value - produktionsphasen.length;
    if (amountOfNewProduktionsphasen > 0) {
      setProduktionsphasen([...produktionsphasen, ...Array(amountOfNewProduktionsphasen).fill({})]);
    }
    setAnzahlProduktionsphasen(value);
  };

  const updateRow = (row: number, updateObj: any): void => {
    setProduktionsphasen(produktionsphasen.map((phase, index) => {
      if (index === row) {
        return {
          ...phase,
          ...updateObj,
        };
      }
      return phase;
    }));
  };

  const renderRows = (): Array<JSX.Element> => {
    const jsxRows = [];
    for (let i = 0; i < anzahlProduktionsphasen; i++) {
      jsxRows.push(
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <DialogFormField fieldLocation={['produktionsphasen', i.toString(), 'zaehlerstand_umbau_start']} lastValidationResponse={validationError}>
              <NumberEditor
                value={produktionsphasen[i].zaehlerstand_umbau_start}
                onChange={(value) => {
                  setValidationError(validationError, ['produktionsphasen', i.toString(), 'zaehlerstand_umbau_start']);
                  updateRow(i, { zaehlerstand_umbau_start: value ?? undefined });
                }}
              />
            </DialogFormField>
          </td>
          <td>
            <DialogFormField fieldLocation={['produktionsphasen', i.toString(), 'zaehlerstand_produktion_start']} lastValidationResponse={validationError}>
              <NumberEditor
                value={produktionsphasen[i].zaehlerstand_produktion_start}
                onChange={(value) => {
                  setValidationError(validationError, ['produktionsphasen', i.toString(), 'zaehlerstand_produktion_start']);
                  updateRow(i, { zaehlerstand_produktion_start: value ?? undefined });
                }}
              />
            </DialogFormField>
          </td>
          <td>
            <DialogFormField fieldLocation={['produktionsphasen', i.toString(), 'zaehlerstand_produktion_ende']} lastValidationResponse={validationError}>
              <NumberEditor
                value={produktionsphasen[i].zaehlerstand_produktion_ende}
                onChange={(value) => {
                  setValidationError(validationError, ['produktionsphasen', i.toString(), 'zaehlerstand_produktion_ende']);
                  updateRow(i, { zaehlerstand_produktion_ende: value ?? undefined });
                }}
              />
            </DialogFormField>
          </td>
          <td>
            <DialogFormField fieldLocation={['produktionsphasen', i.toString(), 'menge_gut']} lastValidationResponse={validationError}>
              <NumberEditor
                value={produktionsphasen[i].menge_gut}
                onChange={(value) => {
                  setValidationError(validationError, ['produktionsphasen', i.toString(), 'menge_gut']);
                  updateRow(i, { menge_gut: value ?? undefined });
                }}
              />
            </DialogFormField>
          </td>
        </tr>,
      );
    }
    return jsxRows;
  };

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Mengen erfassen</H2>

        <DialogFormField fieldLabel='Produktionsphasen' fieldLocation={['produktionsphasen']}>
          <NumberEditor min={2} value={anzahlProduktionsphasen} onChange={(value) => updateAnzahlProduktionsphasen(value == null || value < 2 ? 2 : value)} />
        </DialogFormField>
        <HTMLTable>
          <thead>
            <tr>
              <td>Phase</td>
              <td>ZS-Umbau-Start</td>
              <td>ZS-Prod.-Start</td>
              <td>ZS-Prod.-Ende</td>
              <td>Menge Gut</td>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </HTMLTable>
      </DialogBody>
      <DialogFooter
        onFinish={() => {
          props.finishUserTask(RueckmeldungMengeErfassenMitUnterbrechungResultToJSON({ produktionsphasen: produktionsphasen.slice(0, anzahlProduktionsphasen) }));
        }}
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
