import { Dialog, H2 } from '@blueprintjs/core';
import { addDays, format } from 'date-fns';
import { RueckmeldungIstEnddatumKorrektAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
  PropsWithServices,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';
import { useCallback } from 'react';

export function RueckmeldungIstEnddatumKorrektAbfragen(props: PropsWithServices<CustomFormProps<{ database_id: number; anzahl_teilrueckmeldungen?: number; }>>,
): JSX.Element {
  const serviceFunction = useCallback((initOverrides?: RequestInit) => props.services.produktionsauftrag.getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGet({ database_id: props.tokenPayload.database_id }, initOverrides), [props.services.produktionsauftrag, props.tokenPayload.database_id]);
  const produktionsauftrag = useFetchLikeServiceFunction(serviceFunction, null);
  const blasmaschine = produktionsauftrag.data?.blasmaschine;
  const blasdauer = produktionsauftrag.data?.blasdauer;
  const endeDatum = format(addDays(new Date(blasmaschine?.start_datum ?? new Date()), blasdauer ?? 0), 'd.L.yyyy');

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Ist das Enddatum {endeDatum} korrekt?</H2>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => props.abortUserTask(RueckmeldungIstEnddatumKorrektAbfragenResultToJSON({ ende_datum_korrekt: false }))} disabled={props.disabled}>Nein</AsyncButton>
        <AsyncButton onClick={() => props.finishUserTask(RueckmeldungIstEnddatumKorrektAbfragenResultToJSON({ ende_datum_korrekt: true }))} disabled={props.disabled} intent='primary'>Ja</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
