import { Card } from '@blueprintjs/core';
import React from 'react';
import { DialogStrings } from '../../infrastructure';
import { RequireClaims } from '../../infrastructure/RequireClaims';

import styles from './Dashboard.module.scss';

export type AuftragsabwicklungNavigatorProps = {
  openStartDialog: (startDialogId: string) => void,
};

export class AuftragsabwicklungNavigator extends React.Component<AuftragsabwicklungNavigatorProps> {
  public render(): JSX.Element {
    return (
      <Card className={styles.auftragsabwicklung_navigator}>
        <Card className={styles.auftrag_navigator_title}>Aufträge / Planung / Fertig-Bestand </Card>
        <RequireClaims claims={DialogStrings.Plantafel.readClaims} hideOnError>
          <Card onClick={() => this.props.openStartDialog(DialogStrings.Plantafel.route)} interactive>Plantafel</Card>
        </RequireClaims>
        <RequireClaims claims={DialogStrings.Rahmenauftrag.readClaims} hideOnError>
          <Card onClick={() => this.props.openStartDialog(DialogStrings.Rahmenauftrag.route)} interactive>Rahmenaufträge</Card>
        </RequireClaims>
        <RequireClaims claims={DialogStrings.Kundenauftrag.readClaims} hideOnError>
          <Card onClick={() => this.props.openStartDialog(DialogStrings.Kundenauftrag.route)} interactive>Kundenaufträge</Card>
        </RequireClaims>
        <RequireClaims claims={DialogStrings.Produktionsauftrag.readClaims} hideOnError>
          <Card onClick={() => this.props.openStartDialog(DialogStrings.Produktionsauftrag.route)} interactive>Produktionsaufträge</Card>
        </RequireClaims>
        <RequireClaims claims={DialogStrings.Lagerposten.readClaims} hideOnError>
          <Card onClick={() => this.props.openStartDialog(DialogStrings.Lagerposten.route)} interactive>Lagerposten</Card>
        </RequireClaims>
      </Card>
    );
  }
}
