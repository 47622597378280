import {
  Button,
  Dialog,
  H2,
  Spinner,
} from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateVerpackungsVorschrift,
  MaterialGruppe,
  ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON,
  VerpackungsVorschrift,
} from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  StammdatenModal,
  filterFetchLikeServiceFunctionResult,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { createVerpackungsvorschriftFieldEditors, getVerpackungsVorschriftNummer } from '../../../../stammdaten';
import { VerpackungsVorschriftTable } from '../../../plantafel/modals';

function VerpackungsVorschriftAnlegen(props: PropsWithServices<ModalProps<void>> & { kundenauftrag_database_id: number }): JSX.Element {
  const kundenauftragServiceFunction = useCallback((initOverrides?: RequestInit) => props.services.kundenauftrag.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: props.kundenauftrag_database_id }, initOverrides), [props.services.kundenauftrag, props.kundenauftrag_database_id]);
  const kundenauftragResult = useFetchLikeServiceFunction(kundenauftragServiceFunction, null);

  const verpackungsMaterialResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_material.getAllVerpackungsMaterialGet, props.services.verpackungs_material);
  const verpackungsEinheitResult = useFetchLikeGetAllServiceFunction(props.services.verpackungs_einheit.getAllVerpackungsEinheitGet, props.services.verpackungs_einheit);

  const lagenResult = filterFetchLikeServiceFunctionResult(verpackungsEinheitResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_50);
  const transportEinheitTraegerResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_80);
  const kantenschutzResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_70);
  const folieResult = filterFetchLikeServiceFunctionResult(verpackungsMaterialResult, (value) => value.material_gruppe === MaterialGruppe.NUMBER_90);

  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);

  const editors = createVerpackungsvorschriftFieldEditors(verpackungsEinheitResult, lagenResult, transportEinheitTraegerResult, kantenschutzResult, folieResult, kundenResult, empfaengerResult, flaschenFormResult);


  if (kundenauftragResult.isLoading) {
    return (
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => props.onModalFinished()} intent='warning'>Abbrechen</Button>
        </DialogFooter>
      </Dialog>
    );
  }

  return (
    <StammdatenModal<VerpackungsVorschrift, CreateVerpackungsVorschrift>
      dialogTitle='Verpackungs-Vorschrift erstellen'
      fields={editors}
      onDialogConfirmed={(value) => props.services.verpackungs_vorschrift.postVerpackungsVorschriftPost({ CreateVerpackungsVorschrift: value })}
      onModalFinished={() => props.onModalFinished()}
      primaryButtonIntent='primary'
      primaryButtonTitle='Erstellen'
      stammdatenEntity={kundenauftragResult.data
        ? {
          kunde_database_id: kundenauftragResult.data.artikel.kunde_database_id,
          kunde_version_id: kundenauftragResult.data.artikel.kunde_version_id,
          empfaenger_database_id: kundenauftragResult.data.artikel.empfaenger_database_id ?? kundenauftragResult.data.artikel.kunde_database_id,
          empfaenger_version_id: kundenauftragResult.data.artikel.empfaenger_version_id ?? kundenauftragResult.data.artikel.kunde_version_id,
          flaschen_form_database_id: kundenauftragResult.data.artikel.flaschen_form_database_id,
          flaschen_form_version_id: kundenauftragResult.data.artikel.flaschen_form_version_id,
        }
        : {} as any}
    />
  );
}

export function ProduktionsauftragBlasenVerpackungsVorschriftWaehlen(props: PropsWithServices<CustomFormProps<{ kundenauftrag_database_id: number }>> & { onFinish: () => void, onAbort: () => void }): JSX.Element {

  const verpackungsVorschriftRepository = props.services.verpackungs_vorschrift;
  const serviceFunction = useCallback(
    (initOverrides?: RequestInit) =>
      verpackungsVorschriftRepository.getAllForKundenauftragVerpackungsVorschriftFindKundenauftragDatabaseIdGet({
        kundenauftrag_database_id: props.tokenPayload.kundenauftrag_database_id,
        include_deleted: false,
      }, initOverrides),
    [verpackungsVorschriftRepository, props.tokenPayload.kundenauftrag_database_id],
  );
  const verpackungsVorschriftResult = useFetchLikeServiceFunction(serviceFunction, null);
  const [verpackungsVorschrift, setVerpackungsVorschrift] = useState<VerpackungsVorschrift | null>(null);
  const [showCreateVPVModal, setShowCreateVPVModal] = useState(false);

  useEffect(() => setVerpackungsVorschrift(verpackungsVorschriftResult.data && verpackungsVorschriftResult.data.length > 0 ? verpackungsVorschriftResult.data[0] : null), [verpackungsVorschriftResult.data]);

  return (
    <>
      {showCreateVPVModal && (
        <VerpackungsVorschriftAnlegen
          {...props}
          kundenauftrag_database_id={props.tokenPayload.kundenauftrag_database_id}
          onModalFinished={() => {
            verpackungsVorschriftResult.reload();
            setShowCreateVPVModal(false);
          }}
        />
      )
      }
      <Dialog
        isOpen={true}
        onClose={async () => {
          await props.finishUserTask({ response: 'abort' });
          props.onAbort();
        }}
        title='Verpackungsvorschrift wählen'
        isCloseButtonShown={!props.disabled}
      >
        <DialogBody>
          <H2>Welche Verpackungsvorschrift gilt für diese Produktion?</H2>
          <div style={{ display: 'grid', gap: '5px' }}>
            <DefaultDropDownPicker
              dataProvider={verpackungsVorschriftResult}
              getDisplayData={(data) => {
                return { primaryTitle: getVerpackungsVorschriftNummer(data), secondaryTitle: `Prio: ${data.prioritaet}` };
              }}
              value={verpackungsVorschrift}
              onChange={setVerpackungsVorschrift}
            />
            {
              verpackungsVorschriftResult.data?.length === 0 && (
                <Button onClick={() => setShowCreateVPVModal(true)}>
                  Verpackungsvorschrift anlegen
                </Button>
              )
            }
            <VerpackungsVorschriftTable
              verpackungsVorschrift={verpackungsVorschrift}
              services={props.services}
              atlasEngineClient={props.atlasEngineClient}
              config={props.config}
              subscriptionEventService={props.subscriptionEventService}
            />
          </div>
        </DialogBody>
        <DialogFooter
          onAbort={async () => {
            await props.finishUserTask({ response: 'abort' });
            props.onAbort();
          }}
          disabled={props.disabled}
        >
          <AsyncButton
            disabled={verpackungsVorschrift === null || props.disabled}
            intent='primary'
            onClick={async () => {
              await props.finishUserTask(ProduktionsauftragBlasenVerpackungsVorschriftWaehlenResultToJSON({
                verpackungs_vorschrift_database_id: verpackungsVorschrift?.database_id ?? -1,
                verpackungs_vorschrift_version_id: verpackungsVorschrift?.version_id ?? -1,
              }));
              props.onFinish();
            }}
          >Fertig
          </AsyncButton>
        </DialogFooter>
      </Dialog>
    </>
  );
}
