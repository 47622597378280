"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmitableEventNamesToJSON = exports.EmitableEventNamesFromJSONTyped = exports.EmitableEventNamesFromJSON = exports.EmitableEventNames = void 0;
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var EmitableEventNames;
(function (EmitableEventNames) {
    EmitableEventNames["Kundenauftrag"] = "Kundenauftrag";
    EmitableEventNames["Produktionsauftrag"] = "Produktionsauftrag";
    EmitableEventNames["Rahmenauftrag"] = "Rahmenauftrag";
    EmitableEventNames["Artikel"] = "Artikel";
    EmitableEventNames["Firma"] = "Firma";
    EmitableEventNames["FlaschenForm"] = "FlaschenForm";
    EmitableEventNames["FlaschenTyp"] = "FlaschenTyp";
    EmitableEventNames["HalsTeil"] = "HalsTeil";
    EmitableEventNames["Blindenzeichen"] = "Blindenzeichen";
    EmitableEventNames["Gewichtstoleranz"] = "Gewichtstoleranz";
    EmitableEventNames["GranulatFarbe"] = "GranulatFarbe";
    EmitableEventNames["Lieferant"] = "Lieferant";
    EmitableEventNames["Werkstoff"] = "Werkstoff";
    EmitableEventNames["WerkstoffTyp"] = "WerkstoffTyp";
    EmitableEventNames["WerkstoffMischung"] = "WerkstoffMischung";
    EmitableEventNames["QHinweisProdukt"] = "QHinweisProdukt";
    EmitableEventNames["VerpackungsMaterial"] = "VerpackungsMaterial";
    EmitableEventNames["VerpackungsEinheit"] = "VerpackungsEinheit";
    EmitableEventNames["Packordnung"] = "Packordnung";
    EmitableEventNames["VerpackungsVorschrift"] = "VerpackungsVorschrift";
    EmitableEventNames["Fahrzeug"] = "Fahrzeug";
    EmitableEventNames["FahrzeugZuKunde"] = "FahrzeugZuKunde";
    EmitableEventNames["Abstaende"] = "Abstaende";
    EmitableEventNames["Blasform"] = "Blasform";
    EmitableEventNames["Blasmaschine"] = "Blasmaschine";
    EmitableEventNames["BlasmaschineZeiten"] = "BlasmaschineZeiten";
    EmitableEventNames["EinstellparameterBlasform"] = "EinstellparameterBlasform";
    EmitableEventNames["ExtruderTemperatur"] = "ExtruderTemperatur";
    EmitableEventNames["HalsteilZuBlasform"] = "HalsteilZuBlasform";
    EmitableEventNames["Lagerposten"] = "Lagerposten";
})(EmitableEventNames = exports.EmitableEventNames || (exports.EmitableEventNames = {}));
function EmitableEventNamesFromJSON(json) {
    return EmitableEventNamesFromJSONTyped(json, false);
}
exports.EmitableEventNamesFromJSON = EmitableEventNamesFromJSON;
function EmitableEventNamesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EmitableEventNamesFromJSONTyped = EmitableEventNamesFromJSONTyped;
function EmitableEventNamesToJSON(value) {
    return value;
}
exports.EmitableEventNamesToJSON = EmitableEventNamesToJSON;
