import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import styles from './GewichtskontrollePrint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import logo from './assets/logo.png';
import messinterval from './assets/messinterval.png';
import { formatNumber } from '../../../infrastructure/formatNumber';

const ROWS_TO_FILL_PAGE = 40;

export function GewichtskontrollePrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return Gewichtskontrolle;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function EmptyRows({ count }: { count: number }): JSX.Element {
  return (
    <>
      {
        Array.from({ length: count }).map((_, index) => (
          <tr key={index}>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        ))
      }
    </>
  );
}

function Gewichtskontrolle(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const { maschinenlaufplan, gewichtstoleranz } = payload;

  return (
    <div className={styles.gewichtskontrolle_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <HTMLTable className={styles.gewichtskontrolle_table}>
        <thead>
          <tr>
            <th colSpan={4} rowSpan={2}>MLP</th>
            <th colSpan={3} className={styles.protokoll_header}>PROTOKOLL</th>
            <th colSpan={3} rowSpan={2}>
              <img src={logo} alt='' className={styles.logo} />
            </th>
          </tr>
          <tr>
            <th colSpan={3} className={styles.gewichts_kontrolle_header}>Gewichts-Kontrolle</th>
          </tr>
          <tr className={styles.empty_row} />
          <tr>
            <th colSpan={2} className={styles.bottom_dash}>BLM:</th>
            <td className={styles.border_fat}>{gewichtstoleranz.blasmaschine_nummer}</td>
            <td colSpan={4} />
            <th className={styles.pau_nummer_header} colSpan={2}>PAU-Nr.:</th>
            <td className={styles.border_fat}>{gewichtstoleranz.produktionsauftrag_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2} className={styles.bottom_dash}>Kunde:</th>
            <td colSpan={4} className={styles.border_fat}>{gewichtstoleranz.kunde_name}</td>
          </tr>
          <tr>
            <th colSpan={2} className={styles.bottom_dash}>FL-Typ-Bez | Farbe:</th>
            <td colSpan={4} className={styles.border_fat}>{maschinenlaufplan.flaschentyp_bezeichnung}</td>
            <td />
            <td colSpan={3} className={styles.border_fat}>{gewichtstoleranz.farbe_bezeichnung}</td>
          </tr>
          <tr className={styles.empty_row} />
          <tr>
            <th colSpan={2} className={styles.bottom_dash}>Gewicht (Soll) in g:</th>
            <td colSpan={3} className={styles.border_fat}>
              {formatNumber(gewichtstoleranz.artikel_gewicht, 1)} &#177;{formatNumber(gewichtstoleranz.gewichtstoleranz, 1)}
              <img src={messinterval} alt='' className={styles.messinterval} />
            </td>
            <td colSpan={4} />
            <td className={styles.bei_abweichung}>bei Abweichung<div className={styles.bei_abweichung_pfeil}><div>&#8594;</div></div></td>
          </tr>
          <tr>
            <td colSpan={2} />
            <th colSpan={6} className={styles.gray_bg_center_border}>Gewicht</th>
          </tr>
          <tr>
            <th className={styles.gray_bg_center}>Datum</th>
            <th className={styles.gray_bg_center}>Uhrzeit</th>
            <th className={styles.gray_bg_center}>Nest 1</th>
            <th className={styles.gray_bg_center}>Nest 2</th>
            <th className={styles.gray_bg_center}>Nest 3</th>
            <th className={styles.gray_bg_center}>Nest 4</th>
            <th className={styles.gray_bg_center}>Nest 5</th>
            <th className={styles.gray_bg_center}>Nest 6</th>
            <th className={styles.schichtfuehrer_informiert_header}>Schichtführer<br />informiert</th>
            <th className={styles.gray_bg_center}>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.long} />
            <td className={styles.long} />
            <td className={styles.shorter} />
            <td className={styles.shorter} />
            <td className={styles.shorter} />
            <td className={styles.shorter} />
            <td className={styles.shorter} />
            <td className={styles.shorter} />
            <td />
            <td className={styles.longer} />
          </tr>
          <EmptyRows count={ROWS_TO_FILL_PAGE} />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={10}>MLP1 - Gewichtskontrolle</td>
          </tr>
        </tfoot>
      </HTMLTable>
    </div>
  );
}
