"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LagerpostenToJSON = exports.LagerpostenFromJSONTyped = exports.LagerpostenFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LagerpostenFromJSON(json) {
    return LagerpostenFromJSONTyped(json, false);
}
exports.LagerpostenFromJSON = LagerpostenFromJSON;
function LagerpostenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'pau_nummer': json['pau_nummer'],
        'kuau_nummer': !runtime_1.exists(json, 'kuau_nummer') ? undefined : json['kuau_nummer'],
        'kunde_nummer': !runtime_1.exists(json, 'kunde_nummer') ? undefined : json['kunde_nummer'],
        'kunde_name_kurz': !runtime_1.exists(json, 'kunde_name_kurz') ? undefined : json['kunde_name_kurz'],
        'empfaenger_nummer': !runtime_1.exists(json, 'empfaenger_nummer') ? undefined : json['empfaenger_nummer'],
        'empfaenger_name_kurz': !runtime_1.exists(json, 'empfaenger_name_kurz') ? undefined : json['empfaenger_name_kurz'],
        'bestell_nummer': !runtime_1.exists(json, 'bestell_nummer') ? undefined : json['bestell_nummer'],
        'kunden_artikelNummer': !runtime_1.exists(json, 'kunden_artikelNummer') ? undefined : json['kunden_artikelNummer'],
        'flaschen_form_nummer': !runtime_1.exists(json, 'flaschen_form_nummer') ? undefined : json['flaschen_form_nummer'],
        'flaschen_typ_bezeichnung': !runtime_1.exists(json, 'flaschen_typ_bezeichnung') ? undefined : json['flaschen_typ_bezeichnung'],
        'halsteil_bezeichnung': !runtime_1.exists(json, 'halsteil_bezeichnung') ? undefined : json['halsteil_bezeichnung'],
        'blindenzeichen_bezeichnung': !runtime_1.exists(json, 'blindenzeichen_bezeichnung') ? undefined : json['blindenzeichen_bezeichnung'],
        'gewicht': !runtime_1.exists(json, 'gewicht') ? undefined : json['gewicht'],
        'farbe_nummer': !runtime_1.exists(json, 'farbe_nummer') ? undefined : json['farbe_nummer'],
        'farbe_bezeichnung': !runtime_1.exists(json, 'farbe_bezeichnung') ? undefined : json['farbe_bezeichnung'],
        'einfaerbungsgrad': !runtime_1.exists(json, 'einfaerbungsgrad') ? undefined : json['einfaerbungsgrad'],
        'werkstoff_typ_bezeichnung': !runtime_1.exists(json, 'werkstoff_typ_bezeichnung') ? undefined : json['werkstoff_typ_bezeichnung'],
        'verpackungs_einheit_nummer': !runtime_1.exists(json, 'verpackungs_einheit_nummer') ? undefined : json['verpackungs_einheit_nummer'],
        'verpackungs_einheit_bezeichnung': !runtime_1.exists(json, 'verpackungs_einheit_bezeichnung') ? undefined : json['verpackungs_einheit_bezeichnung'],
        'verpackungs_einheit_ist_karton': !runtime_1.exists(json, 'verpackungs_einheit_ist_karton') ? undefined : json['verpackungs_einheit_ist_karton'],
        'stueck_je_vpe': !runtime_1.exists(json, 'stueck_je_vpe') ? undefined : json['stueck_je_vpe'],
        'anzahl_vpe_je_stapel': !runtime_1.exists(json, 'anzahl_vpe_je_stapel') ? undefined : json['anzahl_vpe_je_stapel'],
        'hoehe_stapel': !runtime_1.exists(json, 'hoehe_stapel') ? undefined : json['hoehe_stapel'],
        'anzahl_stapel': !runtime_1.exists(json, 'anzahl_stapel') ? undefined : json['anzahl_stapel'],
        'bestellmenge': !runtime_1.exists(json, 'bestellmenge') ? undefined : json['bestellmenge'],
        'menge_zugang_pr_stueck': !runtime_1.exists(json, 'menge_zugang_pr_stueck') ? undefined : json['menge_zugang_pr_stueck'],
        'lagerort': !runtime_1.exists(json, 'lagerort') ? undefined : json['lagerort'],
        'lagerplatz': !runtime_1.exists(json, 'lagerplatz') ? undefined : json['lagerplatz'],
        'auslieferung': !runtime_1.exists(json, 'auslieferung') ? undefined : json['auslieferung'],
        'buchung_am': !runtime_1.exists(json, 'buchung_am') ? undefined : json['buchung_am'],
        'buchung_von': !runtime_1.exists(json, 'buchung_von') ? undefined : json['buchung_von'],
        'letzter_vorgang': !runtime_1.exists(json, 'letzter_vorgang') ? undefined : json['letzter_vorgang'],
        'reserviert_am': !runtime_1.exists(json, 'reserviert_am') ? undefined : json['reserviert_am'],
        'reserviert_fuer': !runtime_1.exists(json, 'reserviert_fuer') ? undefined : json['reserviert_fuer'],
        'reserviert_durch': !runtime_1.exists(json, 'reserviert_durch') ? undefined : json['reserviert_durch'],
        'gesperrt': !runtime_1.exists(json, 'gesperrt') ? undefined : json['gesperrt'],
        'hinweis': !runtime_1.exists(json, 'hinweis') ? undefined : json['hinweis'],
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : json['liefertermin_soll'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
    };
}
exports.LagerpostenFromJSONTyped = LagerpostenFromJSONTyped;
function LagerpostenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'pau_nummer': value.pau_nummer,
        'kuau_nummer': value.kuau_nummer,
        'kunde_nummer': value.kunde_nummer,
        'kunde_name_kurz': value.kunde_name_kurz,
        'empfaenger_nummer': value.empfaenger_nummer,
        'empfaenger_name_kurz': value.empfaenger_name_kurz,
        'bestell_nummer': value.bestell_nummer,
        'kunden_artikelNummer': value.kunden_artikelNummer,
        'flaschen_form_nummer': value.flaschen_form_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'blindenzeichen_bezeichnung': value.blindenzeichen_bezeichnung,
        'gewicht': value.gewicht,
        'farbe_nummer': value.farbe_nummer,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'einfaerbungsgrad': value.einfaerbungsgrad,
        'werkstoff_typ_bezeichnung': value.werkstoff_typ_bezeichnung,
        'verpackungs_einheit_nummer': value.verpackungs_einheit_nummer,
        'verpackungs_einheit_bezeichnung': value.verpackungs_einheit_bezeichnung,
        'verpackungs_einheit_ist_karton': value.verpackungs_einheit_ist_karton,
        'stueck_je_vpe': value.stueck_je_vpe,
        'anzahl_vpe_je_stapel': value.anzahl_vpe_je_stapel,
        'hoehe_stapel': value.hoehe_stapel,
        'anzahl_stapel': value.anzahl_stapel,
        'bestellmenge': value.bestellmenge,
        'menge_zugang_pr_stueck': value.menge_zugang_pr_stueck,
        'lagerort': value.lagerort,
        'lagerplatz': value.lagerplatz,
        'auslieferung': value.auslieferung,
        'buchung_am': value.buchung_am,
        'buchung_von': value.buchung_von,
        'letzter_vorgang': value.letzter_vorgang,
        'reserviert_am': value.reserviert_am,
        'reserviert_fuer': value.reserviert_fuer,
        'reserviert_durch': value.reserviert_durch,
        'gesperrt': value.gesperrt,
        'hinweis': value.hinweis,
        'liefertermin_soll': value.liefertermin_soll,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
    };
}
exports.LagerpostenToJSON = LagerpostenToJSON;
