import { BlasmaschineZeiten, CreateBlasmaschineZeiten } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeTextAreaEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';
import { makeExtruderSondertemperaturenEditorRenderer } from './ExtruderSondetemperaturen';
import { makeWbkGraphRenderer } from './WbkGraph';
import { makeWbkNumberEditorRenderer } from './WbkNumberEditor';

import styles from './BlasmaschineZeitenVerwalten.module.scss';
import { defaultColumnState } from './defaultColumnConfiguration';


export type BlasmaschineZeitenVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function BlasmaschineZeitenVerwalten(props: BlasmaschineZeitenVerwaltenProps): JSX.Element {
  const blasmaschineResult = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);
  const blasformResult = useFetchLikeGetAllServiceFunction(props.services.blasform.getAllStellblattBlasformGet, props.services.blasform);
  const halsteilResult = useFetchLikeGetAllServiceFunction(props.services.halsTeil.getAllHalsTeilGet, props.services.halsTeil);
  const werkstoffMischungResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffMischung.getAllWerkstoffMischungGet, props.services.werkstoffMischung);

  const editors: StammdatenFieldEditors<CreateBlasmaschineZeiten> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['werkstoff_mischung_database_id', 'werkstoff_mischung_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(werkstoffMischungResult, (data) => ({
        id: data.database_id,
        primaryTitle: `${data.anteil_1}% ${data.werkstoff_1.bezeichnung}${data.werkstoff_2 ? ` / ${data.anteil_2}% ${data.werkstoff_2.bezeichnung}` : ''}`,
        secondaryTitle: `${data.werkstoff_1.nummer}${data.werkstoff_2 ? ` / ${data.werkstoff_2.nummer}` : ''}`,
      }), 'werkstoff_mischung'),
      label: 'Werkstoff-Mischung',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['blasmaschine_database_id', 'blasmaschine_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(blasmaschineResult, (blasmaschine) => ({ primaryTitle: blasmaschine.nummer?.toString(), id: blasmaschine.database_id }), 'blasmaschine'),
      label: 'Blasmaschine',
    },
    {
      type: 'complex',
      fields: ['blasform_database_id', 'blasform_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(blasformResult, (data) => ({
        id: data.database_id,
        primaryTitle: data.nummer,
      }), 'blasform'),
      label: 'Blasform',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'gewicht',
      renderer: makeNumberEditorRenderer({ precision: 1 }),
      label: 'Gewicht [g]',
    },
    {
      type: 'complex',
      fields: ['hals_teil_database_id', 'hals_teil_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(halsteilResult, (data) => ({
        id: data.database_id,
        primaryTitle: data.bezeichnung,
        secondaryTitle: data.nummer,
      }), 'hals_teil'),
      label: 'Halsteil',
    },
    {
      type: 'simple',
      field: 'programm_zeit',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
      label: 'Programm-Zeit',
    },
    {
      type: 'simple',
      field: 'programm_hub',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
      label: 'Programm-Hub',
    },
    {
      type: 'simple',
      field: 'maschinen_zyklus',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
      label: 'Maschinenzyklus',
    },
    {
      type: 'label',
      label: 'Zeitensollwerte - Verfahrenszeiten - [sec]',
      gridArea: 'zeitensollwerte_label',
    },
    {
      type: 'simple',
      label: 'Stück / h',
      field: 'stueck_pro_stunde',
      renderer: makeNumberEditorRenderer(),
    },
    {
      type: 'simple',
      label: 'Blasdruck [bar]',
      field: 'blasdruck',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Stanze vor',
      field: 'verz_stanze_vor',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Zeit Bandstopp',
      field: 'zeit_bandstop',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Masch.-Taktgeber',
      field: 'maschinen_taktgeber',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Blaszeit (Ist)',
      field: 'blaszeit_ist',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Stanze zur.',
      field: 'verz_stanze_zur',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Stützluft',
      field: 'verz_sturtzluft',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Masch.-Laufkontr.',
      field: 'maschinen_laufkontrolle',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Standzeit',
      field: 'standzeit',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Greifer zu',
      field: 'verz_greifer_zu',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Dauer Stützluft',
      field: 'dauer_stuetzluft',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Trennen vor',
      field: 'verz_trennen_1_vor',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Form zu',
      field: 'verz_form_zu',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Gr. auf (Se)',
      field: 'verz_greifer_auf_se',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Dekomp.',
      field: 'verz_dekomp',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Trennen zur.',
      field: 'verz_trennen_1_zur',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Lüftzeit Dorn',
      field: 'lueftzeit_dorn',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Greifer auf (Le)',
      field: 'verz_greifer_auf_le',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Dauer Dekomp.',
      field: 'dauer_dekomp',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Zeitglied Trennen',
      field: 'zeitglied_trennen_1',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Lüftzeit Form',
      field: 'lueftzeit_form',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Entn. Senk.',
      field: 'verz_ent_senk',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Pneum. 1',
      field: 'verz_pneum_1',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Blas./Blasd.',
      field: 'verz_blas_blad',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Wagen ab',
      field: 'verz_wagen_ab',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Dorn hoch',
      field: 'verz_dorn_hoch',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Dauer Pneum. 1',
      field: 'dauer_pneum_1',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Vorblaszeit',
      field: 'vorblaszeit',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'simple',
      label: 'Verz. Wagen hoch',
      field: 'verz_wagen_hoch',
      renderer: makeNumberEditorRenderer({ precision: 3 }),
    },
    {
      type: 'label',
      label: 'Extruder-Sondertemperaturen',
      gridArea: 'extruder_sondertemperaturen_label',
    },
    {
      type: 'complex',
      fields: new Array(10).fill(0).map((_, index) => [`zone_${index}`, `zone_${index}_temp`]).flat() as any,
      renderer: makeExtruderSondertemperaturenEditorRenderer(),
      gridArea: 'extruder_sondertemperaturen',
    },
    {
      type: 'label',
      label: 'WBKs',
      gridArea: 'wbk_label',
    },
    ...new Array(10).fill(0).map((_, index) => ({ type: 'simple' as any, field: `wbk_${index}` as any, label: `Pos. ${index}`, renderer: makeWbkNumberEditorRenderer() })),
    ...new Array(10).fill(0).map((_, index) => ({ type: 'simple', field: `wbk_${index + 10}` as any, label: `Pos. ${index + 10}`, renderer: makeWbkNumberEditorRenderer() })),
    ...new Array(10).fill(0).map((_, index) => ({ type: 'simple', field: `wbk_${index + 20}` as any, label: `Pos. ${index + 20}`, renderer: makeWbkNumberEditorRenderer() })),
    {
      type: 'complex',
      fields: [],
      renderer: makeWbkGraphRenderer(),
      gridArea: 'wbk_graph',
    },
    {
      type: 'simple',
      field: 'bemerkung',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Bemerkung',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Blasmaschine',
          field: 'blasmaschine',
          colId: 'blasmaschine',
          valueGetter: (params) => params.data.blasmaschine.nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Blasform',
          field: 'blasform',
          colId: 'blasform',
          valueGetter: (params) => params.data.blasform.nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Halsteil',
          colId: 'hals_teil',
          ...makeForeignKeyColumn('hals_teil', (data: BlasmaschineZeiten) => {
            return {
              description: data.hals_teil.bezeichnung,
              number: data.hals_teil.nummer,
            };
          }),
        },
        {
          headerName: 'Werkstoff-Mischung',
          colId: 'werkstoff',
          ...makeForeignKeyColumn('werkstoff_mischung', (data: BlasmaschineZeiten) => {
            return {
              description: `${data.werkstoff_mischung.anteil_1}% ${data.werkstoff_mischung.werkstoff_1.bezeichnung}${data.werkstoff_mischung.werkstoff_2 ? ` / ${data.werkstoff_mischung.anteil_2}% ${data.werkstoff_mischung.werkstoff_2.bezeichnung}` : ''}`,
              number: `${data.werkstoff_mischung.werkstoff_1.nummer}${data.werkstoff_mischung.werkstoff_2 ? ` / ${data.werkstoff_mischung.werkstoff_2.nummer}` : ''}`,
            };
          }),
        },
        {
          headerName: 'Gewicht [g]',
          field: 'gewicht',
          colId: 'gewicht',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Programm-Zeit [sec]',
          field: 'programm_zeit',
          colId: 'programm_zeit',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Programm-Hub [mm]',
          field: 'programm_hub',
          colId: 'programm_hub',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Maschinen-Zyklus',
          field: 'maschinen_zyklus',
          colId: 'maschinen_zyklus',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),

        },
        ...new Array(10).fill(0).map((_, index) => ({
          headerName: `Zone-Nr. ${index + 1}`,
          field: `zone_${index + 1}` as keyof BlasmaschineZeiten,
          colId: `zone_${index + 1}` as keyof BlasmaschineZeiten,
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        })),
        ...new Array(10).fill(0).map((_, index) => ({
          headerName: `Temperatur Zone-Nr. ${index + 1}`,
          field: `zone_${index + 1}_temp` as keyof BlasmaschineZeiten,
          colId: `zone_${index + 1}_temp` as keyof BlasmaschineZeiten,
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        })),
        {
          headerName: 'Stück / h',
          field: 'stueck_pro_stunde',
          colId: 'stueck_pro_stunde',
          filter: 'agNumberColumnFilter',
          valueGetter: (params) => {
            if (params.data.stueck_pro_stunde == null || params.data.stueck_pro_stunde === '' || isNaN(Number(params.data.stueck_pro_stunde))) {
              return null;
            }
            return parseFloat(params.data.stueck_pro_stunde);
          },
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Masch.-Taktgeber [sec]',
          field: 'maschinen_taktgeber',
          colId: 'maschinen_taktgeber',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Masch.-Laufkontrolle [sec]',
          field: 'maschinen_laufkontrolle',
          colId: 'maschinen_laufkontrolle',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Trennen 1 vor [sec]',
          field: 'verz_trennen_1_vor',
          colId: 'verz_trennen_1_vor',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Trennen 1 zur',
          field: 'verz_trennen_1_zur',
          colId: 'verz_trennen_1_zur',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Zeitglied Trennen 1',
          field: 'zeitglied_trennen_1',
          colId: 'zeitglied_trennen_1',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Blas./Blasd.',
          field: 'verz_blas_blad',
          colId: 'verz_blas_blad',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Vorblaszeit',
          field: 'vorblaszeit',
          colId: 'vorblaszeit',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blasdruck',
          field: 'blasdruck',
          colId: 'blasdruck',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Standzeit',
          field: 'standzeit',
          colId: 'standzeit',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Form zu',
          field: 'verz_form_zu',
          colId: 'verz_form_zu',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Lüftzeit Dorn',
          field: 'lueftzeit_dorn',
          colId: 'lueftzeit_dorn',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Lüftzeit Form',
          field: 'lueftzeit_form',
          colId: 'lueftzeit_form',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Wagen ab',
          field: 'verz_wagen_ab',
          colId: 'verz_wagen_ab',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Wagen hoch',
          field: 'verz_wagen_hoch',
          colId: 'verz_wagen_hoch',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Stanze vor',
          field: 'verz_stanze_vor',
          colId: 'verz_stanze_vor',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Stanze zur.',
          field: 'verz_stanze_zur',
          colId: 'verz_stanze_zur',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Greifer zu',
          field: 'verz_greifer_zu',
          colId: 'verz_greifer_zu',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Greifer auf (Se)',
          field: 'verz_greifer_auf_se',
          colId: 'verz_greifer_auf_se',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Greifer auf (Le)',
          field: 'verz_greifer_auf_le',
          colId: 'verz_greifer_auf_le',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Entn. Senk.',
          field: 'verz_ent_senk',
          colId: 'verz_ent_senk',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Dorn hoch',
          field: 'verz_dorn_hoch',
          colId: 'verz_dorn_hoch',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Zeit Bandstop',
          field: 'zeit_bandstop',
          colId: 'zeit_bandstop',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Stützluft',
          field: 'verz_sturtzluft',
          colId: 'verz_sturtzluft',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Dauer Stützluft',
          field: 'dauer_stuetzluft',
          colId: 'dauer_stuetzluft',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Dekomp.',
          field: 'verz_dekomp',
          colId: 'verz_dekomp',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Dauer Dekomp.',
          field: 'dauer_dekomp',
          colId: 'dauer_dekomp',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Verz. Pneum. 1',
          field: 'verz_pneum_1',
          colId: 'verz_pneum_1',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Dauer Pneum. 1',
          field: 'dauer_pneum_1',
          colId: 'dauer_pneum_1',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blaszeit IST',
          field: 'blaszeit_ist',
          colId: 'blaszeit_ist',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        ...new Array(30).fill(0).map((_, index) => ({
          headerName: `WBK ${index}`,
          field: `wbk_${index}` as keyof BlasmaschineZeiten,
          colId: `wbk_${index}` as keyof BlasmaschineZeiten,
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        })),
        {
          headerName: 'Bemerkung',
          field: 'bemerkung',
          colId: 'bemerkung',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateBlasmaschineZeiten) => props.services.blasmaschine_zeiten.postStellblattBlasmaschineZeitenPost({ CreateBlasmaschineZeiten: data }),
        update: (data) => props.services.blasmaschine_zeiten.putByDatabaseIdStellblattBlasmaschineZeitenDatabaseIdPut({ database_id: data.database_id, UpdateBlasmaschineZeiten: data }),
        delete: (data, ignoreIntegrity) => props.services.blasmaschine_zeiten.deleteByDatabaseIdStellblattBlasmaschineZeitenDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.blasmaschine_zeiten.restoreByDatabaseIdStellblattBlasmaschineZeitenDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.blasmaschine_zeiten.getAllStellblattBlasmaschineZeitenGet({ include_deleted }),
        getHistory: (data: BlasmaschineZeiten) => props.services.blasmaschine_zeiten.getHistoryStellblattBlasmaschineZeitenDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.blasmaschine_zeiten.lockByDatabaseIdStellblattBlasmaschineZeitenDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.blasmaschine_zeiten.unlockByDatabaseIdStellblattBlasmaschineZeitenDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
        export: () => props.services.blasmaschine_zeiten.downloadExcelStellblattBlasmaschineZeitenExcelPost(),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      stammdatenModalCssClass={styles.blasmaschine_zeiten_verwalten_modal}
      dialogStrings={DialogStrings.BlasmaschineZeiten}
    />
  );
}
