"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EtikettTrayDatenToJSON = exports.EtikettTrayDatenFromJSONTyped = exports.EtikettTrayDatenFromJSON = exports.EtikettTrayDatenTypEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var EtikettTrayDatenTypEnum;
(function (EtikettTrayDatenTypEnum) {
    EtikettTrayDatenTypEnum["normal"] = "normal";
    EtikettTrayDatenTypEnum["sonax"] = "sonax";
    EtikettTrayDatenTypEnum["shb"] = "shb";
})(EtikettTrayDatenTypEnum = exports.EtikettTrayDatenTypEnum || (exports.EtikettTrayDatenTypEnum = {}));
function EtikettTrayDatenFromJSON(json) {
    return EtikettTrayDatenFromJSONTyped(json, false);
}
exports.EtikettTrayDatenFromJSON = EtikettTrayDatenFromJSON;
function EtikettTrayDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'typ': json['typ'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'halsteil_nummer': json['halsteil_nummer'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'artikel_gewicht': json['artikel_gewicht'],
        'farbe_nummer': json['farbe_nummer'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'vpe_je_stapel_1': json['vpe_je_stapel_1'],
        'vpe_je_stapel_2': !runtime_1.exists(json, 'vpe_je_stapel_2') ? undefined : json['vpe_je_stapel_2'],
        'flaschen_pro_vpe': json['flaschen_pro_vpe'],
        'flaschen_pro_stapel_1': json['flaschen_pro_stapel_1'],
        'flaschen_pro_stapel_2': !runtime_1.exists(json, 'flaschen_pro_stapel_2') ? undefined : json['flaschen_pro_stapel_2'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'stretcher': json['stretcher'],
        'hoehe_stapel_1': json['hoehe_stapel_1'],
        'hoehe_stapel_2': !runtime_1.exists(json, 'hoehe_stapel_2') ? undefined : json['hoehe_stapel_2'],
        'bestell_nummer': !runtime_1.exists(json, 'bestell_nummer') ? undefined : json['bestell_nummer'],
        'empfaenger_auftrags_nummer': !runtime_1.exists(json, 'empfaenger_auftrags_nummer') ? undefined : json['empfaenger_auftrags_nummer'],
        'artikel_nummer_empfaenger': !runtime_1.exists(json, 'artikel_nummer_empfaenger') ? undefined : json['artikel_nummer_empfaenger'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
    };
}
exports.EtikettTrayDatenFromJSONTyped = EtikettTrayDatenFromJSONTyped;
function EtikettTrayDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'typ': value.typ,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'halsteil_nummer': value.halsteil_nummer,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'artikel_gewicht': value.artikel_gewicht,
        'farbe_nummer': value.farbe_nummer,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'vpe_je_stapel_1': value.vpe_je_stapel_1,
        'vpe_je_stapel_2': value.vpe_je_stapel_2,
        'flaschen_pro_vpe': value.flaschen_pro_vpe,
        'flaschen_pro_stapel_1': value.flaschen_pro_stapel_1,
        'flaschen_pro_stapel_2': value.flaschen_pro_stapel_2,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'stretcher': value.stretcher,
        'hoehe_stapel_1': value.hoehe_stapel_1,
        'hoehe_stapel_2': value.hoehe_stapel_2,
        'bestell_nummer': value.bestell_nummer,
        'empfaenger_auftrags_nummer': value.empfaenger_auftrags_nummer,
        'artikel_nummer_empfaenger': value.artikel_nummer_empfaenger,
        'empfaenger_name': value.empfaenger_name,
    };
}
exports.EtikettTrayDatenToJSON = EtikettTrayDatenToJSON;
