"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RahmenauftragToJSON = exports.RahmenauftragFromJSONTyped = exports.RahmenauftragFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RahmenauftragFromJSON(json) {
    return RahmenauftragFromJSONTyped(json, false);
}
exports.RahmenauftragFromJSON = RahmenauftragFromJSON;
function RahmenauftragFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'bestell_nummer': json['bestell_nummer'],
        'auftragsnummer_des_empfaenger': !runtime_1.exists(json, 'auftragsnummer_des_empfaenger') ? undefined : json['auftragsnummer_des_empfaenger'],
        'datum_eingang': (new Date(json['datum_eingang'])),
        'status': _1.RahmenauftragStatusFromJSON(json['status']),
        'menge': json['menge'],
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenFromJSON(json['liefertermin_kennzeichen']),
        'liefertermin_soll': !runtime_1.exists(json, 'liefertermin_soll') ? undefined : (new Date(json['liefertermin_soll'])),
        'artikel_database_id': json['artikel_database_id'],
        'artikel_version_id': json['artikel_version_id'],
        'version_id': json['version_id'],
        'created_at': (new Date(json['created_at'])),
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
        'changed_by_user_name': !runtime_1.exists(json, 'changed_by_user_name') ? undefined : json['changed_by_user_name'],
        'changed_by_user_id': !runtime_1.exists(json, 'changed_by_user_id') ? undefined : json['changed_by_user_id'],
        'database_id': json['database_id'],
        'gefertigte_menge': json['gefertigte_menge'],
        'bestellbestand': json['bestellbestand'],
        'offene_menge': json['offene_menge'],
        'artikel': _1.ArtikelFromJSON(json['artikel']),
    };
}
exports.RahmenauftragFromJSONTyped = RahmenauftragFromJSONTyped;
function RahmenauftragToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'bestell_nummer': value.bestell_nummer,
        'auftragsnummer_des_empfaenger': value.auftragsnummer_des_empfaenger,
        'datum_eingang': (value.datum_eingang.toISOString()),
        'status': _1.RahmenauftragStatusToJSON(value.status),
        'menge': value.menge,
        'liefertermin_kennzeichen': _1.LieferterminKennzeichenToJSON(value.liefertermin_kennzeichen),
        'liefertermin_soll': value.liefertermin_soll === undefined ? undefined : (value.liefertermin_soll.toISOString()),
        'artikel_database_id': value.artikel_database_id,
        'artikel_version_id': value.artikel_version_id,
        'version_id': value.version_id,
        'created_at': (value.created_at.toISOString()),
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
        'changed_by_user_name': value.changed_by_user_name,
        'changed_by_user_id': value.changed_by_user_id,
        'database_id': value.database_id,
        'gefertigte_menge': value.gefertigte_menge,
        'bestellbestand': value.bestellbestand,
        'offene_menge': value.offene_menge,
        'artikel': _1.ArtikelToJSON(value.artikel),
    };
}
exports.RahmenauftragToJSON = RahmenauftragToJSON;
