import { Button, Classes } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';
import { AsyncButton } from './AsyncButton';

import styles from './Dialog.module.scss';

export function DialogHeader(props: PropsWithChildren<{ onClick: () => void }>): JSX.Element {
  return (
    <div className={Classes.DIALOG_HEADER}>
      <div className={Classes.HEADING}>
        {props.children}
      </div>
      <Button className={Classes.DIALOG_CLOSE_BUTTON} minimal={true} icon='cross' onClick={props.onClick} />
    </div>
  );
}

export function DialogBody(props: PropsWithChildren<{ scrollHorizontalOverflow?: boolean }>): JSX.Element {
  return (
    <div className={Classes.DIALOG_BODY} style={props.scrollHorizontalOverflow ? { overflowX: 'scroll' } : { overflow: 'visible' }}>
      {props.children}
    </div>
  );
}

export function DialogFooter(props: PropsWithChildren<{ onAbort?: () => void | Promise<void>, onFinish?: () => void | Promise<void>, disabled?: boolean }>): JSX.Element {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className={styles.footer}>
        {props.onAbort && <AsyncButton onClick={props.onAbort} disabled={props.disabled}>Abbrechen</AsyncButton>}
        <div className={styles.custom_actions}>
          {props.children}
          {props.onFinish && <AsyncButton onClick={props.onFinish} disabled={props.disabled} intent='primary'>Fertig</AsyncButton>}
        </div>
      </div>
    </div>
  );
}
