import {
  Button,
  Dialog,
  H2,
  H5,
} from '@blueprintjs/core';
import { UmbauplanDatenLadenResult } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  formatDateTimeString,
} from '../../../infrastructure';

export function UmbauplanAnzeigen(props: PropsWithServices<CustomFormProps<UmbauplanDatenLadenResult>> & ModalProps<void>): JSX.Element {
  const finish = async (): Promise<void> => {
    await props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog
      isOpen
      onClose={() => props.onModalFinished()}
      title='Umbauplan drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Der Umbauplan kann nun gedruckt werden.</H2>
        {props.userTask.startedAt && <H5>Generiert am: {formatDateTimeString(props.userTask.startedAt)}</H5>}
        <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/umbauplan/${props.userTask.correlationId}`, '_blank')}>Ansehen</Button>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={props.onModalFinished}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
