import { Blasmaschine, HTTPValidationError } from 'wacoplast_wws__api';
import {
  DefaultDropDownPicker,
  DialogFormField,
  NumberEditor,
  TextViewer,
  UseFetchLikeServiceFunctionResult,
} from '../../../infrastructure';
import { calculateFarbmenge } from '.';

export type ProduktionsauftragBlasenErfassenFieldsProps = {
  blasdauer: number | null;
  blasmaschine: Blasmaschine | null;
  blasmaschinen: UseFetchLikeServiceFunctionResult<Array<Blasmaschine>>;
  einfaerbungs_grad: number | null | undefined;
  fertig_vor_liefertermin: number | null;
  gewicht: number | null | undefined;
  menge: number | null;
  vorgegebene_blasmaschine: Blasmaschine | null;
  validationError?: HTTPValidationError | null;
  onChange: (updatedEntries: Record<string, any>) => void;
}

export function ProduktionsauftragBlasenErfassenFields(props: ProduktionsauftragBlasenErfassenFieldsProps): JSX.Element {
  const farbmenge = calculateFarbmenge(props.menge ?? 0, props.gewicht ?? 0, props.einfaerbungs_grad ?? 0);

  return (
    <>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['menge']}
        fieldLabel='Menge'
      >
        <NumberEditor
          value={props.menge}
          min={0}
          onChange={(value) => props.onChange({ menge: value })}
        />
      </DialogFormField>
      <DialogFormField
        fieldLocation={[]}
        fieldLabel='Benötigte Farbmenge [kg]'
      >
        <TextViewer value={farbmenge} />
      </DialogFormField>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['blasmaschine_database_id']}
        fieldLabel='Blasmaschine'
      >
        <DefaultDropDownPicker
          dataProvider={props.blasmaschinen}
          onChange={(value) => {
            props.onChange({
              blasmaschine: value,
              blasmaschine_database_id: value?.database_id,
            });
          }}
          value={props.blasmaschine}
          getDisplayData={(data) => {
            return { primaryTitle: `${data.nummer}` };
          }}
        />
      </DialogFormField>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['blasdauer']}
        fieldLabel='Blasdauer [Tage]'
      >
        <NumberEditor value={props.blasdauer} onChange={(value) => props.onChange({ blasdauer: value })} />
      </DialogFormField>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['fertig_vor_liefertermin']}
        fieldLabel='Fertig vor Liefertermin [Tage]'
      >
        <NumberEditor value={props.fertig_vor_liefertermin} onChange={(value) => props.onChange({ fertig_vor_liefertermin: value })} />
      </DialogFormField>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['vorgegebene_blasmaschine']}
        fieldLabel='Vorgegebene Blasmaschine'
      >
        <DefaultDropDownPicker
          nullable={true}
          dataProvider={props.blasmaschinen}
          onChange={(value) => {
            props.onChange({
              vorgegebene_blasmaschine: value,
            });
          }}
          value={props.vorgegebene_blasmaschine}
          getDisplayData={(data) => {
            return { primaryTitle: `${data.nummer}` };
          }}
        />
      </DialogFormField>
    </>
  );
}
