"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateLieferantToJSON = exports.UpdateLieferantFromJSONTyped = exports.UpdateLieferantFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateLieferantFromJSON(json) {
    return UpdateLieferantFromJSONTyped(json, false);
}
exports.UpdateLieferantFromJSON = UpdateLieferantFromJSON;
function UpdateLieferantFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aenderungsgrund': !runtime_1.exists(json, 'aenderungsgrund') ? undefined : json['aenderungsgrund'],
        'nummer': json['nummer'],
        'artikel_gruppe': !runtime_1.exists(json, 'artikel_gruppe') ? undefined : _1.ArtikelGruppenFromJSON(json['artikel_gruppe']),
        'name_kurz': !runtime_1.exists(json, 'name_kurz') ? undefined : json['name_kurz'],
        'name_lang': json['name_lang'],
        'postleitzahl': !runtime_1.exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'stadt': !runtime_1.exists(json, 'stadt') ? undefined : json['stadt'],
        'strasse_hausnummer': !runtime_1.exists(json, 'strasse_hausnummer') ? undefined : json['strasse_hausnummer'],
        'telefon_1': !runtime_1.exists(json, 'telefon_1') ? undefined : json['telefon_1'],
        'telefon_2': !runtime_1.exists(json, 'telefon_2') ? undefined : json['telefon_2'],
        'fax': !runtime_1.exists(json, 'fax') ? undefined : json['fax'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.UpdateLieferantFromJSONTyped = UpdateLieferantFromJSONTyped;
function UpdateLieferantToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aenderungsgrund': value.aenderungsgrund,
        'nummer': value.nummer,
        'artikel_gruppe': _1.ArtikelGruppenToJSON(value.artikel_gruppe),
        'name_kurz': value.name_kurz,
        'name_lang': value.name_lang,
        'postleitzahl': value.postleitzahl,
        'stadt': value.stadt,
        'strasse_hausnummer': value.strasse_hausnummer,
        'telefon_1': value.telefon_1,
        'telefon_2': value.telefon_2,
        'fax': value.fax,
        'email': value.email,
    };
}
exports.UpdateLieferantToJSON = UpdateLieferantToJSON;
