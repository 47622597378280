import { CreateEinstellparameterBlasform, EinstellparameterBlasform } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMBER_FILTER_OPTS,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeForeignKeyColumn,
  makeImageViewerRenderer,
  makeNumberEditorRenderer,
  makeTextAreaEditorRenderer,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../../infrastructure';

import styles from './EinstellparameterBlasformVerwalten.module.scss';

import abschlagbacken_image from './abschlagbacken.png';
import blasdornspitze_image from './blasdornspitze.png';
import { defaultColumnState } from './defaultColumnConfiguration';

export type EinstellparameterBlasformVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function EinstellparameterBlasformVerwalten(props: EinstellparameterBlasformVerwaltenProps): JSX.Element {
  const blasformResult = useFetchLikeGetAllServiceFunction(props.services.blasform.getAllStellblattBlasformGet, props.services.blasform);
  const halsteilResult = useFetchLikeGetAllServiceFunction(props.services.halsTeil.getAllHalsTeilGet, props.services.halsTeil);

  const editors: StammdatenFieldEditors<CreateEinstellparameterBlasform> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['blasform_database_id', 'blasform_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(blasformResult, (data) => ({ id: data.database_id, primaryTitle: data.nummer }), 'blasform'),
      label: 'Blasform',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['hals_teil_database_id', 'hals_teil_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(halsteilResult, (data) => ({ id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer }), 'hals_teil'),
      label: 'Halsteil',
    },
    {
      type: 'simple',
      field: 'gewicht',
      renderer: makeNumberEditorRenderer({ precision: 1 }),
      label: 'Gewicht',
    },
    {
      type: 'label',
      label: 'Blasdornspitze',
      gridArea: 'blasdornspitze_label',
    },
    {
      type: 'simple',
      field: 'blasdornsp_l1',
      renderer: makeTextEditorRenderer(),
      label: 'Blasdornsp L1 [mm]',
    },
    {
      type: 'simple',
      field: 'blasdornsp_l2',
      renderer: makeTextEditorRenderer(),
      label: 'Blasdornsp L2 [mm]',
    },
    {
      type: 'simple',
      field: 'blasdornsp_l3',
      renderer: makeTextEditorRenderer(),
      label: 'Blasdornsp L3 [mm]',
    },
    {
      type: 'simple',
      field: 'blasdornsp_d1',
      renderer: makeNumberEditorRenderer(),
      label: 'Blasdornsp D1 [mm]',
    },
    {
      type: 'simple',
      field: 'blasdornsp_d2',
      renderer: makeNumberEditorRenderer(),
      label: 'Blasdornsp D2 [mm]',
    },
    {
      type: 'simple',
      field: 'blasdornsp_radius',
      renderer: makeTextEditorRenderer(),
      label: 'Blasdornsp Radius',
    },
    {
      type: 'label',
      label: 'Düse',
      gridArea: 'duese_label',
    },
    {
      type: 'simple',
      field: 'duesen_bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Düsen Bezeichnung',
    },
    {
      type: 'simple',
      field: 'duesen_durchmesser',
      renderer: makeNumberEditorRenderer(),
      label: 'Düsen Durchmesser [mm]',
    },
    {
      type: 'simple',
      field: 'profilierung',
      renderer: makeNumberEditorRenderer(),
      label: 'Profilierung',
    },
    {
      type: 'simple',
      field: 'schneidring_durchmesser',
      renderer: makeNumberEditorRenderer(),
      label: 'Schneidring Durchmesser [mm]',
    },
    {
      type: 'label',
      label: 'Abschlagbacken',
      gridArea: 'abschlagbacken_label',
    },
    {
      type: 'simple',
      field: 'abschlagbacken_konisch',
      renderer: makeTextEditorRenderer(),
      label: 'Abschlagbacken Konisch (ja/nein)',
    },
    {
      type: 'simple',
      field: 'backenhoehe_h1',
      renderer: makeTextEditorRenderer(),
      label: 'Backenhöhe H1 [mm]',
    },
    {
      type: 'simple',
      field: 'steghoehe_h2',
      renderer: makeTextEditorRenderer(),
      label: 'Steghöhe H2 [mm]',
    },
    {
      type: 'simple',
      field: 'abschlagbacken_d1',
      renderer: makeNumberEditorRenderer(),
      label: 'Abschlagbacken D1 [mm]',
    },
    {
      type: 'simple',
      field: 'abschlagbacken_d2',
      renderer: makeNumberEditorRenderer(),
      label: 'Abschlagbacken D2 [mm]',
    },
    {
      type: 'simple',
      field: 'unterlegbleche',
      renderer: makeTextEditorRenderer(),
      label: 'Unterlegbleche [mm]',
    },
    {
      type: 'simple',
      field: 'hinweis',
      renderer: makeTextAreaEditorRenderer(),
      label: 'Hinweise zur Produktion und Optimierung/QS',
    },
    {
      type: 'complex',
      fields: [],
      renderer: makeImageViewerRenderer(abschlagbacken_image),
      gridArea: 'abschlagbacken_image',
    },
    {
      type: 'complex',
      fields: [],
      renderer: makeImageViewerRenderer(blasdornspitze_image),
      gridArea: 'blasdornspitze_image',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Blasform',
          field: 'blasform',
          colId: 'blasform',
          valueGetter: (params) => params.data.blasform.nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Halsteil',
          colId: 'halsteil',
          ...makeForeignKeyColumn('hals_teil', (data: EinstellparameterBlasform) => ({
            description: data.hals_teil.bezeichnung,
            number: data.hals_teil.nummer,
          })),
        },
        {
          headerName: 'Gewicht',
          field: 'gewicht',
          colId: 'gewicht',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Schneidring Durchmesser [mm]',
          field: 'schneidring_durchmesser',
          colId: 'schneidring_durchmesser',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Düsen Bezeichnung',
          field: 'duesen_bezeichnung',
          colId: 'duesen_bezeichnung',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Düsen Durchmesser [mm]',
          field: 'duesen_durchmesser',
          colId: 'duesen_durchmesser',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Profilierung',
          field: 'profilierung',
          colId: 'profilierung',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Blasdornsp L1 [mm]',
          field: 'blasdornsp_l1',
          colId: 'blasdornsp_l1',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blasdornsp L2 [mm]',
          field: 'blasdornsp_l2',
          colId: 'blasdornsp_l2',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blasdornsp L3 [mm]',
          field: 'blasdornsp_l3',
          colId: 'blasdornsp_l3',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Blasdornsp D1 [mm]',
          field: 'blasdornsp_d1',
          colId: 'blasdornsp_d1',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Blasdornsp D2 [mm]',
          field: 'blasdornsp_d2',
          colId: 'blasdornsp_d2',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Blasdornsp Radius',
          field: 'blasdornsp_radius',
          colId: 'blasdornsp_radius',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Abschlagbacken Konisch',
          field: 'abschlagbacken_konisch',
          colId: 'abschlagbacken_konisch',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Backenhöhe H1 [mm]',
          field: 'backenhoehe_h1',
          colId: 'backenhoehe_h1',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Steghöhe H2 [mm]',
          field: 'steghoehe_h2',
          colId: 'steghoehe_h2',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Abschlagbacken D1 [mm]',
          field: 'abschlagbacken_d1',
          colId: 'abschlagbacken_d1',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Abschlagbacken D2 [mm]',
          field: 'abschlagbacken_d2',
          colId: 'abschlagbacken_d2',
          ...NUMBER_FILTER_OPTS,
        },
        {
          headerName: 'Unterlegbleche',
          field: 'unterlegbleche',
          colId: 'unterlegbleche',
          filter: 'agTextColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
        },
        {
          headerName: 'Hinweis zu Produktion und Optimierung',
          field: 'hinweis',
          colId: 'hinweis',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateEinstellparameterBlasform) => props.services.einstellparameter_blasform.postStellblattEinstellparameterBlasformPost({ CreateEinstellparameterBlasform: data }),
        update: (data) => props.services.einstellparameter_blasform.putByDatabaseIdStellblattEinstellparameterBlasformDatabaseIdPut({ database_id: data.database_id, UpdateEinstellparameterBlasform: data }),
        delete: (data, ignoreIntegrity) => props.services.einstellparameter_blasform.deleteByDatabaseIdStellblattEinstellparameterBlasformDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.einstellparameter_blasform.restoreByDatabaseIdStellblattEinstellparameterBlasformDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.einstellparameter_blasform.getAllStellblattEinstellparameterBlasformGet({ include_deleted }),
        getHistory: (data: EinstellparameterBlasform) => props.services.einstellparameter_blasform.getHistoryStellblattEinstellparameterBlasformDatabaseIdHistoryGet({ database_id: data.database_id }),
        lock: (data) => props.services.einstellparameter_blasform.lockByDatabaseIdStellblattEinstellparameterBlasformDatabaseIdLockPatch({ database_id: data.database_id, LockRequest: { aenderungsgrund: data.aenderungsgrund as any } }),
        unlock: (data) => props.services.einstellparameter_blasform.unlockByDatabaseIdStellblattEinstellparameterBlasformDatabaseIdUnlockPatch({ database_id: data.database_id, UnlockRequest: { aenderungsgrund: data.aenderungsgrund } }),
        export: () => props.services.einstellparameter_blasform.downloadExcelStellblattEinstellparameterBlasformExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      stammdatenModalCssClass={styles.einstellparamter_blasform_verwalten_modal}
      dialogStrings={DialogStrings.EinstellparameterBlasform}
    />
  );
}
