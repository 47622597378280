/* eslint-disable react/display-name */
import { TextArea } from '@blueprintjs/core';
import { StammdatenFieldEditorSimpleRendererProps } from '.';
import { TextEditor, TextViewer } from '../..';

export function makeTextEditorRenderer<TEntityType>(placeholder?: string, disabled?: boolean): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, string | null>) => {
    const insertPlaceholder = !props.value && props.disabled && placeholder;

    return (
      <TextEditor
        value={insertPlaceholder ? placeholder : props.value ? String(props.value) : null}
        disabled={props.disabled || disabled}
        placeholder={placeholder}
        onChange={(value) => props.onChange((value?.length ? value : null))}
      />
    );
  };
}

export function makeTextViewerRenderer<TEntityType>(placeholder?: string): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, string | null>) => {
    const insertPlaceholder = !props.value && props.disabled && placeholder;

    return (
      <TextViewer
        value={insertPlaceholder ? placeholder : props.value ? String(props.value) : null}
        placeholder={placeholder}
      />
    );
  };
}

export function makeTextAreaEditorRenderer<TEntityType>(): any {
  return (props: StammdatenFieldEditorSimpleRendererProps<TEntityType, string | null>) => (
    <TextArea
      fill={true}
      style={{ minWidth: '100%', minHeight: '10em' }}
      value={props.value ?? ''}
      disabled={props.disabled}
      onChange={(value) => props.onChange((value.target.value))}
    />
  );
}
