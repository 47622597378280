"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVerpackungsVorschriftToJSON = exports.CreateVerpackungsVorschriftFromJSONTyped = exports.CreateVerpackungsVorschriftFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateVerpackungsVorschriftFromJSON(json) {
    return CreateVerpackungsVorschriftFromJSONTyped(json, false);
}
exports.CreateVerpackungsVorschriftFromJSON = CreateVerpackungsVorschriftFromJSON;
function CreateVerpackungsVorschriftFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transport_einheit_traeger_database_id': json['transport_einheit_traeger_database_id'],
        'transport_einheit_traeger_version_id': json['transport_einheit_traeger_version_id'],
        'unterlage_database_id': !runtime_1.exists(json, 'unterlage_database_id') ? undefined : json['unterlage_database_id'],
        'unterlage_version_id': !runtime_1.exists(json, 'unterlage_version_id') ? undefined : json['unterlage_version_id'],
        'unterlagen_anzahl': !runtime_1.exists(json, 'unterlagen_anzahl') ? undefined : json['unterlagen_anzahl'],
        'zwischenlage_database_id': !runtime_1.exists(json, 'zwischenlage_database_id') ? undefined : json['zwischenlage_database_id'],
        'zwischenlage_version_id': !runtime_1.exists(json, 'zwischenlage_version_id') ? undefined : json['zwischenlage_version_id'],
        'zwischenlagen_anzahl': !runtime_1.exists(json, 'zwischenlagen_anzahl') ? undefined : json['zwischenlagen_anzahl'],
        'abdeckung_database_id': !runtime_1.exists(json, 'abdeckung_database_id') ? undefined : json['abdeckung_database_id'],
        'abdeckung_version_id': !runtime_1.exists(json, 'abdeckung_version_id') ? undefined : json['abdeckung_version_id'],
        'abdeckung_anzahl': !runtime_1.exists(json, 'abdeckung_anzahl') ? undefined : json['abdeckung_anzahl'],
        'kantenschutz_database_id': !runtime_1.exists(json, 'kantenschutz_database_id') ? undefined : json['kantenschutz_database_id'],
        'kantenschutz_version_id': !runtime_1.exists(json, 'kantenschutz_version_id') ? undefined : json['kantenschutz_version_id'],
        'folie_database_id': !runtime_1.exists(json, 'folie_database_id') ? undefined : json['folie_database_id'],
        'folie_version_id': !runtime_1.exists(json, 'folie_version_id') ? undefined : json['folie_version_id'],
        'zustand': _1.VerpackungZustandFromJSON(json['zustand']),
        'aufdruck': !runtime_1.exists(json, 'aufdruck') ? undefined : _1.AufdruckArtFromJSON(json['aufdruck']),
        'geschlossen': !runtime_1.exists(json, 'geschlossen') ? undefined : json['geschlossen'],
        'polybeutel': !runtime_1.exists(json, 'polybeutel') ? undefined : json['polybeutel'],
        'transportweg': _1.TransportwegFromJSON(json['transportweg']),
        'stapel_hoehe_2': !runtime_1.exists(json, 'stapel_hoehe_2') ? undefined : json['stapel_hoehe_2'],
        'stapel_hoehe_1': json['stapel_hoehe_1'],
        'variante': !runtime_1.exists(json, 'variante') ? undefined : json['variante'],
        'prioritaet': json['prioritaet'],
        'bemerkung': !runtime_1.exists(json, 'bemerkung') ? undefined : json['bemerkung'],
        'spezialfall_karton_reduktion': !runtime_1.exists(json, 'spezialfall_karton_reduktion') ? undefined : json['spezialfall_karton_reduktion'],
        'kunde_database_id': json['kunde_database_id'],
        'kunde_version_id': json['kunde_version_id'],
        'empfaenger_database_id': json['empfaenger_database_id'],
        'empfaenger_version_id': json['empfaenger_version_id'],
        'flaschen_form_database_id': json['flaschen_form_database_id'],
        'flaschen_form_version_id': json['flaschen_form_version_id'],
        'verpackungs_einheit_database_id': json['verpackungs_einheit_database_id'],
        'verpackungs_einheit_version_id': json['verpackungs_einheit_version_id'],
    };
}
exports.CreateVerpackungsVorschriftFromJSONTyped = CreateVerpackungsVorschriftFromJSONTyped;
function CreateVerpackungsVorschriftToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transport_einheit_traeger_database_id': value.transport_einheit_traeger_database_id,
        'transport_einheit_traeger_version_id': value.transport_einheit_traeger_version_id,
        'unterlage_database_id': value.unterlage_database_id,
        'unterlage_version_id': value.unterlage_version_id,
        'unterlagen_anzahl': value.unterlagen_anzahl,
        'zwischenlage_database_id': value.zwischenlage_database_id,
        'zwischenlage_version_id': value.zwischenlage_version_id,
        'zwischenlagen_anzahl': value.zwischenlagen_anzahl,
        'abdeckung_database_id': value.abdeckung_database_id,
        'abdeckung_version_id': value.abdeckung_version_id,
        'abdeckung_anzahl': value.abdeckung_anzahl,
        'kantenschutz_database_id': value.kantenschutz_database_id,
        'kantenschutz_version_id': value.kantenschutz_version_id,
        'folie_database_id': value.folie_database_id,
        'folie_version_id': value.folie_version_id,
        'zustand': _1.VerpackungZustandToJSON(value.zustand),
        'aufdruck': _1.AufdruckArtToJSON(value.aufdruck),
        'geschlossen': value.geschlossen,
        'polybeutel': value.polybeutel,
        'transportweg': _1.TransportwegToJSON(value.transportweg),
        'stapel_hoehe_2': value.stapel_hoehe_2,
        'stapel_hoehe_1': value.stapel_hoehe_1,
        'variante': value.variante,
        'prioritaet': value.prioritaet,
        'bemerkung': value.bemerkung,
        'spezialfall_karton_reduktion': value.spezialfall_karton_reduktion,
        'kunde_database_id': value.kunde_database_id,
        'kunde_version_id': value.kunde_version_id,
        'empfaenger_database_id': value.empfaenger_database_id,
        'empfaenger_version_id': value.empfaenger_version_id,
        'flaschen_form_database_id': value.flaschen_form_database_id,
        'flaschen_form_version_id': value.flaschen_form_version_id,
        'verpackungs_einheit_database_id': value.verpackungs_einheit_database_id,
        'verpackungs_einheit_version_id': value.verpackungs_einheit_version_id,
    };
}
exports.CreateVerpackungsVorschriftToJSON = CreateVerpackungsVorschriftToJSON;
