import { CreateVerpackungsMaterial, MaterialGruppe, VerpackungsMaterial } from 'wacoplast_wws__api';
import { MaterialGruppeKeyMapping } from '..';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeEnumDropDownPickerRenderer,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
} from '../../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type VerpackungsMaterialVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function VerpackungsMaterialVerwalten(props: VerpackungsMaterialVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateVerpackungsMaterial> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'material_gruppe',
      renderer: makeEnumDropDownPickerRenderer([MaterialGruppe.NUMBER_70, MaterialGruppe.NUMBER_80, MaterialGruppe.NUMBER_90], (data) => (MaterialGruppeKeyMapping as any)[data]),
      label: 'Material-Gruppe',
    },
    {
      type: 'simple',
      field: 'bezeichnung_kurz',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung (kurz)',
    },
    {
      type: 'simple',
      field: 'bezeichnung_lang',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung (lang)',
    },
    {
      type: 'simple',
      field: 'durchmesser_mm',
      renderer: makeNumberEditorRenderer(),
      label: 'Höhe / Durchmesser [mm]',
    },
    {
      type: 'simple',
      field: 'gewicht_kg',
      renderer: makeNumberEditorRenderer(),
      label: 'Gewicht [kg]',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Material-Gruppe',
          field: 'material_gruppe',
          colId: 'material_gruppe',
          filter: 'agSetColumnFilter',
          valueGetter: (value) => (MaterialGruppeKeyMapping as any)[value.data.material_gruppe],
        },
        {
          headerName: 'Bezeichnung (kurz)',
          field: 'bezeichnung_kurz',
          colId: 'bezeichnung_kurz',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Bezeichnung (lang)',
          field: 'bezeichnung_lang',
          colId: 'bezeichnung_lang',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Höhe / Durchmesser [mm]',
          field: 'durchmesser_mm',
          colId: 'durchmesser_mm',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Gewicht [kg]',
          field: 'gewicht_kg',
          colId: 'gewicht_kg',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateVerpackungsMaterial) => props.services.verpackungs_material.postVerpackungsMaterialPost({ CreateVerpackungsMaterial: data }),
        update: (data) => props.services.verpackungs_material.putByDatabaseIdVerpackungsMaterialDatabaseIdPut({ database_id: data.database_id, UpdateVerpackungsMaterial: data }),
        delete: (data, ignoreIntegrity) => props.services.verpackungs_material.deleteByDatabaseIdVerpackungsMaterialDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.verpackungs_material.restoreByDatabaseIdVerpackungsMaterialDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.verpackungs_material.getAllVerpackungsMaterialGet({ include_deleted }),
        getHistory: (data: VerpackungsMaterial) => props.services.verpackungs_material.getHistoryVerpackungsMaterialDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.verpackungs_material.downloadExcelVerpackungsMaterialExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Verpackungsmaterial}
    />
  );
}
