import {
  Button,
  Dialog,
  H2,
  H5,
} from '@blueprintjs/core';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  formatDateTimeString,
} from '../../../infrastructure';

import { ProduktionsplanDatenLadenResult } from 'wacoplast_wws__api';

export function ProduktionsplanAnzeigen(props: PropsWithServices<CustomFormProps<ProduktionsplanDatenLadenResult>> & ModalProps<void>): JSX.Element {
  const finish = async (): Promise<void> => {
    await props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog
      isOpen
      onClose={() => props.onModalFinished()}
      title='Produktionsplan drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Der Produktionsplan kann nun gedruckt werden.</H2>
        {props.userTask.startedAt && <H5>Generiert am: {formatDateTimeString(props.userTask.startedAt)}</H5>}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px' }}>
          {
            props.tokenPayload.tables.map((table, index) =>
              (
                <Button key={index} intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/produktionsplan/${table.blasmaschine_nummer}/${props.userTask.correlationId}`, '_blank')}>BLM {table.blasmaschine_nummer} ansehen</Button>
              ))
          }
        </div>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={props.onModalFinished}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
