import { Button, Dialog, H2 } from '@blueprintjs/core';
import { VerpackungsVorschrift } from 'wacoplast_wws__api';
import {
  DefaultDropDownPicker,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  useFetchLikeServiceFunction,
} from '../../../infrastructure';
import { getVerpackungsVorschriftNummer } from '../../../stammdaten';
import { VerpackungsVorschriftTable } from './VerpackungsVorschriftModal';
import { useCallback, useState } from 'react';

export type VerpackungsVorschriftAenderModalProps = PropsWithServices<ModalProps<VerpackungsVorschrift | null> & {
  verpackungsVorschrift: VerpackungsVorschrift;
  kundenauftragDatabaseId: number;
}>;

export function VerpackungsVorschriftAenderModal(props: VerpackungsVorschriftAenderModalProps): JSX.Element {
  const serviceFunction = useCallback(
    (initOverrides?: RequestInit) =>
      props.services.verpackungs_vorschrift.getAllForKundenauftragVerpackungsVorschriftFindKundenauftragDatabaseIdGet({
        kundenauftrag_database_id: props.kundenauftragDatabaseId,
        include_deleted: false,
      }, initOverrides),
    [props.services.verpackungs_vorschrift, props.kundenauftragDatabaseId],
  );
  const verpackungsVorschriften = useFetchLikeServiceFunction(serviceFunction, props.services.verpackungs_vorschrift);
  const [verpackungsVorschrift, setVerpackungsVorschrift] = useState<VerpackungsVorschrift | null>(props.verpackungsVorschrift);
  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        props.onModalFinished(null);
      }}
      title='Verpackungsvorschrift wählen'
    >
      <DialogBody>
        <H2>Welche Verpackungsvorschrift gilt für diese Produktion?</H2>
        <div style={{ display: 'grid', gap: '5px' }}>
          <DefaultDropDownPicker
            dataProvider={verpackungsVorschriften}
            getDisplayData={(data) => {
              return { primaryTitle: getVerpackungsVorschriftNummer(data), secondaryTitle: `Prio: ${data.prioritaet}` };
            }}
            value={verpackungsVorschrift}
            onChange={setVerpackungsVorschrift}
          />
          <VerpackungsVorschriftTable
            verpackungsVorschrift={verpackungsVorschrift}
            services={props.services}
            atlasEngineClient={props.atlasEngineClient}
            config={props.config}
            subscriptionEventService={props.subscriptionEventService}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={() => {
            props.onModalFinished(null);
          }}
        >
          Abbrechen
        </Button>
        <Button
          disabled={verpackungsVorschrift === null}
          intent='primary'
          onClick={() => {
            props.onModalFinished(verpackungsVorschrift);
          }}
        >Fertig
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
