import React from 'react';
import { PropsWithServices, StartDialogProps } from '../../infrastructure';
import { AuftragsabwicklungNavigator } from './AuftragsabwicklungNavigator';
import { StellblattNavigator } from './StellblattNavigator';
import { StammdatenNavigator } from './StammdatenNavigator';

import styles from './Dashboard.module.scss';
import { VerpackungNavigator } from './VerpackungsNavigator';

export type DashboardProps = PropsWithServices<StartDialogProps>;

export class Dashboard extends React.Component<DashboardProps> {
  public render(): JSX.Element {
    return (
      <div className={styles.dashboard_columns}>
        <StammdatenNavigator openStartDialog={this.openStartDialog} />
        <AuftragsabwicklungNavigator openStartDialog={this.openStartDialog} />
        <VerpackungNavigator openStartDialog={this.openStartDialog} />
        <StellblattNavigator {...this.props} openStartDialog={this.openStartDialog} />
      </div>
    );
  }

  private openStartDialog = (startDialogId: string): void => {
    this.props.openStartDialog(startDialogId);
  };
}
