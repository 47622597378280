import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  EtikettKartonDaten,
  EtikettKartonDatenTypEnum,
} from 'wacoplast_wws__api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './EtikettKartonPrint.module.scss';
import { ReactChild } from 'react';

export function EtikettKartonPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return EtikettKarton;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function EtikettKarton(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const { etikett_karton } = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  if (!etikett_karton) return <></>;

  if (etikett_karton.typ === EtikettKartonDatenTypEnum.shb) {
    return (
      <Grid2x8>
        <EtikettKartonSHB etikett_karton={etikett_karton} />
      </Grid2x8>
    );
  }

  return (
    <Grid2x8>
      <EtikettKartonDefault etikett_karton={etikett_karton} />
    </Grid2x8>
  );
}

function EtikettKartonSHB({ etikett_karton }: { etikett_karton: EtikettKartonDaten }): JSX.Element {
  return (
    <HTMLTable className={styles.etikett_karton_table}>
      <thead>
        <tr>
          <td colSpan={2} className={styles.right}>PAU: {etikett_karton.produktionsauftrag_nummer}</td>
        </tr>
        <tr>
          <th colSpan={2} className={styles.shb_header}>{etikett_karton.flaschen_typ_bezeichnung} {etikett_karton.bestellnummer} {etikett_karton.empfaenger_name}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2} className={styles.bolder}>{etikett_karton.volumen} ml / {etikett_karton.artikel_gewicht} g / {etikett_karton.farbe_nummer}_{etikett_karton.farbe_bezeichnung}</td>
        </tr>
        <tr>
          <th>Hals:</th>
          <td className={styles.bold}>{etikett_karton.halsteil_bezeichnung}</td>
        </tr>
        <tr>
          <th>KU-AU-Nr.:</th>
          <td className={styles.bold}>{etikett_karton.empfaenger_auftrags_nummer}</td>
        </tr>
        <tr>
          <th>KU-Art-Nr.:</th>
          <td className={styles.bold}>{etikett_karton.artikel_nummer_kunde}</td>
        </tr>
        <tr>
          <th>Stückzahl:</th>
          <td className={styles.bold}>{etikett_karton.flaschen_pro_vpe}</td>
        </tr>
        <tr>
          <td colSpan={2}>Datum:</td>
        </tr>
        <tr>
          <td colSpan={2}>Verpacker:</td>
        </tr>
      </tbody>
    </HTMLTable>
  );
}

function EtikettKartonDefault({ etikett_karton }: { etikett_karton: EtikettKartonDaten }): JSX.Element {
  return (
    <HTMLTable className={styles.etikett_karton_table}>
      <thead>
        <tr>
          <td colSpan={2} className={styles.right}>PAU: {etikett_karton.produktionsauftrag_nummer}</td>
        </tr>
        <tr>
          <th colSpan={2} className={styles.bolder}>{etikett_karton.flaschen_typ_bezeichnung}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={2} className={styles.bolder}>{etikett_karton.flaschen_typ_nummer}.{etikett_karton.halsteil_nummer} {etikett_karton.mit_blindenzeichen ? 'm.BLZ' : 'o.BLZ'}</td>
        </tr>
        <tr>
          <td colSpan={2} className={styles.bolder}>{etikett_karton.farbe_nummer}_{etikett_karton.farbe_bezeichnung}</td>
        </tr>
        <tr>
          <td colSpan={2} className={styles.bold}>{etikett_karton.artikel_gewicht} g / {etikett_karton.halsteil_bezeichnung}</td>
        </tr>
        <tr>
          <th>Stückzahl:</th>
          <td className={styles.stueckzahl}>{etikett_karton.flaschen_pro_vpe}</td>
        </tr>
        <tr className={styles.last_row}>
          <th>KU-Art.-Nr.:</th>
          <td className={styles.bold}>{etikett_karton.artikel_nummer_kunde}</td>
        </tr>
      </tbody>
    </HTMLTable>
  );
}

function Grid2x8({ children }: { children: ReactChild }): JSX.Element {
  return (
    <div className={styles.grid_2x8}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      {children}
      {children}
      {children}
      {children}
      {children}
      {children}
      {children}
      {children}
    </div>
  );
}
