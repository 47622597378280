"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaschinenlaufplanDatenToJSON = exports.MaschinenlaufplanDatenFromJSONTyped = exports.MaschinenlaufplanDatenFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MaschinenlaufplanDatenFromJSON(json) {
    return MaschinenlaufplanDatenFromJSONTyped(json, false);
}
exports.MaschinenlaufplanDatenFromJSON = MaschinenlaufplanDatenFromJSON;
function MaschinenlaufplanDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'bestelldatum': (new Date(json['bestelldatum'])),
        'artikel_gewicht': json['artikel_gewicht'],
        'gewichtstoleranz': !runtime_1.exists(json, 'gewichtstoleranz') ? undefined : json['gewichtstoleranz'],
        'flaschentyp_bezeichnung': json['flaschentyp_bezeichnung'],
        'flaschentyp_nummer': json['flaschentyp_nummer'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'blindenzeichen_bezeichnung': json['blindenzeichen_bezeichnung'],
        'volumen': json['volumen'],
        'halsteil_nummer': json['halsteil_nummer'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'halsteil_verwendung': !runtime_1.exists(json, 'halsteil_verwendung') ? undefined : json['halsteil_verwendung'],
        'halsteil_bemerkung': !runtime_1.exists(json, 'halsteil_bemerkung') ? undefined : json['halsteil_bemerkung'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'kunde_bestell_nummer': !runtime_1.exists(json, 'kunde_bestell_nummer') ? undefined : json['kunde_bestell_nummer'],
        'lagerplatz_nummer': !runtime_1.exists(json, 'lagerplatz_nummer') ? undefined : json['lagerplatz_nummer'],
        'transport_einheit_traeger_bezeichnung': json['transport_einheit_traeger_bezeichnung'],
        'unterlage_anzahl': !runtime_1.exists(json, 'unterlage_anzahl') ? undefined : json['unterlage_anzahl'],
        'unterlage_bezeichnung': !runtime_1.exists(json, 'unterlage_bezeichnung') ? undefined : json['unterlage_bezeichnung'],
        'karton_sorte': !runtime_1.exists(json, 'karton_sorte') ? undefined : _1.MaterialGruppeFromJSON(json['karton_sorte']),
        'karton_aufdruck_art': !runtime_1.exists(json, 'karton_aufdruck_art') ? undefined : _1.AufdruckArtFromJSON(json['karton_aufdruck_art']),
        'vpe_zustand': _1.VerpackungZustandFromJSON(json['vpe_zustand']),
        'zwischenlage_anzahl': !runtime_1.exists(json, 'zwischenlage_anzahl') ? undefined : json['zwischenlage_anzahl'],
        'zwischenlage_bezeichnung': !runtime_1.exists(json, 'zwischenlage_bezeichnung') ? undefined : json['zwischenlage_bezeichnung'],
        'zwischenlage_nummer': !runtime_1.exists(json, 'zwischenlage_nummer') ? undefined : json['zwischenlage_nummer'],
        'abdeckung_anzahl': !runtime_1.exists(json, 'abdeckung_anzahl') ? undefined : json['abdeckung_anzahl'],
        'abdeckung_bezeichnung': !runtime_1.exists(json, 'abdeckung_bezeichnung') ? undefined : json['abdeckung_bezeichnung'],
        'abdeckung_nummer': !runtime_1.exists(json, 'abdeckung_nummer') ? undefined : json['abdeckung_nummer'],
        'karton_geschlossen': !runtime_1.exists(json, 'karton_geschlossen') ? undefined : json['karton_geschlossen'],
        'mit_etikett': json['mit_etikett'],
        'karton_polybeutel': !runtime_1.exists(json, 'karton_polybeutel') ? undefined : json['karton_polybeutel'],
        'stretchen': json['stretchen'],
        'kantenschutz': json['kantenschutz'],
        'liefertermin': !runtime_1.exists(json, 'liefertermin') ? undefined : (new Date(json['liefertermin'])),
        'menge_produktionsauftrag': json['menge_produktionsauftrag'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'farbe_nummer': json['farbe_nummer'],
        'farbe_batch_nummer': !runtime_1.exists(json, 'farbe_batch_nummer') ? undefined : json['farbe_batch_nummer'],
        'einfaerbungsgrad': !runtime_1.exists(json, 'einfaerbungsgrad') ? undefined : json['einfaerbungsgrad'],
        'werkstoff_bezeichnung': json['werkstoff_bezeichnung'],
        'werkstoff_typ_1_bezeichnung': !runtime_1.exists(json, 'werkstoff_typ_1_bezeichnung') ? undefined : json['werkstoff_typ_1_bezeichnung'],
        'werkstoff_typ_2_bezeichnung': !runtime_1.exists(json, 'werkstoff_typ_2_bezeichnung') ? undefined : json['werkstoff_typ_2_bezeichnung'],
        'flaschen_pro_schicht': json['flaschen_pro_schicht'],
        'verpackungs_einheit_bezeichnung': json['verpackungs_einheit_bezeichnung'],
        'flaschen_pro_vpe': json['flaschen_pro_vpe'],
        'fuellweise_ist_schuetten': json['fuellweise_ist_schuetten'],
        'vpes_pro_stapel_1': json['vpes_pro_stapel_1'],
        'vpes_pro_stapel_2': !runtime_1.exists(json, 'vpes_pro_stapel_2') ? undefined : json['vpes_pro_stapel_2'],
        'anzahl_stapel_1': json['anzahl_stapel_1'],
        'anzahl_stapel_2': !runtime_1.exists(json, 'anzahl_stapel_2') ? undefined : json['anzahl_stapel_2'],
        'menge_halbtrays': json['menge_halbtrays'],
        'menge_ganztrays': json['menge_ganztrays'],
        'kunde_name': json['kunde_name'],
        'kunde_nummer': json['kunde_nummer'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'bemerkung': json['bemerkung'],
        'palettenhoehe_1': json['palettenhoehe_1'],
        'palettenhoehe_2': !runtime_1.exists(json, 'palettenhoehe_2') ? undefined : json['palettenhoehe_2'],
    };
}
exports.MaschinenlaufplanDatenFromJSONTyped = MaschinenlaufplanDatenFromJSONTyped;
function MaschinenlaufplanDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'bestelldatum': (value.bestelldatum.toISOString()),
        'artikel_gewicht': value.artikel_gewicht,
        'gewichtstoleranz': value.gewichtstoleranz,
        'flaschentyp_bezeichnung': value.flaschentyp_bezeichnung,
        'flaschentyp_nummer': value.flaschentyp_nummer,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'blindenzeichen_bezeichnung': value.blindenzeichen_bezeichnung,
        'volumen': value.volumen,
        'halsteil_nummer': value.halsteil_nummer,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'halsteil_verwendung': value.halsteil_verwendung,
        'halsteil_bemerkung': value.halsteil_bemerkung,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'kunde_bestell_nummer': value.kunde_bestell_nummer,
        'lagerplatz_nummer': value.lagerplatz_nummer,
        'transport_einheit_traeger_bezeichnung': value.transport_einheit_traeger_bezeichnung,
        'unterlage_anzahl': value.unterlage_anzahl,
        'unterlage_bezeichnung': value.unterlage_bezeichnung,
        'karton_sorte': _1.MaterialGruppeToJSON(value.karton_sorte),
        'karton_aufdruck_art': _1.AufdruckArtToJSON(value.karton_aufdruck_art),
        'vpe_zustand': _1.VerpackungZustandToJSON(value.vpe_zustand),
        'zwischenlage_anzahl': value.zwischenlage_anzahl,
        'zwischenlage_bezeichnung': value.zwischenlage_bezeichnung,
        'zwischenlage_nummer': value.zwischenlage_nummer,
        'abdeckung_anzahl': value.abdeckung_anzahl,
        'abdeckung_bezeichnung': value.abdeckung_bezeichnung,
        'abdeckung_nummer': value.abdeckung_nummer,
        'karton_geschlossen': value.karton_geschlossen,
        'mit_etikett': value.mit_etikett,
        'karton_polybeutel': value.karton_polybeutel,
        'stretchen': value.stretchen,
        'kantenschutz': value.kantenschutz,
        'liefertermin': value.liefertermin === undefined ? undefined : (value.liefertermin.toISOString()),
        'menge_produktionsauftrag': value.menge_produktionsauftrag,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'farbe_nummer': value.farbe_nummer,
        'farbe_batch_nummer': value.farbe_batch_nummer,
        'einfaerbungsgrad': value.einfaerbungsgrad,
        'werkstoff_bezeichnung': value.werkstoff_bezeichnung,
        'werkstoff_typ_1_bezeichnung': value.werkstoff_typ_1_bezeichnung,
        'werkstoff_typ_2_bezeichnung': value.werkstoff_typ_2_bezeichnung,
        'flaschen_pro_schicht': value.flaschen_pro_schicht,
        'verpackungs_einheit_bezeichnung': value.verpackungs_einheit_bezeichnung,
        'flaschen_pro_vpe': value.flaschen_pro_vpe,
        'fuellweise_ist_schuetten': value.fuellweise_ist_schuetten,
        'vpes_pro_stapel_1': value.vpes_pro_stapel_1,
        'vpes_pro_stapel_2': value.vpes_pro_stapel_2,
        'anzahl_stapel_1': value.anzahl_stapel_1,
        'anzahl_stapel_2': value.anzahl_stapel_2,
        'menge_halbtrays': value.menge_halbtrays,
        'menge_ganztrays': value.menge_ganztrays,
        'kunde_name': value.kunde_name,
        'kunde_nummer': value.kunde_nummer,
        'empfaenger_name': value.empfaenger_name,
        'bemerkung': value.bemerkung,
        'palettenhoehe_1': value.palettenhoehe_1,
        'palettenhoehe_2': value.palettenhoehe_2,
    };
}
exports.MaschinenlaufplanDatenToJSON = MaschinenlaufplanDatenToJSON;
