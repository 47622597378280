import { Dialog, H2 } from '@blueprintjs/core';
import { ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
} from '../../../../infrastructure';

export function RueckmeldungIstTeilrueckmeldungAbfragen(props: CustomFormProps): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Rückmeldung durchführen'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Art der Rückmeldung?</H2>
      </DialogBody>
      <DialogFooter
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON({ teilrueckmeldung: true }))} disabled={props.disabled}>Teilrückmeldung</AsyncButton>
        <AsyncButton onClick={() => props.finishUserTask(ProduktionsauftragBlasenIstTeilrueckmeldungAbfragenResultToJSON({ teilrueckmeldung: false }))} disabled={props.disabled} intent='primary'>Vollständige Rückmeldung</AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}
