import {
  Button,
  H2,
  Intent,
  Switch,
  Tag,
} from '@blueprintjs/core';
import {
  IconDefinition,
  faArrowLeft,
  faDownload,
  faEye,
  faHistory,
  faLock,
  faPencilAlt,
  faPlus,
  faTrash,
  faTrashRestore,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnState,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  MenuItemDef,
  ValueGetterParams,
} from 'ag-grid-community';
import { BaseColDefParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { AgGridReact } from 'ag-grid-react';
import React, { PropsWithChildren } from 'react';
import { DeletionState } from 'wacoplast_wws__api';
import { DeletionStateKeyMapping, ForeignKeyCellRenderer, comparator } from '.';
import { AgGridThemeContext } from '../../App';
import {
  AuthProvider,
  PropsWithTransaction,
  agGridLocaleDE,
  applyColumnStateOnColumnDefs,
  resetColumnState,
  storeColumnStateOnChanges,
} from '../../infrastructure';
import { RequireClaims } from '../RequireClaims';
import styles from './StammdatenAgGridRenderer.module.scss';
import './StammdatenAgGridRenderer.scss';
import { formatEnumValueToDescription } from '../filterFormatters';

export type StammdatenFields<TEntityType> = Extract<keyof TEntityType, string>;

export type StammdatenGetRowIdParams<TEntityType> = Omit<GetRowIdParams, 'data'> & {
  data: TEntityType;
}

export type StammdatenBaseColDefParams<TEntityType> = Omit<BaseColDefParams, 'data'> & {
  data: TEntityType;
}

export type StammdatenCellRendererParams<TEntityType> = Omit<ICellRendererParams, 'data'> & {
  data: TEntityType;
}

export type StammdatenGetRowIdFunc<TEntityType> = {
  (params: StammdatenGetRowIdParams<TEntityType>): string;
}

export type StammdatenValueGetterFunc<TEntityType> = {
  (params: Pick<ValueGetterParams, 'getValue'> & StammdatenBaseColDefParams<TEntityType>): any;
}

export type StammdatenColDef<TEntityType> = Omit<ColDef, 'field' | 'valueGetter' | 'colId'> & {
  field?: StammdatenFields<TEntityType>;
  valueGetter?: StammdatenValueGetterFunc<TEntityType>;
  colId: string;
};

export type StammdatenColGroupDef<TEntityType> = ColGroupDef & {
  children: StammdatenColDef<TEntityType>[];
}

export type StammdatenColDefs<TEntityType> = (StammdatenColGroupDef<TEntityType> | StammdatenColDef<TEntityType>)[];

export type StammdatenCustomPerformableAction<TEntityType> = {
  createElement: (gridRenderer: StammdatenAgGridRendererComponent<TEntityType>) => JSX.Element;
};

export type StammdatenPerformableActions<TEntityType> = Array<
  ({
    type: 'custom';
  } & StammdatenCustomPerformableAction<TEntityType> | {
    type: 'create';
    action: () => void;
  } | {
    type: 'exportExcel';
    action: () => void;
  } |{
    type: 'displayDeleted';
    action: (switchValue: boolean) => void;
  }) & {
    requiredClaims?: string[];
  }
>;

export type StammdatenCustomPerformableRowAction<TEntityType> = {
  label: string;
  icon?: IconDefinition;
  intent: Intent
  onPerform: (entity: TEntityType) => void;
}

export type StammdatenPerformableRowActions<TEntityType> = Array<
  ({
    type: 'edit' | 'delete' | 'restore' | 'history' | 'lock' | 'unlock' | 'inspect';
    onPerform: (entity: TEntityType) => void;
  } | {
    type: 'custom';
  } & StammdatenCustomPerformableRowAction<TEntityType>) & {
    requiredClaims?: string[];
  }
>;

export type StammdatenAgGridRendererProps<TEntityType> = PropsWithChildren<PropsWithTransaction<{
  title: string;
  stammdaten: Array<TEntityType> | null;
  columnDefs: StammdatenColDefs<TEntityType>;
  onCloseStartDialogClicked: () => void;
  performableActions: StammdatenPerformableActions<TEntityType>;
  getPerformableRowActions?: (entity: TEntityType & { deletion_state: DeletionState }) => StammdatenPerformableRowActions<TEntityType>;
  isVersioned: boolean;
  isLockable?: boolean;
  getRowId?: StammdatenGetRowIdFunc<TEntityType>;
  defaultColumnState?: Array<ColumnState>;
  agGridTheme: string;
  authProvider: AuthProvider;
}>>;

type StammdatenAgGridState = {
  displayDeleted: boolean;
  gridApi: GridApi | null;
  columnApi: ColumnApi | null;
  claimsInToken: string[];
  displayedDataIsFiltered: boolean;
}

export function StammdatenAgGridRenderer<TEntityType>(props: Omit<StammdatenAgGridRendererProps<TEntityType>, 'agGridTheme'>): JSX.Element {
  return (
    <AgGridThemeContext.Consumer>
      {({ agGridTheme }) => (
        <StammdatenAgGridRendererComponent {...props} agGridTheme={agGridTheme} />
      )}
    </AgGridThemeContext.Consumer>
  );
}

class StammdatenAgGridRendererComponent<TEntityType> extends React.PureComponent<StammdatenAgGridRendererProps<TEntityType>, StammdatenAgGridState> {

  constructor(props: StammdatenAgGridRendererProps<TEntityType>) {
    super(props);
    this.state = {
      displayDeleted: false,
      gridApi: null,
      columnApi: null,
      claimsInToken: [],
      displayedDataIsFiltered: false,
    };
  }

  public componentDidMount(): void {
    this.props.authProvider.getAccessToken().then((token) => this.setState({ claimsInToken: Object.keys(token) }));
  }

  private onGridReady = (params: GridReadyEvent): void => {
    this.setState({
      gridApi: params.api,
      columnApi: params.columnApi,
    });
    storeColumnStateOnChanges(this.props.title, params.api, params.columnApi);
  };

  private getContextMenuItems = (params: GetContextMenuItemsParams): Array<MenuItemDef> => {
    if (params.node === null) {
      return [];
    }

    const stammdatenEntity = params.node?.data as TEntityType;

    const performableRowActions = this.props.getPerformableRowActions?.(stammdatenEntity as any) ?? [];

    const menuNames = {
      edit: 'Editieren',
      delete: 'Löschen',
      restore: 'Wiederherstellen',
      history: 'Historie ansehen',
      lock: 'Sperren',
      unlock: 'Entsperren',
      inspect: 'Ansehen',
    };

    return performableRowActions.map((rowAction): any => {
      const disabled = rowAction.requiredClaims?.some(claim => !this.state.claimsInToken.includes(claim));
      if (disabled) {
        return undefined;
      }
      if (rowAction.type !== 'custom') {
        return {
          name: menuNames[rowAction.type],
          action: () => rowAction.onPerform(stammdatenEntity),
        };
      }
      return {
        name: rowAction.label,
        action: () => rowAction.onPerform(stammdatenEntity),
      };
    }).filter(item => item !== undefined);
  };

  public render(): JSX.Element {
    const addEntryIcon = <FontAwesomeIcon icon={faPlus} />;
    const closeStartDialogIcon = <FontAwesomeIcon icon={faArrowLeft} />;

    const columnDefs: StammdatenColDefs<TEntityType> = [...this.props.columnDefs];

    if (this.props.isVersioned) {
      columnDefs.push({
        headerName: 'Version',
        field: 'version_id' as any,
        filter: false,
        width: 30,
        colId: 'version_id',
      });
    }

    if (this.state.displayDeleted || this.props.isLockable) {
      columnDefs.push({
        headerName: 'Status',
        field: 'deletion_state' as any,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: ({ value }: any) => formatEnumValueToDescription(value, DeletionStateKeyMapping),
        },
        valueFormatter: ({ value }: any) => formatEnumValueToDescription(value, DeletionStateKeyMapping),
        cellClassRules: {
          deleted_entry: (param) => param.value === DeletionState.NUMBER_2,
        },
        width: 80,
        colId: 'deletion_state',
      });
    }

    if (this.props.isLockable) {
      columnDefs.push({
        headerName: 'Änderungsgrund',
        colId: 'aenderungsgrund',
        field: 'aenderungsgrund' as any,
        filter: 'agTextColumnFilter',
      });
    }

    if (this.props.getPerformableRowActions) {
      columnDefs.push({
        headerName: '',
        cellRenderer: this.actionButtonsCellRenderer,
        filter: false,
        cellClass: styles.action_button_cell,
        width: 100,
        colId: 'stammdaten_action_buttons',
      });
    }

    return (
      <div className={styles.grid_container}>
        <div className={styles.grid_container__header}>
          <H2>
            {this.props.title} {' '}
            <Button icon={closeStartDialogIcon} small onClick={() => this.props.onCloseStartDialogClicked()}>Zurück zur Startseite</Button> {' '}
            {this.state.displayedDataIsFiltered &&
              <Tag intent='warning' minimal>Gefilterte Ansicht</Tag>
            }
          </H2>
          <div className={styles.grid_container__header_actions}>
            {
              this.props.performableActions.map((action, index): JSX.Element => {
                const getAction = (): JSX.Element => {
                  if (action.type === 'create') {
                    return (
                      <Button key={index} icon={addEntryIcon} className={styles.header__create_button} intent='primary' onClick={action.action}>
                        Erstellen
                      </Button>
                    );
                  } else if (action.type === 'displayDeleted') {
                    return (
                      <Switch
                        key={index}
                        onChange={(event) => {
                          const checked = (event.target as any).checked;
                          this.setState({ displayDeleted: checked });
                          action.action(checked);
                        }}
                        checked={this.state.displayDeleted}
                        label='Gelöschte Einträge anzeigen'
                      />
                    );
                  } else if (action.type === 'exportExcel') {
                    return (
                      <Button
                        key={index}
                        icon={<FontAwesomeIcon icon={faDownload} />}
                        className={styles.header__create_button}
                        intent='none'
                        onClick={action.action}
                      >
                        Exportieren
                      </Button>
                    );
                  }
                  return action.createElement(this);
                };

                if (action.requiredClaims !== undefined) {
                  return (
                    <RequireClaims key={index} claims={action.requiredClaims} hideOnError>
                      {getAction()}
                    </RequireClaims>
                  );
                }
                return getAction();
              })
            }
          </div>
        </div>
        <div>
          {this.props.children}
        </div>
        <div className={this.props.agGridTheme}>
          <AgGridReact
            suppressCellFocus
            suppressMultiSort
            suppressFieldDotNotation
            onRowDoubleClicked={(event) => {
              const editAction = this.props.getPerformableRowActions?.(event.data).find((rowAction) => rowAction.type === 'edit');
              if (editAction?.requiredClaims === undefined || editAction.requiredClaims.every(claim => this.state.claimsInToken.includes(claim))) {
                editAction?.onPerform(event.data);
                return;
              }
              const viewAction = this.props.getPerformableRowActions?.(event.data).find((rowAction) => rowAction.type === 'inspect');
              viewAction?.onPerform(event.data);
            }}
            rowData={this.props.stammdaten}
            reactUi={false}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true,
              suppressMovable: true,
              comparator: comparator,
              floatingFilter: true,
              filterParams: { applyMiniFilterWhileTyping: true, buttons: ['reset'] },
              menuTabs: ['generalMenuTab'],
            }}
            onGridReady={this.onGridReady}
            getRowId={this.props.getRowId ?? (({ data }) => data.database_id)}
            localeText={agGridLocaleDE}
            columnDefs={this.assignColumnApiToComparatorFunction(applyColumnStateOnColumnDefs(this.props.title, columnDefs, this.props.defaultColumnState ?? []) as any)}
            onFilterChanged={() => {
              this.setState({
                displayedDataIsFiltered: this.state.gridApi?.isAnyFilterPresent() ?? false,
              });
            }}
            getContextMenuItems={this.getContextMenuItems}
            suppressColumnVirtualisation={true}
            getMainMenuItems={(params: GetMainMenuItemsParams): Array<string | MenuItemDef> => {
              const isForeignKeyCell = params.column.getColDef().cellRenderer === ForeignKeyCellRenderer;
              return [
                'pinSubMenu',
                'separator',
                {
                  name: 'Spalten zurücksetzen',
                  action: () => {
                    resetColumnState(this.props.title, this.state.columnApi);
                    this.state.gridApi?.setColumnDefs(applyColumnStateOnColumnDefs(this.props.title, columnDefs, this.props.defaultColumnState ?? []));
                    this.state.gridApi?.resetQuickFilter();
                  },
                },
                {
                  name: 'Spalten ausbreiten',
                  action: () => {
                    this.state.columnApi?.autoSizeAllColumns(false);
                  },
                },
                {
                  name: 'Spalten an Sicht anpassen',
                  action: () => this.state.gridApi?.sizeColumnsToFit(),
                },
                ...isForeignKeyCell
                  ? [
                    'separator',
                    {
                      name: 'Sortieren nach',
                      subMenu: [
                        {
                          name: 'Nummer',
                          checked: params.column.getColDef().headerComponentParams?.sortBy === 'number',
                          disabled: params.column.getColDef().headerComponentParams?.sortBy === 'number',
                          action: () => {
                            params.column.setColDef({ ...params.column.getColDef(), headerComponentParams: { sortBy: 'number' } }, {});
                            params.columnApi.applyColumnState({ state: [{ colId: params.column.getColId(), sort: params.column.isSortAscending() ? 'desc' : 'asc' }] });
                          },
                        },
                        {
                          name: 'Bezeichnung',
                          checked: params.column.getColDef().headerComponentParams?.sortBy === 'description',
                          disabled: params.column.getColDef().headerComponentParams?.sortBy === 'description',
                          action: () => {
                            params.column.setColDef({ ...params.column.getColDef(), headerComponentParams: { sortBy: 'description' } }, {});
                            params.columnApi.applyColumnState({ state: [{ colId: params.column.getColId(), sort: params.column.isSortAscending() ? 'desc' : 'asc' }] });
                          },
                        },
                      ],
                    },
                  ]
                  : [],
                'separator',
                {
                  name: 'Alle Filter zurücksetzen',
                  action: () => this.state.gridApi?.setFilterModel([]),
                },
              ];
            }}
          />
        </div>
      </div>
    );
  }

  private actionButtonsCellRenderer = (props: ICellRendererParams): JSX.Element => {
    const stammdatenEntity = props.data as TEntityType & { deletion_state: DeletionState };

    const performableActions = this.props.getPerformableRowActions?.(stammdatenEntity);

    if (performableActions === undefined) {
      return <></>;
    }

    const rowActionDefintions = {
      edit: {
        name: 'Bearbeiten',
        icon: faPencilAlt,
        intent: 'primary',
      },
      delete: {
        name: 'Löschen',
        icon: faTrash,
        intent: 'danger',
      },
      restore: {
        name: 'Wiederherstellen',
        icon: faTrashRestore,
        intent: 'warning',
      },
      history: {
        name: 'Historie anzeigen',
        icon: faHistory,
        intent: 'none',
      },
      lock: {
        name: 'Sperren',
        icon: faLock,
        intent: 'warning',
      },
      unlock: {
        name: 'Entsperren',
        icon: faUnlock,
        intent: 'warning',
      },
      inspect: {
        name: 'Ansehen',
        icon: faEye,
        intent: 'none',
      },
    };

    return (
      <>
        {
          performableActions.map((rowAction, index): JSX.Element => {
            let ActionElement: JSX.Element = <></>;
            if (rowAction.type !== 'custom') {
              const actionDefinition = rowActionDefintions[rowAction.type];
              ActionElement = (
                <Tag
                  title={actionDefinition.name}
                  interactive
                  minimal
                  icon={<FontAwesomeIcon size='1x' icon={actionDefinition.icon} />}
                  intent={actionDefinition.intent as Intent}
                  onClick={() => rowAction.onPerform(stammdatenEntity)}
                  key={index}
                />
              );
            } else {
              const icon = rowAction.icon ? <FontAwesomeIcon size='1x' icon={rowAction.icon} /> : <>{rowAction.label}</>;
              ActionElement = (
                <Tag
                  title={rowAction.label}
                  interactive
                  minimal
                  intent={rowAction.intent}
                  icon={icon}
                  onClick={() => rowAction.onPerform(stammdatenEntity)}
                  key={index}
                />
              );
            }
            if (rowAction.requiredClaims !== undefined) {
              return (
                <RequireClaims key={index} claims={rowAction.requiredClaims} hideOnError>
                  {ActionElement}
                </RequireClaims>
              );
            }
            return ActionElement;
          })
        }
      </>
    );
  };

  //TODO this function is requirede to wrap invalid comparators with a valid one and pass the gridApi to the comparator.
  //invalid comparators are produced by the makeForeignKeyColumn function
  private assignColumnApiToComparatorFunction = (colDefs: Array<StammdatenColDef<TEntityType>>): StammdatenColDefs<TEntityType> => {
    return colDefs.map(colDef => {
      if (colDef.cellRenderer === ForeignKeyCellRenderer && colDef.comparator) {
        const oldComparator = colDef.comparator as any;
        colDef.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
          return oldComparator(valueA, valueB, nodeA, nodeB, isInverted, this.state.gridApi, colDef.colId);
        };
      }
      return colDef;
    });
  }
}
