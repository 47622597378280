import { ArtikelGruppen, CreateLieferant } from 'wacoplast_wws__api';
import {
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeEnumDropDownPickerRenderer,
  makeNumberEditorRenderer,
  makeTextEditorRenderer,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { formatEmptyStringToLeer } from '../../infrastructure/filterFormatters';


export type LieferantVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function LieferantVerwalten(props: LieferantVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateLieferant> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeNumberEditorRenderer({ max: 79999 }),
      label: 'Nummer',
    },
    {
      type: 'simple',
      field: 'name_kurz',
      renderer: makeTextEditorRenderer(),
      label: 'Name (kurz)',
    },
    {
      type: 'simple',
      field: 'name_lang',
      renderer: makeTextEditorRenderer(),
      label: 'Name (lang)',
    },
    {
      type: 'simple',
      field: 'artikel_gruppe',
      renderer: makeEnumDropDownPickerRenderer(Object.values(ArtikelGruppen), (data) => data, true),
      label: 'Artikelgruppe',
    },
    {
      type: 'simple',
      field: 'postleitzahl',
      renderer: makeTextEditorRenderer(),
      label: 'Postleitzahl',
    },
    {
      type: 'simple',
      field: 'stadt',
      renderer: makeTextEditorRenderer(),
      label: 'Stadt',
    },
    {
      type: 'simple',
      field: 'strasse_hausnummer',
      renderer: makeTextEditorRenderer(),
      label: 'Straße und Hausnummer',
    },
    {
      type: 'simple',
      field: 'telefon_1',
      renderer: makeTextEditorRenderer(),
      label: 'Telefon 1',
    },
    {
      type: 'simple',
      field: 'telefon_2',
      renderer: makeTextEditorRenderer(),
      label: 'Telefon 2',
    },
    {
      type: 'simple',
      field: 'fax',
      renderer: makeTextEditorRenderer(),
      label: 'Fax',
    },
    {
      type: 'simple',
      field: 'email',
      renderer: makeTextEditorRenderer(),
      label: 'E-Mail',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Name (kurz)',
          field: 'name_kurz',
          colId: 'name_kurz',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Name (lang)',
          field: 'name_lang',
          colId: 'name_lang',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Artikel-Gruppe',
          field: 'artikel_gruppe',
          colId: 'artikel_gruppe',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }: any) => formatEmptyStringToLeer(value),
          },
        },
        {
          headerName: 'Postleitzahl',
          field: 'postleitzahl',
          colId: 'postleitzahl',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Stadt',
          field: 'stadt',
          colId: 'stadt',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Strasse & Hausnummer',
          field: 'strasse_hausnummer',
          colId: 'strasse_hausnummer',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Telefonnummer 1',
          field: 'telefon_1',
          colId: 'telefon_1',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Telefonnummer 2',
          field: 'telefon_2',
          colId: 'telefon_2',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Faxnummer',
          field: 'fax',
          colId: 'fax',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'E-Mail Adresse',
          field: 'email',
          colId: 'email',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateLieferant) => props.services.lieferant.postLieferantPost({ CreateLieferant: data }),
        update: (data) => props.services.lieferant.putByDatabaseIdLieferantDatabaseIdPut({ database_id: data.database_id, UpdateLieferant: data }),
        delete: (data, ignoreIntegrity) => props.services.lieferant.deleteByDatabaseIdLieferantDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.lieferant.restoreByDatabaseIdLieferantDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.lieferant.getAllLieferantGet({ include_deleted }),
        getHistory: (data) => props.services.lieferant.getHistoryLieferantDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.lieferant.downloadExcelLieferantExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Lieferant}
    />
  );
}
