"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReducedBlindenzeichenToJSON = exports.ReducedBlindenzeichenFromJSONTyped = exports.ReducedBlindenzeichenFromJSON = void 0;
const _1 = require("./");
function ReducedBlindenzeichenFromJSON(json) {
    return ReducedBlindenzeichenFromJSONTyped(json, false);
}
exports.ReducedBlindenzeichenFromJSON = ReducedBlindenzeichenFromJSON;
function ReducedBlindenzeichenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'database_id': json['database_id'],
        'version_id': json['version_id'],
        'bezeichnung': json['bezeichnung'],
        'nummer': json['nummer'],
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
    };
}
exports.ReducedBlindenzeichenFromJSONTyped = ReducedBlindenzeichenFromJSONTyped;
function ReducedBlindenzeichenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'database_id': value.database_id,
        'version_id': value.version_id,
        'bezeichnung': value.bezeichnung,
        'nummer': value.nummer,
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
    };
}
exports.ReducedBlindenzeichenToJSON = ReducedBlindenzeichenToJSON;
