import { Dialog, H2 } from '@blueprintjs/core';
import React from 'react';
import { RueckmeldungAnzahlNesterAbfragenResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  NumberEditor,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function RueckmeldungAnzahlNesterErfragen(
  props: CustomFormProps<{
    max_anzahl_nester: number;
    anzahl_teilrueckmeldungen?: number;
  }>,
): JSX.Element {
  const [anzahlNester, setAnzahlNester] = React.useState<number | null>(props.tokenPayload.max_anzahl_nester ?? null);

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(props.tokenPayload.anzahl_teilrueckmeldungen)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Anzahl Nester?</H2>
        <NumberEditor onChange={(value) => setAnzahlNester(value)} value={anzahlNester} min={1} max={props.tokenPayload.max_anzahl_nester} />
      </DialogBody>
      <DialogFooter
        onFinish={() => {
          if (anzahlNester !== null) {
            return props.finishUserTask(RueckmeldungAnzahlNesterAbfragenResultToJSON({ anzahl_nester: anzahlNester ?? 1 }));
          }
        }}
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
