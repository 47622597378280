import { CreateLagerposten } from 'wacoplast_wws__api';

import {
  DialogStrings,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  dateTimeValueFormatter,
  makeNumberEditorRenderer,
  makeTextAreaEditorRenderer,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';

export type LagerVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function LagerpostenVerwalten(props: LagerVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateLagerposten> = [
    {
      type: 'simple',
      field: 'anzahl_vpe_je_stapel',
      label: 'Anz. VPE je Stapel',
      renderer: makeNumberEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'anzahl_stapel',
      label: 'Anzahl Stapel',
      renderer: makeNumberEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'lagerort',
      label: 'Lagerort',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'lagerplatz',
      label: 'Lagerplatz',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'auslieferung',
      label: 'Auslieferung',
      renderer: makeTextAreaEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'buchung_von',
      label: 'Buchung von',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'buchung_am',
      label: 'Buchung am',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'letzter_vorgang',
      label: 'Letzter Vorgang',
      renderer: makeTextAreaEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'reserviert_fuer',
      label: 'Reserviert für',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'reserviert_am',
      label: 'Reserviert am',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'reserviert_durch',
      label: 'Reserviert durch',
      renderer: makeTextEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'gesperrt',
      label: 'Gesperrt',
      renderer: makeTextAreaEditorRenderer(),
    },
    {
      type: 'simple',
      field: 'hinweis',
      label: 'Hinweis',
      renderer: makeTextAreaEditorRenderer(),
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      columnDefs={[
        {
          headerName: 'PAU',
          colId: 'pau_nummer',
          valueGetter: (value) => value.data.pau_nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'KUAU-Nr.',
          colId: 'kuau_nummer',
          valueGetter: (value) => value.data.kuau_nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Kunde-Nr.',
          colId: 'kunde_nummer',
          valueGetter: (value) => value.data.kunde_nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Kunde',
          colId: 'kunde_name_kurz',
          valueGetter: (value) => value.data.kunde_name_kurz,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reserviert für Kunde',
          colId: 'reserviert_fuer_kunde',
          valueGetter: (value) => value.data.reserviert_fuer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reserv. durch',
          colId: 'reserviert_durch',
          valueGetter: (value) => value.data.reserviert_durch,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Reserv. am',
          colId: 'reserviert_am',
          valueGetter: (value) => value.data.reserviert_am,
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'gesperrt von am Grund',
          colId: 'gesperrt_von_am_grund',
          valueGetter: (value) => value.data.gesperrt,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Hinweis',
          colId: 'hinweis',
          valueGetter: (value) => value.data.hinweis,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Empf-Nr.',
          colId: 'empfaenger_nummer',
          valueGetter: (value) => value.data.empfaenger_nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Empf.',
          colId: 'empfaenger_name_kurz',
          valueGetter: (value) => value.data.empfaenger_name_kurz,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Bestell-Nr.',
          colId: 'bestell_nummer',
          valueGetter: (value) => value.data.bestell_nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Kunden-Artikel-Nr.',
          colId: 'kunden_artikel_nummer',
          valueGetter: (value) => value.data.kunden_artikelNummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'FL-Form-Nr.',
          colId: 'flaschen_form_nummer',
          valueGetter: (value) => value.data.flaschen_form_nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'FL-Typ-Bez.',
          colId: 'flaschen_typ_bezeichnung',
          valueGetter: (value) => value.data.flaschen_typ_bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'HT-Bez.',
          colId: 'halsteil_bezeichnung',
          valueGetter: (value) => value.data.halsteil_bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'BLZ',
          colId: 'blindenzeichen_bezeichnung',
          valueGetter: (value) => value.data.blindenzeichen_bezeichnung,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Gewicht [g]',
          colId: 'gewicht',
          valueGetter: (value) => value.data.gewicht,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Farb-Nr.',
          colId: 'granulat_farbe_nummer',
          valueGetter: (value) => value.data.farbe_nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Farbe',
          colId: 'granulat_farbe_bezeichnung',
          valueGetter: (value) => value.data.farbe_bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Farb-%',
          colId: 'einfaerbungsgrad',
          valueGetter: (value) => value.data.einfaerbungsgrad,
          valueFormatter: numberValueFormatterGenAGGrid(1),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Werkstoff',
          colId: 'werkstoff_typ_bezeichnung',
          valueGetter: (value) => value.data.werkstoff_typ_bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'VPE-Nr.',
          colId: 'verpackungs_einheit_nummer',
          valueGetter: (value) => value.data.verpackungs_einheit_nummer,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'VPE',
          colId: 'verpackungs_einheit_bezeichnung',
          valueGetter: (value) => value.data.verpackungs_einheit_bezeichnung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Stück je VPE',
          colId: 'stueck_je_vpe',
          valueGetter: (value) => value.data.stueck_je_vpe,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Anz. VPE je Stapel',
          colId: 'anzahl_vpe_je_stapel',
          valueGetter: (value) => value.data.anzahl_vpe_je_stapel,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Stück je Stapel',
          colId: 'stueck_je_stapel',
          valueGetter: (value) => Number(value.data.stueck_je_vpe) * Number(value.data.anzahl_vpe_je_stapel),
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'anNumberColumnFilter',
        },
        {
          headerName: 'Höhe Stapel',
          colId: 'hoehe_stapel',
          valueGetter: (value) => value.data.hoehe_stapel,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Anzahl Kartons',
          colId: 'anzahl_kartons',
          valueGetter: (value) => value.data.verpackungs_einheit_ist_karton ? Number(value.data.anzahl_stapel) * Number(value.data.anzahl_vpe_je_stapel) : undefined,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Anzahl Stapel',
          colId: 'anzahl_stapel',
          valueGetter: (value) => value.data.anzahl_stapel,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Bestand [Stück]',
          colId: 'bestand_stueck',
          valueGetter: (value) => Number(value.data.anzahl_stapel) * Number(value.data.stueck_je_vpe) * Number(value.data.anzahl_vpe_je_stapel),
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Lager-Ort',
          colId: 'lager_ort',
          valueGetter: (value) => value.data.lagerort,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Lager-Platz',
          colId: 'lager_platz',
          valueGetter: (value) => value.data.lagerplatz,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Liefertermin Soll',
          colId: 'liefertermin_soll',
          valueGetter: (value) => value.data.liefertermin_soll,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Datum Lager-Zugang PR',
          colId: 'datum_lagerzugang_pr',
          valueGetter: (value) => value.data.created_at,
          valueFormatter: dateTimeValueFormatter,
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Auslieferung Datum / Menge #',
          colId: 'auslieferung_datum_menge',
          valueGetter: (value) => value.data.auslieferung,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Bestellmenge',
          colId: 'bestellmenge',
          valueGetter: (value) => value.data.bestellmenge,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Menge Zugang PR [Stück]',
          colId: 'menge_zugang_pr_stueck',
          valueGetter: (value) => value.data.menge_zugang_pr_stueck,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Buchung von',
          colId: 'buchung von',
          valueGetter: (value) => value.data.buchung_von,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Buchung am',
          colId: 'buchung_am',
          valueGetter: (value) => value.data.buchung_am,
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'letzter Vorgang',
          colId: 'letzter_vorgang',
          valueGetter: (value) => value.data.letzter_vorgang,
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateLagerposten) => props.services.lagerposten.postLagerpostenPost({ CreateLagerposten: data }),
        update: (data) => props.services.lagerposten.putByDatabaseIdLagerpostenDatabaseIdPut({ database_id: data.database_id, UpdateLagerposten: data }),
        delete: (data, ignoreIntegrity) => props.services.lagerposten.deleteByDatabaseIdLagerpostenDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.lagerposten.restoreByDatabaseIdLagerpostenDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.lagerposten.getAllLagerpostenGet({ include_deleted }),
        getHistory: (data) => props.services.lagerposten.getHistoryLagerpostenDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.lagerposten.downloadExcelLagerpostenExcelPost(),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      defaultColumnState={defaultColumnState}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Lagerposten}
      suppressCreation
    />
  );
}
