"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EtikettKartonDatenToJSON = exports.EtikettKartonDatenFromJSONTyped = exports.EtikettKartonDatenFromJSON = exports.EtikettKartonDatenTypEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var EtikettKartonDatenTypEnum;
(function (EtikettKartonDatenTypEnum) {
    EtikettKartonDatenTypEnum["normal"] = "normal";
    EtikettKartonDatenTypEnum["shb"] = "shb";
})(EtikettKartonDatenTypEnum = exports.EtikettKartonDatenTypEnum || (exports.EtikettKartonDatenTypEnum = {}));
function EtikettKartonDatenFromJSON(json) {
    return EtikettKartonDatenFromJSONTyped(json, false);
}
exports.EtikettKartonDatenFromJSON = EtikettKartonDatenFromJSON;
function EtikettKartonDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'typ': json['typ'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'halsteil_nummer': json['halsteil_nummer'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'farbe_nummer': json['farbe_nummer'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'artikel_gewicht': json['artikel_gewicht'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'flaschen_pro_vpe': json['flaschen_pro_vpe'],
        'artikel_nummer_kunde': !runtime_1.exists(json, 'artikel_nummer_kunde') ? undefined : json['artikel_nummer_kunde'],
        'bestellnummer': !runtime_1.exists(json, 'bestellnummer') ? undefined : json['bestellnummer'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'volumen': json['volumen'],
        'empfaenger_auftrags_nummer': !runtime_1.exists(json, 'empfaenger_auftrags_nummer') ? undefined : json['empfaenger_auftrags_nummer'],
    };
}
exports.EtikettKartonDatenFromJSONTyped = EtikettKartonDatenFromJSONTyped;
function EtikettKartonDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'typ': value.typ,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'halsteil_nummer': value.halsteil_nummer,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'farbe_nummer': value.farbe_nummer,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'artikel_gewicht': value.artikel_gewicht,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'flaschen_pro_vpe': value.flaschen_pro_vpe,
        'artikel_nummer_kunde': value.artikel_nummer_kunde,
        'bestellnummer': value.bestellnummer,
        'empfaenger_name': value.empfaenger_name,
        'volumen': value.volumen,
        'empfaenger_auftrags_nummer': value.empfaenger_auftrags_nummer,
    };
}
exports.EtikettKartonDatenToJSON = EtikettKartonDatenToJSON;
