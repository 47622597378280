import { InputGroup, Tag } from '@blueprintjs/core';
import { DateInput, DateInputProps } from '@blueprintjs/datetime';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addYears,
  format,
  lastDayOfYear,
  parse,
} from 'date-fns';
import { de } from 'date-fns/locale';
import React, { useState } from 'react';
import {
  formatDateString,
  formatDateTimeString,
  parseDateString,
  parseDateTimeString,
} from '..';

import styles from './inputs.module.scss';

export const LOCALE_DE = 'de-DE';

export type CommonEditorProps<TValueType> = {
  placeholder?: string;
  onChange: (data: TValueType) => void;
  value: TValueType;
  disabled?: boolean;
};

export type TextViewerProps = {
  value: string | null,
  placeholder?: string
};

export type TextEditorProps = CommonEditorProps<string | null>;

export type NumberEditorProps = CommonEditorProps<number | null> & {
  min?: number,
  max?: number,
  showButtons?: boolean,
};

export type DateEditorProps = CommonEditorProps<Date | null> & { minDate?: Date, maxDate?: Date };

export function TextViewer(props: TextViewerProps): JSX.Element {
  return (
    <InputGroup
      style={{ backgroundColor: '#e4e4e4' }}
      tabIndex={-1}
      readOnly
      value={props.value ?? undefined}
      placeholder={props.placeholder}
      type='text'
      fill={true}
    />
  );
}

export function TextEditor(props: TextEditorProps): JSX.Element {
  return (
    <InputGroup
      disabled={props.disabled}
      defaultValue={props.value ?? undefined}
      fill={true}
      placeholder={props.placeholder}
      type='text'
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}

export const decimalPointFormatter = (value: string, minimumFractionDigits: number): string => {

  if (value === '') {
    return '';
  }

  const intValue: number | undefined = parseInt(value.replaceAll('.', ''));

  if (intValue === undefined) {
    return '';
  }
  const format = new Intl.NumberFormat(LOCALE_DE, {
    minimumFractionDigits: minimumFractionDigits,
  });

  return format.format(intValue);
};

// eslint-disable-next-line react/display-name
export const DateTimeEditor = React.forwardRef((props: DateEditorProps, ref?: React.ForwardedRef<DateInput>): JSX.Element => {

  const [value, setValue] = useState<Date | null>(props.value ?? null);

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <DateInput
      {...commonDateInputProps}
      defaultValue={value ?? undefined}
      disabled={props.disabled}
      formatDate={date => formatDateTimeString(date)}
      maxDate={props.maxDate}
      minDate={props.minDate}
      onChange={setValue}
      parseDate={(str) => parseDateTimeString(str)}
      placeholder={props.placeholder}
      popoverProps={{ onClose: () => props.onChange(value) }}
      ref={ref}
      timePickerProps={{ showArrowButtons: true, useAmPm: false }}
      timePrecision='minute'
    />
  );
});

// eslint-disable-next-line react/display-name
export const DateEditor = React.forwardRef((props: DateEditorProps, ref?: React.ForwardedRef<DateInput>): JSX.Element => {

  const [value, setValue] = useState<Date | null>(props.value ?? null);

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <DateInput
      {...commonDateInputProps}
      dayPickerProps={{
        onDayClick: props.onChange,
        onTodayButtonClick: props.onChange,
      }}
      defaultValue={value ?? undefined}
      disabled={props.disabled}
      formatDate={date => formatDateString(date)}
      maxDate={props.maxDate ?? commonDateInputProps.maxDate}
      minDate={props.minDate ?? commonDateInputProps.minDate}
      onChange={setValue}
      parseDate={(str) => parseDateString(str)}
      placeholder={props.placeholder}
      popoverProps={{ onClose: () => props.onChange(value) }}
      ref={ref}
    />
  );
});

export const commonDateInputProps: Partial<DateInputProps> = {
  dayPickerProps: { firstDayOfWeek: 1 },
  showActionsBar: true,
  todayButtonText: 'Heute',
  clearButtonText: 'Leeren',
  invalidDateMessage: 'Ungültiges Datum',
  outOfRangeMessage: 'Außerhalb der validen Reichweite',
  minDate: parse('01.01.1900', 'dd.MM.yyyy', new Date(0)),
  maxDate: lastDayOfYear(addYears(new Date(), 10)),
  fill: true,
  rightElement: <Tag minimal={true}><FontAwesomeIcon icon={faCalendar} /></Tag>,
  className: styles.date_input_editor,
  localeUtils: {
    getFirstDayOfWeek: () => 1,
    formatMonthTitle: (month: Date) => format(month, 'LLLL', { locale: de }),
    formatDay: (date: Date) => format(date, 'd', { locale: de }),
    formatWeekdayShort: (index: number) => format(new Date(2021, 0, index + 3), 'EEEEEE', { locale: de }),
    formatWeekdayLong: (index: number) => format(new Date(2021, 0, index + 3), 'EEEE', { locale: de }),
    getMonths: () => ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    formatDate: (date: Date) => format(date, 'dd.MM.yyyy', { locale: de }),
    parseDate: (str: string) => parse(str, 'dd.MM.yyyy', new Date(0)),
  },
};
