"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StellblattDatenToJSON = exports.StellblattDatenFromJSONTyped = exports.StellblattDatenFromJSON = void 0;
const runtime_1 = require("../runtime");
function StellblattDatenFromJSON(json) {
    return StellblattDatenFromJSONTyped(json, false);
}
exports.StellblattDatenFromJSON = StellblattDatenFromJSON;
function StellblattDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
        'halsteil_nummer': json['halsteil_nummer'],
        'lagerplatz_nummer': !runtime_1.exists(json, 'lagerplatz_nummer') ? undefined : json['lagerplatz_nummer'],
        'werkstoff_nummer': json['werkstoff_nummer'],
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'artikel_gewicht': json['artikel_gewicht'],
        'blaszeit_richtwert_gewicht': !runtime_1.exists(json, 'blaszeit_richtwert_gewicht') ? undefined : json['blaszeit_richtwert_gewicht'],
        'datengruppe_3_gewicht': !runtime_1.exists(json, 'datengruppe_3_gewicht') ? undefined : json['datengruppe_3_gewicht'],
        'blasform_ep_gewicht': !runtime_1.exists(json, 'blasform_ep_gewicht') ? undefined : json['blasform_ep_gewicht'],
        'blasform_nummer_intern': !runtime_1.exists(json, 'blasform_nummer_intern') ? undefined : json['blasform_nummer_intern'],
        'blindenzeichen_nummer_1': !runtime_1.exists(json, 'blindenzeichen_nummer_1') ? undefined : json['blindenzeichen_nummer_1'],
        'blindenzeichen_nummer_2': !runtime_1.exists(json, 'blindenzeichen_nummer_2') ? undefined : json['blindenzeichen_nummer_2'],
        'blindenzeichen_nummer_3': !runtime_1.exists(json, 'blindenzeichen_nummer_3') ? undefined : json['blindenzeichen_nummer_3'],
        'blindenzeichen_nummer_4': !runtime_1.exists(json, 'blindenzeichen_nummer_4') ? undefined : json['blindenzeichen_nummer_4'],
        'blindenzeichen_nummer_5': !runtime_1.exists(json, 'blindenzeichen_nummer_5') ? undefined : json['blindenzeichen_nummer_5'],
        'blasform_nummer': !runtime_1.exists(json, 'blasform_nummer') ? undefined : json['blasform_nummer'],
        'stich': !runtime_1.exists(json, 'stich') ? undefined : json['stich'],
        'anzahl_kavitaeten': !runtime_1.exists(json, 'anzahl_kavitaeten') ? undefined : json['anzahl_kavitaeten'],
        'nest_nummer_1': !runtime_1.exists(json, 'nest_nummer_1') ? undefined : json['nest_nummer_1'],
        'nest_nummer_2': !runtime_1.exists(json, 'nest_nummer_2') ? undefined : json['nest_nummer_2'],
        'nest_nummer_3': !runtime_1.exists(json, 'nest_nummer_3') ? undefined : json['nest_nummer_3'],
        'nest_nummer_4': !runtime_1.exists(json, 'nest_nummer_4') ? undefined : json['nest_nummer_4'],
        'nest_nummer_5': !runtime_1.exists(json, 'nest_nummer_5') ? undefined : json['nest_nummer_5'],
        'nest_nummer_6': !runtime_1.exists(json, 'nest_nummer_6') ? undefined : json['nest_nummer_6'],
        'nest_nummer_7': !runtime_1.exists(json, 'nest_nummer_7') ? undefined : json['nest_nummer_7'],
        'nest_nummer_8': !runtime_1.exists(json, 'nest_nummer_8') ? undefined : json['nest_nummer_8'],
        'nest_nummer_9': !runtime_1.exists(json, 'nest_nummer_9') ? undefined : json['nest_nummer_9'],
        'nest_nummer_10': !runtime_1.exists(json, 'nest_nummer_10') ? undefined : json['nest_nummer_10'],
        'wechselbares_blindenzeichen': !runtime_1.exists(json, 'wechselbares_blindenzeichen') ? undefined : json['wechselbares_blindenzeichen'],
        'blasform_hinweis': !runtime_1.exists(json, 'blasform_hinweis') ? undefined : json['blasform_hinweis'],
        'einstellparameter_blasform_hinweis': !runtime_1.exists(json, 'einstellparameter_blasform_hinweis') ? undefined : json['einstellparameter_blasform_hinweis'],
        'duese_bezeichnung': !runtime_1.exists(json, 'duese_bezeichnung') ? undefined : json['duese_bezeichnung'],
        'duese_durchmesser': !runtime_1.exists(json, 'duese_durchmesser') ? undefined : json['duese_durchmesser'],
        'duese_profil': !runtime_1.exists(json, 'duese_profil') ? undefined : json['duese_profil'],
        'schneidring_durchmesser': !runtime_1.exists(json, 'schneidring_durchmesser') ? undefined : json['schneidring_durchmesser'],
        'blasdornsp_l1': !runtime_1.exists(json, 'blasdornsp_l1') ? undefined : json['blasdornsp_l1'],
        'blasdornsp_l2': !runtime_1.exists(json, 'blasdornsp_l2') ? undefined : json['blasdornsp_l2'],
        'blasdornsp_l3': !runtime_1.exists(json, 'blasdornsp_l3') ? undefined : json['blasdornsp_l3'],
        'blasdornsp_d1': !runtime_1.exists(json, 'blasdornsp_d1') ? undefined : json['blasdornsp_d1'],
        'blasdornsp_d2': !runtime_1.exists(json, 'blasdornsp_d2') ? undefined : json['blasdornsp_d2'],
        'blasdornsp_radius': !runtime_1.exists(json, 'blasdornsp_radius') ? undefined : json['blasdornsp_radius'],
        'abschlagbacken_konisch': !runtime_1.exists(json, 'abschlagbacken_konisch') ? undefined : json['abschlagbacken_konisch'],
        'backenhoehe_h1': !runtime_1.exists(json, 'backenhoehe_h1') ? undefined : json['backenhoehe_h1'],
        'steghoehe_h2': !runtime_1.exists(json, 'steghoehe_h2') ? undefined : json['steghoehe_h2'],
        'abschlagbacken_d1': !runtime_1.exists(json, 'abschlagbacken_d1') ? undefined : json['abschlagbacken_d1'],
        'abschlagbacken_d2': !runtime_1.exists(json, 'abschlagbacken_d2') ? undefined : json['abschlagbacken_d2'],
        'unterlegbleche': !runtime_1.exists(json, 'unterlegbleche') ? undefined : json['unterlegbleche'],
        'stellblatt_abstaende_a': !runtime_1.exists(json, 'stellblatt_abstaende_a') ? undefined : json['stellblatt_abstaende_a'],
        'stellblatt_abstaende_b': !runtime_1.exists(json, 'stellblatt_abstaende_b') ? undefined : json['stellblatt_abstaende_b'],
        'stueck_pro_stunde': !runtime_1.exists(json, 'stueck_pro_stunde') ? undefined : json['stueck_pro_stunde'],
        'maschinen_taktgeber': !runtime_1.exists(json, 'maschinen_taktgeber') ? undefined : json['maschinen_taktgeber'],
        'maschinen_laufkontrolle': !runtime_1.exists(json, 'maschinen_laufkontrolle') ? undefined : json['maschinen_laufkontrolle'],
        'verz_trennen_1_vor': !runtime_1.exists(json, 'verz_trennen_1_vor') ? undefined : json['verz_trennen_1_vor'],
        'verz_trennen_1_zur': !runtime_1.exists(json, 'verz_trennen_1_zur') ? undefined : json['verz_trennen_1_zur'],
        'zeitglied_trennen_1': !runtime_1.exists(json, 'zeitglied_trennen_1') ? undefined : json['zeitglied_trennen_1'],
        'verz_blas_blad': !runtime_1.exists(json, 'verz_blas_blad') ? undefined : json['verz_blas_blad'],
        'vorblaszeit': !runtime_1.exists(json, 'vorblaszeit') ? undefined : json['vorblaszeit'],
        'blasdruck': !runtime_1.exists(json, 'blasdruck') ? undefined : json['blasdruck'],
        'standzeit': !runtime_1.exists(json, 'standzeit') ? undefined : json['standzeit'],
        'verz_form_zu': !runtime_1.exists(json, 'verz_form_zu') ? undefined : json['verz_form_zu'],
        'lueftzeit_dorn': !runtime_1.exists(json, 'lueftzeit_dorn') ? undefined : json['lueftzeit_dorn'],
        'lueftzeit_form': !runtime_1.exists(json, 'lueftzeit_form') ? undefined : json['lueftzeit_form'],
        'verz_wagen_ab': !runtime_1.exists(json, 'verz_wagen_ab') ? undefined : json['verz_wagen_ab'],
        'verz_wagen_hoch': !runtime_1.exists(json, 'verz_wagen_hoch') ? undefined : json['verz_wagen_hoch'],
        'verz_stanze_vor': !runtime_1.exists(json, 'verz_stanze_vor') ? undefined : json['verz_stanze_vor'],
        'verz_stanze_zur': !runtime_1.exists(json, 'verz_stanze_zur') ? undefined : json['verz_stanze_zur'],
        'verz_greifer_zu': !runtime_1.exists(json, 'verz_greifer_zu') ? undefined : json['verz_greifer_zu'],
        'verz_greifer_auf_se': !runtime_1.exists(json, 'verz_greifer_auf_se') ? undefined : json['verz_greifer_auf_se'],
        'verz_greifer_auf_le': !runtime_1.exists(json, 'verz_greifer_auf_le') ? undefined : json['verz_greifer_auf_le'],
        'verz_ent_senk': !runtime_1.exists(json, 'verz_ent_senk') ? undefined : json['verz_ent_senk'],
        'verz_dorn_hoch': !runtime_1.exists(json, 'verz_dorn_hoch') ? undefined : json['verz_dorn_hoch'],
        'zeit_bandstop': !runtime_1.exists(json, 'zeit_bandstop') ? undefined : json['zeit_bandstop'],
        'verz_stuetzluft': !runtime_1.exists(json, 'verz_stuetzluft') ? undefined : json['verz_stuetzluft'],
        'dauer_stuetzluft': !runtime_1.exists(json, 'dauer_stuetzluft') ? undefined : json['dauer_stuetzluft'],
        'verz_dekomp': !runtime_1.exists(json, 'verz_dekomp') ? undefined : json['verz_dekomp'],
        'dauer_dekomp': !runtime_1.exists(json, 'dauer_dekomp') ? undefined : json['dauer_dekomp'],
        'verz_pneum_1': !runtime_1.exists(json, 'verz_pneum_1') ? undefined : json['verz_pneum_1'],
        'dauer_pneum_1': !runtime_1.exists(json, 'dauer_pneum_1') ? undefined : json['dauer_pneum_1'],
        'blaszeit': !runtime_1.exists(json, 'blaszeit') ? undefined : json['blaszeit'],
        'extruder_temperatur_richtwert': !runtime_1.exists(json, 'extruder_temperatur_richtwert') ? undefined : json['extruder_temperatur_richtwert'],
        'zone_1': !runtime_1.exists(json, 'zone_1') ? undefined : json['zone_1'],
        'zone_2': !runtime_1.exists(json, 'zone_2') ? undefined : json['zone_2'],
        'zone_3': !runtime_1.exists(json, 'zone_3') ? undefined : json['zone_3'],
        'zone_4': !runtime_1.exists(json, 'zone_4') ? undefined : json['zone_4'],
        'zone_5': !runtime_1.exists(json, 'zone_5') ? undefined : json['zone_5'],
        'zone_6': !runtime_1.exists(json, 'zone_6') ? undefined : json['zone_6'],
        'zone_7': !runtime_1.exists(json, 'zone_7') ? undefined : json['zone_7'],
        'zone_8': !runtime_1.exists(json, 'zone_8') ? undefined : json['zone_8'],
        'zone_9': !runtime_1.exists(json, 'zone_9') ? undefined : json['zone_9'],
        'zone_10': !runtime_1.exists(json, 'zone_10') ? undefined : json['zone_10'],
        'zone_1_temp': !runtime_1.exists(json, 'zone_1_temp') ? undefined : json['zone_1_temp'],
        'zone_2_temp': !runtime_1.exists(json, 'zone_2_temp') ? undefined : json['zone_2_temp'],
        'zone_3_temp': !runtime_1.exists(json, 'zone_3_temp') ? undefined : json['zone_3_temp'],
        'zone_4_temp': !runtime_1.exists(json, 'zone_4_temp') ? undefined : json['zone_4_temp'],
        'zone_5_temp': !runtime_1.exists(json, 'zone_5_temp') ? undefined : json['zone_5_temp'],
        'zone_6_temp': !runtime_1.exists(json, 'zone_6_temp') ? undefined : json['zone_6_temp'],
        'zone_7_temp': !runtime_1.exists(json, 'zone_7_temp') ? undefined : json['zone_7_temp'],
        'zone_8_temp': !runtime_1.exists(json, 'zone_8_temp') ? undefined : json['zone_8_temp'],
        'zone_9_temp': !runtime_1.exists(json, 'zone_9_temp') ? undefined : json['zone_9_temp'],
        'zone_10_temp': !runtime_1.exists(json, 'zone_10_temp') ? undefined : json['zone_10_temp'],
        'programm_zeit': !runtime_1.exists(json, 'programm_zeit') ? undefined : json['programm_zeit'],
        'programm_hub': !runtime_1.exists(json, 'programm_hub') ? undefined : json['programm_hub'],
        'maschinen_zyklus': !runtime_1.exists(json, 'maschinen_zyklus') ? undefined : json['maschinen_zyklus'],
        'wbk_0': !runtime_1.exists(json, 'wbk_0') ? undefined : json['wbk_0'],
        'wbk_1': !runtime_1.exists(json, 'wbk_1') ? undefined : json['wbk_1'],
        'wbk_2': !runtime_1.exists(json, 'wbk_2') ? undefined : json['wbk_2'],
        'wbk_3': !runtime_1.exists(json, 'wbk_3') ? undefined : json['wbk_3'],
        'wbk_4': !runtime_1.exists(json, 'wbk_4') ? undefined : json['wbk_4'],
        'wbk_5': !runtime_1.exists(json, 'wbk_5') ? undefined : json['wbk_5'],
        'wbk_6': !runtime_1.exists(json, 'wbk_6') ? undefined : json['wbk_6'],
        'wbk_7': !runtime_1.exists(json, 'wbk_7') ? undefined : json['wbk_7'],
        'wbk_8': !runtime_1.exists(json, 'wbk_8') ? undefined : json['wbk_8'],
        'wbk_9': !runtime_1.exists(json, 'wbk_9') ? undefined : json['wbk_9'],
        'wbk_10': !runtime_1.exists(json, 'wbk_10') ? undefined : json['wbk_10'],
        'wbk_11': !runtime_1.exists(json, 'wbk_11') ? undefined : json['wbk_11'],
        'wbk_12': !runtime_1.exists(json, 'wbk_12') ? undefined : json['wbk_12'],
        'wbk_13': !runtime_1.exists(json, 'wbk_13') ? undefined : json['wbk_13'],
        'wbk_14': !runtime_1.exists(json, 'wbk_14') ? undefined : json['wbk_14'],
        'wbk_15': !runtime_1.exists(json, 'wbk_15') ? undefined : json['wbk_15'],
        'wbk_16': !runtime_1.exists(json, 'wbk_16') ? undefined : json['wbk_16'],
        'wbk_17': !runtime_1.exists(json, 'wbk_17') ? undefined : json['wbk_17'],
        'wbk_18': !runtime_1.exists(json, 'wbk_18') ? undefined : json['wbk_18'],
        'wbk_19': !runtime_1.exists(json, 'wbk_19') ? undefined : json['wbk_19'],
        'wbk_20': !runtime_1.exists(json, 'wbk_20') ? undefined : json['wbk_20'],
        'wbk_21': !runtime_1.exists(json, 'wbk_21') ? undefined : json['wbk_21'],
        'wbk_22': !runtime_1.exists(json, 'wbk_22') ? undefined : json['wbk_22'],
        'wbk_23': !runtime_1.exists(json, 'wbk_23') ? undefined : json['wbk_23'],
        'wbk_24': !runtime_1.exists(json, 'wbk_24') ? undefined : json['wbk_24'],
        'wbk_25': !runtime_1.exists(json, 'wbk_25') ? undefined : json['wbk_25'],
        'wbk_26': !runtime_1.exists(json, 'wbk_26') ? undefined : json['wbk_26'],
        'wbk_27': !runtime_1.exists(json, 'wbk_27') ? undefined : json['wbk_27'],
        'wbk_28': !runtime_1.exists(json, 'wbk_28') ? undefined : json['wbk_28'],
        'wbk_29': !runtime_1.exists(json, 'wbk_29') ? undefined : json['wbk_29'],
        'produktionshinweis': !runtime_1.exists(json, 'produktionshinweis') ? undefined : json['produktionshinweis'],
    };
}
exports.StellblattDatenFromJSONTyped = StellblattDatenFromJSONTyped;
function StellblattDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
        'halsteil_nummer': value.halsteil_nummer,
        'lagerplatz_nummer': value.lagerplatz_nummer,
        'werkstoff_nummer': value.werkstoff_nummer,
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'artikel_gewicht': value.artikel_gewicht,
        'blaszeit_richtwert_gewicht': value.blaszeit_richtwert_gewicht,
        'datengruppe_3_gewicht': value.datengruppe_3_gewicht,
        'blasform_ep_gewicht': value.blasform_ep_gewicht,
        'blasform_nummer_intern': value.blasform_nummer_intern,
        'blindenzeichen_nummer_1': value.blindenzeichen_nummer_1,
        'blindenzeichen_nummer_2': value.blindenzeichen_nummer_2,
        'blindenzeichen_nummer_3': value.blindenzeichen_nummer_3,
        'blindenzeichen_nummer_4': value.blindenzeichen_nummer_4,
        'blindenzeichen_nummer_5': value.blindenzeichen_nummer_5,
        'blasform_nummer': value.blasform_nummer,
        'stich': value.stich,
        'anzahl_kavitaeten': value.anzahl_kavitaeten,
        'nest_nummer_1': value.nest_nummer_1,
        'nest_nummer_2': value.nest_nummer_2,
        'nest_nummer_3': value.nest_nummer_3,
        'nest_nummer_4': value.nest_nummer_4,
        'nest_nummer_5': value.nest_nummer_5,
        'nest_nummer_6': value.nest_nummer_6,
        'nest_nummer_7': value.nest_nummer_7,
        'nest_nummer_8': value.nest_nummer_8,
        'nest_nummer_9': value.nest_nummer_9,
        'nest_nummer_10': value.nest_nummer_10,
        'wechselbares_blindenzeichen': value.wechselbares_blindenzeichen,
        'blasform_hinweis': value.blasform_hinweis,
        'einstellparameter_blasform_hinweis': value.einstellparameter_blasform_hinweis,
        'duese_bezeichnung': value.duese_bezeichnung,
        'duese_durchmesser': value.duese_durchmesser,
        'duese_profil': value.duese_profil,
        'schneidring_durchmesser': value.schneidring_durchmesser,
        'blasdornsp_l1': value.blasdornsp_l1,
        'blasdornsp_l2': value.blasdornsp_l2,
        'blasdornsp_l3': value.blasdornsp_l3,
        'blasdornsp_d1': value.blasdornsp_d1,
        'blasdornsp_d2': value.blasdornsp_d2,
        'blasdornsp_radius': value.blasdornsp_radius,
        'abschlagbacken_konisch': value.abschlagbacken_konisch,
        'backenhoehe_h1': value.backenhoehe_h1,
        'steghoehe_h2': value.steghoehe_h2,
        'abschlagbacken_d1': value.abschlagbacken_d1,
        'abschlagbacken_d2': value.abschlagbacken_d2,
        'unterlegbleche': value.unterlegbleche,
        'stellblatt_abstaende_a': value.stellblatt_abstaende_a,
        'stellblatt_abstaende_b': value.stellblatt_abstaende_b,
        'stueck_pro_stunde': value.stueck_pro_stunde,
        'maschinen_taktgeber': value.maschinen_taktgeber,
        'maschinen_laufkontrolle': value.maschinen_laufkontrolle,
        'verz_trennen_1_vor': value.verz_trennen_1_vor,
        'verz_trennen_1_zur': value.verz_trennen_1_zur,
        'zeitglied_trennen_1': value.zeitglied_trennen_1,
        'verz_blas_blad': value.verz_blas_blad,
        'vorblaszeit': value.vorblaszeit,
        'blasdruck': value.blasdruck,
        'standzeit': value.standzeit,
        'verz_form_zu': value.verz_form_zu,
        'lueftzeit_dorn': value.lueftzeit_dorn,
        'lueftzeit_form': value.lueftzeit_form,
        'verz_wagen_ab': value.verz_wagen_ab,
        'verz_wagen_hoch': value.verz_wagen_hoch,
        'verz_stanze_vor': value.verz_stanze_vor,
        'verz_stanze_zur': value.verz_stanze_zur,
        'verz_greifer_zu': value.verz_greifer_zu,
        'verz_greifer_auf_se': value.verz_greifer_auf_se,
        'verz_greifer_auf_le': value.verz_greifer_auf_le,
        'verz_ent_senk': value.verz_ent_senk,
        'verz_dorn_hoch': value.verz_dorn_hoch,
        'zeit_bandstop': value.zeit_bandstop,
        'verz_stuetzluft': value.verz_stuetzluft,
        'dauer_stuetzluft': value.dauer_stuetzluft,
        'verz_dekomp': value.verz_dekomp,
        'dauer_dekomp': value.dauer_dekomp,
        'verz_pneum_1': value.verz_pneum_1,
        'dauer_pneum_1': value.dauer_pneum_1,
        'blaszeit': value.blaszeit,
        'extruder_temperatur_richtwert': value.extruder_temperatur_richtwert,
        'zone_1': value.zone_1,
        'zone_2': value.zone_2,
        'zone_3': value.zone_3,
        'zone_4': value.zone_4,
        'zone_5': value.zone_5,
        'zone_6': value.zone_6,
        'zone_7': value.zone_7,
        'zone_8': value.zone_8,
        'zone_9': value.zone_9,
        'zone_10': value.zone_10,
        'zone_1_temp': value.zone_1_temp,
        'zone_2_temp': value.zone_2_temp,
        'zone_3_temp': value.zone_3_temp,
        'zone_4_temp': value.zone_4_temp,
        'zone_5_temp': value.zone_5_temp,
        'zone_6_temp': value.zone_6_temp,
        'zone_7_temp': value.zone_7_temp,
        'zone_8_temp': value.zone_8_temp,
        'zone_9_temp': value.zone_9_temp,
        'zone_10_temp': value.zone_10_temp,
        'programm_zeit': value.programm_zeit,
        'programm_hub': value.programm_hub,
        'maschinen_zyklus': value.maschinen_zyklus,
        'wbk_0': value.wbk_0,
        'wbk_1': value.wbk_1,
        'wbk_2': value.wbk_2,
        'wbk_3': value.wbk_3,
        'wbk_4': value.wbk_4,
        'wbk_5': value.wbk_5,
        'wbk_6': value.wbk_6,
        'wbk_7': value.wbk_7,
        'wbk_8': value.wbk_8,
        'wbk_9': value.wbk_9,
        'wbk_10': value.wbk_10,
        'wbk_11': value.wbk_11,
        'wbk_12': value.wbk_12,
        'wbk_13': value.wbk_13,
        'wbk_14': value.wbk_14,
        'wbk_15': value.wbk_15,
        'wbk_16': value.wbk_16,
        'wbk_17': value.wbk_17,
        'wbk_18': value.wbk_18,
        'wbk_19': value.wbk_19,
        'wbk_20': value.wbk_20,
        'wbk_21': value.wbk_21,
        'wbk_22': value.wbk_22,
        'wbk_23': value.wbk_23,
        'wbk_24': value.wbk_24,
        'wbk_25': value.wbk_25,
        'wbk_26': value.wbk_26,
        'wbk_27': value.wbk_27,
        'wbk_28': value.wbk_28,
        'wbk_29': value.wbk_29,
        'produktionshinweis': value.produktionshinweis,
    };
}
exports.StellblattDatenToJSON = StellblattDatenToJSON;
