import { Blindenzeichen, CreateBlindenzeichen } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeTextEditorRenderer,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';


export type BlindenzeichenVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function BlindenzeichenVerwalten(props: BlindenzeichenVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateBlindenzeichen> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'bezeichnung',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Bezeichnung',
          field: 'bezeichnung',
          colId: 'bezeichnung',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateBlindenzeichen) => props.services.blindenzeichen.postBlindenzeichenPost({ CreateBlindenzeichen: data }),
        update: (data) => props.services.blindenzeichen.putByDatabaseIdBlindenzeichenDatabaseIdPut({ database_id: data.database_id, UpdateBlindenzeichen: data }),
        delete: (data, ignoreIntegrity) => props.services.blindenzeichen.deleteByDatabaseIdBlindenzeichenDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.blindenzeichen.restoreByDatabaseIdBlindenzeichenDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.blindenzeichen.getAllBlindenzeichenGet({ include_deleted }),
        getHistory: (data: Blindenzeichen) => props.services.blindenzeichen.getHistoryBlindenzeichenDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.blindenzeichen.downloadExcelBlindenzeichenExcelPost(),
      }}
      transactionService={props.transactionService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      subscriptionEventService={props.subscriptionEventService}
      dialogStrings={DialogStrings.Blindenzeichen}
    />
  );
}
