import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
  numberValueFormatterGen,
  withBoundProps,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H3,
  H4,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { LieferterminKennzeichen, ProduktionsplanDatenLadenResult, ProduktionsplanDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import styles from './ProduktionsplanPrint.module.scss';
import { format } from 'date-fns';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ProduktionsplanPrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id, blasmaschine_nummer } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ProduktionsplanAnzeigen') {
          return withBoundProps(Produktionsplan, { blasmaschine_nummer: blasmaschine_nummer ?? '' });
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function Produktionsplan(props: CustomFormProps<ProduktionsplanDatenLadenResult> & { blasmaschine_nummer: string }): JSX.Element {
  const payload = ProduktionsplanDatenLadenResultFromJSON(props.tokenPayload);
  const tableData = payload.tables.find(table => table.blasmaschine_nummer === props.blasmaschine_nummer);

  const numberFormatter = numberValueFormatterGen(0);

  return (
    <div>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <HTMLTable className={styles.produktionsplan_table}>
        <thead>
          <tr>
            <th colSpan={12} className={styles.produktionsplan_title}><H4>Produktionsplan</H4></th>
            <th className={styles.blasmaschine_nummer}><H3>BLM</H3></th>
            <th className={styles.blasmaschine_nummer}><H3>{props.blasmaschine_nummer}</H3></th>
          </tr>
          <tr className={styles.main_header_row}>
            <th>PAU-Nr.</th>
            <th>KU-<br />Bestell-<br />Nr.</th>
            <th>Kunde</th>
            <th>Artikel-Bez.</th>
            <th>Gewinde<br /><br />BLZ</th>
            <th>G [g]</th>
            <th>Farbe<br />Farb-Nr.</th>
            <th>Farb-Batch-Nr.</th>
            <th>F-%<br />VP</th>
            <th>WS</th>
            <th>Menge<br />- KU<br />- Prod.</th>
            <th>LT-KU<br />  -KZ</th>
            <th>Start</th>
            <th>Ende</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.entries.map((entry, index) => (
            <tr key={index}>
              <td className={styles.align_right}>{entry.pau_nummer}</td>
              <td>{entry.kunde_bestellnummer}</td>
              <td>{entry.kunde_name}{entry.empfaenger_name ? <br /> : ''}<p className={styles.empfaenger_name}>{entry.empfaenger_name}</p></td>
              <td>{entry.flaschen_typ_nummer}{entry.flaschen_typ_bezeichnung ? <br /> : ''}{entry.flaschen_typ_bezeichnung}</td>
              <td className={styles.cell_with_dashed_line}>
                <div className={styles.cell_with_dashed_line__grid}>
                  <div className={styles.cell_with_dashed_line__top}>
                    {entry.halsteil_bezeichnung}
                  </div>
                  <div className={styles.cell_with_dashed_line__bottom}>
                    {entry.mit_blindenzeichen ? 'm.BLZ' : 'o.BLZ'}
                  </div>
                </div>
              </td>
              <td className={styles.cell_with_dashed_line}>
                <div className={styles.cell_with_dashed_line__grid}>
                  <div className={styles.artikel_gewicht_cell}>
                    {entry.artikel_gewicht}
                  </div>
                  <div className={styles.cell_with_dashed_line__bottom} />
                </div>
              </td>
              <td className={styles.cell_with_dashed_line}>
                <div className={styles.cell_with_dashed_line__grid}>
                  <div className={styles.cell_with_dashed_line__top}>
                    {entry.granulat_farbe_bezeichnung}
                  </div>
                  <div className={styles.granulat_farbe_nummer_cell}>
                    {entry.granulat_farbe_nummer}
                  </div>
                </div>
              </td>
              <td className={styles.align_right}>{entry.granulat_farbe_batch_nummer}</td>
              <td className={styles.align_center}>
                <div className={styles.align_right}>
                  {entry.einfaerbungs_grad?.toFixed(1).replace('.', ',')}
                </div>
                <div className={styles.align_center}>
                  {entry.verpackungsart}
                </div>
              </td>
              <td className={styles.align_center}>{entry.werkstoff_bezeichnung}</td>
              <td className={styles.align_right}>
                {numberFormatter(entry.menge_kundenauftrag)}
                <br />
                {numberFormatter(entry.menge_produktionsauftrag)}
              </td>
              <td className={styles.align_center}>
                <b>
                  {entry.liefertermin_soll ? formatDateString(entry.liefertermin_soll) : 'Frei'}
                  <br />
                  {entry.liefertermin_soll && entry.liefertermin_kennzeichen === LieferterminKennzeichen.KW ? format(entry.liefertermin_soll, 'I') : ''}
                </b>
              </td>
              <td className={styles.align_center}>{format(entry.start_datum, 'dd.LL.yy')}</td>
              <td className={styles.align_center}>{format(entry.end_datum, 'dd.LL.yy')}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
}
