import { Card } from '@blueprintjs/core';
import React from 'react';
import { RequireClaims } from '../../infrastructure/RequireClaims';
import { stellblattDialogs } from '../stammdatenDialogs';

import styles from './Dashboard.module.scss';
import { StellblattNachdruckenModal } from './StellblattNachdruckenDialog';
import { PropsWithServices } from '../../infrastructure';

export type StellblattNavigatorProps = PropsWithServices<{
  openStartDialog: (startDialogId: string) => void,
}>;

export type StellblattNavigatorState = {
  showStellblattDialog: boolean,
};

export class StellblattNavigator extends React.Component<StellblattNavigatorProps, StellblattNavigatorState> {
  constructor(props: StellblattNavigatorProps) {
    super(props);
    this.state = {
      showStellblattDialog: false,
    };
  }

  public render(): JSX.Element {
    return (
      <Card className={styles.stellblatt_navigator}>
        <Card className={styles.stellblatt_navigator_title}>Stellblatt</Card>
        {
          stellblattDialogs.map(dialog => (
            <RequireClaims key={dialog.title} claims={dialog.readClaims} hideOnError>
              <Card key={dialog.title} onClick={() => this.props.openStartDialog(dialog.route)} interactive>{dialog.title}</Card>
            </RequireClaims>
          ))
        }
        <RequireClaims claims={['manipulate_produktionsauftrag']} hideOnError>
          <Card onClick={() => this.setState({ showStellblattDialog: true })} interactive>Stellblatt nachdrucken</Card>
        </RequireClaims>
        {this.state.showStellblattDialog && (
          <StellblattNachdruckenModal
            atlasEngineClient={this.props.atlasEngineClient}
            config={this.props.config}
            onModalFinished={() => this.setState({ showStellblattDialog: false })}
            services={this.props.services}
            subscriptionEventService={this.props.subscriptionEventService}
          />
        )}
      </Card>
    );
  }
}
