"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlantafelEntryApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PlantafelEntryApi extends runtime.BaseAPI {
    /**
     * Get All
     */
    getAllPlantafelEntryGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.produktionsauftrag_database_id !== undefined) {
                queryParameters['produktionsauftrag_database_id'] = requestParameters.produktionsauftrag_database_id;
            }
            if (requestParameters.kundenauftrag_database_id !== undefined) {
                queryParameters['kundenauftrag_database_id'] = requestParameters.kundenauftrag_database_id;
            }
            if (requestParameters.blasmaschine_database_id !== undefined) {
                queryParameters['blasmaschine_database_id'] = requestParameters.blasmaschine_database_id;
            }
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProduktionsauftragEntryFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllPlantafelEntryGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllPlantafelEntryGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Blasdauer
     */
    updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_database_id === null || requestParameters.produktionsauftrag_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_database_id', 'Required parameter requestParameters.produktionsauftrag_database_id was null or undefined when calling updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPost.');
            }
            if (requestParameters.blasdauer === null || requestParameters.blasdauer === undefined) {
                throw new runtime.RequiredError('blasdauer', 'Required parameter requestParameters.blasdauer was null or undefined when calling updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPost.');
            }
            const queryParameters = {};
            if (requestParameters.blasdauer !== undefined) {
                queryParameters['blasdauer'] = requestParameters.blasdauer;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_database_id}/update_blasdauer`.replace(`{${"produktionsauftrag_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.PlantafelEntryFromJSON));
        });
    }
    /**
     * Update Blasdauer
     */
    updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBlasdauerPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasdauerPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Blasmachine Datum Aktuelle Produktion Start
     */
    updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.blasmaschine_database_id === null || requestParameters.blasmaschine_database_id === undefined) {
                throw new runtime.RequiredError('blasmaschine_database_id', 'Required parameter requestParameters.blasmaschine_database_id was null or undefined when calling updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPost.');
            }
            if (requestParameters.datum_aktuelle_produktion_start === null || requestParameters.datum_aktuelle_produktion_start === undefined) {
                throw new runtime.RequiredError('datum_aktuelle_produktion_start', 'Required parameter requestParameters.datum_aktuelle_produktion_start was null or undefined when calling updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPost.');
            }
            const queryParameters = {};
            if (requestParameters.datum_aktuelle_produktion_start !== undefined) {
                queryParameters['datum_aktuelle_produktion_start'] = requestParameters.datum_aktuelle_produktion_start.toISOString();
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/update_blasmachine_datum_aktuelle_produktion_start/{blasmaschine_database_id}`.replace(`{${"blasmaschine_database_id"}}`, encodeURIComponent(String(requestParameters.blasmaschine_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PlantafelUpdateBlasmachineDatumAktuelleProduktionStartResultFromJSON(jsonValue));
        });
    }
    /**
     * Update Blasmachine Datum Aktuelle Produktion Start
     */
    updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBlasmachineDatumAktuelleProduktionStartPlantafelEntryUpdateBlasmachineDatumAktuelleProduktionStartBlasmaschineDatabaseIdPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Blasmachine Nummer And Nnn
     */
    updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_database_id === null || requestParameters.produktionsauftrag_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_database_id', 'Required parameter requestParameters.produktionsauftrag_database_id was null or undefined when calling updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPost.');
            }
            if (requestParameters.blasmachine_nummer === null || requestParameters.blasmachine_nummer === undefined) {
                throw new runtime.RequiredError('blasmachine_nummer', 'Required parameter requestParameters.blasmachine_nummer was null or undefined when calling updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPost.');
            }
            if (requestParameters.nnn === null || requestParameters.nnn === undefined) {
                throw new runtime.RequiredError('nnn', 'Required parameter requestParameters.nnn was null or undefined when calling updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPost.');
            }
            const queryParameters = {};
            if (requestParameters.blasmachine_nummer !== undefined) {
                queryParameters['blasmachine_nummer'] = requestParameters.blasmachine_nummer;
            }
            if (requestParameters.nnn !== undefined) {
                queryParameters['nnn'] = requestParameters.nnn;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_database_id}/update_blasmachine_nummer_and_nnn`.replace(`{${"produktionsauftrag_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.PlantafelEntryFromJSON));
        });
    }
    /**
     * Update Blasmachine Nummer And Nnn
     */
    updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBlasmachineNummerAndNnnPlantafelEntryProduktionsauftragDatabaseIdUpdateBlasmachineNummerAndNnnPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Fertig Vor Liefertermin
     */
    updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_database_id === null || requestParameters.produktionsauftrag_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_database_id', 'Required parameter requestParameters.produktionsauftrag_database_id was null or undefined when calling updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPost.');
            }
            if (requestParameters.fertig_vor_liefertermin === null || requestParameters.fertig_vor_liefertermin === undefined) {
                throw new runtime.RequiredError('fertig_vor_liefertermin', 'Required parameter requestParameters.fertig_vor_liefertermin was null or undefined when calling updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPost.');
            }
            const queryParameters = {};
            if (requestParameters.fertig_vor_liefertermin !== undefined) {
                queryParameters['fertig_vor_liefertermin'] = requestParameters.fertig_vor_liefertermin;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_database_id}/update_fertig_vor_liefertermin`.replace(`{${"produktionsauftrag_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Update Fertig Vor Liefertermin
     */
    updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateFertigVorLieferterminPlantafelEntryProduktionsauftragDatabaseIdUpdateFertigVorLieferterminPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Liefertermin
     */
    updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_blasen_database_id === null || requestParameters.produktionsauftrag_blasen_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_blasen_database_id', 'Required parameter requestParameters.produktionsauftrag_blasen_database_id was null or undefined when calling updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPost.');
            }
            if (requestParameters.kundenauftrag_database_id === null || requestParameters.kundenauftrag_database_id === undefined) {
                throw new runtime.RequiredError('kundenauftrag_database_id', 'Required parameter requestParameters.kundenauftrag_database_id was null or undefined when calling updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPost.');
            }
            if (requestParameters.liefertermin_kennzeichen === null || requestParameters.liefertermin_kennzeichen === undefined) {
                throw new runtime.RequiredError('liefertermin_kennzeichen', 'Required parameter requestParameters.liefertermin_kennzeichen was null or undefined when calling updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPost.');
            }
            const queryParameters = {};
            if (requestParameters.kundenauftrag_database_id !== undefined) {
                queryParameters['kundenauftrag_database_id'] = requestParameters.kundenauftrag_database_id;
            }
            if (requestParameters.liefertermin_kennzeichen !== undefined) {
                queryParameters['liefertermin_kennzeichen'] = requestParameters.liefertermin_kennzeichen;
            }
            if (requestParameters.liefertermin_soll !== undefined) {
                queryParameters['liefertermin_soll'] = requestParameters.liefertermin_soll.toISOString();
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_blasen_database_id}/update_liefertermin`.replace(`{${"produktionsauftrag_blasen_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_blasen_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Update Liefertermin
     */
    updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateLieferterminPlantafelEntryProduktionsauftragBlasenDatabaseIdUpdateLieferterminPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Pau Menge
     */
    updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_blasen_database_id === null || requestParameters.produktionsauftrag_blasen_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_blasen_database_id', 'Required parameter requestParameters.produktionsauftrag_blasen_database_id was null or undefined when calling updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePost.');
            }
            if (requestParameters.pau_menge === null || requestParameters.pau_menge === undefined) {
                throw new runtime.RequiredError('pau_menge', 'Required parameter requestParameters.pau_menge was null or undefined when calling updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePost.');
            }
            const queryParameters = {};
            if (requestParameters.pau_menge !== undefined) {
                queryParameters['pau_menge'] = requestParameters.pau_menge;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_blasen_database_id}/update_pau_menge`.replace(`{${"produktionsauftrag_blasen_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_blasen_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Update Pau Menge
     */
    updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updatePauMengePlantafelEntryProduktionsauftragBlasenDatabaseIdUpdatePauMengePostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Verpackungs Vorschrift
     */
    updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_database_id === null || requestParameters.produktionsauftrag_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_database_id', 'Required parameter requestParameters.produktionsauftrag_database_id was null or undefined when calling updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPost.');
            }
            if (requestParameters.verpackungs_vorschrift_database_id === null || requestParameters.verpackungs_vorschrift_database_id === undefined) {
                throw new runtime.RequiredError('verpackungs_vorschrift_database_id', 'Required parameter requestParameters.verpackungs_vorschrift_database_id was null or undefined when calling updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPost.');
            }
            if (requestParameters.verpackungs_vorschrift_version_id === null || requestParameters.verpackungs_vorschrift_version_id === undefined) {
                throw new runtime.RequiredError('verpackungs_vorschrift_version_id', 'Required parameter requestParameters.verpackungs_vorschrift_version_id was null or undefined when calling updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPost.');
            }
            const queryParameters = {};
            if (requestParameters.verpackungs_vorschrift_database_id !== undefined) {
                queryParameters['verpackungs_vorschrift_database_id'] = requestParameters.verpackungs_vorschrift_database_id;
            }
            if (requestParameters.verpackungs_vorschrift_version_id !== undefined) {
                queryParameters['verpackungs_vorschrift_version_id'] = requestParameters.verpackungs_vorschrift_version_id;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_database_id}/update_verpackungs_vorschrift`.replace(`{${"produktionsauftrag_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Update Verpackungs Vorschrift
     */
    updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateVerpackungsVorschriftPlantafelEntryProduktionsauftragDatabaseIdUpdateVerpackungsVorschriftPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Werkstoff Typ
     */
    updateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.produktionsauftrag_database_id === null || requestParameters.produktionsauftrag_database_id === undefined) {
                throw new runtime.RequiredError('produktionsauftrag_database_id', 'Required parameter requestParameters.produktionsauftrag_database_id was null or undefined when calling updateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/plantafel_entry/{produktionsauftrag_database_id}/update_werkstoff_typ`.replace(`{${"produktionsauftrag_database_id"}}`, encodeURIComponent(String(requestParameters.produktionsauftrag_database_id))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BodyUpdateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPostToJSON(requestParameters.BodyUpdateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPost),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Update Werkstoff Typ
     */
    updateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateWerkstoffTypPlantafelEntryProduktionsauftragDatabaseIdUpdateWerkstoffTypPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.PlantafelEntryApi = PlantafelEntryApi;
