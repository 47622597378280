import { Dialog, H2 } from '@blueprintjs/core';
import { RueckmeldungProduktionsdatenErfassenResult } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  TextViewer,
} from '../../../../infrastructure';
import { buildRueckmeldungTitle } from '../buildRueckmeldungTitle';

export function ProduktionsauftragsDatenAnzeigen(
  props: CustomFormProps<RueckmeldungProduktionsdatenErfassenResult>,
): JSX.Element {
  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title={buildRueckmeldungTitle(0)}
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Abschließende Produktionswerte: </H2>
        <DialogFormField fieldLabel='Menge Gut' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_gut.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Einricht-Ausschuss' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_einricht_ausschuss.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Produktions-Ausschuss' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_produktion_ausschuss.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Menge Gesamt' fieldLocation=''>
          <TextViewer value={props.tokenPayload.menge_gesamt.toString()} />
        </DialogFormField>
        <DialogFormField fieldLabel='Einrichtausschuss [%]' fieldLocation=''>
          <TextViewer value={`${props.tokenPayload.prozent_einricht_ausschuss.toFixed(2)}%`} />
        </DialogFormField>
        <DialogFormField fieldLabel='Produktionsausschuss [%]' fieldLocation=''>
          <TextViewer value={`${props.tokenPayload.prozent_produktions_ausschuss.toFixed(2)}%`} />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onFinish={() => props.finishUserTask({})}
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
