import { Kundenauftrag } from 'wacoplast_wws__api';
import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../infrastructure';
import { KundenauftragInformationTable } from './custom_forms';
import { Button, Dialog } from '@blueprintjs/core';

export type KundenauftragEditierenModalProps = PropsWithServices<ModalProps<boolean>> & {
  stammdatenEntity: Kundenauftrag;
};

export function KundenauftragAnsehenModal(props: KundenauftragEditierenModalProps): JSX.Element {
  return (
    <Dialog isOpen onClose={() => props.onModalFinished(true)}>
      <DialogBody>
        <KundenauftragInformationTable {...props} kundenauftrag={{ isLoading: false, error: null, data: props.stammdatenEntity, reload: () => {} }} />
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={() => props.onModalFinished(true)}>Fertig</Button>
      </DialogFooter>
    </Dialog>
  );
}
