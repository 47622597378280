"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UmbauplanDatenLadenResultToJSON = exports.UmbauplanDatenLadenResultFromJSONTyped = exports.UmbauplanDatenLadenResultFromJSON = exports.UmbauplanDatenLadenResultSortierkriteriumEnum = void 0;
const _1 = require("./");
/**
* @export
* @enum {string}
*/
var UmbauplanDatenLadenResultSortierkriteriumEnum;
(function (UmbauplanDatenLadenResultSortierkriteriumEnum) {
    UmbauplanDatenLadenResultSortierkriteriumEnum["Datum"] = "Datum";
    UmbauplanDatenLadenResultSortierkriteriumEnum["Blasmaschine"] = "Blasmaschine";
})(UmbauplanDatenLadenResultSortierkriteriumEnum = exports.UmbauplanDatenLadenResultSortierkriteriumEnum || (exports.UmbauplanDatenLadenResultSortierkriteriumEnum = {}));
function UmbauplanDatenLadenResultFromJSON(json) {
    return UmbauplanDatenLadenResultFromJSONTyped(json, false);
}
exports.UmbauplanDatenLadenResultFromJSON = UmbauplanDatenLadenResultFromJSON;
function UmbauplanDatenLadenResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
        'sortierkriterium': json['sortierkriterium'],
        'entries': (json['entries'].map(_1.UmbauplanEntryFromJSON)),
    };
}
exports.UmbauplanDatenLadenResultFromJSONTyped = UmbauplanDatenLadenResultFromJSONTyped;
function UmbauplanDatenLadenResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
        'sortierkriterium': value.sortierkriterium,
        'entries': (value.entries.map(_1.UmbauplanEntryToJSON)),
    };
}
exports.UmbauplanDatenLadenResultToJSON = UmbauplanDatenLadenResultToJSON;
