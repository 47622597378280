/* eslint-disable @blueprintjs/no-deprecated-type-references */
import {
  Button,
  ButtonGroup,
  Classes,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { IItemRendererProps, Select } from '@blueprintjs/select';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CommonEditorProps } from '..';
import { UseFetchLikeServiceFunctionResult } from '../..';
import styles from './DropDownPicker.module.scss';


export type DropDownPickerProps<TEntryType> = CommonEditorProps<TEntryType | null> & {
  dataProvider: UseFetchLikeServiceFunctionResult<Array<TEntryType>>,
  nullable?: boolean,
  itemRenderer: (item: TEntryType, itemProps?: IItemRendererProps, changeActiveItem?: () => void) => JSX.Element,
  valueRenderer: (item: TEntryType) => JSX.Element,
  filter: (query: string, data: TEntryType) => boolean
};

export function DropDownPicker<TEntryType>(props: DropDownPickerProps<TEntryType>): JSX.Element {

  const [activeItem, setActiveItem] = React.useState<TEntryType | null>(null);

  const removeIcon = (<FontAwesomeIcon size='sm' icon={faTrash} />);

  if (props.dataProvider.error) {
    if ((props.dataProvider.error as any)?.status === 403) {
      return (
        <Button fill disabled rightIcon='caret-down' alignText='left'>
          Fehlende Berechtigung
        </Button>
      );
    }
    return (
      <ButtonGroup fill>
        <Button disabled rightIcon='caret-down' alignText='left'>
          Daten konnten nicht geladen werden
        </Button>
        {!props.disabled &&
          <Button className={Classes.FIXED} intent='primary' onClick={props.dataProvider.reload}>
            Erneut versuchen
          </Button>
        }
      </ButtonGroup>
    );
  }

  if (props.dataProvider.isLoading) {
    return (
      <Button fill={true} disabled={true} rightIcon='caret-down' alignText='left'>
        <div className={styles.loading_message}><Spinner size={SpinnerSize.SMALL} /> Daten werden geladen</div>
      </Button>
    );
  }

  if (activeItem === null) {
    setActiveItem(props.dataProvider.data[0]);
  }

  return (
    // eslint-disable-next-line @blueprintjs/no-deprecated-components
    <Select
      disabled={props.disabled}
      items={props.dataProvider.data}
      itemRenderer={(item, itemProps) => props.itemRenderer(item, itemProps, () => setActiveItem(item))}
      onActiveItemChange={(item) => setActiveItem(item)}
      activeItem={activeItem}
      popoverProps={{
        minimal: true,
        hasBackdrop: true,
        onClose: (event) => event?.stopPropagation(),
      }}
      fill={true}
      itemPredicate={props.filter}
      onItemSelect={(item) => props.onChange(item)}
      noResults={<>Keine Einträge gefunden</>}
      matchTargetWidth={true}
    >
      <ButtonGroup fill>
        <Button
          rightIcon='caret-down'
          disabled={props.disabled}
          alignText='left'
          style={{ overflow: 'auto' }}
        >
          {(props.value && props.valueRenderer(props.value)) ?? ' '}
        </Button>
        {props.nullable &&
          <Button
            icon={removeIcon}
            className={Classes.FIXED}
            disabled={props.disabled}
            onClick={(event) => {
              event.stopPropagation();
              props.onChange(null);
            }}
          />
        }
      </ButtonGroup>
    </Select>
  );
}
