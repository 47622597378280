import { DataModels } from '@atlas-engine/atlas_engine_client';
import { ValueFormatterParams } from 'ag-grid-community';
import { Switch } from '@blueprintjs/core';
import React from 'react';
import {
  AbwicklungspfadProduktionsauftrag,
  EmitableEventNames,
  KundenauftragArt,
  Produktionsauftrag,
  ProduktionsauftragBlasen,
  ProduktionsauftragStatus,
} from 'wacoplast_wws__api';
import { ProduktionsauftragAbwickelnModal, ProduktionsauftragStatusKeyMapping, VerpackungsVorschriftAnzeigenModal } from '.';
import { KundenauftragArtKeyMapping } from '..';
import {
  AuthProviderContext,
  DATE_COLUMN_OPTIONS,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGridRenderer,
  StammdatenModal,
  StammdatenPerformableRowActions,
  StartDialogProps,
  dateValueFormatter,
  formatLiefertermin,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  numberValueFormatterGenAGGrid,
  useSubProcesses,
  useUserTasks,
} from '../../infrastructure';
import { PauDurchfuehrenModels, ProcessConstants } from '../../processes';
import { StornierenModals, renderStornierenModal, stornieren } from '../auftragStornieren';
import { defaultColumnState } from './defaultColumnConfiguration';
import { formatBooleanToJaNein } from '../../infrastructure/filterFormatters';
import { differenceInCalendarMonths, format, sub } from 'date-fns';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QUERY_WAITING_USER_TASKS_FOR_ERFASSEN = {
  state: DataModels.FlowNodeInstances.FlowNodeInstanceState.suspended,
  processModelId: [
    ProcessConstants['Produktionsauftrag durchführen Lager'].processId,
    ProcessConstants['Produktionsauftrag durchführen Extern'].processId,
  ],
};

export function ProduktionsauftragVerwalten(props: PropsWithTransaction<PropsWithServices<StartDialogProps>>): JSX.Element {
  const waitingUserTasksForDurchfuehren = useUserTasks(props.atlasEngineClient, QUERY_WAITING_USER_TASKS_FOR_ERFASSEN);
  const runningSubprocesses = useSubProcesses(props.atlasEngineClient);

  return <ProduktionsauftragVerwaltenView {...props} waitingUserTasksForDurchfuehren={waitingUserTasksForDurchfuehren} runningSubprocesses={runningSubprocesses} />;
}

export type ProduktionsauftragVerwaltenViewProps = PropsWithTransaction<PropsWithServices<StartDialogProps>> & {
  waitingUserTasksForDurchfuehren: DataModels.FlowNodeInstances.UserTaskInstance[];
  runningSubprocesses: DataModels.FlowNodeInstances.FlowNodeInstance[];
};

type ShownModal = null | {
  modal: 'continue';
  processModelId: string;
  correlationId: string;
} | {
  modal: StornierenModals,
  produktionsauftrag?: Produktionsauftrag;
} | {
  modal: 'edit';
  produktionsauftrag: Produktionsauftrag;
} | {
  modal: 'showVPV';
  produktionsauftrag: Produktionsauftrag;
};

type ProduktionsauftragVerwaltenViewState = {
  produktionsauftraege: Produktionsauftrag[] | null;
  showModal: ShownModal;
  displayFinished: boolean;
};

class ProduktionsauftragVerwaltenView extends React.Component<ProduktionsauftragVerwaltenViewProps, ProduktionsauftragVerwaltenViewState> {

  constructor(props: ProduktionsauftragVerwaltenViewProps) {
    super(props);
    this.state = {
      produktionsauftraege: null,
      showModal: null,
      displayFinished: false,
    };
  }

  private updateStammdaten(changes: Array<Produktionsauftrag>): void {
    const databaseIdsToFilter = changes.map(change => change.database_id);
    const stammdaten = this.state.produktionsauftraege?.filter(entity => !databaseIdsToFilter.includes(entity.database_id)) ?? [];
    const stammdatenChanges = changes
      .filter((changedEntity, index) => index === changes.findIndex(entity => entity.database_id === changedEntity.database_id) && (this.state.displayFinished || (changedEntity.status !== ProduktionsauftragStatus.NUMBER_6 && changedEntity.status !== ProduktionsauftragStatus.NUMBER_9)));
    const produktionsauftraege = [...stammdaten, ...stammdatenChanges.map(entry => ({ ...entry, pau_start: (entry as any).datum_start || entry.pau_start, pau_ende: (entry as any).datum_ende || entry.pau_ende }))];
    this.setState({ produktionsauftraege });
  }

  public componentDidMount(): void {
    this.props.subscriptionEventService.subscribe(EmitableEventNames.Produktionsauftrag, (data) => this.updateStammdaten(data.affected_entities as any));
    this.loadData();
  }

  private handleDisplayFinishedEntriesChanged = (value: boolean): void => {
    this.setState({ displayFinished: value }, () => this.loadData());
  };

  public loadData(): Promise<void> {
    return this.props.transactionService.runTransaction({
      isRetryable: true,
      isAbortable: true,
      execute: async () => {
        const produktionsauftraege = await this.props.services.produktionsauftraegeZuKundenauftrag.getAllProduktionsauftragZuKundenauftragGet({ include_finished: this.state.displayFinished });
        const mappedProduktionsauftraege = produktionsauftraege.flatMap(produktionsauftraegeZuKUAU => {
          const pau_lager = (produktionsauftraegeZuKUAU.produktionsauftrag_lager as Array<Produktionsauftrag>);
          const pau_extern = (produktionsauftraegeZuKUAU.produktionsauftrag_extern as Array<Produktionsauftrag>);
          const pau_blasen = produktionsauftraegeZuKUAU.produktionsauftrag_blasen.map((produktionsauftragBlasen) => ({
            ...produktionsauftragBlasen.produktionsauftrag_blasen,
            pau_start: produktionsauftragBlasen?.datum_start,
            pau_ende: produktionsauftragBlasen?.datum_ende,
          }));
          return [...pau_lager, ...pau_extern, ...pau_blasen];
        });
        this.setState({
          produktionsauftraege: mappedProduktionsauftraege,
        });
      },
    }).catch(e => {
      console.error('Error while loading PAUs', e);
    });
  }

  public render(): JSX.Element {
    const deprecatedProduktionsauftraege = this.state.produktionsauftraege?.filter(auftrag =>
      auftrag.status < ProduktionsauftragStatus.NUMBER_6 &&
      differenceInCalendarMonths(Date.now(), auftrag.kundenauftrag.liefertermin_soll ?? Date.now()) >= 1,
    ) ?? [];

    const displayFinished = (
      <Switch
        onChange={(event) => {
          const checked = (event.target as any).checked;
          this.handleDisplayFinishedEntriesChanged(checked);
        }}
        checked={this.state.displayFinished}
        label='Fertige PAUs anzeigen'
      />
    );

    return (
      <>
        {this.state.showModal?.modal === 'showVPV' && (
          <VerpackungsVorschriftAnzeigenModal
            atlasEngineClient={this.props.atlasEngineClient}
            config={this.props.config}
            onModalFinished={() => {
              this.setState({ showModal: null });
            }}
            produktionsauftrag={this.state.showModal.produktionsauftrag}
            services={this.props.services}
            subscriptionEventService={this.props.subscriptionEventService}
          />
        )}
        {this.state.showModal?.modal === 'continue' && (
          <ProduktionsauftragAbwickelnModal
            onModalFinished={(result) => {
              this.setState({
                showModal: null,
              });
            }}
            processModelId={this.state.showModal.processModelId}
            subscriptionEventService={this.props.subscriptionEventService}
            transactionService={this.props.transactionService}
            atlasEngineClient={this.props.atlasEngineClient}
            services={this.props.services}
            getIdentity={this.props.getIdentity}
            config={this.props.config}
            correlationId={this.state.showModal.correlationId}
          />
        )}
        {this.state.showModal?.modal === 'edit' && (
          <StammdatenModal<Produktionsauftrag, Produktionsauftrag>
            dialogTitle='Produktionsauftrag bearbeiten'
            fields={[
              {
                type: 'simple',
                field: 'menge',
                label: 'Menge',
                renderer: makeNumberEditorRenderer(),
              },
            ]}
            onDialogConfirmed={async (data) => {
              if (data.menge !== null && data.menge !== undefined) {
                return this.props.services.produktionsauftrag.updateMengeProduktionsauftragDatabaseIdMengeMengePatch({ menge: data.menge, database_id: data.database_id });
              }
            }}
            onModalFinished={() => this.setState({ showModal: null })}
            primaryButtonTitle='Speichern'
            primaryButtonIntent='primary'
            stammdatenEntity={this.state.showModal.produktionsauftrag}
            hideChangingReason
          />
        )
        }
        {this.state.showModal?.modal !== 'continue' && this.state.showModal?.modal !== 'edit' && this.state.showModal?.modal !== 'showVPV' &&
          renderStornierenModal(
            this.state.showModal?.modal ?? null,
            (modal) => {
              if (this.state.showModal?.modal === 'stornieren-confirm' && modal === null && this.state.showModal.produktionsauftrag) {
                const pauDatabaseId = this.state.showModal.produktionsauftrag.database_id;
                const kuauCorrelationId = this.state.showModal.produktionsauftrag.kundenauftrag.process_correlation_id!;

                this.props.services.produktionsauftrag.getActiveByKundenauftragDatabaseIdProduktionsauftragKundenauftragDatabaseIdActiveGet({
                  kundenauftrag_database_id: this.state.showModal.produktionsauftrag.kundenauftrag_database_id,
                }).then(paus => {
                  const activePAUsExceptCurrent = paus.filter(pau => pau.database_id !== pauDatabaseId).filter(pau => pau.status !== ProduktionsauftragStatus.NUMBER_9);
                  if (activePAUsExceptCurrent.length > 0) {
                    this.setState({ showModal: { modal, produktionsauftrag: undefined } });
                  } else {
                    this.setState({ showModal: { modal: 'continue', correlationId: kuauCorrelationId, processModelId: ProcessConstants['Kundenauftrag abwickeln'].processId } });
                  }
                });
              } else {
                this.setState({ showModal: { modal, produktionsauftrag: undefined } });
              }
            })
        }
        <AuthProviderContext.Consumer>
          {(authProvider) => (
            <StammdatenAgGridRenderer
              title='Produktionsauftrag verwalten'
              onCloseStartDialogClicked={() => this.props.closeStartDialog()}
              stammdaten={this.state.produktionsauftraege}
              isVersioned={false}
              performableActions={[
                {
                  type: 'custom',
                  createElement: (gridRenderer) => deprecatedProduktionsauftraege.length > 0 ?
                    <span
                      onClick={() => gridRenderer.state.gridApi?.setFilterModel(this.getFilterModel(sub(Date.now(), { months: 1 })))}
                      style={{ color: 'red', cursor: 'pointer' }}
                    >
                      {deprecatedProduktionsauftraege.length} überfällige PAUs <FontAwesomeIcon size='sm' icon={faFilter} />
                    </span> :
                    <></>,
                },
                { type: 'custom', createElement: () => displayFinished },
                { type: 'exportExcel',
                  requiredClaims: ['read_produktionsauftrag'],
                  action: () => {
                    this.props.services.produktionsauftrag.downloadExcelProduktionsauftragExcelPost().then((result) => {
                      const link = document.createElement('a');
                      link.href = URL.createObjectURL(result);
                      link.download = 'exportProduktionsauftrag.xls';

                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  },
                },
              ]}
              getRowId={({ data }) => data.database_id.toString()}
              defaultColumnState={defaultColumnState}
              authProvider={authProvider.authProvider}
              getPerformableRowActions={(row) => {
                const { matchingUserTask, matchingSubProcess, stornierenSignal } = this.findMatchingFlowNodes(row);
                const actions: StammdatenPerformableRowActions<Produktionsauftrag> = [];
                const rueckmeldenLabel = matchingUserTask?.flowNodeId === ProcessConstants['Produktionsauftrag durchführen Extern'].flowNodes['Bestellung extern aufgeben'] ? 'Bestellung aufgeben' : 'Rückmelden';

                const editPossilbe = row.abwicklungspfad !== AbwicklungspfadProduktionsauftrag.blasen && [ProduktionsauftragStatus.NUMBER_1, ProduktionsauftragStatus.NUMBER_3, ProduktionsauftragStatus.NUMBER_4].includes(row.status);
                if (editPossilbe) {
                  actions.push({
                    type: 'edit',
                    onPerform: () => this.setState({ showModal: { modal: 'edit', produktionsauftrag: row } }),
                    requiredClaims: ['manipulate_produktionsauftrag'],
                  });
                }
                if (matchingUserTask) {
                  actions.push({
                    label: rueckmeldenLabel,
                    type: 'custom',
                    onPerform: () => this.rueckmelden(matchingUserTask),
                    intent: 'none',
                    requiredClaims: ['manipulate_produktionsauftrag'],
                  });
                }
                if (matchingSubProcess) {
                  actions.push({
                    label: 'Stornieren',
                    type: 'custom',
                    onPerform: () => stornieren(matchingSubProcess, stornierenSignal, this.props.atlasEngineClient, (modal) => this.setState({ showModal: { modal, produktionsauftrag: row } })),
                    intent: 'warning',
                    requiredClaims: ['manipulate_produktionsauftrag'],
                  });
                }
                if (row.abwicklungspfad === AbwicklungspfadProduktionsauftrag.blasen) {
                  actions.push({
                    label: 'VPV ansehen',
                    type: 'custom',
                    onPerform: () => this.setState({ showModal: { modal: 'showVPV', produktionsauftrag: row } }),
                    intent: 'none',
                    requiredClaims: ['read_produktionsauftrag'],
                  });
                }

                return actions;
              }}
              columnDefs={[
                {
                  headerName: 'Nummer',
                  colId: 'nummer',
                  valueGetter: ({ data }) => data.nummer,
                  filter: 'agTextColumnFilter',
                  ...NUMMER_SORT_OPTS,
                },
                {
                  headerName: 'Kundenauftrag',
                  colId: 'kundenauftrag_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.nummer,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Rahmenauftrag',
                  colId: 'rahmenauftrag_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.rahmenauftrag?.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Auftragsart',
                  colId: 'auftragsart',
                  valueGetter: ({ data }) => KundenauftragArtKeyMapping[KundenauftragArt[data.kundenauftrag.auftragsart]],
                  filter: 'agSetColumnFilter',
                },
                {
                  headerName: 'Datum-Eingang',
                  colId: 'datum_eingang',
                  valueGetter: ({ data }) => data.kundenauftrag.datum_eingang,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Datum Anlage',
                  colId: 'datum_anlage',
                  valueGetter: ({ data }) => data.datum_anlage,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Abwicklungspfad',
                  colId: 'abwicklungspfad',
                  valueGetter: ({ data }) => data.abwicklungspfad,
                  valueFormatter: (val: ValueFormatterParams) => val.value === 'lager' ? 'Lager' : val.value,
                  filter: 'agSetColumnFilter',
                },
                {
                  headerName: 'Status',
                  colId: 'status',
                  valueGetter: ({ data }) => data.status,
                  valueFormatter: (val: ValueFormatterParams) => `${(ProduktionsauftragStatusKeyMapping as any)[val.value]} (${val.value})`,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: (val: ValueFormatterParams) => `${(ProduktionsauftragStatusKeyMapping as any)[val.value]} (${val.value})`,
                  },
                },
                {
                  headerName: 'Liefertermin-Soll',
                  colId: 'liefertermin_soll',
                  valueGetter: ({ data }) => data.kundenauftrag.liefertermin_soll,
                  valueFormatter: ({ data }) =>
                    formatLiefertermin(data.kundenauftrag.liefertermin_kennzeichen, data.kundenauftrag.liefertermin_soll ?? null),
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'Kunde',
                  colId: 'kunde',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.kunde.name_kurz,
                      number: data.kundenauftrag.artikel.kunde.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Bestell-Nummer',
                  colId: 'bestell_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.bestell_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'KU-Art-Nr.',
                  colId: 'artikel_nummer_des_kunden',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.artikel_nummer_des_kunden,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Bestell Menge (KUAU)',
                  colId: 'kundenauftrag_menge',
                  valueGetter: ({ data }) => data.kundenauftrag.menge,
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge (PAU)',
                  colId: 'produktion_auftrag_menge',
                  valueGetter: ({ data }) => data.menge,
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Flaschentyp',
                  colId: 'flaschen_typ',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.flaschen_form.flaschentyp.bezeichnung,
                      number: data.kundenauftrag.artikel.flaschen_form.flaschentyp.nummer,
                    };
                  }),
                },
                {
                  headerName: 'BLZ',
                  colId: 'blinden_zeichen',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.blindenzeichen.bezeichnung,
                      number: data.kundenauftrag.artikel.blindenzeichen.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Volumen [ml]',
                  colId: 'volumen_ml',
                  filter: 'agNumberColumnFilter',
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.flaschen_form.flaschentyp.volumen,
                },
                {
                  headerName: 'Halsteil',
                  colId: 'hals_teil',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.flaschen_form.hals_teil.bezeichnung,
                      number: data.kundenauftrag.artikel.flaschen_form.hals_teil.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Gewicht [g]',
                  colId: 'gewicht_g',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.gewicht,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Farbe',
                  colId: 'farbe',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.granulat_farbe.bezeichnung,
                      number: data.kundenauftrag.artikel.granulat_farbe.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Batch-Nr.',
                  colId: 'granulat_farbe_batch_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.granulat_farbe.batch_nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Farb-%',
                  colId: 'einfaerbungs_grad',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.einfaerbungs_grad,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Farb-Bedarf [kg]',
                  colId: 'farb_bedarf',
                  valueGetter: ({ data }) => data.kundenauftrag.farb_bedarf,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'F-Bestand [kg]',
                  colId: 'lagerbestand',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.granulat_farbe.bestand,
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Werkstoff 1',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: `${data.anteil_werkstoff_1}% ${data.kundenauftrag.artikel.werkstoff_mischung.werkstoff_1.bezeichnung}`,
                      number: data.kundenauftrag.artikel.werkstoff_mischung.werkstoff_1.nummer,
                    };
                  }),
                  colId: 'werkstoff_1',
                },
                {
                  headerName: 'WerkstoffTyp 1',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.werkstoff_typ_1.bezeichnung ?? '',
                      number: String(data.werkstoff_typ_1.nummer),
                    };
                  }),
                  colId: 'werkstoff_typ_1',
                },
                {
                  headerName: 'Werkstoff 2',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.werkstoff_mischung.werkstoff_2 ? `${data.anteil_werkstoff_2}% ${data.kundenauftrag.artikel.werkstoff_mischung.werkstoff_2.bezeichnung}` : '',
                      number: data.kundenauftrag.artikel.werkstoff_mischung.werkstoff_2?.nummer,
                    };
                  }),
                  colId: 'werkstoff_2',
                },
                {
                  headerName: 'WerkstoffTyp 2',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.werkstoff_typ_2?.bezeichnung ?? '',
                      number: data.werkstoff_typ_2?.nummer?.toString(),
                    };
                  }),
                  colId: 'werkstoff_typ_2',
                },
                {
                  headerName: 'Empfänger',
                  colId: 'empfaenger',
                  ...makeForeignKeyColumn('kundenauftrag', (data: Produktionsauftrag) => {
                    return {
                      description: data.kundenauftrag.artikel.empfaenger?.name_kurz ?? '',
                      number: data.kundenauftrag.artikel.empfaenger?.nummer,
                    };
                  }),
                },
                {
                  headerName: 'Blasdauer [Tage]',
                  colId: 'blasdauer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).blasdauer : null,
                },
                {
                  headerName: 'Fertig vor LT [Tage]',
                  colId: 'fertig_vor_liefertermin',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).fertig_vor_liefertermin : null,
                },
                {
                  headerName: 'BLM',
                  colId: 'blasmaschine_nummer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).blasmaschine.nummer : null,
                },
                {
                  headerName: 'M,nnn',
                  colId: 'blasmaschine_nnn',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? `${(data as ProduktionsauftragBlasen).blasmaschine.nummer},${(data as ProduktionsauftragBlasen).nnn}` : null,
                },
                {
                  headerName: 'PAU-Start',
                  colId: 'pau_start',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen & { pau_start: Date, pau_ende: Date }).pau_start : null,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'PAU-Ende',
                  colId: 'pau_ende',
                  valueGetter: ({ data }) => data.pau_ende,
                  valueFormatter: dateValueFormatter,
                  ...DATE_COLUMN_OPTIONS,
                },
                {
                  headerName: 'mögliche BLM (WACO)',
                  colId: 'vorgegebene_blasmaschine_nummer',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).vorgegebene_blasmaschine?.nummer : null,
                },
                {
                  headerName: 'Menge Gesamt',
                  colId: 'menge_gesamt',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_gesamt : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Gut',
                  colId: 'menge_gut',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_gut : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Umbau-Start)',
                  colId: 'zaehlerstand_umbau_start',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_umbau_start : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Prod. Start) {99=Unterbr.}',
                  colId: 'zaehlerstand_produktion_start',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_produktion_start : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Zähler-Stand (Prod. Ende)',
                  colId: 'zaehlerstand_produktion_ende',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).zaehlerstand_produktion_ende : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Einricht Ausschuss',
                  colId: 'menge_einricht_ausschuss',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_einricht_ausschuss : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                {
                  headerName: 'Menge Produktions Ausschuss',
                  colId: 'menge_produktion_ausschuss',
                  filter: 'agNumberColumnFilter',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).menge_produktion_ausschuss : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                },
                // Datum letzte Aktualisierung
                // LT KZ
                {
                  headerName: 'Artikel',
                  colId: 'artikel_nummer',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.nummer,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger-Auftragsnummer',
                  colId: 'auftragsnummer_des_empfaenger',
                  valueGetter: ({ data }) => data.kundenauftrag.auftragsnummer_des_empfaenger,
                  filter: 'agTextColumnFilter',
                },
                {
                  headerName: 'Empfänger Artikel Nr',
                  colId: 'artikel_nummer_des_empfaengers',
                  valueGetter: ({ data }) => data.kundenauftrag.artikel.artikel_nummer_des_empfaengers,
                  filter: 'agTextColumnFilter',
                },
                // Anzahlnester (alte BLM)
                {
                  headerName: 'Einricht Ausschuss [%]',
                  colId: 'prozent_einricht_ausschuss',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).prozent_einricht_ausschuss?.toFixed(2) : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Prod. Ausschuss [%]',
                  colId: 'prozent_produktions_ausschuss',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? (data as ProduktionsauftragBlasen).prozent_produktions_ausschuss?.toFixed(2) : null,
                  valueFormatter: numberValueFormatterGenAGGrid(0),
                  filter: 'agNumberColumnFilter',
                },
                {
                  headerName: 'Invalide Ausschusswerte',
                  colId: 'invalide_ausschusswerte',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? formatBooleanToJaNein((data as ProduktionsauftragBlasen).invalide_ausschusswerte, false) : null,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: ({ data: { invalide_ausschusswerte } }: { data: ProduktionsauftragBlasen }) => formatBooleanToJaNein(invalide_ausschusswerte),
                  },
                },
                {
                  headerName: 'Technische Unterbrechung',
                  colId: 'technische_unterbrechung',
                  valueGetter: ({ data }) => data.abwicklungspfad === 'blasen' ? formatBooleanToJaNein((data as ProduktionsauftragBlasen).technische_unterbrechung, false) : null,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    valueFormatter: ({ data: { technische_unterbrechung } }: { data: ProduktionsauftragBlasen }) => formatBooleanToJaNein(technische_unterbrechung),
                  },
                },
              ]}
              transactionService={this.props.transactionService}
            />
          )}
        </AuthProviderContext.Consumer>
      </>
    );
  }

  private rueckmelden(userTask: DataModels.FlowNodeInstances.UserTaskInstance): void {
    this.setState({ showModal: { modal: 'continue', correlationId: userTask.correlationId, processModelId: userTask.processModelId } });
  }

  private findMatchingFlowNodes(produktionsauftrag: Produktionsauftrag): { matchingUserTask: DataModels.FlowNodeInstances.UserTaskInstance | undefined, matchingSubProcess: DataModels.FlowNodeInstances.FlowNodeInstance | undefined, stornierenSignal: string } {
    const process_correlation_id = produktionsauftrag.kundenauftrag.process_correlation_id;
    const noProcessFound = !process_correlation_id;
    const isStorniert = produktionsauftrag.status === ProduktionsauftragStatus.NUMBER_9;
    const isFertigBlasenPau = produktionsauftrag.abwicklungspfad === 'blasen' && [ProduktionsauftragStatus.NUMBER_5, ProduktionsauftragStatus.NUMBER_6].includes(produktionsauftrag.status);

    if (noProcessFound || isStorniert || isFertigBlasenPau) {
      return {
        matchingUserTask: undefined,
        matchingSubProcess: undefined,
        stornierenSignal: '',
      };
    }

    const matchingUserTask = this.props.waitingUserTasksForDurchfuehren.find(userTask => userTask.correlationId === process_correlation_id && userTask.processModelId === PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].processId);
    const matchingSubProcess = this.props.runningSubprocesses.find(subProcess => subProcess.correlationId === process_correlation_id && subProcess.processModelId === PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].processId);

    return {
      matchingUserTask,
      matchingSubProcess,
      stornierenSignal: PauDurchfuehrenModels[produktionsauftrag.abwicklungspfad].signals.stornieren,
    };
  }

  private getFilterModel(minimumDate: Date): any {
    return {
      liefertermin_soll: {
        dateFrom: format(minimumDate, 'yyyy-MM-dd HH:mm:ss'),
        dateTo: null,
        filterType: 'date',
        type: 'lessThan',
      },
      status: {
        values: [
          '1',
          '3',
          '4',
          '5',
        ],
        filterType: 'set',
      },
    };
  }
}
