import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON, QHinweisEntry } from 'wacoplast_wws__api';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import styles from './QHinweisePrint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

export function QHinweisePrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return QHinweise;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function QHinweise(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const { qhinweise } = payload;

  return (
    <div className={styles.qhinweise_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <HTMLTable className={styles.qhinweise_table}>
        <thead>
          <tr>
            <th colSpan={2}>KUNDE:</th>
            <td>{qhinweise.kunde_name}</td>
          </tr>
          <tr>
            <th colSpan={2}>FL-TYP:</th>
            <td>{qhinweise.flaschen_typ_bezeichnung}</td>
            <td>{qhinweise.flaschen_typ_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2}>PAU-NR.:</th>
            <td>{qhinweise.produktionsauftrag_nummer}</td>
          </tr>
          <tr>
            <th colSpan={8} className={styles.qualitaets_information_header}>Qualitäts-Information</th>
          </tr>
          <tr>
            <td colSpan={4} className={styles.qualitaets_information} />
            <td className={styles.empty_cell} />
            <td colSpan={3} className={styles.qualitaets_information} />
          </tr>
          <tr className={styles.empty_row} />
          <tr>
            <th className={styles.lfd_nr_header}>lfd. Nr.</th>
            <th className={styles.datum_header}>Datum</th>
            <th className={styles.text_header}>Hinweise von QS (Reklamationen)</th>
            <th className={styles.text_header}>durchgeführte Korrekturmaßnahmen</th>
            <th className={styles.empty_cell} />
            <th className={styles.lfd_nr_header}>lfd. Nr.</th>
            <th className={styles.datum_header}>Datum</th>
            <th className={styles.text_header}>Hinweis zur Prozessführung</th>
          </tr>
        </thead>
        <tbody>
          {qhinweise.hinweise.map((qhinweis: QHinweisEntry, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatDateString(qhinweis.datum)}</td>
              <td className={styles.text_cell}>{qhinweis.hinweis}</td>
              <td className={styles.text_cell}>{qhinweis.korrekturmanahme}</td>
              <td className={styles.empty_cell} />
              <td />
              <td />
              <td />
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
}
