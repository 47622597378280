"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GranulatFarbeBedarfVorschauEntryToJSON = exports.GranulatFarbeBedarfVorschauEntryFromJSONTyped = exports.GranulatFarbeBedarfVorschauEntryFromJSON = void 0;
const runtime_1 = require("../runtime");
function GranulatFarbeBedarfVorschauEntryFromJSON(json) {
    return GranulatFarbeBedarfVorschauEntryFromJSONTyped(json, false);
}
exports.GranulatFarbeBedarfVorschauEntryFromJSON = GranulatFarbeBedarfVorschauEntryFromJSON;
function GranulatFarbeBedarfVorschauEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'granulat_farbe_nummer': json['granulat_farbe_nummer'],
        'granulat_farbe_bezeichnung': json['granulat_farbe_bezeichnung'],
        'granulat_farbe_batch_nummer': !runtime_1.exists(json, 'granulat_farbe_batch_nummer') ? undefined : json['granulat_farbe_batch_nummer'],
        'pau_nummer': json['pau_nummer'],
        'pau_datum_start': !runtime_1.exists(json, 'pau_datum_start') ? undefined : (new Date(json['pau_datum_start'])),
        'granulat_farbe_bedarf': json['granulat_farbe_bedarf'],
        'granulat_farbe_bestand': json['granulat_farbe_bestand'],
        'granulat_farbe_fehlmenge': json['granulat_farbe_fehlmenge'],
    };
}
exports.GranulatFarbeBedarfVorschauEntryFromJSONTyped = GranulatFarbeBedarfVorschauEntryFromJSONTyped;
function GranulatFarbeBedarfVorschauEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'granulat_farbe_nummer': value.granulat_farbe_nummer,
        'granulat_farbe_bezeichnung': value.granulat_farbe_bezeichnung,
        'granulat_farbe_batch_nummer': value.granulat_farbe_batch_nummer,
        'pau_nummer': value.pau_nummer,
        'pau_datum_start': value.pau_datum_start === undefined ? undefined : (value.pau_datum_start.toISOString().substr(0, 10)),
        'granulat_farbe_bedarf': value.granulat_farbe_bedarf,
        'granulat_farbe_bestand': value.granulat_farbe_bestand,
        'granulat_farbe_fehlmenge': value.granulat_farbe_fehlmenge,
    };
}
exports.GranulatFarbeBedarfVorschauEntryToJSON = GranulatFarbeBedarfVorschauEntryToJSON;
