"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFirmaToJSON = exports.CreateFirmaFromJSONTyped = exports.CreateFirmaFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateFirmaFromJSON(json) {
    return CreateFirmaFromJSONTyped(json, false);
}
exports.CreateFirmaFromJSON = CreateFirmaFromJSON;
function CreateFirmaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'typ': _1.FirmaTypFromJSON(json['typ']),
        'nummer': !runtime_1.exists(json, 'nummer') ? undefined : json['nummer'],
        'name_kurz': json['name_kurz'],
        'name_lang': !runtime_1.exists(json, 'name_lang') ? undefined : json['name_lang'],
    };
}
exports.CreateFirmaFromJSONTyped = CreateFirmaFromJSONTyped;
function CreateFirmaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'typ': _1.FirmaTypToJSON(value.typ),
        'nummer': value.nummer,
        'name_kurz': value.name_kurz,
        'name_lang': value.name_lang,
    };
}
exports.CreateFirmaToJSON = CreateFirmaToJSON;
