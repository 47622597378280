import { CreateVerpackungsEinheit, MaterialGruppe, VerpackungsEinheit } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  makeNumberEditorRenderer,
  makeStammdatenEntityRelatedDisabledEditor,
  makeTextEditorRenderer,
  numberValueFormatterGenAGGrid,
} from '../../../infrastructure';
import { MaterialGruppeKeyMapping } from '..';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makeMaterialGruppeEditor } from './MaterialGruppeEditor';

export type VerpackungsEinheitVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function VerpackungsEinheitVerwalten(props: VerpackungsEinheitVerwaltenProps): JSX.Element {
  const editors: StammdatenFieldEditors<CreateVerpackungsEinheit> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['material_gruppe'],
      renderer: makeMaterialGruppeEditor([MaterialGruppe.NUMBER_10, MaterialGruppe.NUMBER_30, MaterialGruppe.NUMBER_50], (data) => (MaterialGruppeKeyMapping as any)[data]),
      label: 'Material-Gruppe',
    },
    {
      type: 'simple',
      field: 'bezeichnung_kurz',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung (kurz)',
    },
    {
      type: 'simple',
      field: 'bezeichnung_lang',
      renderer: makeTextEditorRenderer(),
      label: 'Bezeichnung (lang)',
    },
    {
      type: 'simple',
      field: 'breite_mm',
      renderer: makeStammdatenEntityRelatedDisabledEditor(makeNumberEditorRenderer(), (data: VerpackungsEinheit) => data.material_gruppe === MaterialGruppe.NUMBER_50),
      label: 'Breite [mm]',
    },
    {
      type: 'simple',
      field: 'laenge_mm',
      renderer: makeStammdatenEntityRelatedDisabledEditor(makeNumberEditorRenderer(), (data: VerpackungsEinheit) => data.material_gruppe === MaterialGruppe.NUMBER_50),
      label: 'Länge [mm]',
    },
    {
      type: 'simple',
      field: 'durchmesser_mm',
      renderer: makeNumberEditorRenderer(),
      label: 'Höhe / Durchmesser [mm]',
    },
    {
      type: 'simple',
      field: 'pro_lage_bei_euro_palette',
      renderer: makeTextEditorRenderer(),
      label: 'VPE pro Lage bei Europalette',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          colId: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
        },
        {
          headerName: 'Material-Gruppe',
          field: 'material_gruppe',
          colId: 'material_gruppe',
          filter: 'agSetColumnFilter',
          valueGetter: (value) => (MaterialGruppeKeyMapping as any)[value.data.material_gruppe],
        },
        {
          headerName: 'Bezeichnung (kurz)',
          field: 'bezeichnung_kurz',
          colId: 'bezeichnung_kurz',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Bezeichnung (lang)',
          field: 'bezeichnung_lang',
          colId: 'bezeichnung_lang',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Breite [mm]',
          field: 'breite_mm',
          colId: 'breite_mm',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Länge [mm]',
          field: 'laenge_mm',
          colId: 'laenge_mm',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Höhe / Durchmesser [mm]',
          field: 'durchmesser_mm',
          colId: 'durchmesser_mm',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'VPE pro Lage bei Europalette',
          field: 'pro_lage_bei_euro_palette',
          colId: 'pro_lage_bei_euro_palette',
          filter: 'agTextColumnFilter',
        },
      ]}
      stammdatenService={{
        create: (data: CreateVerpackungsEinheit) => props.services.verpackungs_einheit.postVerpackungsEinheitPost({ CreateVerpackungsEinheit: data }),
        update: (data) => props.services.verpackungs_einheit.putByDatabaseIdVerpackungsEinheitDatabaseIdPut({ database_id: data.database_id, UpdateVerpackungsEinheit: data }),
        delete: (data, ignoreIntegrity) => props.services.verpackungs_einheit.deleteByDatabaseIdVerpackungsEinheitDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.verpackungs_einheit.restoreByDatabaseIdVerpackungsEinheitDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.verpackungs_einheit.getAllVerpackungsEinheitGet({ include_deleted }),
        getHistory: (data: VerpackungsEinheit) => props.services.verpackungs_einheit.getHistoryVerpackungsEinheitDatabaseIdHistoryGet({ database_id: data.database_id }),
        export: () => props.services.verpackungs_einheit.downloadExcelVerpackungsEinheitExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Verpackungseinheit}
    />
  );
}
