"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RueckstellMessprotokollEntryToJSON = exports.RueckstellMessprotokollEntryFromJSONTyped = exports.RueckstellMessprotokollEntryFromJSON = void 0;
function RueckstellMessprotokollEntryFromJSON(json) {
    return RueckstellMessprotokollEntryFromJSONTyped(json, false);
}
exports.RueckstellMessprotokollEntryFromJSON = RueckstellMessprotokollEntryFromJSON;
function RueckstellMessprotokollEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blasmaschine_nummer': json['blasmaschine_nummer'],
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'halsteil_bezeichnung': json['halsteil_bezeichnung'],
        'granulat_farbe_nummer': json['granulat_farbe_nummer'],
        'artikel_gewicht': json['artikel_gewicht'],
        'material_legende_index': json['material_legende_index'],
        'toleranz': json['toleranz'],
    };
}
exports.RueckstellMessprotokollEntryFromJSONTyped = RueckstellMessprotokollEntryFromJSONTyped;
function RueckstellMessprotokollEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blasmaschine_nummer': value.blasmaschine_nummer,
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'halsteil_bezeichnung': value.halsteil_bezeichnung,
        'granulat_farbe_nummer': value.granulat_farbe_nummer,
        'artikel_gewicht': value.artikel_gewicht,
        'material_legende_index': value.material_legende_index,
        'toleranz': value.toleranz,
    };
}
exports.RueckstellMessprotokollEntryToJSON = RueckstellMessprotokollEntryToJSON;
