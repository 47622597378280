"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BegleitdokumentDatenToJSON = exports.BegleitdokumentDatenFromJSONTyped = exports.BegleitdokumentDatenFromJSON = void 0;
const runtime_1 = require("../runtime");
function BegleitdokumentDatenFromJSON(json) {
    return BegleitdokumentDatenFromJSONTyped(json, false);
}
exports.BegleitdokumentDatenFromJSON = BegleitdokumentDatenFromJSON;
function BegleitdokumentDatenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'produktionsauftrag_nummer': json['produktionsauftrag_nummer'],
        'kunde_name': json['kunde_name'],
        'empfaenger_name': !runtime_1.exists(json, 'empfaenger_name') ? undefined : json['empfaenger_name'],
        'liefertermin': !runtime_1.exists(json, 'liefertermin') ? undefined : (new Date(json['liefertermin'])),
        'flaschen_typ_bezeichnung': json['flaschen_typ_bezeichnung'],
        'mit_blindenzeichen': json['mit_blindenzeichen'],
        'farbe_bezeichnung': json['farbe_bezeichnung'],
        'artikel_gewicht': json['artikel_gewicht'],
        'flaschen_typ_nummer': json['flaschen_typ_nummer'],
    };
}
exports.BegleitdokumentDatenFromJSONTyped = BegleitdokumentDatenFromJSONTyped;
function BegleitdokumentDatenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'produktionsauftrag_nummer': value.produktionsauftrag_nummer,
        'kunde_name': value.kunde_name,
        'empfaenger_name': value.empfaenger_name,
        'liefertermin': value.liefertermin === undefined ? undefined : (value.liefertermin.toISOString()),
        'flaschen_typ_bezeichnung': value.flaschen_typ_bezeichnung,
        'mit_blindenzeichen': value.mit_blindenzeichen,
        'farbe_bezeichnung': value.farbe_bezeichnung,
        'artikel_gewicht': value.artikel_gewicht,
        'flaschen_typ_nummer': value.flaschen_typ_nummer,
    };
}
exports.BegleitdokumentDatenToJSON = BegleitdokumentDatenToJSON;
