import { CreateWerkstoffMischung } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps, makeNumberEditorRenderer } from '../../infrastructure';

export function makeAnteilNumberEditor() {
  return (props: StammdatenFieldEditorComplexRendererProps<CreateWerkstoffMischung>) => {
    const modifiedProps = {
      ...props,
      onChange: (newValue: number | null) => {
        props.onChange({
          anteil_1: newValue ?? undefined,
          anteil_2: newValue && props.stammdatenEntity.werkstoff_2_database_id ? 100 - newValue : undefined,
        });
      },
      value: props.stammdatenEntity.anteil_1,
      disabled: props.stammdatenEntity.werkstoff_2_database_id === undefined || props.disabled,
    };

    return makeNumberEditorRenderer({ min: 1, max: 99 })(modifiedProps);
  };
}
