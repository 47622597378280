"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReducedFirmaToJSON = exports.ReducedFirmaFromJSONTyped = exports.ReducedFirmaFromJSON = void 0;
const _1 = require("./");
function ReducedFirmaFromJSON(json) {
    return ReducedFirmaFromJSONTyped(json, false);
}
exports.ReducedFirmaFromJSON = ReducedFirmaFromJSON;
function ReducedFirmaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'database_id': json['database_id'],
        'version_id': json['version_id'],
        'name_kurz': json['name_kurz'],
        'nummer': json['nummer'],
        'deletion_state': _1.DeletionStateFromJSON(json['deletion_state']),
    };
}
exports.ReducedFirmaFromJSONTyped = ReducedFirmaFromJSONTyped;
function ReducedFirmaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'database_id': value.database_id,
        'version_id': value.version_id,
        'name_kurz': value.name_kurz,
        'nummer': value.nummer,
        'deletion_state': _1.DeletionStateToJSON(value.deletion_state),
    };
}
exports.ReducedFirmaToJSON = ReducedFirmaToJSON;
