import { Dialog, H2 } from '@blueprintjs/core';
import React from 'react';
import { MaschinenlaufplanErstellenModalResult, MaschinenlaufplanErstellenModalResultFromJSON, MaschinenlaufplanErstellenModalResultToJSON } from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  ModalProps,
  TextEditor,
  getPayloadOrBpmnValidationErrorFromToken,
} from '../../../infrastructure';

export function MLPDatenErfassenDialog(props: CustomFormProps<MaschinenlaufplanErstellenModalResult> & ModalProps<void>): JSX.Element {
  const [
    ,
    previousValues,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, MaschinenlaufplanErstellenModalResultFromJSON);

  const [bemerkung, setBemerkung] = React.useState<string | null>(previousValues.bemerkung ?? null);

  const abort = async (): Promise<void> => {
    await props.finishUserTask({ response: 'abort' });
    props.onModalFinished();
  };

  const finish = async (): Promise<void> => {
    return props.finishUserTask(MaschinenlaufplanErstellenModalResultToJSON({
      bemerkung,
    } as any));
  };

  return (
    <Dialog
      isOpen
      onClose={abort}
      title='Papiere drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Maschinenlaufplandetails angeben</H2>
        <DialogFormField fieldLabel='Bemerkung' fieldLocation={['bemerkung']}>
          <TextEditor value={bemerkung}
            onChange={(data) => {
              setBemerkung(data);
            }}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={abort}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
